import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { encode } from '../utils';
import copy from 'copy-to-clipboard';

import SectionHeader from '../components/SectionHeader';
import Switch from '../components/Switch';
import Toggle from '../components/Toggle';
import Tooltip from '../components/Tooltip';

import * as FlashNotificationActions from '../actions/FlashNotifications';
import * as AccountActions from '../actions/Accounts';

import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import jsx from 'react-syntax-highlighter/dist/languages/prism/jsx';
import style from 'react-syntax-highlighter/dist/styles/prism/tomorrow'; 
SyntaxHighlighter.registerLanguage('jsx', jsx);

class CopySection extends Component {
  render() {
    let code = this.props.code;

    return (
      <div className={`embed-code card`} onClick={e => {
        copy(code);
        this.props.flash('The code has been copied to your clipboard.');
      }}>
        <SyntaxHighlighter 
          wrapLines={true} 
          lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
          language='javascript'
          style={style}
          customStyle={{padding:25, fontSize: 14, borderRadius: 10, overflow: 'hidden'}}>{code}</SyntaxHighlighter>
      </div>
    );
  }
}

class Tip extends Component {
  render() {
    return (
      <div className="info-code" style={{ display: this.props.active ? 'block' : 'none' }}>
        <div className="card info wide" style={{ marginTop: 20, maxWidth: 550 }}>
          <div className="top">{ this.props.title }</div>
          <p>{ this.props.content }</p>
        </div>
        <CopySection code={this.props.code} flash={this.props.flash} />
      </div>
    );
  }
}

class JSSnippets extends Component {
  constructor(props) {
    super(props);

    this.tips = [{
      title: 'How To: Show this survey on click',
      content: <span>
        <strong>Step 1.</strong> Copy the code below.<br/>
        <strong>Step 2.</strong> Connect the <strong>onClick</strong> function it to your button's click handler.
      </span>,
      code: `function onClick() {
  window.Zigpoll.pollId = "${this.props.poll._id}";
  window.Zigpoll.onload = function () {
    window.Zigpoll.open();
  }
  window.Zigpoll.reload();
}`
    },
    {
      title: 'How To: Load this survey programatically',
      content: <span>
        <strong>Step 1.</strong> Copy your <a href={`/a/${encode(this.props.account._id)}?tab=embed`} target="_blank">embed code</a> and paste it on your webpage if you haven't done so already.<br/>
        <strong>Step 2.</strong> Copy the code below and paste it below your embed code.
      </span>,
      code: `window.Zigpoll.pollId = "${this.props.poll._id}";
window.Zigpoll.onload = function () {
  window.Zigpoll.open();
}
window.Zigpoll.reload();`
    }

    ];
    this.state = { idx: 0 };
  }
  next() {
    let idx = this.state.idx;
    idx = idx + 1;
    if (idx > (this.tips.length - 1)) {
      idx = 0;
    }

    this.setState({ idx })
  }
  prev() {
    let idx = this.state.idx;
    idx = idx - 1;
    if (idx < 0) {
      idx = this.tips.length - 1;
    }

    this.setState({ idx })    
  }
  render() {
    return (
      <span>
        <SectionHeader
          title=<span><i className="fas fa-code" />JS Code Snippets</span>
          subtitle="Helpful code snippets for triggering your survey."
          tooltip="Use these snippets to load your survey programatically. If you need help, please reach out and we are happy to assist you."
          className={'no-margin'}
        />

        <div className="tips-container">
        <div className="next" onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          this.next()
        }}><i className="fas fa-chevron-right" /></div>
        { this.tips.map((tip, idx) => {
          return (
            <Tip {...this.props} active={idx === this.state.idx} {...tip} />
          );
        } )}
        <div className="prev" onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          this.prev()
        }}><i className="fas fa-chevron-left" /></div>
        </div>
      </span>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...FlashNotificationActions }, dispatch);
}

export default connect(null, mapDispatchToProps)(JSSnippets);
