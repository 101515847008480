import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import * as FlashNotificationsActions from '../actions/FlashNotifications';

class FlashNotifications extends Component {
  render() {
    return (
      <div
        id="flash-notifications"
        className={this.props.notifications.length ? 'active' : ''}
      >
        <TransitionGroup className="notifications">
        { this.props.notifications.map((notification, idx) => {
          return (<CSSTransition
                key={notification.key}
                timeout={300}
                classNames="ease"
              ><div onClick={this.props.popNotification} className={`notification ${notification.type}`}>
            { notification.message.toString() }
          </div></CSSTransition>)
        })}
        </TransitionGroup>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    notifications: state.flashNotifications
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(FlashNotificationsActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FlashNotifications);

