import { applyMiddleware, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';

import history from './history';

import thunk from 'redux-thunk';

import rootReducer from '../reducers';

const logger = createLogger({
  level: 'info',
  collapsed: true,
  // diff: true
});

const router = routerMiddleware(history);

let store;

if (process.env.NODE_ENV === 'production') {
  store = createStore(
    connectRouter(history)(rootReducer),
    undefined,
    compose(
      applyMiddleware(thunk, router)
    )
  );
} else {
  store = createStore(
    connectRouter(history)(rootReducer),
    undefined,
    compose(
      applyMiddleware(thunk, router, logger)
    )
  );
}

export default store;