import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as AccountActions from '../actions/Accounts';
import * as FlashNotificationActions from '../actions/FlashNotifications';

import { getRecipients } from '../utils';
import { NameInput, DomainInput, DomainsInput, RecipientsInput } from './SimpleInputs';
import { AuthSubmitButton } from './Buttons';

import copy from 'copy-to-clipboard';

import Toggle from './Toggle';
import Tooltip from './Tooltip';
import Confirm from './Confirm';
import AuthForm from './AuthForm';
import HeaderButtons from './HeaderButtons';
import SectionHeader from './SectionHeader';

import Switch from './Switch';

class AccountEditForm extends AuthForm {
  constructor(props)  {
    super(props);

    let customSenderDomain;
    if (this.props.account.customSenderDomain) {
      customSenderDomain = this.props.account.customSenderDomain.name;
    }

    let customShareDomain;
    if (this.props.account.customShareDomain) {
      if (typeof this.props.account.customShareDomain === 'string') {
        customShareDomain = this.props.account.customShareDomain;
        this.props.account.customShareDomain = {
          name: customShareDomain,
          state: 'complete'
        }
      } else {
        customShareDomain = this.props.account.customShareDomain.name;
      }
    }

    this.state = { 
      inputs: {
        title: this.props.account.title,
        domains: this.props.account.domains,
        recipients: this.props.account.recipients,
        customShareDomain,
        notificationLimitPercentage: this.props.account.notificationLimitPercentage,
        delimiter: this.props.account.delimiter || ',',
        exportType: this.props.account.exportType || 'attachment',
        disableWeeklyNotifications: this.props.account.disableWeeklyNotifications
      },
      showCustomShareDomainInput: this.props.account.customShareDomain,
      showCustomSenderDomainInput: this.props.account.customSenderDomain,
      customSenderDomain,
      customShareDomain,
      valid: undefined
    };
    this.inputStates = {};
    this.setInitialState();
  }

  copyToClipboard(value, message) {
    copy(value);
    this.props.flash(message);
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.account._id !== this.props.account._id) || (this.props.loading !== prevProps.loading && !this.props.loading)) {
      this.setState({ 
        inputs: {
          title: this.props.account.title,
          domains: this.props.account.domains,
          delimiter: this.props.account.delimiter,
          exportType: this.props.account.exportType,
          notificationLimitPercentage: this.props.account.notificationLimitPercentage,
          recipients: this.props.account.recipients,
          disableWeeklyNotifications: this.props.account.disableWeeklyNotifications
        },
        valid: undefined
      })
      this.inputStates = {};
      this.setInitialState();
    }
  }

  submitAction() {
    // if ((this.state.inputs.customShareDomain && !this.props.account.customShareDomain)) {
    //   this.setState({ showConfirmModal: true });
    //   return;
    // }
    this.submit();
  }

  submit() {
    const account = {};

    account.title = this.state.inputs.title;
    account.domains = this.state.inputs.domains;
    account.recipients = this.state.inputs.recipients;
    // account.customShareDomain = this.state.inputs.customShareDomain;
    account.delimiter = this.state.inputs.delimiter;
    account.exportType = this.state.inputs.exportType;
    account.notificationLimitPercentage = this.state.inputs.notificationLimitPercentage;
    account.disableWeeklyNotifications = this.state.inputs.disableWeeklyNotifications;
    account._id = this.props.account._id;

    this.props.updateAccount(account);
  }

  renderInputs() {
    let customShareDomainInput = null;
    if (this.state.showCustomShareDomainInput) {
      const customShareDomain = this.props.account.customShareDomain || {};
      // let status = this.props.account.customShareDomainValidation;

      const name = customShareDomain.name || '';
      const subdomain = name.split('.').shift();
      const status = customShareDomain.state || 'unknown';
      const loading = this.props.loadingDomain;

      let domainButton = (
        <button className={`create-domain ${loading ? 'loading' : ''}`} onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          this.props.createCustomShareDomain(this.state.customShareDomain);
        }}><span>Save Domain</span></button>
      );

      let step2Input = <div>
        <div className="step"><span>2.</span>Add the following DNS records.<Tooltip>Add the CNAME listed below to connect your subdomain to Zigpoll. To learn more about this, <a href="https://www.zigpoll.com/blog/custom-survey-domain" target="_blank">click here</a>.</Tooltip></div>
        <div className="sender-domain-validations">
          <div>
            <div className="header">
              <div className="type"><strong>Type:</strong>CNAME</div>
            </div>
            <div className="records">
              <div className="key"><strong>Name:</strong><span onClick={() => {
                this.copyToClipboard(subdomain, 'The record name has been copied to your clipboard.')
              }}>{subdomain} <i className="fas fa-copy"/></span></div>
              <div className="value"><strong>Value:</strong><span onClick={() => {
                this.copyToClipboard('zigpoll.pages.dev ', 'The record value has been copied to your clipboard.')
              }}>zigpoll.pages.dev <i className="fas fa-copy" /></span></div>
            </div>
          </div>
        </div>
      </div>

      let step3Input = <div>
        <div className="step"><span>3.</span>Let us know the CNAME record has been added <Tooltip><a href="https://www.zigpoll.com/blog/custom-survey-domain" target="_blank">Click here</a> to learn more about the process and please contact us if you have any questions.</Tooltip></div>
        { status === 'unknown' && <div className="verify-action left">
          <button className={`verify-domain`} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.props.verifyCustomShareDomain(this.state.customShareDomain);
            this.submit();
          }}><span>Notify Us</span></button>
        </div> }
        { status === 'pending' && <div>
          <p className="info">Thank you for notifying us! We will approve and link your domain to Zigpoll within 24 hours. Please check back here to confirm it has been approved. If you have any questions about the process, <a href="https://www.zigpoll.com/contact" target="_blank">please get in touch</a>.</p>
        </div>}
        { status === 'complete' && <div><p className="info">Your domain has been approved! You can now view your surveys on your custom domain.</p></div> }
      </div>

      if (name) {
        domainButton = null;
      } else {
        step2Input = null;
        step3Input = null;
      }

      customShareDomainInput = (
      <div className="custom-domain-input">
        <div>
          <div className="step"><span>1.</span>Enter your preferred survey domain name.<Tooltip>Enter the domain where you want to host your surveys. For example survey.yourwebsite.com</Tooltip></div>
          <div className="custom-share-domain-input"><DomainInput
            onChange={(e) => {
              this.setState({ customShareDomain: e.target.value });
            }}
            onValidate={this.onValidate.bind(this)}
            label={null}
            type="text"
            placeholder="Title for the account"
            name="customShareDomain"
            value={this.state.customShareDomain}
            errorMessage="Please a valid domain name."
            ref={this.setRef.bind(this)}
            placeholder={'survey.mywebsite.com'}
            disabled={name ? true : false}
          /></div>
          { domainButton }
        </div>

        { step2Input }
        { step3Input }
      </div>
      );
    }

    let customSenderDomainInput = null;
    if (this.state.showCustomSenderDomainInput) {
      const customSenderDomain = this.props.account.customSenderDomain || {};
      const sending_dns_records = customSenderDomain.sending_dns_records || [];
      const name = customSenderDomain.name;
      const status = customSenderDomain.state || 'unverified';
      const approved = customSenderDomain.apiKey;
      const loading = this.props.loadingDomain;

      let domainButton = (
        <button className={`create-domain ${loading ? 'loading' : ''}`} onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          this.props.createCustomSenderDomain(this.state.customSenderDomain);
        }}><span>Save Domain</span></button>
      );

      if (name) {
        domainButton = null;
      }

      let step2Input = null;
      if (name) {
        step2Input  = <div>
          <div className="step"><span>2.</span>Add the following text records <Tooltip>You can learn some more about adding text records to your DNS settings <a href="https://www.zigpoll.com/blog/adding-dns-records" target="_blank">here</a>.</Tooltip></div>
          <div className="sender-domain-validations">
            { [...sending_dns_records].reverse().map((record) => {
              const status = record.valid === 'unknown' ? 'unverified' : record.valid;
              if (record.record_type === 'CNAME') { return null; }

              return (<div>
                <div className="header">
                  <div className="type"><strong>Type:</strong>{ record.record_type }</div>
                  <div className={`status ${status}`}>{ status }</div>
                </div>
                <div className="records">
                  <div className="key"><strong>Name:</strong><span onClick={() => {
                    this.copyToClipboard(record.name, 'The record name has been copied to your clipboard.')
                  }}>{ record.name } <i className="fas fa-copy"/></span></div>
                  <div className="value"><strong>Value:</strong><span onClick={() => {
                    this.copyToClipboard(record.value, 'The record value has been copied to your clipboard.')
                  }}>{ record.value } <i className="fas fa-copy" /></span></div>
                </div>
              </div>)
            })}


            { status !== 'active' && <div className="verify-action">
              <button className={`verify-domain ${loading ? 'loading' : ''}`} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.verifyCustomSenderDomain(this.state.customSenderDomain);
              }}><span>Verify Domain</span></button>
            </div> }
          </div>
        </div>
      }

      let step3Input = null;
      if (status === 'active') {
        step3Input = <div>
          <div className="step"><span>3.</span> Manual Approval</div>
          { !approved && <p className="info">We need to review your account to finalize the sender domain. We will update your account within 24 hours. The dashboard will automatically update when you are approved so please check back. If you have any questions at this stage, please <a href="https://www.zigpoll.com/contact" target="_blank">contact us</a> with any questions.</p> }
          <div className="manual-approval">
            <div className={`status ${approved ? 'valid' : ''}`}>{ approved ? 'Approved' : 'Pending Approval' }</div>
          </div>
        </div>
      }

      customSenderDomainInput = (<div className="custom-sender-domain-input">
        <div className="step"><span>1.</span>Add your preferred sending domain <Tooltip>Your sending domain should be something like mg.yourdomain.com.</Tooltip></div>
        <DomainInput
          onChange={(e) => {
            this.setState({ customSenderDomain: e.target.value });
          }}
          onValidate={this.onValidate.bind(this)}
          label={null}
          type="text"
          placeholder="mg.mywebsite.com"
          name="customSenderDomain"
          value={this.state.customSenderDomain}
          errorMessage="Please a valid domain name."
          ref={this.setRef.bind(this)}
          disabled={name ? true : false}
        />
        { domainButton }
        { step2Input }
        { step3Input }

      </div>);
    }

    let inputVal = this.state.inputs.notificationLimitPercentage;
    if (this.state.inputs.notificationLimitPercentage === undefined) {
      inputVal = 85;
    }
    if (this.state.inputs.notificationLimitPercentage === false) {
      inputVal = '';
    }

    console.log('hello');
    console.log(this.props);

    return (
    <div>
      <div className="inputs card" style={{marginBottom: 25}}>
        <NameInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Account Title"
          moreInfo="This title will be used for your reference so pick something simple that you can easily identify."
          type="text"
          maxlength={40}
          placeholder="Title for the account"
          name="title"
          value={this.state.inputs.title}
          errorMessage="Please a title for the account."
          ref={this.setRef.bind(this)}
        />

        <div className="input" style={{ marginTop: 20, marginBottom: 20, position: 'relative' }}>
          <label>Custom Survey Domain<Tooltip>If you are willing to add a CNAME can use your own domain for your public survey links <a href="https://www.zigpoll.com/blog/custom-survey-domain" target="_blank">click here</a> to learn more.</Tooltip></label>
          <div>
            <div className="inline-toggle">
              <Toggle
                active={this.state.showCustomShareDomainInput}
                onChange={(value) => {
                  if (!value && this.props.account.customShareDomain) {
                    this.setState({ showConfirmModal: true });
                  } else {
                    this.setState({ showCustomShareDomainInput: value });
                  }
                }}
              />
              <div className="subtle">Use a custom domain for public survey links.</div>
            </div>

            { customShareDomainInput }
          </div>
        </div>

        <div className="input" style={{ marginTop: 20, marginBottom: 20, position: 'relative' }}>
          <label>Custom Sender Domain<Tooltip>If you are willing to add a CNAME can use your own domain for your outbound emails <a href="https://www.zigpoll.com/blog/custom-sender-domain" target="_blank">click here</a> to learn more.</Tooltip></label>
          <div>
            <div className="inline-toggle">
              <Toggle
                active={this.state.showCustomSenderDomainInput}
                onChange={(value) => {
                  if (!value && this.props.account.customSenderDomain) {
                    this.setState({ showRemoveSenderDomainModal: true });
                  } else {
                    this.setState({ showCustomSenderDomainInput: value });
                  }
                }}
              />
              <div className="subtle">Use a custom domain for outbound survey emails.</div>
            </div>

            { customSenderDomainInput }
          </div>
        </div>

        <DomainsInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Domain Restrictions"
          noMargin={true}
          moreInfo={<span>Fill out this section if you want to ensure that the survey only shows up on certain domains. If you're not worried about who can display your survey select <strong>allow on all domains</strong>.</span>}
          type="text"
          placeholder="domain.com"
          name="domains"
          value={this.state.inputs.domains}
          errorMessage="Please enter a valid domain name."
          ref={this.setRef.bind(this)}
        />
      </div>

      <SectionHeader
        title=<span><i class="fas fa-envelope-open-text"></i>Email Notifications</span>
        subtitle="Control where all survey notifications get sent."
        tooltip="By default, all notifications are sent to the account owner."
        className="no-margin"
      />

      <div className="inputs card" style={{ marginBottom: 25 }}>
        <RecipientsInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Notification Recipients"
          moreInfo={<span>By default, notifications will be sent to the account owner. If you would like to send notifications to someone else or more than one person please update the list below.</span>}
          placeholder="email@domain.com"
          name="recipients"
          value={ getRecipients(this.state.inputs.recipients, this.props.account) }
          isAccount={true}
          toggleCopy="Send to the account owner only."
          errorMessage="Please enter a valid domain name."
          ref={this.setRef.bind(this)}
        />

        <div className="input" style={{ marginTop: 25 }}>
          <label>Send Weekly Recaps<Tooltip>You will automatically receieve a weekly email that includes relevant Zigpoll statistics for each week. The email is sent every Monday at 7AM EST.</Tooltip></label>
          <div className="inline-toggle">
            <Toggle
              active={!this.state.inputs.disableWeeklyNotifications}
              onChange={(value) => {
                this.onChange({ target: { name: 'disableWeeklyNotifications', value: !value } });
              }}
            />
            <div className="subtle">Send weekly updates to the recipients listed above.</div>
          </div>
        </div>

        <div style={{ position: 'relative' }} className={`input has-dynamic-input`}>
          <label>Submission Limit Notification<Tooltip>Control when you receive a warning notice about your submission limit for the month. You will always be notified via email when you hit or exceed your limit.</Tooltip></label>

          <div className="inline-toggle">
            <Switch
                value={ this.state.inputs.notificationLimitPercentage === false ? false : true }
                vertical={true}
                options={[
                  { label: 'Do not notify until I reach my limit.', value: false 
                  },
                  { 
                    inlineInput: true,
                    name: "notificationLimitPercentage",
                    value: true,
                    inputValue: inputVal,
                    prefix: "Notify when I have reached ",
                    suffix: "% of my monthly limit."
                   },
                ]}
                onChange={(value) => {
                  let val = value;
                  if (value === true) {
                    val = this.state.inputs.notificationLimitPercentage || 85;
                  }
                  this.onChange({ target: { name: 'notificationLimitPercentage', value: val } });
                }}
              />
          </div>
        </div>

        <Confirm 
          title="Are you sure?"
          subtitle={<span>This will remove your existing custom domain from our records. You will need to redo the DNS steps it if you want to enable this feature later on.</span>}
          show={this.state.showConfirmModal}
          onConfirm={() => {
            this.props.removeCustomShareDomain();

            const inputs = { ...this.state.inputs };
            inputs.customSenderDomain = '';
            this.setState({ showCustomShareDomainInput: false, showConfirmModal: false, inputs }, () => {
              this.setInitialState();
            });        
          }}
          onCancel={() => {
            this.setState({ showConfirmModal: false });
          }}
        />

        <Confirm 
          title="Are you sure?"
          subtitle={<span>This will remove your existing sender domain from our records. You will need to re-authorize it if you want to enable this feature later on.</span>}
          show={this.state.showRemoveSenderDomainModal}
          onConfirm={() => {
            this.props.removeCustomSenderDomain();

            const inputs = { ...this.state.inputs };
            inputs.customSenderDomain = '';
            this.setState({ showCustomSenderDomainInput: false, showRemoveSenderDomainModal: false, inputs }, () => {
              this.setInitialState();
            });
          }}
          onCancel={() => {
            this.setState({ showRemoveSenderDomainModal: false });
          }}
        />
      </div>

      <SectionHeader
        title=<span><i class="fas fa-file-export"></i>Export Settings</span>
        subtitle="Manage your export preferences."
        className="no-margin"
      />

      <div className="inputs card" style={{ marginBottom: 25 }}>
        <div className="input" style={{ marginTop: 0, marginBottom: 25, position: 'relative' }}>
          <label>Export Type<Tooltip>By default, we send exports as email attatchments. If yours are larger than 20MB you should use links instead.</Tooltip></label>
          <Switch
              value={ this.state.inputs.exportType }
              options={[
                { label: 'Include as attachment', value: 'attachment' },
                { label: 'Send a download link', value: 'link' },
              ]}
              onChange={(value) => {
                this.onChange({ target: { name: 'exportType', value: value } });
              }}
            />
        </div>

        <div className="input" style={{ marginTop: 0, marginBottom: 0, position: 'relative' }}>
          <label>Delimiter<Tooltip>By default, we use a comma to seperate values in our form and multi-choice response values. If you would like to use something different for your CSV exports, enter the value below.</Tooltip></label>
          <div>
            <div className="delimiter-input">Use a <input
              onChange={this.onChange.bind(this)}
              type="text"
              value={this.state.inputs.delimiter}
              placeholder=""
              name="delimiter"
            /> character to seperate cells with multiple values.</div>
          </div>
        </div>

      </div>
    </div>
    );
  }

  renderActions() {
    return (
      <div className="actions card">
        <AuthSubmitButton 
          title="Update Account"
          disabled={!this.hasChanged()}
        />

        <div className="login">
          <div>Need help understanding accounts?</div>
          <a href="https://docs.zigpoll.com/accounts" target="_blank" rel="noopener noreferrer">Learn more</a>
        </div>

        <HeaderButtons>
          <button 
            disabled={!this.hasChanged()}
            onClick={this.onSubmit.bind(this)}
          >Update Account</button>
        </HeaderButtons>
      </div>
    )
  }

  render() {
    return (
      <form onSubmit={this.onSubmit.bind(this)} className={`${this.state.valid === false ? 'invalid' : ''} ${this.state.loading || this.props.loading ? 'loading' : ''} ${this.props.className} account-edit-form`}>
          { this.renderInputs() }
          { this.renderActions() }
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.accounts.loading,
    loadingDomain: state.accounts.loadingDomain
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions, ...FlashNotificationActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountEditForm);

