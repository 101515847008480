import { 
  FETCHING_RESPONSES,
  FETCHED_RESPONSES,
  FETCHING_RESPONSE,
  FETCHED_RESPONSE,
  APPROVE_RESPONSE,
  UPDATE_RESPONSE_RANK
} from '../actions/Responses';

import _ from 'lodash';

export default function responses(state = {}, action) {
  switch (action.type) {
    case FETCHING_RESPONSES: {
      return { ...state, loading: true }
    }
    case FETCHED_RESPONSES: {
      responses = {};
      responses[action.slideId] = action.responses;
      return { ...state, ...responses, loading: false }
    }
    case FETCHING_RESPONSE: {
      if (!state[action.slideId] || !state[action.slideId].data) { return state; }

      let data = [ ...state[action.slideId].data ];
      let idx = _.findIndex(data, ({ _id }) => _id === action.eventId);
      data[idx].loading = true
      state[action.slideId] = { ...state[action.slideId], ...{ data } };

      return { ...state }
    }
    case FETCHED_RESPONSE: {
      if (!state[action.slideId] || !state[action.slideId].data) { return state; }

      let data = [ ...state[action.slideId].data ];
      let idx = _.findIndex(data, ({ _id }) => _id === action.eventId);
      data[idx].response = action.response;
      data[idx].loading = false;

      state[action.slideId] = { ...state[action.slideId], ...{ data } };

      return { ...state }
    } case APPROVE_RESPONSE: {
      if (!state[action.slideId] || !state[action.slideId].data) { return state; }

      let data = [ ...state[action.slideId].data ];
      let idx = _.findIndex(data, ({ _id }) => _id === action.responseId);;
      data[idx].response.approved = action.approved;
      state[action.slideId] = { ...state[action.slideId], ...{ data } };

      return { ...state }
    }
  case UPDATE_RESPONSE_RANK: {
      if (!state[action.slideId] || !state[action.slideId].data) { return state; }

      let data = [ ...state[action.slideId].data ];
      let idx = _.findIndex(data, ({ _id }) => _id === action.responseId);
      data[idx].response.rank = action.rank;
      state[action.slideId] = { ...state[action.slideId], ...{ data } };

      return { ...state }
    }
    default:
      return state;
  }
}
