import React, { Component } from 'react';
import { Portal } from 'react-portal';

export default class LoadingIndicatorPortal extends Component {
  constructor(props) {
    super(props);
    this.state = { firstRender: true }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading && !this.props.loading) {
      // this.setState({ copy: <i className="fas fa-check" /> });
    }
  }
  render() {
    if (this.state.firstRender) {
      this.setState({ firstRender: false });
      setTimeout(this.forceUpdate.bind(this), 0);
    }

    return <Portal node={document && document.getElementById('loading-indicator')}>
      <div className={`loading ${this.props.loading ? 'active' : ''}`} >{this.state.copy}</div>
    </Portal>
  }
}