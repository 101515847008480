import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { url } from '../settings';

import _ from 'lodash';
import qs from 'querystring';
import moment from 'moment';

import * as AccountActions from '../actions/Accounts';
import * as ChartActions from '../actions/Charts';

import * as FlashNotificationActions from '../actions/FlashNotifications';

import ColumnLayout from '../components/ColumnLayout';
import ColumnLoading from './ColumnLoading';

import { PieChart, DonutChart, ColumnChart, NPSChart } from '../components/Charts';

import SectionHeader from '../components/SectionHeader';
import LoadingIndicator from '../components/LoadingIndicator';
import DateRangeInput from '../components/DateRangeInput';

import { decode, getPoll, stripTags, getSlideIcon, getSlideLabel, numberWithCommas, renderNumber, getTimestamp, truncate, getDateAsTimestamp, encode, sortChartDataByAnswers, capitalize, handleize } from '../utils';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'

function getAnswerIdentifier(title = '', handle = '') {
  // if (handleize(title) === handle) { return title; }
  return handle;
}

class TimeSeriesChart extends Component {
  constructor(props) {
    super(props);

    const options = {
      chart: {
        styledMode: true,
        height: 300,
        type: 'spline',
        reflow: false
      },
      time: {
        useUTC: false,
      },
      tooltip: {
        useHTML: true,
        borderRadius: 10,
        shared: true,
        formatter: function (val) {
          let hasSubmissions = false;
          return `<div class="tooltip-wrapper">
            <div class="tooltip-header-large">${moment(this.x).format('MM/DD/YYYY')}</div>
            <div class="tooltip-content">
              ${this.points.map((point, idx) => {
                if (!point.y) { return; }
                const series = val.chart.series.filter(({ visible }) => visible);
                hasSubmissions = true;
                return `<div><b>${truncate(series[idx].name, 25)}</b>: ${renderNumber(point.y)}${props.isTrendChart ? '%' : ''}</div>`;
              }).join('')}
              ${!hasSubmissions ? "<div class='tooltip-content'>No submissions</div>" : ''}
            </div>
          </div>`;
        }
      },
      plotOptions: {
        series: {
          animation: false,
          marker: {
            symbol: 'circle',
            radius: 4,
            lineWidth: 1,
            lineColor: '#ffffff',
            enabled: true,
          },
        }
      },
      yAxis: {
        min: 0,
        title: { text: props.isTrendChart ? 'Percent' : 'Responses' },
        minRange: props.isTrendChart ? undefined : 10,
        endOnTick: false,
        // max: props.isTrendChart ? 100 : undefined,
        labels: {
          formatter: function () {
            return renderNumber(this.value)
          }
        }
      },
      xAxis: {
        type: 'datetime',
        // categories: props.categories,
        crosshair: true,
        format: '{value:%H:%M}',
      },
      legend: {
        navigation: {
          enabled: false
        },
        // floating: true,
        labelFormatter: function () {
          return truncate(this.name, 25);
        }
      },
      series: props.series
    };

    this.state = { options };
  }

  shouldComponentUpdate(nextProps) {
    if (!_.isEqual(nextProps.series, this.props.series)) {
      const state = { ...this.state };

      // state.options.xAxis.categories = nextProps.categories;
      state.options.series = nextProps.series;

      this.setState(state, () => this.forceUpdate());
    }
    return false;
  }
 
  render() {
    return <div className="highcharts-wrapper">
      <HighchartsReact
        key={this.props.slideId}
        highcharts={Highcharts}
        options={this.state.options}
        updateArgs={[true, false, false]}
      />
    </div>
  }
}

function addDays(d, days) {
  var date = new Date(d.valueOf());
  date.setUTCDate(date.getUTCDate() + days);
  return date;
}

function getDates(startDate, stopDate, interval = 'day') {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).startOf(interval).valueOf());
      currentDate = addDays(currentDate, 1);
    }
    return dateArray;
}

class Charts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      summaryData: [],
      interval: 'day'
    }
  }

  componentDidMount() {
    this.prepareData(this.props.data);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(nextProps.data, this.props.data)) {
      this.prepareData(nextProps.data);
    }
    if (!_.isEqual(nextState, this.state)) {
      if (nextState.interval !== this.state.interval) {
        console.log('prepping data again');
        setTimeout(() => this.prepareData(this.props.data), 0);
      }
      return true;
    }
    return false;
  }

  prepareData(data) {
    if (data === 'loading' || data === undefined) {
      this.setState({ 
        series: [],
        trendSeries: [],
        data: 'loading',
        summaryData: 'loading'
      });
      return;
    }

    const interval = this.state.interval || 'day';

    let formattedData = [];
    let summaryData = {};
    let total = 0;
    let valueTotal = 0;

    const categories = {};
    const answers = {};
    const slide = this.props.slide;

    data.slice().reverse().forEach((event) => {
      const e = event;
      const ts = getTimestamp(event._id);
      const key = moment(ts).startOf(interval).valueOf();
      const responses = event.responses || [event.response];

      responses.forEach((response) => {      
        if (!response) { return }

        let title = getAnswerIdentifier(response.title, response.handle);
        if (this.props.answers.indexOf(response.title) === -1) {
          title = response.handle;
        }

        if (event.valueType === 'email') {
          title = "Email Capture"
        }
        if (event.valueType === 'form-response') {
          title = "Form Submission";
        }
        if (event.valueType === 'file-upload') {
          title = "File Upload(s)";
        }
        if (event.valueType === 'response') {
          title = "Open-Ended Submission";
        }

        if (this.props.answers.indexOf(title) === -1) { return; }

        /* Adjust for weighted averages on ranking */
        let value = 1;
        if (response.rank) {
          value = (this.props.answers.length + 1) - response.rank;
        }

        if (categories[key]) {
          categories[key]['day-total'] += value;

          if (categories[key][title]) {
            categories[key][title].value += value;
            categories[key][title].total += 1;
          } else {
            categories[key][title] = {
              value,
              total: 1
            } 
          }
        } else {
          categories[key] = {};
          categories[key][title] = { value, total: 1 };
          categories[key]['day-total'] = value;
        }

        if (summaryData[title]) {
          summaryData[title].y += value;
          summaryData[title].total += 1;
        } else {
          summaryData[title] = {
            name: title,
            y: value,
            total: 1
          }
        }

        total += 1;
        valueTotal += value;
      });
    });

    let series = [];
    let trendSeries = [];
    const dates = getDates(this.props.dateRange.startDate, this.props.dateRange.endDate, interval);

    let filteredAnswers = [ ...this.props.answers ];
    if (filteredAnswers.length > 20) {
      filteredAnswers = [ ...this.props.slide.answers ].filter(({ votes }) => votes > 0).map(({ handle, title }) => getAnswerIdentifier(title, handle));
    }

    filteredAnswers.forEach((key) => {
      const data = [];
      dates.forEach((day) => {
        if (categories[day] === undefined) {
          data.push({ x: day, y: 0 });
        } else {
          if (slide.type === 'rank') {
            data.push({ x: day, y: (categories[day][key] ? (categories[day][key].value / categories[day]['day-total']) * 100 : 0) });
          } else {
            data.push({ x: day, y: (categories[day][key] ? categories[day][key].value : 0) });
          }
        }
      });
      series.push({
        name: key,
        data
      });

      if (summaryData[key] === undefined) {
        summaryData[key] = { name: key, y: 0, perc: 0 };
      } else {
        summaryData[key]['perc'] = summaryData[key].y / total;
      }
      if (slide.type === 'rank') {
        summaryData[key].y = (summaryData[key].y / valueTotal) * 100;
        summaryData[key].perc = undefined;
      }
    });

    summaryData = Object.keys(summaryData).map((key) => {
      return summaryData[key];
    });
    summaryData = _.orderBy(summaryData, 'y', 'desc');
    const titles = summaryData.map(({ name }) => name);
    series = _.orderBy(series, (s) => titles.indexOf(s.name));

    if (series.length && series[0].data) {
      trendSeries = _.cloneDeep(series);

      series[0].data.forEach((day, i) => {
        let total = 0;
        series.forEach((answer) => {
          let val = answer.data[i].y;
          total += val;
        });
        series.forEach((answer, j) => {
          let val = answer.data[i].y;
          let perc = val / total;

          /* Split evenly if no votes on the day */
          if (_.isNaN(perc)) {
            perc = 1/series.length;
          }

          trendSeries[j].data[i].y = parseInt(perc * 100);
        });
      });
    }

    series.map((item) => {
      const match = _.find(this.props.slide.answers, ({ title, handle }) => {
        return handle === item.name;
      });
      if (match) {
        item.name = match.title;
      }
    });
    trendSeries.map((item) => {
      const match = _.find(this.props.slide.answers, ({ title, handle }) => {
        return handle === item.name;
      });
      if (match) {
        item.name = match.title;
      }
    });
    summaryData.map((item) => {
      const match = _.find(this.props.slide.answers, ({ title, handle }) => {
        return handle === item.name;
      });
      if (match) {
        item.name = match.title;
      }
    });

    this.setState({ 
      series,
      data,
      trendSeries,
      summaryData
    })
  }

  render() {
    const { data, series } = this.state;

    if (!data || !this.props.slide) { return null; }

    if (data.length === 0 || !this.props.slideId) {
    return (<div className="chart-wrapper">
      <div className="no-data">
        <div className="content">
          <i className="fas fa-warning" />
          <p className="top">No Data</p>
          <p>Try a different slide that has more responses or check back later.</p>
        </div>
      </div>
    </div>)
    }

    if (data === 'loading') { 
    return (<div className="chart-wrapper">
      <div className="no-data loading">
        <div className="content">
          <p className="top">Loading...</p>
        </div>
      </div>
    </div>)
    }

    let otherCharts = null;
    let pieOrNps = <div className="chart">
      <PieChart
        key={this.props.slideId}
        data={[ ...this.state.summaryData ]}
        tooltipContent={this.props.slide.type === 'rank' && function () {
          return `<div><b>Weighted Avg:</b> ${renderNumber(this.point.y)}</div>`
        }}
        labelFormatter={this.props.slide.type === 'rank' && function () { return `<strong>${this.point.name}</strong>: ${renderNumber(this.point.y)} WAVG` } }
      />
    </div>

    const slide = this.props.slide;
    if (slide.type === 'range' || slide.type === 'star-rating' || slide.type === 'satisfaction') {
      pieOrNps = <div className="chart">
        <NPSChart
          key={this.props.slideId}
          data={sortChartDataByAnswers(this.state.summaryData, this.props.slide.answers)}
        />
      </div>
    }

    let trendChart = null;
    if (this.state.data && ['email-capture', 'long-answer', 'short-answer', 'form', 'date', 'file-upload'].indexOf(slide.type) === -1) {
      trendChart = <div className="chart wide trend-chart">
        <div className="title-pill"><i className="fas fa-arrow-trend-up" />Trends / { capitalize(this.state.interval) }</div>
        <TimeSeriesChart
          key={this.props.slideId}
          data={this.state.data}
          series={this.state.trendSeries}
          isTrendChart={true}
        />
      </div>

      otherCharts = <div>
        <div className="chart wide">
          <DonutChart
            key={this.props.slideId}
            tooltipContent={slide.type === 'rank' && function () {
              return `<div><b>Weighted Avg:</b> ${renderNumber(this.point.y)}</div>`
            }}
            labelFormatter={slide.type === 'rank' && function () { return `<strong>${this.point.name}</strong>: ${renderNumber(this.point.y)} WAVG` } }
            data={[ ...this.state.summaryData ]}
          />
        </div>
        { pieOrNps }
        <div className="chart">
          <ColumnChart
            key={this.props.slideId}
            data={[ ...this.state.summaryData ]}
            categories={this.state.summaryData.map(({ name }) => name)}
            tooltipContent={slide.type === 'rank' && function () {
              return `<div><b>Weighted Avg:</b> ${renderNumber(this.point.y)}</div>`
            }}
            labelFormatter={slide.type === 'rank' && function () { return `<strong>${this.point.name}</strong>: ${renderNumber(this.point.y)} WAVG` } }
          />
        </div>
      </div>
    }

    let openEndedResponses = null;
    let responses = [];
    if (this.state.data) {
      this.state.data.slice().reverse().forEach((event) => {
        const valueType = event.valueType || '';
        if (valueType.indexOf('response') !== -1 || valueType.indexOf('email') !== -1) {
          responses.unshift(event);
        }
      });
    }
    if (responses.length) {
      let responsesUrl = `/responses/a/${encode(this.props.accountId)}?pollId=${encode(this.props.pollId)}&slideId=${encode(this.props.slideId)}`;

      if (window.location.href.indexOf('/p/') !== -1) {
        responsesUrl = `/responses/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}?slideId=${encode(this.props.slideId)}`;          
      }

      if (window.location.href.indexOf('/s/') !== -1) {
        responsesUrl = `/responses/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(this.props.slideId)}`;        
      }

      openEndedResponses = <div className="event-responses-wrapper">
        <div className="title-pill"><i className="fas fa-chart-pie" />Open-Ended Responses</div>
        <div className="event-responses">{ responses.slice(0, 500).map((event) => {
          let response = <div>{event.response.value || event.response.title}<i className="fas fa-arrow-up-right-from-square" /></div>;

          if (event.responses) {
            if (event.valueType === 'form-response') {
              response = (<div>
                { event.responses.map(({ label, value }) => {
                  if (!value) { return null; }

                  return (<div className="value" style={{ clear: 'both', paddingTop: 3, paddingBottom: 3 }}><strong style={{ fontWeight: 700 }}>{label}</strong>: {value}</div>);
                })}
              </div>)
            } else {
              /* Only want to print out the other response */
              response = <div>{ event.responses.map(({ title, value }) => value || title).join(', ') }<i className="fas fa-arrow-up-right-from-square" /></div>
            }
          }
          if (event.response.type === 'date') {
            response = <div>{moment(event.response.value || event.response.title).format('MM/DD/YYYY')}<i className="fas fa-arrow-up-right-from-square" /></div>
          }
          if (event.response.type === 'rank') {
            response = <div>{ _.sortBy(event.responses, 'rank').map(({ title, value, rank }) => {
              return <div>#{rank} — {value || title}</div>
            })}</div>
          }

          return <a href={`/participants/a/${encode(this.props.accountId)}/pa/${event.participantId}?useId=true`} target="_blank" className="event"><div className="date">{getDateAsTimestamp(event._id, 'MM/DD/YY, h:mm:ss A')}</div>{ response }</a>
        }) }

        { responses.length > 500 ? <a href={responsesUrl} target="_blank" className="event" style={{ textAlign: 'center' }}>View { renderNumber(responses.length - 500) } more responses</a> : null }

        </div>
      </div>
    }

    return (<div className="chart-wrapper">
      <div className="top">
        <div className="interval-selector">
          <i className="fas fa-stopwatch" />
          <select onChange={(e) => {
            this.setState({ interval: e.target.value });
          }}>
            <option value="day">Daily</option>
            <option value="week">Weekly</option>
          </select>
        </div>
        <div className="title-pill"><i className="fas fa-chart-line" />{ slide.type === 'rank' ? 'Weighted Avgs' : 'Responses' } / { capitalize(this.state.interval) }</div>
        <TimeSeriesChart
          key={this.props.slideId}
          data={this.state.data}
          series={this.state.series}
        />
      </div>

      { otherCharts && <div className="below-charts-wrapper">
        { trendChart }
        <div className="cumulative-charts">
          <div className="title-pill"><i className="fas fa-chart-pie" />Cumulative Charts</div>
          { otherCharts }
        </div>
      </div> }

      { openEndedResponses }
    </div>)
  }
}

class ChartPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pollId: props.pollId,
      slideId: props.slideId,
      dateRange: { 
        startDate: moment().subtract(1, 'month').startOf('day').toDate(),
        endDate: moment().endOf('day').toDate() 
      },
    };

    // if (!this.props.account && this.props.accountId) {
    //   this.props.fetchAccount(this.props.accountId);
    // }
    props.fetchAccountWithPolls(props.accountsId)
    .then(() => {
      const polls = this.props.account.polls || [];
      if (!polls.length) { return; }

      const pollId = this.state.pollId || polls[0]._id;

      const poll = getPoll(this.props.polls, pollId);
      let slide;
      if (!this.state.slideId) {
        const slides = poll.slides || [];
        slide = slides[0] || {};
      }

      const state = {
        pollId: poll._id,
        slideId: this.state.slideId || slide._id,
      }

      this.onChange(state);
    });
  }

  onChange(state) {
    this.setState(state, () => {
      if (this.state.slideId) {
        this.props.fetchChartDataForSlide(this.props.accountId, this.state.pollId, this.state.slideId, this.state.dateRange);
      }
    });
  }

  renderNotReady() {
    return (
      <ColumnLayout
        title={`Charts`}
        className="account-responses"
        graphics={true}
      >
        <div style={{ minWidth: 1000 }}>
          <div className="section-header no-margin">
            <div className="top">
              <div className="section-subtitle">
                <span>
                  <i className="fas fa-chart-pie" />Charts
                </span>
              </div>
            </div>

            <div style={{ lineHeight: '18px', fontSize: 16 }} className="section-description">View responses for your survey over time.</div>
          </div>

          <div className="card empty-object-list responses">
            <h3>No Surveys Created Yet</h3>
            <p>Try creating a survey or making your existing surveys live in order to collect data and process charts.</p>
          </div>
        </div>
      </ColumnLayout>
    );
  }


  render() {
    if (!this.props.account) {
      return <ColumnLoading />
    }
    if (!this.props.account.polls) {
      return <ColumnLoading />
    }
    if (this.props.account.polls.length === 0) {
      return this.renderNotReady();
    }

    const pollId = this.state.pollId || this.props.account.polls[0]._id;
    const poll = getPoll(this.props.polls, pollId);
    const slides = poll.slides || [];

    let slideBrief = null;
    const slideId = this.state.slideId;
    const slide = _.find(poll.slides, ({ _id }) => slideId === _id );

    let pollSelector = <div className="select-wrapper"><select
      value={this.state.pollId}
      onChange={(e) => {
        e.preventDefault();
        e.stopPropagation();

        const pollId = e.target.value;
        const poll = getPoll(this.props.polls, pollId);
        const slides = poll.slides || [];
        const slideId = slides[0] ? slides[0]._id : undefined;

        this.onChange({ pollId, slideId: slideId })
      }}
    >
      { this.props.account.polls.map((poll) => 
        <option value={poll._id}>{poll.title}</option>
      ) }
    </select></div>

    let slideSelector = <div className="select-wrapper"><select
    value={this.state.slideId}
    onChange={(e) => {
      e.preventDefault();
      e.stopPropagation();

      const slideId = e.target.value;
      this.onChange({ slideId, page: 0 })
    }}>
      { slides.map((slide) => 
        <option value={slide._id}>{stripTags(slide.handle || slide.title)}</option>
      ) }
      { slides.length === 0 && <option selected={true} value={''} disabled={true}>No slides exist</option>}
    </select></div>

    if (slide) {
      slideBrief = <div className="slide-brief">
        <i className="fas fa-quote-left" />
        <div className="title">{ stripTags(slide.title) } — <div className="type"><i className={`fas ${ getSlideIcon(slide.type) }`} />{ getSlideLabel(slide.type) }</div></div>
        { slide.copy && <div className="copy">{ stripTags(slide.copy) }</div> }
      </div>
    }

    let answers = [];
    if (slide) {
      answers = slide.answers.map(({ title, handle }) => getAnswerIdentifier(title, handle));

      if (slide.type === 'email-capture') {
        answers = ["Email Capture"];
      }
      if (slide.type === 'form') {
        answers = ["Form Submission"];
      }
      if (slide.type === 'file-upload') {
        answers = ["File Upload(s)"];
      }
      if (slide.type === 'long-answer' || slide.type === 'short-answer' || slide.type === 'date') {
        answers = ["Open-Ended Submission"];
      }
    }

    return (
      <ColumnLayout title="Charts" graphics={true}>
        <div style={{ minWidth: 1000, margin: '0px auto', padding: '0 20px' }}>
          <LoadingIndicator loading={this.props.loading} />

          <div className="section-header no-margin wide left">
            <div className="top">
              <div className="section-subtitle">
                <span>
                  <i className="fas fa-chart-pie" />Charts
                </span>
              </div>
            </div>
            <div className="section-description">
              Showing for survey { pollSelector } and slide { slideSelector }
            </div>
          </div>

          { slideBrief }

          <div className="date-range-container">
            <label>Date Range</label>
            <DateRangeInput
              startDate={this.state.dateRange.startDate}
              endDate={this.state.dateRange.endDate}
              onChange={(dateRange) => {
                this.onChange({ dateRange })
              }}
            />
          </div>

          <Charts
            key={slideId}
            accountId={this.props.accountId}
            slideId={slideId}
            pollId={pollId}
            slide={slide}
            answers={answers}
            data={this.props.charts[slideId]}
            dateRange={this.state.dateRange}
          />

        </div>
      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId] || {};

  const allResponses = state.responses || [];
  const responses = allResponses[accountId];

  const params = qs.parse(ownProps.location.search);
  const pollId = decode(params.pollId) || decode(ownProps.match.params.pollId);
  const slideId = decode(params.slideId) || decode(ownProps.match.params.slideId);

  return {
    accountId,
    account,
    charts: state.charts,
    polls: account.polls || [],
    responses,
    pollId,
    slideId,
    loading: accounts.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions, ...ChartActions, ...FlashNotificationActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartPage);
