import { post } from '../ajax';
import { url } from '../settings';

export const FETCHING_CHART_DATA = 'FETCHING_CHART_DATA';
export const FETCHED_CHART_DATA = 'FETCHED_CHART_DATA';

export function fetchChartDataForAccount(accountId) {
  return (dispatch, getState) => {
    dispatch({ type: FETCHING_CHART_DATA, key: accountId });
    return post(`${url}/chart/account/${accountId}`, { timezoneOffset: new Date().getTimezoneOffset() })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        console.log('error');
        console.log(json);
      } else {
        console.log('got events data');
        console.log(json);
        dispatch({ type: FETCHED_CHART_DATA, data: json });
      }
    })
    .catch(error => console.log(error))
  }
}

export function fetchChartDataForPoll(accountId, pollId) {
  return (dispatch, getState) => {
    dispatch({ type: FETCHING_CHART_DATA, key: pollId });
    return post(`${url}/chart/account/${accountId}/poll/${pollId}`, { timezoneOffset: new Date().getTimezoneOffset() })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        console.log('error');
        console.log(json);
      } else {
        console.log('got poll events data');
        console.log(json);
        dispatch({ type: FETCHED_CHART_DATA, data: json });
      }
    })
    .catch(error => console.log(error))
  }
}

export function fetchChartDataForSlide(accountId, pollId, slideId, dateRange) {
  return (dispatch, getState) => {
    dispatch({ type: FETCHING_CHART_DATA, key: slideId, reset: true });
    return post(`${url}/chart/account/${accountId}/poll/${pollId}/slide/${slideId}`, { dateRange })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        console.log('error');
        console.log(json);
      } else {
        console.log('got poll events data');
        console.log(json);
        dispatch({ type: FETCHED_CHART_DATA, data: json });
      }
    })
    .catch(error => console.log(error))
  }
}

