import React, { Component } from 'react';

import Modal from './Modal';

export default class DeleteConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.setState({ showModal: this.props.show });
    }
  }

  closeModal() {
    this.setState({ showModal: false });
    this.props.onCancel();
  }

  onChange(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ value: e.target.value.toLowerCase() });

    if (e.target.value === 'permanently delete') {
      this.setState({ confirmed: true });
    } else {
      this.setState({ confirmed: false });
    }
  }

  render() {
    return (
      <Modal 
        isOpen={this.state.showModal}
        onRequestClose={this.closeModal.bind(this)}
      >
        <div className="frame">
          <div className="close" onClick={this.closeModal.bind(this)} />
          <div className="title">{ this.props.title }</div>
          <div className="content">
            <div className="subtitle">{ this.props.subtitle }</div>

            <div className="verification-input">
              <label>To proceed type <strong>permanently delete</strong> below.</label>
              <input 
                type="text"
                value={this.state.value}
                onChange={this.onChange.bind(this)}
              />
            </div>

            <div className="actions">
              <button className="positive" disabled={!this.state.confirmed} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (!this.state.confirmed) { return; }

                this.props.onConfirm();
                this.closeModal();
              }}>Yes</button>
              <button className="negative" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                this.closeModal();
              }}>No</button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}