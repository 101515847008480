import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as AccountsActions from '../actions/Accounts';
import * as UsersActions from '../actions/Users';

import SectionHeader from './SectionHeader'
import Confirm from './Confirm';

import { encode } from '../utils';

class AccountRow extends Component {
  constructor(props) {
    super(props);
    this.state = { showLeaveModal: false };
  }
  render() {
    const { account, user } = this.props;

    if (!account) { return null; }

    let permission = (<div className="permission pill grey">Admin</div>);
    let link = null;
    let agencyLink = null;
    let updatePlanLink = null;

    if (account.owner === user._id) {
      permission = (<div className="permission pill blue">Owner</div>);
      link = (<div className="link"><Link to={`/a/${encode(account._id)}/manage`}>Manage Users</Link></div>)
      updatePlanLink = <div className="link update-plan"><Link to={`/a/${encode(account._id)}/manage`}>Change Your Plan</Link></div>
    }

    // if ((account.owner === user._id) && (account.plan.key === 'agency')) {
    //   agencyLink = (<div className="link agency"><Link to={`/a/${encode(account._id)}/manage-white-label`}>Manage White Label Settings</Link></div>)      
    // }

    let actions = null;
    if (this.props.idx !== 0) {
      actions = (
        <div className="actions">
          <button onClick={() => {
            this.props.setDefaultAccount(this.props.account);
          }}>Make Default</button>
        </div>
      );
    }

    let leave = null;
    let leaveModal = null;
    if (account.owner !== user._id) {
      leave = <div className="link leave"
        onClick={() => {
          console.log(this.state.showLeaveModal);
          this.setState({ showLeaveModal: true });
        }}
      >
        <span>Leave Account</span>
      </div>

      leaveModal = <Confirm 
        title="Are you sure?"
        subtitle="You will need to be re-invited by the account admin if you want to access this account again."
        show={this.state.showLeaveModal}
        onConfirm={() => {
          this.props.leaveAccount(user._id, account._id);
        }}
        onCancel={() => {
          this.setState({ showLeaveModal: false });
        }}
      />
    }

    return <div className="account-row">
      <div className="top">
        { permission }
        <p className="title">{ account.title }</p>
      </div>
      <p className="plan"><span className="inline-label">Plan:</span><strong>{ account.plan.label }</strong></p>

      <div className="links">
        <div className="link dashboard"><Link to={`/dashboard/a/${encode(account._id)}`}>View Dashboard</Link></div>
        { link }
        { updatePlanLink }
        { agencyLink }
        { leave }
      </div>

      { actions }

      { leaveModal }
    </div>
  }
}

class AccountsOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {}

    // if (!this.props.accountIds.length) {
      this.props.fetchAllAccounts().then(() => {
        setTimeout(() => {        
          this.forceUpdate();
        }, 1000);
      });
    // }
  }

  render() {
    if (!this.props.accountIds.length) {
      return (
        <div className="account-overview">
          <SectionHeader
            title=<span><i className="fas fa-folder-open" />Accounts</span>
            subtitle="See and manage all the accounts you are a part of."
            tooltip="You can manage your team and subscription settings if you are the account owner."
          />
          <div className="empty-object-list" style={{ marginTop: 0 }}>
            <h3>You do not belong to any accounts.</h3>
            <p>Create a new account by clicking the link below</p>
            <Link to="/a/create">Create Account</Link>
          </div>
        </div>
      );
    }

    let actions = (
      <div className="actions">
        <p>Want to make a new account? <Link to="/a/create">Create New Account</Link></p>
      </div>
    );

    if (this.props.user.type === 'shopify') {
      actions = null;
    }

    let accountIds = this.state.showAll ? this.props.accountIds : this.props.accountIds.slice(0, 3);
    let showAll = null;

    if (this.props.accountIds.length > 3 && !this.state.showAll) {
      showAll = <div 
        className="show-all"
      >Showing 3 of {this.props.accountIds.length} accounts. <span onClick={() => this.setState({ showAll: true }) }>Show All</span></div>
    }

    return (
      <div className="account-overview">
        <SectionHeader
          title=<span><i className="fas fa-folder-open" />Accounts</span>
          subtitle="See and manage all the accounts you are a part of."
          tooltip="You can manage your team and subscription settings if you are the account owner."
        />

        <div className="object-list">
          { accountIds.map((id, idx) => 
            <AccountRow key={idx} account={this.props.accounts[id]} idx={idx} user={this.props.user} setDefaultAccount={this.props.setDefaultAccount.bind(this)} leaveAccount={this.props.leaveAccount.bind(this)} />
          ) }
          { showAll }
        </div>

        { actions }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { accounts, user } = state;
  const accountIds = user.accounts.map((account) => {
    if (typeof account === 'object') {
      return account._id
    } else {
      return account;
    }
  });

  return {
    loading: state.accounts.loading,
    user,
    accounts,
    accountIds
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountsActions, ...UsersActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountsOverview);
