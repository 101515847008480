import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import _ from 'lodash';
import moment from 'moment';

import Tooltip from '../components/Tooltip';
import Diff from '../components/Diff';
import SlideAnswers from '../components/SlideAnswers';
import DateRangeToggle from '../components/DateRangeToggle';

import * as FlashNotificationActions from '../actions/FlashNotifications';
import * as SlideActions from '../actions/Slides';

import { encode, renderNumber, renderCurrency, numberWithCommas, previewSlides, getSlideLabel, getSlideIcon, stripTags, truncate, getDateAsTimestamp, prepareInsights, getSlideTitle, getDate } from '../utils';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'

Highcharts.setOptions({
  lang: {
    decimalPoint: '.',
    thousandsSep: ','
  }
});

const Copy = (props) => {
  if (!props.value) { return null; }

  return (
    <div className="inline">
      <label>{ props.label }</label>
      <div
        className="copy"
        dangerouslySetInnerHTML={{ __html : props.value }} 
      />
    </div>
  );
}

const Info = (props) => {
  if (!props.value) { return null; }

  let content = null;
  if (props.image) {
    content = (<img src={`${props.value}`} />)
  } else {
    content = (<p>{ props.value }</p>);
  }

  return (
    <div className="inline">
      <label>{ props.label }</label>
      { content }
    </div>
  );
}

const Counter = (props) => {
  if (props.idx === undefined || props.count === undefined) { return null; }

  return (
    <div className="counter">
      <span><span>{props.idx}</span><span>/</span><span>{props.count}</span></span>
    </div>
  )
}


export class SlideInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { insightIdx: 0, responseInsightIdx: 0, showMoreInsights: false }

    this.responsesUrl = '';
    this.setResponsesUrl();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.slide._id !== this.props.slide._id) {
      this.setResponsesUrl();
      this.setState({ insightIdx: 0, responseInsightIdx: 0 });
    }
  }

  setResponsesUrl() {
    this.responsesUrl = `/responses/a/${encode(this.props.accountId)}?pollId=${encode(this.props.pollId)}&slideId=${encode(this.props.slide._id)}`;

    if (window.location.href.indexOf('/p/') !== -1) {
      this.responsesUrl = `/responses/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}?slideId=${encode(this.props.slide._id)}`;          
    }

    if (window.location.href.indexOf('/s/') !== -1) {
      this.responsesUrl = `/responses/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(this.props.slide._id)}`;        
    }

    this.forceUpdate();
  }

  renderInfo() {
    const slide = this.props.slide;
    let optional = null;
    if (slide.settings.optional) {
      optional = <strong className="optional">Optional</strong>
    }
    if (slide.settings.hidden) {
      optional = <strong className="optional">Hidden</strong>
    }

    return (
      <div className="section no-padding">
        <div className="padding">
          <Counter idx={this.props.slideIdx} count={this.props.slideCount}  />

          <Info
            label="Type"
            value={<span><i className={`fas ${getSlideIcon(slide.type)}`} /><strong>{getSlideLabel(slide.type)}</strong>{ optional }</span>}
          />

          { (slide.handle === stripTags(slide.title)) ? null : <Copy
            label="Handle"
            value={ slide.handle }
          /> }

          <Copy
            label="Title"
            value={ truncate(slide.title, 300) }
          />

          <Info
            label="Image"
            value={ slide.image }
            image={true}
          />

          <Info
            label="Subtitle"
            value={ truncate(slide.subtitle, 300) }
          />

          <Copy 
            label="Copy"
            value={ truncate(slide.copy, 300) }
          />

          <Info
            label="Reward Code"
            value={ slide.rewardCode && slide.type === 'reward' ? <strong>{ slide.rewardCode }</strong> : null}
          />
        </div>
      </div>
    );
  }

  renderEngagement() {
    const slide = this.props.slide;
    const diff = this.props.diffs[slide._id] || {};

    const answers = slide.answers;
    const total = _.sumBy(answers, a => a.votes || 0);

    let emailsCaptured = null;

    let className = 'half';
    let hasDynamicResponses = null;
    if (
      slide.type === 'question' && slide.answers ||
      slide.type === 'checkbox' && slide.answers ||
      slide.type === 'dropdown' && slide.answers ||
      slide.type === 'inline-multiple-choice' && slide.answers
    ) {
      answers.forEach(answer => {
        if (answer.dynamic) {
          hasDynamicResponses = true;
          className = "third";
        }
      });
    }

    if (slide.type === 'copy' || slide.type === 'reward') {
      className = "single";
    }

    if (slide.type === 'email-capture') {
      if (slide.emailCount !== 0 && !this.props.hideLinks) {
        emailsCaptured = (<Link className="link" to={`/emails/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(slide._id)}`}><div className={`inline ${className}`}>
          <label>Emails<Tooltip>The total number of emails collected by this slide since it was created.</Tooltip></label>
          <p className="email-count" title={slide.emailCount || 0}><span>{ renderNumber(slide.emailCount) }<Diff value={diff.emailsDiff} /></span></p>
        </div></Link>);
      } else {
        emailsCaptured = (<div className={`inline ${className}`}>
          <label>Email Submissions<Tooltip>The total number of emails collected by this slide since it was created.</Tooltip></label>
          <p className="email-count" title={slide.emailCount || 0}><span>{ renderNumber(slide.emailCount) }</span></p>
        </div>)
      }
    }

    let totalVotes = null;
    if (previewSlides[slide.type].answers && slide.type !== 'rank') {
      totalVotes = (
        <Link className="link" to={this.responsesUrl}><div className={`inline ${className}`}>
          <label><div className="pill">fixed-choice</div>Responses<Tooltip>The number of fixed-choice responses that have been submitted to this slide.</Tooltip></label>
          <p className="vote-count" title={total || 0}><span>{ renderNumber(total) }<Diff value={diff.votesDiff} /></span></p>
        </div></Link>
      );
    }

    if (slide.type === 'rank') {
      totalVotes = (
        <Link className="link" to={this.responsesUrl}><div className={`inline ${className}`}>
          <label><div className="pill">ranking</div>Responses<Tooltip>The number of ranking responses that have been submitted to this slide.</Tooltip></label>
          <p className="vote-count" title={slide.submissionCount || 0}><span>{ renderNumber(slide.submissionCount) }<Diff value={diff.submissionCountDiff} /></span></p>
        </div></Link>
      );      
    }

    let reponsesCount = null;
    if (
      slide.type === 'short-answer' ||
      slide.type === 'long-answer' ||
      slide.type === 'form' ||
      slide.type === 'date' ||
      slide.type === 'file-upload' ||
      hasDynamicResponses
    ) {
      if (slide.responseCount !== 0 && !this.props.hideLinks) {
        reponsesCount = (<Link className="link" to={this.responsesUrl}><div className={`inline ${className}`}><label><div className="pill">open-ended</div>Responses<Tooltip>The number of open-ended responses that have been submitted to this slide.</Tooltip></label><p className="response-count" title={slide.responseCount || 0}><span>{ renderNumber(slide.responseCount) }<Diff value={diff.responsesDiff} /></span></p></div></Link>);
      } else {
        reponsesCount = (<div className={`inline ${className}`}><label><div className="pill">open-ended</div>Responses<Tooltip>The number of open-ended responses that have been submitted to this slide.</Tooltip></label><p className="response-count" title={slide.responseCount || 0}><span>{ renderNumber(slide.responseCount) }</span></p></div>);
      }
    }

    let dropoffRate = 100;
    let responseRate = 0;
    let engagementRate = 0;
    if (slide.engagementCount !== undefined && slide.presentationCount !== undefined) {

      // let presentationCount = (slide.presentationCount * .5);
      let presentationCount = slide.presentationCount;

      dropoffRate = (1 - (slide.engagementCount / presentationCount)) * 100;
      engagementRate = (slide.engagementCount / presentationCount) * 100;
      responseRate = ((slide.replyCount || 0) / presentationCount) * 100;

      if (dropoffRate > 100) {
        dropoffRate = 100;
      }
      if (engagementRate > 100) {
        engagementRate = 100;
      }
      if (responseRate > 100) {
        responseRate = 100;
      }
    }

    let showEngagementStats = false;
    if (slide.presentationCount) {
      showEngagementStats = true;
    }

    /* Show presentation count instead if created after 2023 */
    let viewCount = slide.viewCount || 0;
    if (getDateAsTimestamp(slide._id, 'YYYY') >= 2023) {
      viewCount = slide.presentationCount || 0;
    }

    return (
      <div>
        <div className="engagement section">
          <div className="padding flex">
            <div className={`inline ${className}`}>
              <label>View Count<Tooltip>The number of times this slide has been viewed across the web.</Tooltip></label>
              <p className="view-count" title={viewCount}><span>{ renderNumber(viewCount) }</span></p>
            </div>
    
            { totalVotes }

            { reponsesCount }
            
            { emailsCaptured }

          </div>
        </div>

        { showEngagementStats && <div className="engagement section">
          <div className="padding flex">
            { (slide.type !== 'reward' && slide.type !== 'copy') && <div className={`inline third`}>
              <label>Response Rate<Tooltip>The percentage of participants who responded the question in this slide.</Tooltip></label>
              <p className="reply-rate" title={renderNumber(responseRate)+'%'}><span>{ renderNumber(responseRate) }%</span></p>
            </div> }

            <div className={`inline third`}>
              <label>Engagement Rate<Tooltip>The percentage of participants who engaged with this slide in any way, including skipping past.</Tooltip></label>
              <p className="engagement-rate" title={renderNumber(engagementRate)+'%'}><span>{ renderNumber(engagementRate) }%</span></p>
            </div>

            { (slide.type !== 'reward' && slide.type !== 'copy') && <div className={`inline third`}>
              <label>Dropoff Rate<Tooltip>The percentage of participants who stop filling out the survey at this slide.</Tooltip></label>
              <p className="dropoff-rate" title={renderNumber(dropoffRate)+'%'}><span>{ renderNumber(dropoffRate) }%</span></p>
            </div> }

          </div>
        </div> }
      </div>
    );
  }

  renderResponses() {
    const slide = this.props.slide;

    if (this.props.hideLinks) { return null; }
    if (!previewSlides[slide.type].hasResponses) { return null; }

    if (slide.type === 'question' || slide.type === 'dropdown' || slide.type === 'checkbox' || slide.type === 'inline-multiple-choice') {
      let hasDynamic = false;
      slide.answers.forEach(({ dynamic }) => {
        if (dynamic) { hasDynamic = true; }
      })
      if (!hasDynamic) { return null; }
      if (hasDynamic && (!slide.responses || slide.responses.length === 0)) {
        return null;
      }
    }

    if (!slide.responses || slide.responses.length === 0) {
      if (this.state.hasUsedDateRange) {
        return (<div className="section">
          <DateRangeToggle
            key={slide._id}
            slide={this.props.slide}
            pollId={this.props.pollId}
            fetchSlide={this.props.fetchFullSlide}
            onSubmit={(dateRange) => {
              this.props.fetchVotes(this.props.accountId, this.props.pollId, this.props.slide._id, dateRange);
              this.setState({ hasUsedDateRange: true })
            }}
          />
          <div className="empty-object-list">
            <h3>No Data Found For This Range.</h3>
            <p>Please select another date range and try again.</p>
          </div>
        </div>);        
      }
      
      return (<div className="section">
        <div className="empty-object-list">
          <h3>No responses have been submitted yet.</h3>
          <p>Check back later to see how people responded to this question</p>
        </div>
      </div>);
    }

    return (
      <div className="section">
        <DateRangeToggle
          key={slide._id}
          slide={this.props.slide}
          pollId={this.props.pollId}
          fetchSlide={this.props.fetchFullSlide}
          onSubmit={(dateRange) => {
            this.props.fetchVotes(this.props.accountId, this.props.pollId, this.props.slide._id, dateRange);
            this.setState({ hasUsedDateRange: true })
          }}
        />

        <p className="stats">Latest Responses<Tooltip>A list of responses to this slide.</Tooltip></p>
        <div className='responses active'>
          {slide.responses.map((response, idx) => {
            // let val = truncate(value, 1000);
            const value = response.value;
            let val = value;
            if (!val) { return null; }

            const url = `${this.responsesUrl.replace('responses', 'participants').split('?').shift()}/pa/${response.participantId}?useId=true`;

            if (response.type === 'date') {
              val = moment(val).format('MM/DD/YYYY');
            }

            let timestamp = <div className="timestamp">{getDate(response._id)}</div>;

            if (response.type === 'file-upload') {
              return (
                <div className="response no-padding">
                  <div className="value">
                  {
                    val.split(',').map((file) => {
                      return <div><a target="_blank" href={file}>{file}</a></div>
                    })
                  }
                  <div className="value participant-link"><a target="_blank" href={url}><i className="fas fa-user" />View Participant</a></div>
                  </div>
                </div>
              );
            }

            return (
            <div className="response no-padding">
              <div className="value">{timestamp}<a target="_blank" href={url}>{val}</a></div>
            </div>
            );
          })}
        </div>

        <Link to={this.responsesUrl} className="view-more">{ slide.responseCount <= 1 ? 'See all responses' : `See all ${renderNumber(slide.responseCount)} responses`}</Link>
      </div>
    );
  }

  renderEmails() {
    const slide = this.props.slide;

    if (this.props.hideLinks) { return null; }
    if (slide.type !== 'email-capture') { return null; }

    if (!slide.emails || slide.emails.length === 0) {
      return (<div className="section">
        <div className="empty-object-list">
          <h3>No emails have been submitted yet.</h3>
          <p>Check back later to see all email submissions for this slide.</p>
        </div>
      </div>);
    }

    return (
      <div className="section">
        <p className="stats">Latest Emails Collected<Tooltip>A list of email submissions to this slide.</Tooltip></p>
        <div className='responses active'>
          {slide.emails.map(({ email }, idx) => {
            let val = truncate(email, 100);
            if (!val) { return null; }

            return (
            <div className="response no-padding">
              <div className="value">{val}</div>
            </div>
            );
          })}
        </div>

        <Link to={`/reports/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}`} className="view-more">See all emails</Link>
      </div>
    );
  }

  renderAnswers() {
    return <SlideAnswers
      key={this.props.slideIdx}
      accountId={this.props.accountId}
      pollId={this.props.pollId}
      slide={this.props.slide}
      diffs={this.props.diffs}
      loading={this.props.loading}
      responsesUrl={this.responsesUrl}
      hideDateRange={this.props.hideDateRange}
    />
  }

  renderActions() {
    const slide = this.props.slide;

    if (!previewSlides[slide.type].actions) { return null; }

    const actions = slide.actions;
    const total = _.sumBy(actions, a => a.clicks || 0);
    const maxAction = _.maxBy(actions, a => a.clicks || 0);
    const sortedActions = _.orderBy(actions, ({clicks}) => { return clicks || 0 }, 'desc');

    if (total === 0) {
      return (<div className="section">
        <div className="empty-object-list">
          <h3>No Clicks yet.</h3>
          <p>Check back later to see what people have clicked on</p>
        </div>
      </div>);
    }

    return (
      <div className={`section ${this.props.loading ? 'loading' : ''}`}>
        <p className="stats">Clicks<Tooltip>Number of times each button has been clicked.</Tooltip></p>

        <div className='results active'>
          {sortedActions.map(({ title, handle, clicks }, idx) => {
            let perc = (clicks/total) * 100 + '%';

            if (!clicks) {
              perc = '0%';
            }

            const selected = maxAction.handle === handle;

            let number = clicks;

            let color = '#e4e0e0';

            if (selected && clicks) {
              color = 'rgba(6, 147, 227, 0.5)';
            }

            return (
              <div key={handle} className={`result ${selected ? 'selected' : ''}`}>
                <div className="fill" style={{ width: perc }}></div>
                <div className="perc" title={number || 0}>{renderNumber(number)}</div>
                <div className="title">{getSlideTitle(slide, handle, title)}</div>
                <div className="percentage">{renderNumber((clicks/total) * 100)}%</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderInsights() {
    const slide = this.props.slide;
    const insights = prepareInsights(slide.insights);

    if (!insights || insights.length === 0) {
      return null;
    }
    const insight = insights[this.state.insightIdx] || {};
    let { content } = insight;
    const items = content;

    let next = <div className="next" onClick={() => this.setState({ insightIdx: ++this.state.insightIdx })}><i className="fas fa-arrow-right" /></div>;
    let prev = <div className="prev" onClick={() => this.setState({ insightIdx: --this.state.insightIdx })}><i className="fas fa-arrow-left" /></div>
    let hasNext = true;
    let hasPrev = true;
    if (this.state.insightIdx === 0) {
      prev = (<div className="prev disabled"><i className="fas fa-arrow-left" /></div>);
      hasPrev = false;
    }
    if (this.state.insightIdx >= insights.length - 1) {
      next = (<div className="next disabled"><i className="fas fa-arrow-right" /></div>);
      hasNext = false;
    }
    let nextPrev = null;
    if (hasNext || hasPrev) {
      nextPrev = (<span className="next-prev">{ prev }{ next }</span>);
    }

    return (<div className="section">
      <div className="insights">
        <p className="stats"><span className="title"><i className="fas fa-wand-magic-sparkles" />Slide Insights</span><span>{ getDateAsTimestamp(insight._id, 'MMMM Do YYYY, h:mm A') }</span>{nextPrev}</p>
        <ul>
        { items.map((item) => {
          return <li>{ item }</li>
        }) }
        </ul>
      </div>
    </div>)
  }

  renderResponseInsights() {
    const slide = this.props.slide;
    const insights = prepareInsights(slide.responseInsights);

    if (!insights || insights.length === 0) {
      return null;
    }

    const insight = insights[this.state.responseInsightIdx];
    let { content } = insight;
    const items = content;

    if (!items || items.length === 1) {
      return null;
    }

    let showMore = (
      <div
        className="show-more"
        onClick={() => {
          this.setState({ showMoreInsights: !this.state.showMoreInsights });
        }}
      >{ !this.state.showMoreInsights ? 'Show more' : 'Show less' }</div>
    );
    if (items.length <= 4) {
      showMore = null;
    }

    let next = <div className="next" onClick={() => this.setState({ responseInsightIdx: ++this.state.responseInsightIdx })}><i className="fas fa-arrow-right" /></div>;
    let prev = <div className="prev" onClick={() => this.setState({ responseInsightIdx: --this.state.responseInsightIdx })}><i className="fas fa-arrow-left" /></div>
    let hasNext = true;
    let hasPrev = true;
    if (this.state.responseInsightIdx === 0) {
      prev = (<div className="prev disabled"><i className="fas fa-arrow-left" /></div>);
      hasPrev = false;
    }
    if (this.state.responseInsightIdx >= insights.length - 1) {
      next = (<div className="next disabled"><i className="fas fa-arrow-right" /></div>);
      hasNext = false;
    }
    let nextPrev = null;
    if (hasNext || hasPrev) {
      nextPrev = (<span className="next-prev">{ prev }{ next }</span>);
    }

    return (<div className="section">
      <div className={`insights ${items.length > 3 ? 'has-more' : ''}`}>
        <p className="stats"><span className="title"><i className="fas fa-arrow-trend-up" />Open-ended response trends</span><span>{ getDateAsTimestamp(insight._id) }</span>{nextPrev}</p>
        <ul>
        { items.map((item, idx) => {
          if ((idx >= 4) && !this.state.showMoreInsights) {
            return null;
          }
          return <li>{ item }</li>
        }) }
        </ul>
        { showMore }
      </div>
    </div>)
  }

  render() {
    return (<div>
      { this.renderInfo() }
      { this.renderAnswers() }
      { this.renderActions() }
      { this.renderEmails() }
      { this.renderResponses() }
      { this.renderResponseInsights() }
      { this.renderInsights() }
      { this.renderEngagement() }
    </div>);
  }
}

function mapStateToProps(state, ownProps) {
  return {
    loading: state.slides.loading || ownProps.loading,
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...SlideActions, ...FlashNotificationActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SlideInfo);
