import React, { Component } from 'react';

export default class Toggle extends Component {
  handleClick(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.props.disabled) {
      return;
    }
    this.props.onChange(!this.props.active);
  }

  render() {
    let active = this.props.active;
    if (typeof active === 'boolean') {
      active = active.toString();
    }

    let words = this.props.words || ['On', 'Off'];
    let wordsDom = <span><span className={`${this.props.active ? 'active' : ''} on`}>{words[0]}</span>
      <span className={`${!this.props.active ? 'active' : ''} off`}>{words[1]}</span></span>
    // if (this.props.mini) {
    //   words = null;
    // }

    return (<div
      className={`toggle ${this.props.mini ? 'mini' : ''} ${this.props.active ? 'active' : ''} ${this.props.disabled ? 'disabled' : ''} ${this.props.className ? this.props.className : ''}`}
      onClick={this.handleClick.bind(this)}
      active={active}
    >
      { wordsDom }
    </div>)
  }
}