import React, { Component } from 'react';

export default class SortBy extends Component {
  onChange(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onChange(e.target.value);
  }

  render() {
    return (
      <div className="sort-by">
        <p>Sort By</p>
        <div className="select-wrapper high-contrast"><select
          value={this.props.value}
          onChange={this.onChange.bind(this)}
        >
          { this.props.options.map(({ label, value }) => {
            return (<option value={value} key={value}>{label}</option>)
          })}
        </select></div>
      </div>
    );
  }
}