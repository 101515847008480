import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import RangeNextSlideInput from './RangeNextSlideInput';

import _ from 'lodash';

import * as FlashNotificationsActions from '../actions/FlashNotifications';

const numbers = [2,3,4,5,6,7,8,9,10];

class RangeInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      min: props.min === undefined ? 1 : props.min,
      max: props.max || 5
    };
  }

  onMinChange(e) {
    this.setState({ min: e.target.value }, this.update.bind(this))
  }

  onMaxChange(e) {
    this.setState({ max: e.target.value }, this.update.bind(this))
  }

  onChange(rules) {
    this.setState({ rules }, this.update.bind(this))
  }

  update() {
    const answers = [];

    for (var i = this.state.min; i <= this.state.max; i++) {
      answers.push({ title: i.toString(), handle: i.toString() });
    }

    const rules = this.state.rules || [];
    if (rules.length) {
      answers.forEach((answer) => {
        const match = _.find(rules, (rule) => (rule.handle == answer.handle));
        if (match) {
          answer.nextSlide = match.nextSlide;
        }
      });
    } else {
      answers.forEach((answer) => {
        answer.nextSlide = undefined;
      })
    }

    this.props.onChange({ target: { name: 'answers', value: answers } })
  }

  render() {
    return (
      <div className="range-input-box" style={{ marginBottom: 10 }}>
        <div className="input unchanged">
          <div>
            Values range from <select
              onChange={this.onMinChange.bind(this)}
            >{ [0, 1].map((val) => {
              return <option selected={val === this.state.min}>{val}</option>
            }) }</select> to <select
              onChange={this.onMaxChange.bind(this)}
            >{ numbers.map((val) => {
              return <option selected={val === this.state.max}>{val}</option>
            }) }</select>
          </div>
          {/* <RangeNextSlideInput
            slides={this.props.slides}
            nextSlide={this.props.nextSlide}
            value={this.props.title || 'Dynamic Input'}
            onChange={this.onChange.bind(this)}
            min={this.state.min}
            max={this.state.max}
            answers={this.props.answers}
          />*/}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(FlashNotificationsActions, dispatch);
}

export default connect(null, mapDispatchToProps, null, { withRef: true })(RangeInput);
