import React from 'react';

export const AuthSubmitButton = (props) => (
  <button
    type="submit"
    name="submit"
    className={props.className}
    onClick={props.onClick || null}
    disabled={props.disabled ? 'disabled' : ''}
  >
    <div>
      <span>{ props.title }</span>
      <span className="loading"></span>
    </div>
  </button>
)
