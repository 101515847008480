import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as UserActions from '../actions/Users';

import { EmailInput, PasswordInput } from './SimpleInputs';
import { AuthSubmitButton } from './Buttons';

import AuthForm from './AuthForm';

class ForgotPasswordForm extends AuthForm {
  constructor(props)  {
    super(props);
    this.state = { 
      inputs: {
        email: props.email,
        password: '',
      },
      valid: undefined
    };
    this.inputStates = {};
  }

  submitAction() {
    this.props.resetPassword(this.state.inputs);
  }

  renderInputs() {
    return (
      <div className="inputs">
        <EmailInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Email Address"
          type="email"
          placeholder="name@company.com"
          name="email"
          value={this.state.inputs.email}
          errorMessage="Please enter a valid email address."
          ref={this.setRef.bind(this)}
        />

        <PasswordInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Password"
          type="password"
          placeholder="Password (at least 6 characters)"
          name="password"
          value={this.state.inputs.password}
          errorMessage="Please enter a password with at least 6 characters."
          ref={this.setRef.bind(this)}
        />
      </div>
    );
  }

  renderActions() {
    return (
      <div className="actions">
        <AuthSubmitButton title="Reset Password" />

        <div className="login">
          <div>Remember it now?</div>
          <Link to="/log-in">Log In</Link>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.user.loading,
    email: state.user.email
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(UserActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);

