import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import { encode } from '../utils';

import Poll from './Poll';

class ActivePolls extends Component {
  render() {
    const props = this.props;

    if (!props.account.polls || !props.account.polls.length) {
      return <div className="empty-object-list" style={{ marginTop: 25 }}>
        <h3>There are no surveys for this account.</h3>
        <p>You will need to create a survey in order to ask questions to your users. Click the link below to get started.</p>
        <Link className="green" to={`/a/${encode(props.accountId)}/p/create`}>New Survey</Link>
      </div>
    }

    return (
      <div className="polls-container">
        <div className="polls">
          { props.account.polls.map((poll, idx) => {
            if (poll.isArchived) { return null; }
            return (<div onClick={() => this.props.onClick(idx)}><Poll
              account={this.props.account}
              key={poll._id}
              accountId={props.accountId}
              idx={idx}
              pollCount={props.account.polls.length}
              toggleVisibility={props.toggleVisibility}
              duplicate={props.duplicate}
              delete={props.delete}
              archive={props.archive}
              active={idx === this.props.selectedPollIdx}
              polls={this.props.account.polls}
              {...poll}
            /></div>);
          }) }
        </div>

        <div className="actions">
          <p>Looking for your archived surveys? Check your <Link to={`/dashboard/a/${encode(this.props.account._id)}`}>Dashboard</Link>.</p>
        </div>

        <Link className="create-additional" to={`/a/${encode(props.accountId)}/p/create`}>New Survey</Link>      


      </div>
    );
  }
}

export default DragDropContext(HTML5Backend)(ActivePolls);
