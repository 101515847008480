import React from 'react';

const SingleFormPageGraphics = (props) => (
  <div className="single-form-page-graphics">
    <div className="bottom-left">
      <div>
        {/*<img alt="Bottom Left" src={props.bottomLeft} />*/}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
          <g transform="translate(300,300)">
            <path d="M107.7,-142.6C145.3,-121,185.3,-97.1,202.8,-61.6C220.3,-26.1,215.3,21.1,203.8,69.3C192.4,117.5,174.7,166.8,139.7,189.4C104.6,212,52.3,208,0,208C-52.3,208,-104.6,212,-134.1,187.6C-163.6,163.2,-170.2,110.3,-173.1,64.9C-175.9,19.5,-175,-18.5,-170.5,-62.5C-166,-106.5,-157.9,-156.4,-128.9,-180.8C-99.9,-205.2,-50,-204.1,-7.4,-193.9C35.1,-183.6,70.1,-164.2,107.7,-142.6Z" />
          </g>
        </svg>
      </div>
    </div>

    <div className="top-right">
      <div>
        {/*<img alt="Top Right" src={props.topRight} />*/}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
          <g transform="translate(300,300)">
            <path d="M107.7,-142.6C145.3,-121,185.3,-97.1,202.8,-61.6C220.3,-26.1,215.3,21.1,203.8,69.3C192.4,117.5,174.7,166.8,139.7,189.4C104.6,212,52.3,208,0,208C-52.3,208,-104.6,212,-134.1,187.6C-163.6,163.2,-170.2,110.3,-173.1,64.9C-175.9,19.5,-175,-18.5,-170.5,-62.5C-166,-106.5,-157.9,-156.4,-128.9,-180.8C-99.9,-205.2,-50,-204.1,-7.4,-193.9C35.1,-183.6,70.1,-164.2,107.7,-142.6Z" />
          </g>
        </svg>
      </div>
    </div>
  </div>
);

export default SingleFormPageGraphics;