import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import _ from 'lodash';
import moment from 'moment';

import qs from 'query-string';

import ColumnLoading from './ColumnLoading';

import ColumnLayout from '../components/ColumnLayout';
import Breadcrumbs from '../components/Breadcrumbs';
import SectionHeader from '../components/SectionHeader';
import SlideInfo from '../components/SlideInfo';
import Pagination from '../components/Pagination';
import Toggle from '../components/Toggle';
import SortBy from '../components/SortBy';
import HeaderButtons from '../components/HeaderButtons';
import ResponseEditForm from '../components/ResponseEditForm';

import * as ResponsesActions from '../actions/Responses';

import { decode, encode, getDate, getSlideLabel, renderParticipantId, renderNumber, truncate, getSlideIcon, uuid, scrollToTop, isUrl, getDateAsTimestamp } from '../utils';

class Response extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const poll = this.props.poll;
    const accountId = this.props.accountId;
    const event = this.props.event;

    let href = null;
    if (event) {
      href = <div className="page"><a target='_blank' href={this.props.event.href}>{truncate(this.props.event.href, 40)}</a></div>
    }

    let response = (<div>{ this.props.value }</div>);

    if (this.props.event.responses && this.props.event.valueType === 'form-response') {
      response = (<div>
        { this.props.event.responses.map(({ label, value }) => {
          if (!value) { return null; }

          return (<div className="value" style={{ clear: 'both', paddingTop: 3 }}><strong style={{ fontWeight: 700 }}>{label}</strong>: {value}</div>);
        })}
      </div>)
    }

    if (this.props.event.respons && this.props.event.response.type === 'date') {
      response = (
        <div>{ moment(this.props.value).format('MM/DD/YYYY') }</div>
      );
    }

    const metadata = this.props.event.metadata;
    let metadataOutput = null;

    if (metadata) {
      const keys = Object.keys(metadata);
      metadataOutput = (<div>
      <div className="heading-tag"><i className="fas fa-asterisk" />Metadata</div>
      { keys.map((key, idx) => {
        let val = metadata[key];
        
        if (key === 'shopify_order_id' && this.props.account.shop) {
          val = <a target="_blank" rel="noopener noreferrer" href={`https://${this.props.account.shop}/admin/orders/${val}`}>{val}</a>
        } else if (key === 'shopify_customer_id' && this.props.account.shop) {
          val = <a target="_blank" rel="noopener noreferrer" href={`https://${this.props.account.shop}/admin/customers/${val}`}>{val}</a>
        } else {
          val = isUrl(val) ? <a href={val} title={val} target="_blank">{truncate(val, 40)}</a> : val;
        }

        const style = { marginBottom: 10 };

        if (['original-referrer', 'landing-page'].indexOf(key) !== -1) {
          style.wordBreak = 'break-all';
        }

        return (<div className="row" style={style}>
          <label>{ key }</label>
          <div>{ val }</div>
        </div>);
      }) }

      <div className="row" style={{ marginBottom: 10 }}>
        <label>Timestamp</label>
        <div>{getDateAsTimestamp(event._id)}</div>
      </div>

      <div className="row">
        <label>Participant</label>
        <div><Link to={`/participants/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/pa/${encode(this.props.participant._id)}`}>{this.props.participant.handle || renderParticipantId(this.props.participant.id, 20, true) }</Link></div>
      </div>

      </div>);
    }

    let metadataToggle = (
      <div className="show-more" onClick={() => {
      this.setState({ showDetails: !this.state.showDetails });
      }}>{ this.state.showDetails ? <span><i className="fas fa-minus" />Hide Details</span> : <span><i className="fas fa-plus"/>Show Details</span> }</div>
    );

    return (<div className={`object-list events`}>
      {/*<div className="events-meta"><i className="fas fa-chart-pie" />Survey: <Link to={`/a/${encode(accountId)}/p/${encode(poll._id)}`}>{ poll.title }</Link></div>*/}

        {/*
        <div className="row">
          <label>Slide <span>/ {getSlideLabel(this.props.slide.type)}</span></label>
          <p><Link to={`/a/${encode(this.props.account._id)}/p/${encode(this.props.poll._id)}/s/${encode(this.props.slide._id)}`}>{ this.props.slide.handle || this.props.slide.title }</Link></p>
        </div>
        */}

        <div className="row top">

          <label className="block"><i className={`fas ${getSlideIcon(this.props.slide.type)}`} /><Link to={`/a/${encode(this.props.account._id)}/p/${encode(this.props.poll._id)}/s/${encode(this.props.slide._id)}`}>{ truncate(this.props.slide.handle || this.props.slide.title, 30000) }</Link></label>
          { response }
          { metadataToggle }
          {/*
          <div className="object-metadata">
            <div className="date">{getDate(this.props._id)}</div>

            { href }

            <div className="participant"><Link to={`/participants/a/${encode(this.props.accountId)}/p/${encode(this.props.poll._id)}/pa/${encode(this.props.participant._id)}`}>{this.props.participant.handle || renderParticipantId(this.props.participant.id, 20) }</Link></div>
          </div>
          */}
        </div>

        <div style={{ display: this.state.showDetails ? 'block' : 'none' }}>
          { metadataOutput }
        </div>
        <div style={{ display: this.state.showDetails ? 'block' : 'none' }}>
          <ResponseEditForm
            { ...this.props }
          />
        </div>

    </div>);
  }
}

class Responses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: props.sortBy
    };

    let page = 0;
    if (props.page) {
      page = props.page - 1;
    }
    props.fetchPollResponses(page, props.sortBy);

  }

  onChange(value) {
    this.setState({ sortBy: value }, () => {
      this.props.fetchPollResponses(0, this.state.sortBy)
    });
  }

  export(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.exportPollResponses();
  }

  render() {
    if (!this.props.responses) {
      return <ColumnLoading />
    }

    let results;
    let header = null;
    let footer = null;

    let sortOptions = [
      { label: "Newest to Oldest", value: 'new-old' },
      { label: "Oldest to Newest", value: 'old-new' },
    ];

    console.log(this.props.responses.data);

    if (this.props.responses.data.length) {
      header = (
        <div className="responses-header pages">
          <Pagination
            showPagination={this.props.responses.pages}
            showNext={this.props.responses.page !== this.props.responses.pages}
            showPrev={this.props.responses.page !== 0}
            next={() => { this.props.fetchPollResponses(this.props.responses.page + 1, this.state.sortBy); }}
            prev={() => { this.props.fetchPollResponses(this.props.responses.page - 1, this.state.sortBy); }}
            curPage={this.props.responses.page + 1 }
            pageLength={this.props.responses.pages + 1}
          />
          <SortBy
            onChange={this.onChange.bind(this)}
            value={this.state.sortBy}
            options={sortOptions}
          />
        </div>
      );


      let ids = [];
      this.props.responses.data.forEach(({ event }) => {
        if (event._id) {
          ids.push(event._id);
        } else {
          const id = uuid()
          event._id = id;
          ids.push(id);
        }
      });

      ids = _.uniq(ids);

      const responses = [];
      ids.forEach((eventId) => {
        const response = _.find(this.props.responses.data, ({ event }) => event._id === eventId);
        responses.push(response);
      });

      // const responses = this.props.responses.data.map((response, idx) => {
      //   let next = this.props.responses.data[idx+1];

      //   if (!next) { 
      //     return response;
      //   }

      //   if (!response.event._id) {
      //     return response;
      //   }

      //   if (response.event._id === next.event._id) {
      //     return null;
      //   }

      //   return response;
      // });

      results = (
        <div className={`responses ${this.props.loading ? 'loading' : ''}`}>
          {responses.map((res) => {
            if (res === null) { return null; }

            return (<Response {...res} submitTags={this.props.submitTags} setApproval={this.props.setApproval} setRank={this.props.setRank} key={res._id} />);
          })}
        </div>
      );
      footer = (
        <div className="responses-footer">
          <button
            onClick={this.export.bind(this)}
          >Download CSV</button>
        </div>
      );
    } else {
      results = (
        <div className="card empty-object-list responses">
          <h3>No Responses Yet</h3>
          <p>A list will show up here when people have responded.</p>
        </div>
      );
    }

    return (
      <ColumnLayout
        title={`Survey: Responses`}
        className="poll-responses"
        graphics={true}
      >
        <SectionHeader
          title=<span><i className="fas fa-comment-alt" />Open-Ended Responses</span>
          subtitle="All open-ended responses submitted to this survey."
          className="no-margin"
        />

        { header }

        { results }

        { footer }

        <HeaderButtons>
          <button
            onClick={this.export.bind(this)}
            disabled={this.props.responses.data.length ? false : true}
          >Download CSV</button>
        </HeaderButtons>

      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const pollId = decode(ownProps.match.params.pollId);

  const allResponses = state.responses || [];
  const responses = allResponses[pollId];

  const params = qs.parse(ownProps.location.search);
  const page = parseInt(params.page) || 0;
  const sortBy = decode(params.sortBy);

  return {
    accountId,
    responses,
    page,
    sortBy,
    loading: state.responses.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ResponsesActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Responses));
