import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import copy from 'copy-to-clipboard';

import * as FlashNotificationActions from '../actions/FlashNotifications';

import Tooltip from '../components/Tooltip';
import SectionHeader from '../components/SectionHeader';


class PollIdCard extends Component {
  onClick(e) {
    copy(this.props._id);
    this.props.flash('The survey id has been copied to your clipboard.');
  }

  render() {
    return (<div>
      <SectionHeader
        title=<span><i class="fas fa-id-badge" />Survey Id</span>
        subtitle="Click to copy to your clipboard."
        tooltip="You may want this when if you are doing some custom work to dynamically embed this survey."
      />
      <div className="card copy-poll-id">
        <div onClick={this.onClick.bind(this)}>{ this.props._id }</div>
      </div>
    </div>)
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...FlashNotificationActions }, dispatch);
}

export default connect(null, mapDispatchToProps)(PollIdCard);
