import React, { Component } from 'react';

import $ from 'jquery';
import _ from 'lodash';

import BrowserFrame from './BrowserFrame';
import Embed from './Embed';

export default class VisualDisplay extends Component {
  constructor(props) {
    super(props);
    this.resize = _.throttle(this.resize.bind(this), 100, { leading: true, trailing: true });
    this.el = React.createRef();
  }

  componentDidMount() {
    // $(window).on('resize.display', this.resize);
    // this.resize();
    window.pollState = 'before-submission';
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showLink !== this.props.showLink) {
      this.resize();
    }

    if ((prevProps.active !== this.props.active) && this.props.active) {
      this.resize();
    }

    return null;
  }

  componentWillUnmount() {
    // $(window).off('resize.display', this.resize)
  }

  resize() {
    // const $this = $(this.el.current);
    // $this.height($this.prev().height());
  }

  render() {
    let slides = this.props.slides || [];
    if (slides.length === 0) {
      slides = [{
        title: 'Example Slide',
        subtitle: "You're doing great!",
        copy: `<p>This is an example slide. Your published zigpoll will look look something like this given your current settings.</p>`,
        settings: {
          showTitle: true
        }
      }]
    }

    return (
      <div className="visual-display" ref={this.el}>
        <BrowserFrame 
          title="Survey Preview"
          subtitle="See your settings below."
        />

        <Embed
          {...this.props}
          scalePoll={true}
          slides={slides}
          next={() => {
            // console.log('Do nothing');
          }}
          pollState={window.pollState}
        />
      </div>
    );
  }
}