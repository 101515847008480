import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Loading from './Loading';

import Layout from '../components/Layout';
import SingleFormPageHeader from '../components/SingleFormPageHeader';
import ResetPasswordForm from '../components/ResetPasswordForm';

import * as UserActions from '../actions/Users';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };

    const { token } = qs.parse(this.props.location.search);
    this.props.validateToken(token);
  }

  render() {
    if (!this.props.user.email) {
      return <Loading />
    }

    return (
      <Layout title="Reset Password" graphics={true}>
          <SingleFormPageHeader
            title="Reset your password"
            subtitle="Enter a new password below."
          />
          <ResetPasswordForm />
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(UserActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
