import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import PageDisplaySettingsForm from './PageDisplaySettingsForm';

import * as PollActions from '../actions/Polls';
import * as FlashNotificationActions from '../actions/FlashNotifications';

class PollPageDisplaySettingsForm extends PageDisplaySettingsForm {
  updateSettings() {
    const poll = {};
    poll._id = this.props.poll._id;
    poll.pageDisplaySettings = { ...this.state };

    const formData = new FormData();

    if (this.state.localLogoImage) {
      formData.append('logoImage', this.state.localLogoImage);
      delete poll.pageDisplaySettings.localLogoImage;
    }
    if (this.state.localBackgroundImage) {
      formData.append('backgroundImage', this.state.localBackgroundImage);
      delete poll.pageDisplaySettings.localBackgroundImage;
    }

    formData.append('poll', JSON.stringify(poll));

    this.props.updatePollPageDisplaySettings(formData, 'Your changes have been saved.').then(() => this.setInitialState());
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PollActions, ...FlashNotificationActions }, dispatch);
}

function mapStateToProps(state) {
  return {
    loading: state.polls.loading
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PollPageDisplaySettingsForm);
