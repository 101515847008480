import React, { Component } from 'react';

import { renderNumber } from '../utils';

export default class Pagination extends Component {
  render() {

    const { showPagination, showNext, showPrev, curPage, pageLength } = this.props;

    let pagination = null;

    if (showPagination) {
      let next = null;
      let prev = null;

      if (showPrev) {
        prev = (<div className="prev active" onClick={this.props.prev.bind(this)} />);
      } else {        
        prev = (<div className="prev" />);
      }

      if (showNext) {
        next = (<div className="next active" onClick={this.props.next.bind(this)} />);
      } else {
        next = (<div className="next" />);        
      }

      pagination = (<div className="pagination">
        { prev }
        <div>Page <span>{ curPage }</span> of <span>{ renderNumber(pageLength) }</span></div>
        { next }
      </div>);
    }

    return pagination;
  }
}