import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import LanguageSettingsForm from './LanguageSettingsForm';

import * as PollActions from '../actions/Polls';
import * as AccountActions from '../actions/Accounts';
import * as FlashNotificationActions from '../actions/FlashNotifications';

class PollLanguageSettingsForm extends LanguageSettingsForm {
  updateSettings() {
    const poll = {};
    poll._id = this.props.poll._id;
    poll.languageSettings = this.state.inputs;

    console.log(this.state.inputs);

    this.props.updateLanguageSettings(this.state.inputs, 'Your changes have been saved.').then(() => {
      this.setInitialState();
    });
    // this.props.updatePoll(poll, 'Your changes have been saved and pushed live.');
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PollActions, ...AccountActions, ...FlashNotificationActions }, dispatch);
}

function mapStateToProps(state) {
  const polls = state.polls || [];
  return {
    loading: state.accounts.loading,
    loadingKeys: state.accounts.loadingKeys || [],
    polls
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PollLanguageSettingsForm);
