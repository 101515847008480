import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import SmsDisplaySettingsForm from './SmsDisplaySettingsForm';

import * as PollActions from '../actions/Polls';
import * as FlashNotificationActions from '../actions/FlashNotifications';

class PollSmsDisplaySettingsForm extends SmsDisplaySettingsForm {
  updateSettings() {
    const poll = {};
    poll._id = this.props.poll._id;
    poll.smsDisplaySettings = { ...this.state };

    const formData = new FormData();

    formData.append('poll', JSON.stringify(poll));

    this.props.updatePollSmsDisplaySettings(formData, 'Your changes have been saved.').then(() => this.setInitialState());
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PollActions, ...FlashNotificationActions }, dispatch);
}

function mapStateToProps(state) {
  return {
    loading: state.polls.loading
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PollSmsDisplaySettingsForm);
