import React, { Component } from 'react';
import moment from 'moment';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as FlashNotificationActions from '../actions/FlashNotifications';
import * as AccountsActions from '../actions/Accounts';

import Modal from './Modal';
import Tooltip from './Tooltip';
import Switch from './Switch';

import { decode, validateEmail } from '../utils';

class ExportReportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.user.email,
      includeColumns: true
    };
  }

  onChange(e) {
    if (e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <Modal 
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
      >
        <div className="frame export-csv">
          <div className="close" onClick={this.props.closeModal} />
          <div className="title">Where should we send it?</div>
          <div className="content">
            <div>
              <label>Send To:</label>
              <input type="email" name="email" value={ this.state.email } onChange={this.onChange.bind(this)}/>
            </div>

            <div style={{ marginTop: -10, marginBottom: 25 }}>
              <label>Data Format</label>
              <Switch
                vertical={true}
                options={[
                  { label: 'Include selected columns only', value: true },
                  { label: 'Include all data', value: false },
                ]}
                value={this.state.includeColumns}
                onChange={(value) => {
                  this.onChange({ target: { name: 'includeColumns', value: value } }); 
                }}
              />
            </div>

            <div className="actions">
              <button className="positive" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (!validateEmail(this.state.email)) {
                  return this.props.flash('Please enter a valid email address.');
                }

                this.props.onConfirm(this.state.email, this.state.includeColumns);
              }}>Send Export</button>
              <button className="negative" onClick={this.props.closeModal}>Cancel</button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId] || {};

  return {
    account,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...FlashNotificationActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExportReportModal));