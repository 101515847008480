import { 
  FETCHING_PARTICIPANTS,
  FETCHED_PARTICIPANTS,
  FETCHING_EVENTS,
  FETCHED_EVENTS,
  FETCHING_PARTICIPANT_DATA,
  FETCHED_PARTICIPANT_DATA,
} from '../actions/Participants';

export default function participants(state = {}, action) {
  let participants;

  switch (action.type) {
    case FETCHING_PARTICIPANTS:
      return { ...state, loading: true }
    case FETCHED_PARTICIPANTS:
      participants = {};
      participants[action.id] = action.participants;
      return { ...state, ...participants, loading: false }
    case FETCHING_EVENTS:
      participants = state;
      participants[action.id].events.loading = true;
      return { ...participants, loadingEvents: true }
    case FETCHED_EVENTS:
      participants = state;
      participants[action.id].events = action.events;
      return { ...participants, loadingEvents: false }
    case FETCHED_PARTICIPANT_DATA:
      participants = state;
      participants[action.id][action.key] = action.data;
      return { ...participants, loadingEvents: false }
    default:
      return state;
  }
}
