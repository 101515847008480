import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import * as AccountActions from '../actions/Accounts';
import Dropdown from './Dropdown';

import { connect } from 'react-redux';

class PollsDropdown extends Dropdown {
  constructor(props) {
    super(props);
    this.state = { active: false };
    this.url = this.props.url;
    this.icon = 'fa-chart-pie';
    this.title = 'Survey';
    this.className = 'polls-dropdown';
  }

  fetchItems() {
    this.props.fetchAccount();
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions }, dispatch);
}

export default withRouter(connect(undefined, mapDispatchToProps)(PollsDropdown));
