import React, { Component } from 'react';
// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';

import $ from 'jquery';
import _ from 'lodash';

// import * as AccountActions from '../actions/Accounts';
// import * as FlashNotificationActions from '../actions/FlashNotifications';

import HeaderButtons from '../components/HeaderButtons';
import ColorPicker from '../components/ColorPicker';
import Toggle from '../components/Toggle';
import Switch from '../components/Switch';
import PositionInput from '../components/PositionInput';
import TopOffsetInput from '../components/TopOffsetInput';
import SizeInput from '../components/SizeInput';

import AlignmentInput from '../components/AlignmentInput';
import AccountSettingsVisualDisplay from '../components/AccountSettingsVisualDisplay';
import Tooltip from '../components/Tooltip';
import { NameInput, CSSInput, HTMLInput } from '../components/SimpleInputs';
import IconSelector from '../components/IconSelector';
import SectionHeader from '../components/SectionHeader';
import { AuthSubmitButton } from './Buttons';
import { FontSizeSlider, BorderRadiusSlider, LauncherBorderRadiusSlider, BorderWidthSlider } from './Sliders';

import SingleImageUploader from './SingleImageUploader';

import { encode } from '../utils';

export default class DisplaySettingsForm extends Component {
  constructor(props) {
    super(props);

    const settings = _.cloneDeep(props.settings);

    this.state = {
      backgroundColor: settings.backgroundColor,
      backgroundImage: settings.backgroundImage,
      actionColor: settings.actionColor,
      notificationColor: settings.notificationColor,
      borderRadius: settings.borderRadius,
      launcherBorderRadius: settings.launcherBorderRadius,
      borderWidth: settings.borderWidth,
      fontSize: settings.fontSize || 16,
      launcherFontSize: settings.launcherFontSize || settings.fontSize || 16,
      fontFamily: settings.fontFamily || 'sans',
      textDirection: settings.textDirection || 'ltr',
      // enableSounds: settings.enableSounds === undefined ? true : settings.enableSounds,
      showLink: settings.showLink,
      useCustomCSS: settings.useCustomCSS,
      useCustomCode: settings.useCustomCode,
      useCustomZIndex: settings.useCustomZIndex,
      shape: settings.shape || 'circle',
      customCSS: settings.customCSS,
      customCode: settings.customCode,
      zIndex: settings.zIndex,
      align: settings.align === undefined ? true : settings.align,
      maxWidth: settings.maxWidth || 400,
      maxHeight: settings.maxHeight || 500,
      position: settings.position,
      topOffset: settings.topOffset || 0,
      hideStatus: settings.hideStatus,
      hideLauncher: settings.hideLauncher,
      launcherSettings: settings.launcherSettings
    };

    this.pollState = 'before-submission';
    this.launcherSettings = this.state.launcherSettings['before-submission'] || {};
    this.showPollStateSelector = this.state.launcherSettings['after-submission'] ? true : false

    this.active = true
    if (this.state.align === 'modal' || this.state.hideLauncher) {
      this.active = true;      
    }

    this.setInitialState();

    this.scroll = _.throttle(this.scroll.bind(this), 0, { leading: true, trailing: true });
  }

  // componentDidMount() {
  //   $('.foreground').on('scroll.account', this.scroll);
  //   this.scroll();
  // }

  // componentWillUnmount() {
  //   $('.foreground').off('scroll.account', this.scroll)
  // }

  scroll() {
    let $el = $('.account-display .visual-display').first();
    
    let zoom = $('html').css('zoom') || 1;
    zoom = parseFloat(zoom);

    let t = $('form.account-settings')[0].getBoundingClientRect().y - $('.foreground')[0].getBoundingClientRect().y * zoom;
    let st = 0;

    let b = $('form.account-settings').height();
    let atBottom = ((st - t) + $el.height()) >= b;

    if ( (t) < 10 && !atBottom ) {
      $el.css('transform', `translateY(${-t + 20}px)`);
    } else if (atBottom) {
      $el.css('transform', `translateY(${b - $el.height()}px)`);
    } else {
      $el.css('transform', `translateY(0px)`);
    }  
  }

  setInitialState() {
    const settings = this.props.settings || {};
    this.initialState = { ...this.state, position: [ ...settings.position ], launcherSettings: _.cloneDeep(settings.launcherSettings) };

    setTimeout(() => {
      this.forceUpdate();
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    const prevSettings = { ...prevProps.settings, position: [ ...prevProps.settings.position ], launcherSettings: _.cloneDeep(prevProps.settings.launcherSettings) };

    if (JSON.stringify(prevSettings) !== JSON.stringify(this.props.settings)) {
      this.setState({ launcherSettings: this.props.settings.launcherSettings }, () => {
          this.launcherSettings = this.state.launcherSettings[this.pollState] || {};
          this.setInitialState();
      })
    }
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.updateSettings();
  }

  updateSettings() {
    const account = {};
    account._id = this.props.account._id;
    account.displaySettings = { ...this.state };

    const formData = new FormData();

    const launcherSettings = this.state.launcherSettings;
    const beforeSubmission = launcherSettings['before-submission'];
    if (beforeSubmission && beforeSubmission.launcherLocalImage) {
      if (beforeSubmission.launcherLocalImage === -1) {
        delete beforeSubmission.image;
      } else {
        formData.append('before-submission-launcherImage', beforeSubmission.launcherLocalImage);
      }
      delete account.displaySettings.launcherSettings['before-submission'].launcherLocalImage;
    }
    const afterSubmission = launcherSettings['after-submission'];
    if (afterSubmission && afterSubmission.launcherLocalImage) {
      if (afterSubmission.launcherLocalImage === -1) {
        delete afterSubmission.image;
      } else {
        formData.append('after-submission-launcherImage', afterSubmission.launcherLocalImage);
      }
      delete account.displaySettings.launcherSettings['after-submission'].launcherLocalImage;
    }

    if (this.state.localBackgroundImage) {
      formData.append('backgroundImage', this.state.localBackgroundImage);
      delete account.displaySettings.localBackgroundImage;
    }

    formData.append('account', JSON.stringify(account));
    this.props.updateAccountDisplaySettings(account._id, formData, 'Your changes have been saved and pushed live.').then(() => this.setInitialState());
  }

  toggleActive() {
    if (this.state.align === 'modal') {
      this.active = true;
      this.forceUpdate();
      return;
    }

    this.active = !this.active;
    this.forceUpdate();
  }

  showPoll() {
    this.active = true;
    this.forceUpdate();
  }

  hidePoll() {
    if (this.state.align === 'modal') {
      this.active = true;
      this.forceUpdate();
      return;
    }

    this.active = false;
    this.forceUpdate();
  }

  updatePollState(value) {
    window.pollState = value;
    this.pollState = value;
    this.launcherSettings = this.state.launcherSettings[value] || {};
    this.forceUpdate();
  }

  updateLauncherSettings(key, value) {
    this.launcherSettings[key] = value;
    const launcherSettings = this.state.launcherSettings;
    launcherSettings[this.pollState] = this.launcherSettings;

    if (value === null) {
      delete this.launcherSettings[key];
    }

    this.setState({ launcherSettings });
    // this.forceUpdate();
  }

  cancel() {
    this.launcherSettings = _.cloneDeep(this.initialState.launcherSettings)[this.pollState] || {};
    this.setState({ ...this.initialState, position: [ ...this.initialState.position ], launcherSettings: _.cloneDeep(this.initialState.launcherSettings) });

    if (this.initialState.launcherSettings['after-submission'] === undefined) {
      this.updatePollState('before-submission');
      this.showPollStateSelector = false;
      this.forceUpdate();
    } else {
      this.showPollStateSelector = true;
      this.forceUpdate();
    }

    // this.forceUpdate();
    this.props.flash('Your changes have been cancelled.');
  }

  onBackgroundImageUpload(image) {
    if (!image) {
      this.setState({ 
        backgroundImage: undefined,
        localBackgroundImage: undefined
      });
    } else {
      this.setState({
        localBackgroundImage: image,
        backgroundImage: image.url
      });
    }
  }

  render() {
    const hasChanged = JSON.stringify(this.state) !== JSON.stringify(this.initialState);

    let title = (<SectionHeader
      title=<span><i className="fas fa-palette" />Widget Design Settings</span>
      subtitle="Control the appearance of your launcher, survey, and slides."
      className="no-margin center"
    />
  );
    if (this.props.hideTitle) {
      title = null;
    }


    let zIndexInput = null;
    if (this.state.useCustomZIndex) {
      zIndexInput = (
        <NameInput
          onChange={(e) => 
            this.setState({ zIndex: e.target.value })
          }
          type="text"
          placeholder="2147483647"
          name="zIndex"
          onValidate={() => console.log('validate')}
          noValidate={true}
          value={this.state.zIndex}
        />
      );
    }

    let cssInput = null;
    if (this.state.useCustomCSS) {
      cssInput = (
        <CSSInput
          style={{ marginTop: 25 }}
          disabled={ !this.state.useCustomCSS }
          onChange={(value) => this.setState({ customCSS: value })}
          label="Custom CSS"
          moreInfo="This CSS will be included in your survey wherever it is rendered."
          type="text"
          placeholder="* { background: red; }"
          name="customCSS"
          onValidate={() => console.log('validate')}
          noValidate={true}
          value={this.state.customCSS}
        />
      );
    }

    let customCodeInput = null;
    if (this.state.useCustomCode) {
      customCodeInput = (
        <HTMLInput
          style={{ marginTop: 25 }}
          disabled={ !this.state.useCustomCode }
          onChange={(value) => this.setState({ customCode: value })}
          label="Custom Code"
          moreInfo="This Code will be included the iframes for all Zigpoll components."
          type="text"
          placeholder="<script src='' type='text/javascript' />"
          name="customCSS"
          onValidate={() => console.log('validate')}
          noValidate={true}
          value={this.state.customCode}
        />
      );
    }

    let pollStateSelector = null;
    if (this.showPollStateSelector) {
      pollStateSelector = (
      <div>
        <div>
          <label>Survey State<Tooltip>Use this if you want to show a different launcher once a user fills out a survey. For example you can change the copy to read "Thanks!" or some other message once the survey is completed.</Tooltip></label>
          <div className="subtle">Create before / after submission copy for your launcher.</div>

          <div className="inline-toggle">
            <Switch 
              options={[
                { label: 'Before Submission', value: 'before-submission'},
                { label: 'After Submission', value: 'after-submission'}
              ]}
              value={this.pollState}
              onChange={(value) => {
                this.updatePollState(value);
              }}
            />
          </div>
        </div>

        <div className="card-divider dashed"><h6>{ this.pollState === 'before-submission' ? "Before Submission" : "After Submission" }</h6></div>
      </div>
      );
    }

    let modalWarning = null;
    if (this.state.align === 'modal') {
      modalWarning = (<div className="input-warning"><div className="content"><div>Your survey configured to be a modal. These settings will not have any effect.</div></div></div>);
    }

    return (
      <div className="account-display" style={this.props.style || {}}>
        { title }
        <div className="split">
          <div>
            <form 
              onSubmit={this.onSubmit.bind(this)}
              className={`account-settings ${this.props.loading ? 'loading' : ''}`}
            >
              <div className="card">
                <div className="card-divider" style={{ marginTop: -15 }}>
                  <h6>Color</h6>
                </div>

                <div style={{zIndex: 5, position: 'relative'}}>
                  <label>Border Color</label>
                  <div className="subtle">Color for the border of your survey.</div>
                  <ColorPicker
                    color={this.state.backgroundColor}
                    onOpen={this.showPoll.bind(this)}
                    onChange={(color, event) => {
                      this.setState({ backgroundColor: color.hex });
                    }}
                  />
                </div>

                {/*
                <div>
                  <label>Background Image<Tooltip>Image should be 1080x300 pixels It will be resized automatically on upload.</Tooltip> <span>optional</span></label>
                  <div className="subtle">Use an image for your survey background.</div>


                  <span onClick={this.showPoll.bind(this)}>
                    <SingleImageUploader
                      hideLabel={true}
                      key={this.state.backgroundImage}
                      image={this.state.backgroundImage}
                      onChange={this.onBackgroundImageUpload.bind(this)} />
                  </span>
                </div>
                */}

                <div>
                  <label>Action Color</label>
                  <div className="subtle">Used in buttons, links and highlights for emphasis.</div>
                  <ColorPicker
                    color={this.state.actionColor}
                    onChange={(color, event) => {
                      this.setState({ actionColor: color.hex });
                    }}
                  />
                </div>

                {/*
                <div>
                  <label>Notification Color</label>
                  <div className="subtle">Used in when there is a notification to show.</div>
                  <ColorPicker
                    color={this.state.notificationColor}
                    onOpen={this.hidePoll.bind(this)}
                    onChange={(color, event) => {
                      this.setState({ notificationColor: color.hex });
                    }}
                  />
                </div>
                */}

                <div className="card-divider">
                  <h6>Font</h6>
                </div>

                <div style={{ marginTop: 15 }} className="font-family">
                  <label>Font Family</label>
                  <div className="subtle">Choose a font family that matches your site.</div>

                  <div className="inline-toggle">
                    <Switch 
                      options={[
                        { label: 'Sans Serif', value: 'sans'},
                        { label: 'Serif', value: 'serif', className: 'serif' },
                        { label: 'Slab', value: 'slab', className: 'slab' },
                      ]}
                      value={this.state.fontFamily}
                      onChange={(value) => {
                        if (!this.active) {
                          this.showPoll();
                        }
                        this.setState({ fontFamily: value });
                      }}
                    />
                  </div>
                </div>

                <div style={{ marginTop: 15 }}>
                  <label>Text Direction</label>
                  <div className="subtle">Adjust to match your language preferences.</div>

                  <div className="inline-toggle">
                    <Switch 
                      options={[
                        { label: 'Left to right', value: 'ltr' },
                        { label: 'Right to left', value: 'rtl' }
                      ]}
                      value={this.state.textDirection}
                      onChange={(value) => {
                        if (!this.active) {
                          this.showPoll();
                        }
                        this.setState({ textDirection: value });
                      }}
                    />
                  </div>
                </div>

                <div className="card-divider">
                  <h6>Border</h6>
                </div>

                <div className="inline-toggle">
                  <label>Border Radius<Tooltip>The default value is a 10px border radius.</Tooltip></label>
                  <BorderRadiusSlider
                    value={this.state.borderRadius === undefined ? 10 : this.state.borderRadius}
                    onChange={(value) => {
                      this.setState({ borderRadius: value });
                    }}
                    onBeforeChange={() => {
                      if (!this.active) {
                        this.showPoll();
                      }                      
                    }}
                  />
                </div>

                <div className="inline-toggle">
                  <label>Border Width<Tooltip>The default value is a 2px border width.</Tooltip></label>
                  <BorderWidthSlider
                    value={this.state.borderWidth === undefined ? 2 : this.state.borderWidth}
                    onChange={(value) => {
                      this.setState({ borderWidth: value });
                    }}
                    onBeforeChange={() => {
                      if (!this.active) {
                        this.showPoll();
                      }                      
                    }}
                  />
                </div>

                <div className="card-divider">
                  <h6>Layout</h6>
                </div>

                <div>
                  <label>Position</label>
                  <div className="subtle">Configure where your zigpoll widget appears.</div>

                  <div className="inline-toggle" style={{marginBottom: 20 }}>
                    <AlignmentInput
                      value={this.state.align}
                      onChange={(value) => {
                        if (!this.active && value === 'modal') {
                          this.showPoll();
                        }

                        this.setState({ align: value });
                      }}
                    />
{/*                    <Switch 
                      options={[
                        { label: 'Left', value: 'left'},
                        { label: 'Right', value: 'right'},
                        { label: 'Right Middle', value: 'right-middle'},
                        { label: 'Left Middle', value: 'left-middle'},
                        { label: 'Modal', value: 'modal'}
                      ]}
                      value={this.state.align}
                      onChange={(value) => {
                        if (!this.active && value === 'modal') {
                          this.showPoll();
                        }

                        this.setState({ align: value });
                      }}
                    />*/}
                  </div>
                </div>

                <div className="inline-toggle">
                  <label>Offset</label>
                  <div className="subtle" style={{ marginTop: 0 }}>Configure where your zigpoll widget appears.</div>
                </div>

                <div className="inline-toggle" style={{ marginBottom: 15 }}>
                  { (this.state.align.indexOf('-middle') === -1) && <PositionInput
                    disabled={(this.state.align === 'modal') || (this.state.align.indexOf('-middle') !== -1)}
                    bottomDisabled={this.state.shape === 'bumper'}
                    align={this.state.align}
                    position={this.state.position}
                    onChange={(position) => {
                      this.setState({ position });
                    }}
                  /> }

                  { (this.state.align.indexOf('-middle') !== -1) && <TopOffsetInput
                    value={this.state.topOffset === undefined ? 0 : this.state.topOffset}
                    onChange={(topOffset) => {
                      this.setState({ topOffset });
                    }}
                  /> }
                </div>

                <div className="inline-toggle">
                  <label>Size</label>
                  <div className="subtle" style={{ marginTop: 0 }}>Adjust the width and maximum height of your survey.</div>
                </div>

                <div className="inline-toggle">
                  <SizeInput
                    size={{
                      maxWidth: this.state.maxWidth,
                      maxHeight: this.state.maxHeight
                    }}
                    onChange={({ maxWidth, maxHeight }) => {
                      this.setState({ maxWidth, maxHeight });
                    }}
                  />
                </div>

                <div className="card-divider">
                  <h6>Size</h6>
                </div>

                <div>
                  <label>Survey Scale</label>
                  <div className="subtle">Configure the size of survey.</div>

                  <FontSizeSlider
                    value={this.state.fontSize}
                    onChange={(value) => {
                      this.setState({ fontSize: value });
                    }}
                    onBeforeChange={() => {
                      if (!this.active) {
                        this.showPoll();
                      }                      
                    }}
                  />
                </div>

                <div className="card-divider">
                  <h6>Launcher</h6>
                </div>

                <div style={{ marginBottom: 20, position: 'relative' }}>

                  { modalWarning }

                  <div style={{ marginBottom: 30 }}>
                    <label>Hide Launcher</label>
                    <div className="inline-toggle">
                      <Toggle 
                        active={this.state.hideLauncher}
                        disabled={this.state.align === 'modal'}
                        onChange={(value) => {
                          this.showPoll();
                          this.setState({ hideLauncher: value });
                        }}
                      />
                      <div className="subtle">Never show the launcher.</div>
                    </div>
                  </div>

                  <label>Shape</label>
                  <div className="subtle">Pick what shape the launcher should be.</div>

                  <div className="inline-toggle">
                    <Switch 
                      options={[
                        { label: 'Circle', value: 'circle'},
                        { label: 'Bumper', value: 'bumper'}
                      ]}
                      disabled={this.state.align === 'modal' || this.state.hideLauncher}
                      value={this.state.shape || 'circle'}
                      onChange={(value) => {
                        if (!this.active && value === 'modal') {
                          this.showPoll();
                        }

                        if (value === 'circle') {
                          this.setState({ launcherBorderRadius: 25 })
                        } else {
                          this.setState({ launcherBorderRadius: 10 })                          
                        }
                        this.setState({ shape: value });
                      }}
                    />
                  </div>
                </div>

                <div style={{ marginBottom: 30 }}>
                  <label>Border Radius</label>
                  <LauncherBorderRadiusSlider
                    disabled={this.state.align === 'modal' || this.state.hideLauncher}
                    value={this.state.launcherBorderRadius === undefined ? (this.state.shape === 'circle' ? 25 : 10) : this.state.launcherBorderRadius}
                    onChange={(value) => {
                      this.setState({ launcherBorderRadius: value });
                    }}
                    onBeforeChange={() => {
                    }}
                  />
                </div>

                <div style={{ marginBottom: 30 }}>
                  <label>Launcher Scale</label>
                  <div className="subtle">Configure the size of your launcher.</div>

                  <FontSizeSlider
                    value={this.state.launcherFontSize}
                    onChange={(value) => {
                      this.setState({ launcherFontSize: value });
                    }}
                    disabled={this.state.align === 'modal' || this.state.hideLauncher}
                    onBeforeChange={() => {
                      if (!this.active) {
                        this.showPoll();
                      }                      
                    }}
                  />
                </div>

                <div style={{ marginBottom: 30 }}>
                  <label>Dynamic Launcher<Tooltip>If you enable this, you can change the appearance of the launcher after the survey has been filled out.</Tooltip></label>
                  <div className="inline-toggle">
                    <Toggle 
                      active={this.showPollStateSelector}
                      disabled={this.state.align === 'modal' || this.state.hideLauncher}
                      onChange={(value) => {
                        this.showPollStateSelector = value;

                        if (!this.showPollStateSelector) {
                          delete this.state.launcherSettings['after-submission'];
                          this.updatePollState('before-submission');
                        }

                        this.forceUpdate();
                      }}
                    />
                    <div className="subtle">Change copy or icon after the survey is completed.</div>
                  </div>
                </div>

                { pollStateSelector }

                <div>
                  <label>Desktop Layout</label>
                  <div className="subtle">Configure how your launcher looks.</div>

                  <div className="inline-toggle">
                    <Switch 
                      options={[
                        { label: 'Icon & Text', value: 'icon-text'},
                        { label: 'Icon Only', value: 'icon-only'},
                        { label: 'Text Only', value: 'text-only'}
                      ]}
                      disabled={this.state.align === 'modal' || this.state.hideLauncher}
                      value={this.launcherSettings.launcherDisplayType || 'icon-text'}
                      onChange={(value) => {
                        if (this.active) {
                          this.hidePoll()
                        }
                        this.updateLauncherSettings('launcherDisplayType', value);
                        // this.setState({ launcherDisplayType: value });
                      }}
                    />
                  </div>

                <div style={{ marginTop: 15 }}>
                  <label>Mobile Layout</label>
                  <div className="subtle">Configure how your launcher looks.</div>

                    <div className="inline-toggle">
                      <Switch 
                        options={[
                          { label: 'Icon & Text', value: 'icon-text'},
                          { label: 'Icon Only', value: 'icon-only'},
                          { label: 'Text Only', value: 'text-only'}
                        ]}
                        disabled={this.state.align === 'modal' || this.state.hideLauncher}
                        value={this.launcherSettings.launcherDisplayTypeMobile || 'icon-only'}
                        onChange={(value) => {
                          if (this.active) {
                            this.hidePoll()
                          }
                          this.updateLauncherSettings('launcherDisplayTypeMobile', value);
                          // this.setState({ launcherDisplayType: value });
                        }}
                      />
                    </div>
                  </div>

                  <div className="inline-toggle" style={{ marginTop: 15 }}>
                    <NameInput
                      disabled={(this.state.align === 'modal') || this.state.hideLauncher}
                      onChange={(e) => this.updateLauncherSettings(e.target.name, e.target.value)}
                      onFocus={() => {
                        if (this.active) {
                          this.hidePoll()
                        }                        
                      }}
                      label="Launcher Text"
                      moreInfo="This title will be used for your reference so pick something simple that you can easily identify."
                      maxlength={25}
                      type="text"
                      placeholder="Example: Got a sec?"
                      name="launcherText"
                      onValidate={() => console.log('validate')}
                      noValidate={true}
                      value={this.launcherSettings.launcherText}
                    />
                  </div>

                  <IconSelector
                    key={this.launcherSettings.launcherIcon}
                    selected={this.launcherSettings.launcherIcon}
                    disabled={(this.state.align === 'modal') || this.state.hideLauncher}
                    type={this.launcherSettings.launcherImage ? 'image' : 'default'}
                    image={this.launcherSettings.launcherImage}
                    pollState={this.pollState}
                    onChange={(data, type) => {
                      if (this.active) {
                        this.hidePoll()
                      }
                      if (type === 'image') {
                        if (data) {
                          let fd = new FormData();
                          fd.append("file", data, data.name);
                          let _data = fd.get("file");

                          this.updateLauncherSettings('launcherLocalImage', _data );
                          this.updateLauncherSettings('launcherImage', data.url);
                        } else {
                          if (this.launcherSettings.launcherImage.indexOf('blob') !== -1) {
                            this.updateLauncherSettings('launcherLocalImage', null);
                            this.updateLauncherSettings('launcherImage', null);
                          } else {
                            this.updateLauncherSettings('launcherLocalImage', -1);
                            this.updateLauncherSettings('launcherImage', null);
                          }
                        }
                      } else {
                        this.updateLauncherSettings('launcherIcon', data);

                        if (this.launcherSettings.launcherImage) {
                          this.updateLauncherSettings('launcherLocalImage', -1);
                          this.updateLauncherSettings('launcherImage', null);
                        }
                      }
                    }}
                  />
                  { this.showPollStateSelector ? <div className="card-divider dashed" ><h6 /></div> : null }
                </div>

                <div className="card-divider">
                  <h6>Branding</h6>
                </div>

                <div>
                  <label>Zigpoll Link<Tooltip>You can only disable branding if you subscribe to a <a href={`/a/${encode(this.props.account._id)}/manage`}><strong>Plus</strong></a> plan or better.</Tooltip></label>
                  <div className="inline-toggle">
                    <Toggle 
                      active={this.state.showLink}
                      disabled={this.props.account.plan.forceBranding}
                      onChange={(value) => {
                        this.setState({ showLink: value });
                      }}
                    />
                    <div className="subtle">Display a subtle link to the Zigpoll website.</div>
                  </div>
                </div>

                <div className="card-divider">
                  <h6>Advanced</h6>
                </div>

                <div>
                  <label>Custom Z Index<Tooltip>Adjust the stack placement of your survey within your webpage to avoid overlaps.</Tooltip></label>
                  <div className="inline-toggle">
                    <Toggle 
                      active={this.state.useCustomZIndex}
                      onChange={(value) => {
                        if (!value) {
                          this.setState({ zIndex: undefined });
                        }
                        this.setState({ useCustomZIndex: value });
                      }}
                    />
                    <div className="subtle">Apply a custom z-index to your survey.</div>
                  </div>
                </div>

                { zIndexInput }

                <div style={{ marginTop: 25 }}>
                  <label>Custom CSS<Tooltip>You can only use custom CSS if you subscribe to a <strong>Standard</strong> plan or better.</Tooltip></label>
                  <div className="inline-toggle">
                    <Toggle 
                      active={this.state.useCustomCSS}
                      disabled={this.props.account.plan.forceBranding}
                      onChange={(value) => {
                        this.setState({ useCustomCSS: value });
                      }}
                    />
                    <div className="subtle">Apply custom CSS rules to Zigpoll.</div>
                  </div>
                </div>

                { cssInput }

                <div style={{ marginTop: 25 }}>
                  <label>Custom Code<Tooltip>You can only use custom Code if you subscribe to a <strong>Pro</strong> plan or better.</Tooltip></label>
                  <div className="inline-toggle">
                    <Toggle 
                      active={this.state.useCustomCode}
                      disabled={this.props.account.plan.forceBranding}
                      onChange={(value) => {
                        this.setState({ useCustomCode: value });
                      }}
                    />
                    <div className="subtle">Insert custom code into Zigpoll iframes.</div>
                  </div>
                </div>

                { customCodeInput }

                {/*
                <div>
                  <label>Sounds</label>
                  <div className="inline-toggle">
                    <Toggle
                      active={this.state.enableSounds}
                      onChange={(value) => {
                        this.setState({ enableSounds: value });
                      }}
                    />
                    <div className="subtle">Play a sound when zigpoll first loads</div>
                  </div>
                </div>
                */}

                <div className={`actions ${hasChanged ? 'active' : ''}`}>
                  <AuthSubmitButton 
                    title="Save Changes"
                    disabled={!hasChanged}
                  />
                  <button 
                    className="cancel"
                    disabled={!hasChanged}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.cancel();
                    }}
                  >Cancel</button>
                </div>
              </div>
            </form>
          </div>
          <div className="preview-container">
            <AccountSettingsVisualDisplay
              {...this.state}
              slides={this.props.poll ? this.props.poll.slides : []}
              active={this.active}
              toggleActive={this.toggleActive.bind(this)}/>
          </div>
        </div>

        <HeaderButtons>
          <button 
            disabled={!hasChanged}
            onClick={this.onSubmit.bind(this)}>{ this.props.loading ? 'Saving...' : 'Save Changes' }</button>

          <button 
            className="cancel"
            disabled={!hasChanged}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.cancel();
            }}
          >Cancel</button>
        </HeaderButtons>
      </div>
    );
  }
}
