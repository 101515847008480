/* User Reducer */
import { PUSH_FLASH_NOTIFICATION, POP_FLASH_NOTIFICATION } from '../actions/FlashNotifications';

export default function user(state = [], action) {
  switch (action.type) {
    case PUSH_FLASH_NOTIFICATION:
      return [ ...state, action.notification ]
    case POP_FLASH_NOTIFICATION:
      const copy = [ ...state ];
      copy.shift();
      return copy;
    default:
      return state;
  }
}
