import download from 'downloadjs';
import qs from 'query-string';
import _ from 'lodash';

import { replace } from 'connected-react-router';
import { pushServerError } from './Notifications';
import { flash } from './FlashNotifications';
import { post } from '../ajax';
import { parseUrl, encode, generateFilename } from '../utils';
import { url } from '../settings';

import { fetchAccountWithPolls } from './Accounts';
import { fetchPollWithSlides } from './Polls';
import { fetchSlide } from './Slides';

export const FETCHING_RESPONSES = 'FETCHING_RESPONSES';
export const FETCHED_RESPONSES = 'FETCHED_RESPONSES';

export const FETCHING_RESPONSE = 'FETCHING_RESPONSE';
export const FETCHED_RESPONSE = 'FETCHED_RESPONSE';

export const APPROVE_RESPONSE = 'APPROVE_RESPONSE';
export const UPDATE_RESPONSE_RANK = 'UPDATE_RESPONSE_RANK';

function fetchingResponse(slideId, eventId) {
  return {
    type: FETCHING_RESPONSE,
    slideId,
    eventId,
  };
}

function fetchedResponse(slideId, eventId, response) {
  return {
    type: FETCHED_RESPONSE,
    slideId,
    eventId,
    response
  };
}

function fetchingResponses(slideId) {
  return {
    type: FETCHING_RESPONSES,
    slideId
  };
}

function fetchedResponses(slideId, json) {
  return {
    type: FETCHED_RESPONSES,
    slideId,
    responses: json
  };
}

export function fetchSlideAndResponses(page = 0, sortBy) {
  return (dispatch, getState) => {
    return dispatch(fetchSlide())
      .then(dispatch(fetchResponses(page, sortBy)));
  }
}

function updateQuery(dispatch, getState, page, sortBy, filter, pollId, slideId) {
  const curQuery = qs.parse(getState().router.location.search);
  const params = { ...curQuery, page: page+1, sortBy };
  if (filter !== undefined) {
    params.filter = filter;
  }
  if (pollId) {
    params.pollId = encode(pollId);
  }
  if (slideId) {
    params.slideId = encode(slideId);
  }
  dispatch(replace({ search: qs.stringify(params) }));
}

export function fetchResponses(page = 0, sortBy) {
  return (dispatch, getState) => {

    const [ accountId, pollId, slideId ] = parseUrl(getState().router);

    dispatch(fetchingResponses(slideId));
    updateQuery(dispatch, getState, page, sortBy);

    return post(`${url}/account/${accountId}/poll/${pollId}/slide/${slideId}/responses/getPage/${page}`, { sortBy })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(fetchedResponses(slideId, json));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function fetchResponse(slideId, eventId, responseId) {
  return (dispatch, getState) => {
    if (!responseId) { return; }

    const [ accountId ] = parseUrl(getState().router);

    dispatch(fetchingResponse(slideId, eventId));
    return post(`${url}/account/${accountId}/response/${responseId}`)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(fetchedResponse(slideId, eventId, json));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function exportResponses(accountId, pollId, slideId, filter) {
  return (dispatch, getState) => {

    // const [ accountId, pollId, slideId ] = parseUrl(getState().router);

    // dispatch(fetchingEmails(slideId));
    return post(`${url}/account/${accountId}/poll/${pollId}/slide/${slideId}/responses/export`, { filter })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }

      return response.blob();
    }).then((blob) => {
      download(blob, generateFilename('Slide Responses Export'), 'text/csv');
      dispatch(flash('Successfully downloaded responses.'));
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function exportAccountResponses() {
  return (dispatch, getState) => {

    const [ accountId ] = parseUrl(getState().router);

    // dispatch(fetchingEmails(slideId));
    return post(`${url}/account/${accountId}/responses/export`)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }

      return response.blob();
    }).then((blob) => {
      download(blob, generateFilename('Account Responses Export'), 'text/csv');
      dispatch(flash('Successfully downloaded responses.'));
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function exportPollResponses() {
  return (dispatch, getState) => {

    const [ accountId, pollId ] = parseUrl(getState().router);

    // dispatch(fetchingEmails(slideId));
    return post(`${url}/account/${accountId}/poll/${pollId}/responses/export`)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }

      return response.blob();
    }).then((blob) => {
      download(blob, generateFilename('Survey Responses Export'), 'text/csv');
      dispatch(flash('Successfully downloaded responses.'));
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}


export function setApproval(eventId, responseId, approved) {
  return (dispatch, getState) => {

    const [ accountId ] = parseUrl(getState().router);

    dispatch({
      type: APPROVE_RESPONSE,
      slideId: accountId,
      responseId: eventId,
      approved
    });

    return post(`${url}/account/${accountId}/responses/${responseId}/setApproval`, { approved })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        console.log(json);
        // dispatch(fetchedResponses(slideId, json));
      }
    })
    .catch(error => dispatch(pushServerError(error)));
  };
}

export function setRank(eventId, responseId, rank) {
  return (dispatch, getState) => {

    const [ accountId ] = parseUrl(getState().router);

    dispatch({
      type: UPDATE_RESPONSE_RANK,
      slideId: accountId,
      responseId: eventId,
      rank
    });

    return post(`${url}/account/${accountId}/responses/${responseId}/setRank`, { rank })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        console.log(json);
        // dispatch(fetchedResponses(slideId, json));
      }
    })
    .catch(error => dispatch(pushServerError(error)));
  };
}

export function submitTags(tags, response) {
  return (dispatch, getState) => {

    const [ accountId ] = parseUrl(getState().router);
    const { _id } = response;

    return post(`${url}/account/${accountId}/responses/${_id}/addTags`, { tags, responseId: _id, accountId })
    .then(json => {
      if (json.error) {
        dispatch(flash(json.error));
      } else {
        console.log('done');
        console.log(json);
      }
    })
    .catch(error => dispatch(flash(error)));
  };
}

export function fetchAccountResponses(page = 0, sortBy, filter, pollId, slideId, isFirst) {
  return (dispatch, getState) => {

    const state = getState();
    const [ accountId ] = parseUrl(state.router);
    const account = _.find(state.accounts, ({ _id }) => _id === accountId);

    if (!account) { return; }

    // return dispatch(fetchAccountWithPolls())
    // .then((account) => {
      let poll = _.find(account.polls, ({ _id }) => _id == pollId) || (account.polls ? account.polls[0] : undefined);
      pollId = poll ? poll._id : undefined;

      if (!slideId && poll) {
        slideId = poll.slides.length ? poll.slides[0]._id : undefined;
      }

      if (!slideId) {
        return dispatch(fetchedResponses(accountId, {data: []}));
      }

      dispatch(fetchingResponses(accountId));
      if (!isFirst) {
        updateQuery(dispatch, getState, page, sortBy, filter, pollId, slideId);
      }

      return post(`${url}/account/${accountId}/poll/${pollId}/slide/${slideId}/responses/getPage/${page}`, { sortBy, filter })
      .then(response => {
        if (response.status === 404) {
          dispatch(replace('/404'));
        }
        return response.json()
      })
      .then(json => {
        if (json.error) {
          return dispatch(pushServerError(json.error));
        } else {
          return dispatch(fetchedResponses(accountId, json));
        }
      })
      .catch(error => dispatch(pushServerError(error)))
    // })
  };
}


export function fetchPollResponses(page = 0, sortBy) {
  return (dispatch, getState) => {

    const [ accountId, pollId ] = parseUrl(getState().router);

    dispatch(fetchingResponses(pollId));
    updateQuery(dispatch, getState, page, sortBy);
    return post(`${url}/account/${accountId}/poll/${pollId}/responses/getPage/${page}`, { sortBy })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(fetchedResponses(pollId, json));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}
