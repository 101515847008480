import { replace, push } from 'connected-react-router';
import qs from 'query-string';

export function setParam(key, value) {
  return (dispatch, getState) => {
    const curParams = qs.parse(getState().router.location.search);
    const params = { ...curParams, [key]: value }
    return dispatch(replace({ search: qs.stringify(params) }))
  }
} 

export function navigateTo(value) {
  return (dispatch, getState) => {
    return dispatch(push(value));
  }
} 
