import React, { Component } from 'react';
import _ from 'lodash';

const options = [
  { label: 'Top Left', value: 'top-left', disabled: true},
  { label: 'Top Middle', value: 'top-middle', disabled: true},
  { label: 'Top Right', value: 'top-right', disabled: true},
  { label: 'Left Middle', value: 'left-middle', description: "The survey will appear on the left side and centered on the page. Good for feedback and contact us surveys where you want the survey to be accessible at all times."},
  { label: 'Modal', value: 'modal', description: "The survey will appear as a modal. Good for exit intent surveys and other situations where you want the customer's full attention"},
  { label: 'Right Middle', value: 'right-middle', description: "The survey will appear on the right side and centered on the page. Good for feedback and contact us surveys where you want the survey to be accessible at all times."},
  { label: 'Bottom Left', value: 'left', description: "The survey will appear on the bottom left side of the page. Good for post-purchase surveys and giveaway surveying campaigns."},
  { label: 'Bottom Middle', value: 'middle', disabled: true},
  { label: 'Bottom Right', value: 'right', description: "The survey will appear on the bottom right side of the page. Good for post-purchase surveys and giveaway campaigns."}
];

export default class AlignmentInput extends Component {
  onChange(value) {
    console.log(value);
    this.props.onChange(value);
  }

  render() {
    const active = _.find(options, ({ value }) => value === this.props.value);

    return (
      <div id="alignment-input">
        <div className="grid">
          { options.map(({ label, value, disabled }) => 
            <div title={label} className={`${disabled ? 'disabled' : ''} ${value === this.props.value ? 'active' : ''}`} onClick={() => {
                if (disabled) { return; }
                this.onChange(value)
              }} />
          )}
        </div>
        <div className="details">
          <div>
            <div className="label">{ active.label }</div>
            <div className="description">{ active.description }</div>
          </div>
        </div>
      </div>
    );
  }
}