import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DatePicker from "react-datepicker";

export default class DateInput extends Component {
  render() {
    return (
      <div className="date-input">
        <DatePicker
          onChange={this.props.onChange}
          selected={this.props.value}
        />
      </div>
    );
  }
}