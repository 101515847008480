import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DatePicker from "react-datepicker";

import moment from 'moment';

export default class DateRangeInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: props.startDate,
      endDate: props.endDate
    };
  }

  setEndDate(date) {
    this.setState({
      endDate: date
    });
  }

  render() {
    const { startDate, endDate } = this.props;

    return (
      <div className="date-input">
        <DatePicker
          selected={startDate}
          onChange={(date) => {
            this.props.onChange({ startDate: moment(date).startOf('day').toDate(), endDate })
          }}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate}
        />
        <i className="fas fa-arrow-right-long" />
        <DatePicker
          selected={endDate}
          onChange={(date) => {
            this.props.onChange({ startDate, endDate: moment(date).endOf('day').toDate() })
          }}
          selectsEnd
          startDate={startDate}
          maxDate={new Date()}
          endDate={endDate}
          minDate={startDate}
        />
      </div>
    );
  }
}