import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ColumnLoading from './ColumnLoading';

import ColumnLayout from '../components/ColumnLayout';
import Breadcrumbs from '../components/Breadcrumbs';
import Pagination from '../components/Pagination';
import Email from '../components/Email';
import SectionHeader from '../components/SectionHeader';
import HeaderButtons from '../components/HeaderButtons';

import * as EmailsActions from '../actions/Emails';

import { encode, decode, getDate, renderAccountTitle } from '../utils';

class Emails extends Component {
  constructor(props) {
    super(props);
    props.fetchAccountAndEmails();
  }

  export() {
    this.props.exportAllEmails();
  }

  render() {

    if (!this.props.emails || !this.props.account) {
      return <ColumnLoading />
    }

    let results = null;
    if (this.props.emails.data.length) {
      results = (<span>
        <div className="responses-header">
          <button
            onClick={this.export.bind(this)}
          >Download CSV</button>

          <Pagination
            showPagination={this.props.emails.pages}
            showNext={this.props.emails.page !== this.props.emails.pages}
            showPrev={this.props.emails.page !== 0}
            next={() => { this.props.fetchAllEmails(this.props.emails.page + 1); }}
            prev={() => { this.props.fetchAllEmails(this.props.emails.page - 1); }}
            curPage={this.props.emails.page + 1 }
            pageLength={this.props.emails.pages + 1}
          />
        </div>
        <div className={`card object-table emails ${this.props.loading ? 'loading' : ''}`}>
          {this.props.emails.data.map(email => <Email {...email} />)}
        </div>
      </span>);
    } else {
      results = (
        <div className="card empty-object-list emails">
          <h3>No Emails Yet</h3>
          <p>A list will show once someone has submitted their email.</p>
        </div>
      );
    }

    return (
      <ColumnLayout
        title={`Email Addresses`}
        className="emails"
        graphics={true}
      >

        <SectionHeader
          title=<span><i className="fas fa-at" />Collected Email Addresses</span>
          subtitle="Use these to re-engage with your visitors."
          className="no-margin"
        />

        { results }

        <HeaderButtons>
          <button
            onClick={this.export.bind(this)}
            disabled={this.props.emails.data.length ? false : true}
          >Download CSV</button>
        </HeaderButtons>

      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];

  const allEmails = state.emails || [];
  const emails = allEmails[accountId];

  return {
    accountId,
    account,
    emails,
    loading: state.emails.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(EmailsActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Emails));
