import { post } from '../ajax';
import { url } from '../settings';
import { pushServerError } from './Notifications';

import { fetchAccountWithPolls } from './Accounts';
import { fetchPollWithSlides } from './Polls';
import { fetchFullSlide } from './Slides';
import { setPollId } from './Dashboard';

import { replace } from 'connected-react-router';

import qs from 'query-string';
import _ from 'lodash';

import { encode, decode, parseUrl } from '../utils';

export const FETCHING_REPORT = 'FETCHING_REPORT';
export const FETCHED_REPORT = 'FETCHED_REPORT';
export const FETCHING_REPORT_PAGE = 'FETCHING_REPORT_PAGE';
export const FETCHED_REPORT_PAGE = 'FETCHED_REPORT_PAGE';
export const CLEAR_REPORTS = 'CLEAR_REPORTS';

function fetchingReport(id) {
  return {
    type: FETCHING_REPORT,
    id
  };
}

function fetchedReport(id, json) {
  return {
    type: FETCHED_REPORT,
    id,
    report: json
  };
}

function fetchingReportPage(id) {
  return {
    type: FETCHING_REPORT_PAGE,
    id
  };
}

function fetchedReportPage(id, json) {
  return {
    type: FETCHED_REPORT_PAGE,
    id,
    report: json
  };
}

export function fetchData(accountId) {
  return (dispatch, getState) => {
    if (!accountId) { return; }

    return dispatch(fetchAccountWithPolls())
    .then((account) => {
      if (!account) {
        return replace('/404');
      }
    });
  }
} 

export function clear() {
  return (dispatch, getState) => {
    dispatch({ type: CLEAR_REPORTS });
  }  
}

export function fetchReport({ accountId, pollId, slideId, dateRange, sortBy, page }) {
  return (dispatch, getState) => {
    let endpoint = `${url}/account/${accountId}/report`;

    dispatch(fetchingReport(accountId));
    return post(endpoint, { pollId, slideId, dateRange, page, sortBy })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(fetchedReport(accountId, json));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  }
}

export function fetchReportPage({ accountId, pollId, slideId, dateRange, sortBy, page }) {
  return (dispatch, getState) => {
    let endpoint = `${url}/account/${accountId}/report`;

    dispatch(fetchingReportPage(accountId));
    return post(endpoint, { pollId, slideId, dateRange, page, sortBy })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(fetchedReportPage(accountId, json));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  }
}