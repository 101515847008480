import React, { Component } from 'react';
import { renderNumber } from '../utils';

import Tooltip from '../components/Tooltip';
import SectionHeader from '../components/SectionHeader';

export default class MonthlyRequestCounter extends Component {
  render() {
    const requestLimit = this.props.account.plan.requestLimit;
    const requestCount = this.props.account.requestCount;
    // const requestCount = 5000;

    let className = '';

    if (requestCount/requestLimit > .8) {
      className += ' warning';
    }

    if (requestCount/requestLimit >= 1) {
      className += ' exceeded';
    }

    let header = (<SectionHeader
      title="Monthly Requests"
      subtitle="See your requests for this month."
      tooltip="If you reach your request limit, your surveys will stop showing on your website. If you want more requests you can change your plan."
    />);

    let tooltip = null;
    if (this.props.hideHeader) {
      header = null;
      tooltip = (<Tooltip>If you reach your request limit, your surveys will stop showing on your website. If you want more requests you can change your plan.</Tooltip>);
    }

    return (
      <div className={`monthly-request-info ${className}`}>
        { header }
        <div className="card">
          <div className="info">
            <label>Request Count{tooltip}</label>
            <p><span title={requestCount || 0}>{ renderNumber(requestCount) }</span> / <span title={requestLimit || 0}>{ renderNumber(requestLimit) }</span> Requests</p>

            <div className="bar">
              <div className="bg" />
              <div className="fill" style={{ width: `${ (requestCount / requestLimit) * 100 }%`}}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}