import React, { Component } from 'react';

class InlineInput extends Component {
  onChange(e) {
    let value = e.target.value;
    this.props.onChange(value, this.props.idx);
  }

  render() {
    return (
      <div className="inline-input">
        <label>{ this.props.label }</label>
        <input
          type="text"
          name={ this.props.name }
          value={ this.props.value }
          onChange={ this.onChange.bind(this) }
          disabled={this.props.disabled}
        />
        <span className="suffix">{ this.props.suffix }</span>
      </div> 
    );
  }
}

export default class PositionInput extends Component {
  handleChange(value, idx) {
    let position = this.props.position;
    position[idx] = value.replace(/[^\d.-]/g,'');

    this.props.onChange(position);
  }

  render() {
    let label = "Left";
    let name = 'left';

    if (this.props.align === 'right') {
      label = "Right";
      name = "right";
    }

    return (<div
      className={`position-input ${this.props.disabled ? 'disabled' : ''}`}
      active={this.props.active}
    >

      <InlineInput
        label="Bottom"
        name="bottom"
        value={ this.props.position[0] }
        suffix="Pixels"
        idx={0}
        disabled={this.props.disabled || this.props.bottomDisabled}
        onChange={this.handleChange.bind(this)}
      />

      <InlineInput
        label={ label }
        name={ name }
        value={ this.props.position[1]}
        suffix="Pixels"
        idx={1}
        disabled={this.props.disabled}
        onChange={this.handleChange.bind(this)}
      />

    </div>);
  }
}