import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as UserActions from '../actions/Users';

import ColumnLayout from '../components/ColumnLayout';
import ColumnLoading from './ColumnLoading';

import SectionHeader from '../components/SectionHeader';
import AffiliateLink from '../components/AffiliateLink';
import AffiliateSettings from '../components/AffiliateSettings';

import stripeButton from '../images/stripe-button-grey/light-on-dark@3x.png';

import { url, stripeConnectId } from '../settings';
import { renderCurrency } from '../utils';

function getPayout({ affiliateUsers }) {
  let payout = 0;

  affiliateUsers.forEach(({ accounts }) => {
    accounts.forEach(({ plan }) => {
      payout = payout + (plan.price * 0.5);
    });
  });

  return payout;
}

class AffiliateAccounts extends Component {
  render() {
    const accounts = this.props.accounts || [];

    if (accounts.length === 0) {
      return <div className="accounts">
        <div className="warning">This user has not created any accounts yet.</div>
      </div>
    }

    return (<div className="accounts">
      <div className="heading-tag"><i className="fas fa-user" />{ accounts.length === 1 ? 'Account' : 'Accounts' }</div>
      {accounts.map(({ title, plan }, idx) => {
        return (<div className={`account ${idx === 0 && 'first'}`}>

          <div className="row">
            <label className="inline"><i className="fas fa-tag" />Title:</label> <div title={title}>{ title }</div>
          </div>

          <div className="row">
            <label className="inline"><i className="fas fa-file-invoice-dollar" />Plan:</label> <div title={plan.label}>{ plan.label }</div>
          </div>

          <div className="row">
            <label className="inline"><i className="fas fa-dollar-sign" />Price:</label> <div title={plan.price}>{ renderCurrency(plan.price) }</div>
          </div>
        </div>)
      })}
    </div>);
  }
}

class AffiliateProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (!this.props.user.affiliateData && !this.props.user.fetching) {
      this.props.fetchAffiliateData();
    }
  }

  render() {
    if (!this.props.user.affiliateData) {
      return <ColumnLoading />
    }

    const affiliateData = this.props.user.affiliateData;
    const affiliateUsers = affiliateData.affiliateUsers;

    let pastPayoutsLink = <div className="below-card-info" style={{ marginBottom: 20 }} />;

    if (this.props.user.stripeConnectId !== 'paypal') {
      pastPayoutsLink = (
        <div className="below-card-info" style={{ marginBottom: 20 }}>
          Want to see your history? <div className="link" onClick={(e) => {
          this.props.loadStripeDashboard();
        }}>View past payouts</div>.
        </div>
      );
    }

    return (
      <ColumnLayout title="Affiliate Program" graphics={true}>

        <SectionHeader
          title=<span><i className="fas fa-comments-dollar" />Affiliate Program</span>
          subtitle="Track your progress & get paid."
          className="no-margin"
        />

        <AffiliateSettings user={this.props.user} />

        <SectionHeader
          title="Payouts"
          subtitle="View your transaction history and estimated payout."
        />

        <div className="card affiliate-payouts" style={{marginBottom: 0}}>
          <div className="half">
            <div>
              <label>Referrals</label><strong>{affiliateData.affiliateUsers.length}</strong></div>
            <div><label>Estimated Payout</label><strong className="money">${getPayout(affiliateData)}</strong></div>
          </div>

        </div>

        { pastPayoutsLink }

        <div className="card info" style={{marginBottom: 20}}>
          <p>Payouts are made on the 28th of each month. For more info, check out our <a href="https://docs.zigpoll.com/affiliate-program">docs</a>.</p>
        </div>

        <SectionHeader
          title="Referrals"
          subtitle="See a full list of your referrals, their accounts, and their subscriptions."
        />

        {
          affiliateUsers.map(({ name, email, accounts }) => {
            return <div className="card referral" style={{marginBottom: 20}}>
              <div className="user">
                <div className="heading-tag"><i className="fas fa-user" />User</div>
                <div className="row">
                  <label className="inline"><i className="fas fa-tag" />Name:</label> <div title={name}>{ name }</div>
                </div>
                <div className="row">
                  <label className="inline"><i className="fas fa-at" />Email:</label> <div title={email}>{ email }</div>
                </div>
              </div>

              <AffiliateAccounts accounts={accounts} />
            </div>
          })
        }

        { affiliateUsers.length === 0 ? <div className="empty-object-list" style={{ marginTop: 25, maxWidth: 515 }}>
        <h3>You don't have any referrals yet.</h3>
        <p>Get some by sharing your affiliate URL with other people. When they sign up and create accounts, they will show up here.</p>
      </div> : null}

      </ColumnLayout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;

  return {
    loading: state.user.loading,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(UserActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateProgram);
