import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as AccountActions from '../actions/Accounts';

import ColumnLoading from './ColumnLoading';

import ColumnLayout from '../components/ColumnLayout';
import Breadcrumbs from '../components/Breadcrumbs';
import SingleFormPageHeader from '../components/SingleFormPageHeader';

import ManageAdmin from '../components/ManageAdmin';
import AccountSubscriptionForm from '../components/AccountSubscriptionForm';
import ShopifyAccountSubscriptionForm from '../components/ShopifyAccountSubscriptionForm';

import DeleteConfirm from '../components/DeleteConfirm';
import SectionHeader from '../components/SectionHeader';

import { decode } from '../utils';

class ManageAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    if (!this.props.account) {
      this.props.fetchAccount();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.accountId !== prevProps.accountId) {
      this.props.fetchAccount();
    }
  }

  render() {
    if (!this.props.account) {
      return (
        <ColumnLoading />
      );
    }

    const { account } = this.props;

    if (account.owner !== this.props.user._id) {
      return (<div>NOT AUTHORIZED</div>);
    }

    let SubscriptionForm = <AccountSubscriptionForm />;
    if (this.props.user.type === 'shopify') {
      SubscriptionForm = <ShopifyAccountSubscriptionForm />;
    }

    let ManagementOptions = (
      <div>
        <SectionHeader
          title=<span><i class="fas fa-tasks"></i>Management Options</span>
        />
        <div className="account-management card">
          <div>
            <label>Delete Account</label>
            <div>
              <div className="subtle">This will erase the survey, its slides, and responses.</div>
              <button
                className="delete-poll"
                onClick={(value) => {
                  this.setState({ showDeleteConfirm: true });
                }}
              >Delete</button>
            </div>
          </div>
        </div>
      </div>
    );

    if (this.props.user.type === 'shopify') {
      ManagementOptions = null;
    }

    return (
      <ColumnLayout
        title={`Manage Account → ${this.props.account.title}`}
        className="manage-account"
        graphics={true}
      >

        <ManageAdmin admin={account.admin} owner={account.owner} />

        { SubscriptionForm }

        { ManagementOptions }

        <DeleteConfirm 
          title="Are you sure?"
          subtitle="This will permanently erase the account, its surveys, its slides, and responses forever."
          show={this.state.showDeleteConfirm}
          onConfirm={this.props.deleteAccount}
          onCancel={() => {
            this.setState({ showDeleteConfirm: false });
          }}
        />

      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];
  const user = state.user;

  return {
    accountId,
    accounts,
    account,
    user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(AccountActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageAccount));
