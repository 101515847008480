import _ from 'lodash';

import React, { Component } from 'react';

import { NameInput } from './SimpleInputs';
import Modal from './Modal';

import { truncate } from '../utils';

function calculateWidth(str) {
  if (!str) { return 60; }

  const w = parseInt(str.length * 12 + 31);
  if (w > 250) {
    return 250;
  }
  return w;
}

function getSlideTitle(slideId, slides) {
  if (!slides || !slides.length || !slideId) { return ''; }

  const slide = _.find(slides, ({_id}) => _id === slideId);
  if (!slide) { return ''; }

  return truncate(slide.handle || slide.title, 25);
}

export class ActionSelector extends Component {
  constructor(props) {
    super(props)
    this.state = { width: calculateWidth(props.value) }
  }

  onChange(e) {
    const w = calculateWidth(e.target.value);
    this.setState({ width: w });
    this.props.onChange(e);
  }

  render() {
    return (
      <select
        value={this.props.value}
        name="action"
        style={{ width: this.state.width }}
        onChange={this.onChange.bind(this)}
      >
        <option value="skip-to">skip to</option>
        <option value="end-poll">end survey</option>
      </select>
    );
  }
}

export class SlideSelector extends Component {
  constructor(props) {
    super(props);

    let width;
    const firstSlide = props.slides[0];
    if (props.value && props.value !== -1) {
      const selectedSlide = _.find(props.slides, ({_id}) => _id === props.value) || firstSlide;
      width = calculateWidth(selectedSlide.handle || selectedSlide.title);
    } else {
      width = calculateWidth(firstSlide.handle || firstSlide.title);
    }

    this.state = { width };
  }

  onChange(e) {
    const idx = e.nativeEvent.target.selectedIndex;
    const w = calculateWidth(e.nativeEvent.target[idx].text);
    this.setState({ width: w });
    this.props.onChange(e);
  }

  render() {
    return (
      <select
        value={this.props.value}
        style={{ width: this.state.width }}
        onChange={this.onChange.bind(this)}
        name="nextSlide"
      >
        {this.props.slides.map((slide) =>
          <option
            value={slide._id}
            name={slide.handle || slide.title}
            key={slide._id}
          >{slide.handle || slide.title}</option>
        )}
      </select>
    );
  }
}

export default class NextSlideInput extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, nextSlide: props.nextSlide, action: 'skip-to' };
  }

  openModal(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showModal: true });
  }

  closeModal(e) {
    this.state = { showModal: false, nextSlide: this.props.nextSlide, action: 'skip-to' };
    this.setState({ showModal: false });
  }

  onChange() {
    let e = { target: { name: 'nextSlide', value: false }};
    if (this.state.action === 'end-poll') {
      e.target.value = -1;
    } else {
      if (this.state.nextSlide) {
        e.target.value = this.state.nextSlide;
      } else {
        e.target.value = this.props.slides[0]._id;
      }
    }
    this.props.onChange(e, this.props.idx);
  }

  remove() {
    let e = { target: { name: 'nextSlide', value: false }};
    this.props.onChange(e, this.props.idx);
    setTimeout(() => {
      this.setState({ action: 'skip-to' });
    }, 500);
  }

  renderModal() {
    const actionSelector = (<ActionSelector
      value={this.state.action}
      onChange={(e) => this.setState({ action: e.target.value }) }
    />);

    const slideSelector = (<span>
      slide: <SlideSelector
        value={this.state.nextSlide}
        onChange={(e) => this.setState({ nextSlide: e.target.value })}
        slides={this.props.slides}
      />
    </span>);

    let remove = null;
    if (this.props.nextSlide) {
      remove = (
        <button className="negative" onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          this.closeModal();
          setTimeout(() => {
            this.remove();
          }, 100);
        }}>Remove</button>
      );
    }

    let title = this.props.title || (<span>If answer is equal to <strong>{ this.props.value }</strong></span>);

    return (
      <Modal 
        isOpen={this.state.showModal}
        onRequestClose={this.closeModal.bind(this)}
      >
        <div className="frame large">
          <div className="close" onClick={this.closeModal.bind(this)} />
          <div className="title"><i className="fas fa-code-branch" />{this.props.nextSlide ? 'Edit' : 'Add'} Logic</div>
          <div className="content">
            <div className="copy" style={{ marginBottom: 15 }}>{title}:</div>
            <div className="copy">{actionSelector} {this.state.action === 'skip-to' ? slideSelector : ''}</div>
            <div className="actions">
              <button className="positive" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.closeModal();
                setTimeout(() => {
                  this.onChange()
                }, 100);
              }}>Apply</button>
              { remove }
              <button className="neutral" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.closeModal();
              }}>Cancel</button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderLabel() {
    let label = (<div className="add-rules" onClick={this.openModal.bind(this)}>Add logic</div>);

    if (this.props.nextSlide === -1) {
      label = <div className="add-rules edit" onClick={this.openModal.bind(this)}>End Survey</div>
    } else if (this.props.nextSlide) {
      let slideTitle = getSlideTitle(this.props.nextSlide, this.props.slides);
      if (!slideTitle) {
        label = <div className="add-rules" onClick={this.openModal.bind(this)}>Edit Logic</div>
      } else {
        label = <div className="add-rules edit" onClick={this.openModal.bind(this)}>Skip to "{slideTitle}"</div>      
      }
    }

    return label;
  }

  render() {
    if (this.props.hidden) { return null; }
    if (!this.props.slides|| !this.props.slides.length) { return null; }
    if (!this.props.value) { return null; }

    return (<div className="skip-to-slide">
      { this.renderLabel() }
      { this.renderModal() }
    </div>)
  }
}