import { combineReducers } from 'redux';

import notifications from './Notifications';
import flashNotifications from './FlashNotifications';
import user from './User';
import accounts from './Accounts';
import polls from './Polls';
import slides from './Slides';
import responses from './Responses';
import emails from './Emails';
import activities from './Activities';
import participants from './Participants';
import reports from './Reports';
import diffs from './Diffs';
import charts from './Charts';
import insights from './Insights';
import misc from './Misc';

const rootReducer = combineReducers({
  user,
  accounts,
  polls,
  notifications,
  flashNotifications,
  slides,
  responses,
  emails,
  activities,
  participants,
  reports,
  diffs,
  charts,
  insights,
  misc
});

export default rootReducer;
