import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Modal from './Modal';
import SatisfactionNextSlideInput from './SatisfactionNextSlideInput';

import _ from 'lodash';

const sortedHandles = ['1-star','2-stars','3-stars','4-stars','5-stars' ];

const sortedAnswers = [
  { title: '1 Star', handle: '1-star' },
  { title: '2 Stars', handle: '2-stars' },
  { title: '3 Stars', handle: '3-stars' },
  { title: '4 Stars', handle: '4-stars' },
  { title: '5 Stars', handle: '5-stars' }
];

export default class StarRatingSlideInput extends SatisfactionNextSlideInput {

  addRule(e) {
    const answer = e.target.value;
    const rules = this.state.rules;

    let idx = 0;
    const sortedRules = _.sortBy(rules, ({ handle }) => sortedHandles.indexOf(handle));

    sortedRules.forEach((rule) => {
      if (rule.handle == sortedAnswers[idx].handle) {
        ++idx;
      }
    });

    rules.push({ nextSlide: this.props.slides[0]._id, ...sortedAnswers[idx] });
    this.setState({ rules });
  }

}