import React, { Component } from 'react';
import { encode } from '../utils';

class Notification extends Component {
  render() {
    return (
      <div className={`notification ${this.props.type}`}>
        <div className="content"
          dangerouslySetInnerHTML={{ __html : this.props.message }} 
        />
        <div 
          className="close"
          onClick={this.props.close}
        ><i className="fas fa-close" /></div>
      </div>
    );
  }
}

export default class HeaderNotifications extends Component {
  render() {
    let notification = null;
    let count = null;

    return (
    <div id="header-notifications" className={'active'}>
      <div className="notification">
        <div className="content">This slide is currently hidden and will not appear in your survey <strong onClick={() => { 
            this.props.showSlide();
          }}>click here to make it visible</strong>.</div>
      </div>
    </div>
    )
  }
}