import React, { Component } from 'react';
import DateRangeInput from '../components/DateRangeInput';
import moment from 'moment';

export default class DateRangeToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      dateRange: { 
        startDate: moment().subtract(6, 'days').startOf('day').toDate(),
        endDate: moment().endOf('day').toDate() 
      },
      submittedDateRange: false
    };
  }

  render() {
    // if (this.props.slide.type === 'checkbox' || this.props.slide.type === 'inline-multiple-choice') {
      // return null;
    // }

    let time = "All Time";
    if (this.state.submittedDateRange) {
      time = <span>{moment(this.state.submittedDateRange.startDate).format('MM/DD/YYYY')}<i className="fas fa-arrow-right-long" /> {moment(this.state.submittedDateRange.endDate).format('MM/DD/YYYY')}</span>
    }
    return (<p className="date-range">
      <div className="date-toggle" onClick={() => {
        this.setState({ active: !this.state.active });
      }}><i className="fas fa-calendar-days" />{ time }</div>
      <div className={`date-range-input ${this.state.active ? 'active' : ''}`}>
        <p className="date-range-title">Date Range</p>
        <DateRangeInput
          startDate={this.state.dateRange.startDate}
          endDate={this.state.dateRange.endDate}
          onChange={(dateRange) => this.setState({ dateRange })}
        />
        <div className="submit" onClick={() => {
          this.props.onSubmit(this.state.dateRange);
          this.setState({ submittedDateRange: this.state.dateRange, active: false, hasSubmittedRange: true });
        }}>Submit</div>
        <div className={`${this.state.submittedDateRange ? '' : 'disabled'} clear`} onClick={() => {
          if (!this.props.slide) {
            this.props.fetchPoll(this.props.pollId);
          } else {
            this.props.fetchSlide(this.props.slide._id, this.props.pollId);
          }

          this.setState({ submittedDateRange: false, active: false, hasSubmittedRange: false });
        }}>Clear</div>
        <div className="close" onClick={() => {
          this.setState({ active: false });
        }}>Close</div>
      </div>
    </p>);
  }
}