import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Modal from './Modal';

import ColorPicker from '../components/ColorPicker';
import Toggle from '../components/Toggle';
import Switch from '../components/Switch';
import WysiwygInput from './WysiwygInput';
import Tooltip from '../components/Tooltip';

import SingleImageUploader from './SingleImageUploader';
import { defaultEmailDisplaySettings } from '../settings';

import { uuid } from '../utils';

import * as AccountActions from '../actions/Accounts';

import EmailSettingsVisualDisplay from '../components/EmailSettingsVisualDisplay';

import { NameInput, CCRecipientsInput, ReplyToInput } from '../components/SimpleInputs';

class CreateEmailTemplateModal extends Component {
  constructor(props) {
    super(props);
    const defaultState = { ...defaultEmailDisplaySettings, ...props.settings }

    delete defaultState.buttonFontColor;
    delete defaultState.linkType;

    if (defaultState.content) {
      delete defaultState.content.footer;
    }
    if (!defaultState.id) {
      defaultState.id = uuid();
    }
    this.state = defaultState;
  }
  onValidate(e) {
    console.log('on validate');
  }
  setRef() {
    console.log('set ref called');
  }
  onContentChange(e) {
    const state = this.state;
    state.content[e.target.name] = e.target.value;
    this.setState(state);
  }
  onLogoImageUpload(image) {
    if (!image) {
      this.setState({ 
        logoImage: undefined,
        localLogoImage: undefined
      });
    } else {
      this.setState({
        localLogoImage: image,
        logoImage: image.url
      });
    }
  }
  render() {
    return (
      <Modal 
        className="modal full-width"
        isOpen={this.props.isOpen}
        onRequestClose={() => {
          this.props.onClose();
        }}
      >
        <div className="frame create-email-template account-display">
          <div className="close" onClick={() => {
            this.props.onClose();
          }} />
          <div className="title">Create Email Template</div>
          <div className="content">

          <div className="left-side">
            <form className="account-settings">
              <div className="">
                <div style={{ paddingTop: 5 }}>
                  <NameInput
                    onChange={(e) => this.setState({ title: e.target.value }) }
                    noValidate={true}
                    onValidate={this.onValidate.bind(this)}
                    label="Title"
                    moreInfo='The title of the survey for your reference.'
                    bottom={true}
                    type="text"
                    placeholder='Ex: "Review follow up email"'
                    name="title"
                    value={this.state.title}
                    ref={this.setRef.bind(this)}
                    optional={false}
                  />
                </div>

                <div className="card-divider" style={{ marginTop: 0 }}>
                  <h6>Color</h6>
                </div>

                <div style={{zIndex: 9, position: 'relative'}}>
                  <label>Background Color</label>
                  <div className="subtle">Used in the background of your email.</div>
                  <ColorPicker
                    color={this.state.backgroundColor}
                    onChange={(color, event) => {
                      this.setState({ backgroundColor: color.hex });
                    }}
                  />
                </div>

                <div style={{zIndex: 8, position: 'relative'}}>
                  <label>Foreground Color</label>
                  <div className="subtle">Used in the foreground of your email.</div>
                  <ColorPicker
                    color={this.state.foregroundColor}
                    onChange={(color, event) => {
                      this.setState({ foregroundColor: color.hex });
                    }}
                  />
                </div>

                <div style={{zIndex: 7, position: 'relative'}}>
                  <label>Bar Color</label>
                  <div className="subtle">Background color for the top bar.</div>
                  <ColorPicker
                    color={this.state.buttonColor}
                    onChange={(color, event) => {
                      this.setState({ buttonColor: color.hex });
                    }}
                  />
                </div>

                <div className="card-divider">
                  <h6>Font</h6>
                </div>

                <div style={{zIndex: 5, position: 'relative'}}>
                  <label>Font Color</label>
                  <div className="subtle">Font color for your email.</div>
                  <ColorPicker
                    color={this.state.fontColor}
                    onChange={(color, event) => {
                      this.setState({ fontColor: color.hex });
                    }}
                  />
                </div>

                <div style={{ marginTop: 15 }}>
                  <label>Font Family</label>
                  <div className="subtle">Choose a font family that matches your site.</div>

                  <div className="inline-toggle">
                    <Switch 
                      options={[
                        { label: 'Sans Serif', value: 'sans'},
                        { label: 'Serif', value: 'serif', className: 'serif' },
                        { label: 'Slab', value: 'slab', className: 'slab' },
                      ]}
                      value={this.state.fontFamily}
                      onChange={(value) => {
                        this.setState({ fontFamily: value });
                      }}
                    />
                  </div>
                </div>

                <div className="card-divider">
                  <h6>Branding</h6>
                </div>

                <div>
                  <label>Logo<Tooltip>Image should be 800x300 pixels It will be resized automatically on upload.</Tooltip> <span>optional</span></label>
                  <div className="subtle">Use an image for your logo.</div>

                  <SingleImageUploader
                    hideLabel={true}
                    key={this.state.logoImage}
                    image={this.state.logoImage}
                    onChange={this.onLogoImageUpload.bind(this)} />
                </div>

                <div>
                  <label>Zigpoll Link<Tooltip>You can only disable branding if you subscribe to a <strong>Standard</strong> plan or better.</Tooltip></label>
                  <div className="inline-toggle">
                    <Toggle 
                      active={this.state.showLink}
                      disabled={this.props.account.plan.forceBranding}
                      onChange={(value) => {
                        this.setState({ showLink: value });
                      }}
                    />
                    <div className="subtle">Display a subtle link to the Zigpoll website.</div>
                  </div>
                </div>

                <div className="card-divider">
                  <h6>Content</h6>
                </div>

                <div style={{ marginBottom: 25 }}>
                  <CCRecipientsInput
                    onChange={(e) => {
                      const val = e.target.value;
                      this.setState({ ccRecipients: val });
                    }}
                    onValidate={() => {
                      console.log('On validate')
                    }}
                    placeholder="email@domain.com"
                    name="CC Recipients"
                    value={ this.state.ccRecipients }
                    toggleCopy="Do not include anyone as CC."
                    errorMessage="Please enter a valid domain name."
                    ref={this.setRef.bind(this)}
                  />
                </div>

                <div style={{ marginBottom: 25 }}>
                  <ReplyToInput
                    onChange={(e) => {
                      const val = e.target.value;
                      this.setState({ replyTo: val });
                    }}
                    onValidate={() => {
                      console.log('On validate')
                    }}
                    placeholder="email@domain.com"
                    name="Reply To"
                    value={ this.state.replyTo }
                    toggleCopy="Do not include anyone as CC."
                    errorMessage="Please enter a valid domain name."
                    ref={this.setRef.bind(this)}
                  />
                </div>

                <div>
                  <NameInput
                    onChange={(e) => this.setState({ sender: e.target.value }) }
                    onValidate={this.onValidate.bind(this)}
                    noValidate={true}
                    label="Sender"
                    moreInfo='By default all customer facing emails will be sent as "Zigpoll". If you would like the name to be something else, enter it below.'
                    type="text"
                    placeholder="Zigpoll"
                    name="sender"
                    value={this.state.sender}
                    ref={this.setRef.bind(this)}
                    optional={false}
                    maxlength={100}
                  />
                </div>

                <div>
                  <NameInput
                    onChange={this.onContentChange.bind(this)}
                    onValidate={this.onValidate.bind(this)}
                    noValidate={true}
                    label="Subject"
                    type="text"
                    placeholder="Have a minute for a quick survey?"
                    name="subject"
                    value={this.state.content.subject}
                    ref={this.setRef.bind(this)}
                    optional={false}
                    maxlength={150}
                  />
                </div>

                <div>
                  <WysiwygInput
                    name="header"
                    label="Header"
                    maxlength={500}
                    optional={false}
                    onChange={this.onContentChange.bind(this)}
                    value={this.state.content.header}
                    defaultTextAlignment="center"
                  />
                </div>

                <div>
                  <WysiwygInput
                    name="body"
                    label="Body"
                    maxlength={500}
                    optional={false}
                    onChange={this.onContentChange.bind(this)}
                    value={this.state.content.body}
                    defaultTextAlignment="center"
                  />
                </div>

              </div>
            </form>
          </div>

          <div className="right-side">
            <EmailSettingsVisualDisplay
              noButton={true}
              noFooter={true}
              poll={this.props.poll}
              {...this.state}
            />
          </div>
        </div>

        <div className="actions">
          <button className="positive" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (this.state.saving) { return; }

            const emailTemplate = { ...this.state };
            const formData = new FormData();

            if (this.state.localLogoImage) {
              formData.append('logoImage', this.state.localLogoImage);
              delete emailTemplate.localLogoImage;
            }

            formData.append('emailTemplate', JSON.stringify(emailTemplate));

            if (this.props.isEdit) {
              this.props.updateEmailTemplate(formData)
              .then(() => {
                this.props.onSave();                
              });
            } else {
              this.props.saveEmailTemplate(formData)
              .then(() => {
                this.props.onSave(this.state.id);
              });
            }

            this.setState({ saving: true });
            // this.props.onConfirm();
            // this.props.onClose();
          }}>{this.props.isEdit ? 'Update' : 'Create'} Template</button>
          <button className="negative" onClick={this.props.onClose.bind(this)}>Cancel</button>
        </div>

      </div>
    </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEmailTemplateModal);
