import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Layout from '../components/Layout';
import SingleFormPageHeader from '../components/SingleFormPageHeader';

import Accounts from '../components/Accounts';

import { encode } from '../utils';

class AccountsPage extends Component {

  componentDidMount() {
    /* Auto redirect if you only have one account */
    if (this.props.accounts.length === 1) {
      this.props.history.replace(`/a/${encode(this.props.accounts[0]._id)}`);
    }
  }

  render() {
    return (
      <Layout
        title="Accounts"
      >
        <SingleFormPageHeader
          title="See your accounts below."
          subtitle="Each account is associated with a different domain."
        />
        <Accounts />
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    accounts: state.user.accounts || []
  }
}

export default withRouter(connect(mapStateToProps)(AccountsPage));
