import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import qs from 'query-string';

import SectionHeader from './SectionHeader';

import * as FlashNotificationActions from '../actions/FlashNotifications';

import { encode, decode, capitalize } from '../utils';

class AffiliateSettings extends Component {
  render() {
    if (!this.props.account) { return null; }

    let link = '/integrations';
    if (this.props.accountId) {
      link += `/a/${encode(this.props.accountId)}`;
    }
    if (this.props.pollId) {
      link += `/p/${encode(this.props.pollId)}`;
    }
    if (this.props.slideId) {
      link += `/s/${encode(this.props.slideId)}`;
    }

    const integrationSettings = this.props.account.integrationSettings || {};
    const integrations = [];

    if (this.props.account.shop) {
      integrations.push(<div className="integration"><span><i class="fas fa-link"></i>Shopify</span></div>)
    }
    Object.keys(integrationSettings).forEach((key) => {
      /* Fix for typo... Should update database entries instead but this works going forward. */
      if (key === 'googleAnalyics') {
        return;
      }

      integrations.push(<div className="integration"><span><i class="fas fa-link"></i>{ capitalize(key) }</span></div>)
    });

    let content = <div>
      <div className="integration empty"><span><i class="fas fa-info-circle"></i>No integrations enabled.</span></div>
    </div>;

    if (integrations.length > 0) {
      content = (<div>
        <label>Active Itegrations</label>
        { integrations.map((integration) => integration) }
      </div>)
    }

    return (<span>
        <SectionHeader
          title=<span><i className="fas fa-plug" />Integrations</span>
          subtitle="Connect Zigpoll with your favorite apps."
        />

        <div className="card integrations-card">
          <div className="links">
            <div>
              { content }
              <Link to={link}>Manage Integrations</Link>
            </div>
          </div>
        </div>
    </span>)
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const pollId = decode(ownProps.match.params.pollId);
  const slideId = decode(ownProps.match.params.slideId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];

  return {
    accountId,
    pollId,
    slideId,
    account
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(FlashNotificationActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AffiliateSettings))
