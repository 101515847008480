import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Toggle from '../components/Toggle';
import { renderNumber } from '../utils';

import * as ResponsesActions from '../actions/Responses';

class ResponseRankAndApprovalInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (this.props.active && (this.props.active !== prevProps.active) && !this.props.response._id) {
      return this.props.fetchResponse(this.props.account._id, this.props._id, this.props.responseId).then(() => {
        this.setState({
          value: this.props.response.rank || 0,
          approved: this.props.response.approved
        });
      });
    }
  }

  onChange(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.saved) { return; }

    this.setState({ value: e.target.value.replace(/[^\d.-]/g,'') })
    this.queueUpdate();
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    console.log('submitting');
  }

  markSaved() {
    this.setState({ saved: true });
    this.saveTimeout = setTimeout(() => {
      this.setState({ saved: false });
      this.saveTimeout = undefined;
    }, 500);
  }

  queueUpdate() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }

    this.timeout = setTimeout(() => {
      console.log('making change');
      console.log(this.state.value);

      this.props.setRank(this.props._id, this.props.response._id, this.state.value);

      this.markSaved();
      this.timeout = undefined;
    }, 1500);
  }

  renderRankInput() {
    if (!this.props.showRankInput) {
      return null;
    }

    return <div className={`rank-input ${this.state.saved ? 'saved' : ''}`}>
      <form onSubmit={this.onSubmit.bind(this)}>
        <label>Rank</label>

        <div>
          <span
          className="minus"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (this.state.saved) { return; }

            this.setState({ value: parseInt(this.state.value) - 1 });
            this.queueUpdate();
          }} />
          <input 
            type="text"
            onChange={this.onChange.bind(this)}
            value={this.state.saved ? '✓' : this.state.value}
            onBlur={this.queueUpdate.bind(this)}
          />
          <span
          className="plus"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (this.state.saved) { return; }

            this.setState({ value: parseInt(this.state.value) + 1 })
            this.queueUpdate();
          }} />
        </div>
      </form>
    </div>
  }

  render() {
    if (!this.props.response || !this.props.response._id) {
      return null;
    }

    if (!this.props.active) {
      return null;
    }

    return (
    <div className="actions">
      <div>
        <div className="response-actions">
          <div className="inline-toggle">
            <label>Status</label>
            <Toggle
              active={this.props.response.approved}
              disabled={this.props.loading}
              mini={true}
              onChange={(value) => {
                this.setState({ approved: value });
                this.props.setApproval(this.props._id, this.props.response._id, value)}
              }
            />
            <div className="subtle">{ this.state.approved ? 'Approved' : 'Not Approved' }</div>
          </div>

          <div className="votes">
            <label>Votes</label>
            <i class="fa-solid fa-check-to-slot"></i>{ renderNumber(this.props.response.voteCount) }
          </div>

          { this.renderRankInput() }
        </div>
      </div>
    </div>);
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ResponsesActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResponseRankAndApprovalInput));

