import React, { Component } from 'react';
import { connect } from 'react-redux';

import ColumnLayout from '../components/ColumnLayout';
import ColumnLoading from './ColumnLoading';

import SingleFormPageHeader from '../components/SingleFormPageHeader';
import AccountCreateForm from '../components/AccountCreateForm';

class CreateAccount extends Component {
  render() {
    if (!this.props.user) {
      return <ColumnLoading />
    }

    let showDomainsInput = false;
    if (this.props.user.accounts.length !== 0) {
      showDomainsInput = true;
    }

    return (
      <ColumnLayout
        title="Create Account"
      >
        <SingleFormPageHeader
          title="First, create an account."
          subtitle="This account will contain all the surveys you create."
          className="no-margin"
        />

        <AccountCreateForm showDomainsInput={showDomainsInput} className={`${showDomainsInput ? '' : 'split-form'}`} />
      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const user = state.user;

  return {
    user
  }
}

export default connect(mapStateToProps)(CreateAccount);