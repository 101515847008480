import React, { Component } from 'react';

export default class InlineInput extends Component {
  onChange(e) {
    const value = e.target.value;
    const name = e.target.name;
    this.props.onChange(name, value);
  }

  render() {
    let label = null;
    if (this.props.label) {
      label = (<label>{ this.props.label }{ this.props.tooltip }</label>);
    }

    let input = (
      <input
        type="text"
        name={ this.props.name }
        value={ this.props.value }
        onChange={ this.onChange.bind(this) }
      />
    );
    if (this.props.hideInput) {
      input = null;
    }
    if (this.props.select) {
      input = <select
        onChange={this.onChange.bind(this)}
        name={this.props.name}
        value={this.props.value}
      >{ this.props.options.map(option => <option>{ option }</option>)}</select>
    }

    return (
      <div className="inline-input">
        { label }
        <span className="prefix">{ this.props.prefix }</span>
        { input }
        <span className="suffix">{ this.props.suffix }</span>
      </div> 
    );
  }
}
