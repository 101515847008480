import React, { Component } from 'react';
import Switch from './Switch';

import _ from 'lodash';

const shortList = ["fab fa-instagram","fab fa-youtube","fab fa-tiktok","fab fa-twitter", "fab fa-facebook" ];

const icons = ["fab fa-pinterest", "fab fa-yelp", "fab fa-wordpress", "fab fa-windows", "fab fa-apple", "fab fa-snapchat", "fab fa-spotify", "fab fa-whatsapp", "fab fa-steam", "fab fa-slack", "fab fa-discord", " fab fa-shopify", "fab fa-reddit", "fab fa-product-hunt", "fab fa-paypal", "fab fa-npm", "fab fa-node-js", "fab fa-microsoft", "fab fa-medium", "fab fa-linkedin", "fab fa-google", "fab fa-github", "fab fa-facebook-messenger", "fab fa-ethereum", "fab fa-bitcoin", "fas fa-star", "fas fa-smile", "fas fa-skull", "fas fa-play", "fas fa-phone", "fas fa-percentage", "fas fa-pen", "fas fa-paste", "fas fa-paper-plane", "fas fa-mouse-pointer", "fas fa-laptop", "fas fa-info-circle", "fas fa-globe-americas", "fas fa-gift", "fas fa-eye", "fas fa-exclamation", "fas fa-envelope", "fas fa-ellipsis-h", "fas fa-edit", "fas fa-directions", "fas fa-crown", "fas fa-credit-card", "fas fa-cookie", "fas fa-comments", "fas fa-cog", "fas fa-check", "fas fa-ban", "fas fa-asterisk", "fas fa-thumbs-up"];

function filter(values, query) {
  if (!query) { return values; }
  query = _.trim(query).replace(/ /g, '-');
  return _.filter(values, (icon) => icon.includes(query));
}

class Icon extends Component {
  render() {
    return <i className={`${this.props.name} ${this.props.active ? 'active' : ''}`} onClick={() => this.props.onClick(this.props.name)} />
  }
}

export default class IconSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIcon: props.selected || 'fab fa-instagram',
      type: props.selected ? 'icon' : 'none'
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const props = this.props;

    if ((prevProps.selected !== props.selected) || (prevProps.pollState !== props.pollState)) {
      this.setState({
        selectedIcon: props.selected || 'fab fa-instagram'
      });
    }
  }

  onClick(name) {
    if (this.props.disabled) {
      return true;
    }

    this.setState({ selectedIcon: name });

    if (this.props.onChange) {
      this.props.onChange(name);
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    let content = (
      <div style={{ marginTop: 20 }} className={`icon-input ${this.props.disabled ? 'disabled' : '' }`}>
        <div className="selected-icon">
          <Icon name={this.state.selectedIcon} active={true} onClick={this.onClick.bind(this)} />
        </div>

        <div className="short-list">
          <div className="icon-list">
          { shortList.map((name) => {
            return (<Icon name={name} active={name === this.state.selectedIcon} onClick={this.onClick.bind(this)} />);
          })}
          </div>
          <div className="more-options">
            More Options
            <div className="all-icons">
              <div>
                <div className="input">
                  <i className="fas fa-search" />
                  <input
                    type="text"
                    name="query"
                    value={this.state.query}
                    onChange={this.onChange.bind(this)}
                    placeholder="Search icons"
                  />
                </div>
                { filter(icons, this.state.query).map((name) => {
                  return (<Icon name={name} active={name === this.state.selectedIcon} onClick={this.onClick.bind(this)} />);
                })}
              </div>
            </div>
          </div>
          <span>Choose an icon</span>
        </div>
      </div>
    );

    if (this.state.type === 'none') {
      content = null;
    }

    return (<div className="icon-selector">
      <label>Icon</label>

      <Switch
        options={[
          { label: 'No Icon', value: 'none' },
          { label: 'Icon', value: 'icon' }
        ]}
        disabled={this.props.disabled}
        onChange={(value) => {
          if (value === 'none') {
            this.props.onChange('');
          }
          this.setState({ type: value })
        }}
        value={this.state.type}
      />

      { content }
    </div>)
  }
}
