import React, { Component } from 'react';
import tinycolor from 'tinycolor2';

class Option extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onMouseOver() {
    this.setState({ hover: true });
  }

  onMouseOut() {
    this.setState({ hover: false });
  }

  render() {
    const { value, color } = this.props;

    let style = { borderColor: tinycolor(color).darken(10).toString() };
    if (this.state.hover) {
      style.background = tinycolor(color).setAlpha(.25).toString();
    }

    if (this.props.selected) {
      style.background = tinycolor(color).toString();
    }

    return (
      <div
        className={`${this.props.selected ? 'selected' : ''} ${this.props.className}`}
        onClick={(e) => { this.props.onClick(e, value) }}
        onMouseOver={this.onMouseOver.bind(this)}
        onMouseOut={this.onMouseOut.bind(this)}
      >
        <span style={style} />
        <div>{ value }</div>
      </div>  
    );
  }
}

export default class Checkbox extends Component {
  constructor(props) {
    super(props);

    let values = [];
    if (this.props.value) {
      values = this.props.value.split(', ');
    }

    this.state = { values };
  }

  handleClick(e, value) {
    e.preventDefault();
    e.stopPropagation();

    if (this.props.disabled) {
      return;
    }

    const values = this.state.values;
    if (values.indexOf(value) === -1) {
      values.push(value);
    } else {
      values.splice(values.indexOf(value), 1);
    }

    this.setState({ values });
    this.props.onChange(values.join(', '));
  }

  render() {
    return (<div
      className={`checkbox-input ${this.props.disabled ? 'disabled' : ''} ${this.props.className}`}
      active={this.props.active}
    >
      { this.props.options.map(value => {
        let selected;
        if (this.state.values.indexOf(value) !== -1) {
          selected = true;
        }
        return <Option
          value={value}
          key={value}
          color={this.props.color}
          selected={selected}
          onClick={this.handleClick.bind(this)}
        />
      }) }
    </div>)
  }
}