import React, { Component } from 'react';
import Dropzone from 'react-dropzone'

export default class CSVUploader extends Component {
  constructor(props) {
    super(props);
  }

  clear() {
    this.props.onChange(null);
  }

  render() {
    const onDrop = (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        this.props.onChange(file);
      }, [])
    };

    let name = <p>Drop a CSV here, or click to upload</p>;
    if (this.props.file) {
      name = <p>{ this.props.file.name }</p>;
    }

    let content = (
      <Dropzone
        onDrop={onDrop}
        multiple={false}
        disabled={this.props.disabled}
        accept={[".csv", "text/csv", "application/vnd.ms-excel", "application/csv", "text/x-csv", "application/x-csv", "text/comma-separated-values", "text/x-comma-separated-values"]}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          { isDragActive ? <p>Drop the CSV</p> : name }
        </div>
        )}
      </Dropzone>
    );

    let remove = null;
    if (this.props.file) {
      remove = (
        <div className="remove-file" style={{ maxWidth: this.props.maxWidth || null }}>
          <div className="remove" onClick={this.clear.bind(this)}/>
        </div>
      );
    }

    return (
      <div className={`csv-uploader ${this.props.disabled ? 'disabled' : '' }`}>
        {content}
        {remove}
      </div>
    )
  }
}
