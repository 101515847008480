import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { encode, getDate, renderParticipantId, truncate } from '../utils';

export default class Email extends Component {
  render() {
    const { _id, email, accountId, event } = this.props;
    let participant = this.props.participant || {};

    if (typeof email !== 'string') { return null; }

    let url = `/participants/a/${encode(accountId)}/pa/${encode(participant._id)}`;
    if (this.props.url) {
      url = this.props.url;
    }

    let participantRow;
    if (participant) {
      participantRow = (<div className="participant subtle t-right"><Link to={url}>{participant.handle || renderParticipantId(participant.id)}</Link></div>)
    }

    let href = null;
    // if (event.href) {
    //   href = <div className="page"><a target='_blank' href={this.props.event.href}>{truncate(this.props.event.href, 40)}</a></div>
    // }

    return (
      <div className="email-row">
        <div className="value"><a href={url}>{email}<i className="fas fa-arrow-up-right-from-square" /></a></div>
        <div className="time subtle t-right">{getDate(_id)}</div>
        {/*{ participantRow }*/}
      </div>
    );
  }
}
