import React, { Component } from 'react';

import $ from 'jquery';
import _ from 'lodash';

import BrowserFrame from './BrowserFrame';
import PagePreview from './PagePreview';

export default class VisualDisplay extends Component {
  render() {
    let slides = this.props.poll ? this.props.poll.slides : [{
      title: 'Example Slide',
      copy: `<p>This is an example slide. Your published zigpoll will look look something like this given your current settings.</p>`,
      settings: {
        showTitle: true
      }
    }];

    let settings = this.props.account.displaySettings;
    if (this.props.poll && this.props.poll.displaySettings) {
      settings = this.props.poll.displaySettings;
    }

    return (
      <div className="visual-display">
        <BrowserFrame />
        <PagePreview
          slides={slides}
          {...settings}
          {...this.props}
        />
      </div>
    );
  }
}