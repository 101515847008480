import $ from 'jquery';

var rand = function() {
  return Math.random().toString(36).substr(2);
};

var token = function() {
  return rand() + rand();
};

function getUserToken() {
  if (window.localStorage.getItem('userToken')) {
    return window.localStorage.getItem('userToken');
  }
  window.localStorage.setItem('userToken', token());
  return window.localStorage.getItem('userToken');
}

const algoliasearch = window.algoliasearch;
const aa = window.aa;

var client = algoliasearch('AFPNOH9KVA', '2fe2c063e7b94799eba9053c0d1014e5', {
  headers: {
    'X-Algolia-UserToken': getUserToken()
  }
});
var index = client.initIndex('zigpoll_search');

function performSearch(val) {
  return index
  .search(val, { clickAnalytics: true })
  .then(function (data) {
    var hits = data.hits;
    var query = data.query;


    if (query === window.searchQuery) {
      window.searchResults = hits;
      window.queryID = data.queryID;
    }

    renderSearchResults();
  })
  .catch(err => {
    console.log(err);
  });
}

function renderSearchResults() {
  if (window.searchResults === undefined) {
    $("#search-modal").removeClass('show-results');
  } else {
    $("#search-modal").addClass('show-results');
  }

  var results = window.searchResults || [];
  var $results = $("#search-modal .results");
  $results.scrollTop(0);
  $results.empty();
  results.slice(0, 20).forEach(function (result, idx) {
    var $result = $('<a class="result" href="'+result.objectID+'">');
    var $content = $('<div class="result-content">');

    $result.on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();

      aa('clickedObjectIDsAfterSearch', {
        index: 'zigpoll_search',
        eventName: 'Click item',
        queryID: window.queryID,
        objectIDs: [$(e.currentTarget).attr('href')],
        positions: [idx+1],
      });


      window.open($(e.currentTarget).attr('href'), '_blank').focus();
    });

    $content.append('<div class="title">'+result.title+'</div>');
    $content.append('<div class="description">'+result.description+'<i class="fas fa-external-link" /></div>');

    $result.append("<div class='image'><img src='"+result.image+"'/></div>");
    $result.append($content);
    $results.append($result);
  });
}

window.showSearch = function () {
  $("#search-modal").addClass('active');
  $("#search-modal input").focus();
}

function hideSearch() {
  $("#search-modal").removeClass('active');
  setTimeout(function () {
    $("#search-modal input").val('').blur();
    window.searchResults = undefined;
    window.searchQuery = undefined;
    renderSearchResults();
  }, 250);
}

$("body").on('keyup', "#search-modal input", function (e) {
  if (e.which === 27) {
    return hideSearch();
  }

  window.searchQuery = e.currentTarget.value;
  performSearch(window.searchQuery);
});

$("body").on('click', "#search-modal .overlay", function (e) {
  hideSearch();
});

aa('init', {
  appId: 'AFPNOH9KVA',
  apiKey: '2fe2c063e7b94799eba9053c0d1014e5',
});

aa("setUserToken", getUserToken());