import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Tooltip from './Tooltip';

import { decode, encode, getDate, getDateFromUpdatedAt, renderParticipantId } from '../utils';

export default class Participant extends Component {
  render() {
    let tooltip;
    if (!this.props.handle) {
      tooltip = (<Tooltip>You can add a handle to make it easier to tell who's who. To learn how, <a style={{ color: '#fff', textDecoration: 'underline' }} target="_blank" href="https://docs.zigpoll.com/embed/participant-metadata">check out the documentation</a>.</Tooltip>);
    }

    return (
      <div className="poll-participant">
        <div className="value"><a
          href={this.props.url}
          target="_blank"
        >{ this.props.handle || renderParticipantId(this.props.id, 40) }</a>{tooltip}</div>
        <div className="object-metadata">
          <div className="time">Last updated { getDateFromUpdatedAt(this.props.updatedAt) || getDate(this.props._id) }</div>
          { this.props.responseCount > 0 && <div className="responses">{ this.props.responseCount } { this.props.responseCount === 1 ? 'response' : 'responses' } across { this.props.polls.length } { this.props.polls.length === 1 ? 'survey' : 'surveys' }.</div> }
        </div>
      </div>
    );
  }
}