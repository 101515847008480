import React, { Component } from 'react';
import { Portal } from 'react-portal';

export default class BodyVisibilityToggle extends Component {
  constructor(props) {
    super(props);
    this.state = { firstRender: true }
  }
  render() {
    if (this.state.firstRender) {
      this.setState({ firstRender: false });
      setTimeout(this.forceUpdate.bind(this), 0);
    }

    return <Portal node={document && document.getElementById('body-visibility-status')}>
      { this.props.children }
    </Portal>
  }
}