import React, { Component } from 'react';
import tinycolor from 'tinycolor2';

class Option extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onMouseOver() {
    this.setState({ hover: true });
  }

  onMouseOut() {
    this.setState({ hover: false });
  }

  render() {
    const { value, color } = this.props;

    let style = {};
    if (this.props.selected) {
      style.background = tinycolor(color).setAlpha(.5).toString();
    }

    return (
      <div
        className={`${this.props.selected ? 'selected' : ''} ${this.props.className || ''}`}
        onClick={(e) => { this.props.onClick(e, value) }}
        onMouseOver={this.onMouseOver.bind(this)}
        onMouseOut={this.onMouseOut.bind(this)}
        style={style}
      >
        <div>{ value }</div>
      </div>  
    );
  }
}

export default class Radio extends Component {
  handleClick(e, value) {
    e.preventDefault();
    e.stopPropagation();

    if (this.props.disabled) {
      return;
    }

    if (value === this.props.value) {
      return this.props.onChange(undefined);
    }

    this.props.onChange(value);
  }

  render() {
    return (<div
      className={`${this.props.className ? this.props.className : 'binary-input'} ${this.props.disabled ? 'disabled' : ''}`}
      active={this.props.active}
    >
      { this.props.options.map(value => {
        let selected;
        if (value === this.props.value) {
          selected = true;
        }

        return <Option
          value={value}
          key={value}
          color={this.props.color}
          selected={selected}
          onClick={this.handleClick.bind(this)}
        />
      }) }
    </div>)
  }
}