import React, { Component } from 'react';

import Layout from '../components/Layout';
import UserCreateForm from '../components/UserCreateForm';
import SingleFormPageHeader from '../components/SingleFormPageHeader';

import shopifyLogo from '../images/shopifyLogo.png';

class CreateAccount extends Component {
  render() {
    return (
      <Layout title="Sign Up" graphics={true}>
        <SingleFormPageHeader
          title="Knowledge is power."
          subtitle="Create an account and start getting insights."
        />
        <UserCreateForm />
        <div className="below-card-info shopify"><img src={shopifyLogo} /><div className="top">Using our <a href="https://apps.shopify.com/zigpoll" target="_blank">Shopify App?</a></div><div><a href="https://docs.zigpoll.com/shopify-app/log-in" target="_blank" rel="noopener noreferrer">Click here</a> to learn how to log in through your storefront.</div></div>
      </Layout>
    );
  }
}

export default CreateAccount;
