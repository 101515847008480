import React, { Component } from 'react';
// import { findDOMNode } from 'react-dom'

import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import _ from 'lodash';

import * as RouterActions from '../actions/Router';
import * as AccountActions from '../actions/Accounts';

import { DragSource, DropTarget } from 'react-dnd';
import { Link } from 'react-router-dom';
import { encode, getTargetingSettings, getVisibilitySettings, getTemplateFromDisplayRules, prepareShopifyCustomerTargeting, capitalizeFirstLetter, customerTargetingOptions } from '../utils';

import { getName } from 'country-list';
import Tooltip from './Tooltip';

class MoreActions extends Component {
  constructor(props) {
    super(props);
    this.state = { active: false };
  }

  render() {
    return (
      <span className={`more-actions ${this.props.idx === 0 ? 'first' : ''}`}>
        <div className="menu-toggle" onClick={() => this.setState({ active: !this.state.active })}><i className="fas fa-ellipsis-v" /></div>
        <div className="menu">
          <div>
            <div className="red" onClick={() => { this.props.delete(this.props._id) }}><i className="fas fa-trash" />Delete</div>
            <div onClick={() => { this.props.archive(this.props._id) }}><i className="fas fa-archive" />Archive</div>
            <div onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.open(`/preview/${this.props.accountId}/${this.props._id.toString()}`, '_blank');
            }}><i className="fas fa-arrow-up-right-from-square" />Preview</div>
            <div 
              onClick={() => { this.props.toggleVisibility(this.props._id, { isVisible: this.props.isVisible }) }}
            ><i className={`fas ${this.props.isVisible ? 'fa-eye-slash' : 'fa-eye'}`} />{this.props.isVisible ? 'Hide' : 'Activate'}</div>
            <div onClick={() => { this.props.duplicate(this.props._id, this.props.title)}}><i className="fas fa-copy" />Duplicate</div>
            <div onClick={() => { this.props.navigateTo(`/a/${encode(this.props.accountId)}/p/${encode(this.props._id)}?tab=general`) } }><i className="fas fa-edit" />Edit Survey</div>
            <div onClick={() => { this.props.navigateTo(`/a/${encode(this.props.accountId)}/p/${encode(this.props._id)}?tab=slides`) } }><i className="fas fa-sliders" />Edit Slides</div>
          </div>
        </div>
      </span>
    );
  }
}

class Poll extends Component {
  render() {
    const { connectDragPreview, connectDragSource, connectDropTarget, isDragging, isOver, settings, isArchived, isVisible } = this.props;

    if (!settings) { return null; }

    const props = this.props;
    const poll = props;

    let isEmail = null;
    let isSms = null;
    if (settings.shopifyWebhooks || settings.shopifyAbandonedCheckout) {
      isEmail = true;
      if (poll.settings.sms) {
        isSms = true;
      }
    }

    let status = <i className="fas fa-eye-slash" title="This survey is hidden." />;
    let visibility = <div className="poll-info"><i className="fas fa-eye-slash" />Hidden</div>;
    let editLink = <div className="edit-poll has-inline-tooltip no-animate" title="Edit this survey."><Link to={`/dashboard/a/${encode(this.props.accountId)}/p/${encode(this.props._id)}`}><i className="fas fa-edit" /></Link><div className="tooltip-content">Edit this survey</div></div>

    if (isVisible) {
      status = <i className="fas fa-eye" title="This survey is visible." />;
      visibility = <div className="visible poll-info"><i className="fas fa-eye" />Live</div>;
    }
    if (isArchived) {
      status = <i className="fas fa-archive" title="This survey is archived." />;
    }

    let linkOnly = null;
    if (this.props.settings && this.props.settings.apiOnly) {
      linkOnly = <div className="poll-info"><i className="fas fa-link" title="This survey configured to be link only." />Link</div>; 
    }
    if (this.props.settings && this.props.settings.apiOnly && this.props.settings.pollType === 'api') {
      linkOnly = <div className="poll-info"><i className="fas fa-code" title="This survey configured to be visible via a link and API calls." />Link</div>; 
    }

    let visibilityStatus = <p className="active status">Visible</p>
    if (!isVisible) {
      visibilityStatus = <p className="inactive status">Hidden</p>
    }
    if (props.isArchived) {
      visibilityStatus = <p className="archived status">Archived</p>
    }

    let pageExcludeRules = null;
    if (this.props.pageExcludeRules && this.props.pageExcludeRules.length) {
      pageExcludeRules = <span>
        <label style={{marginTop: 10 }}>Exclude</label>
        <ul>{this.props.pageExcludeRules.map((rule,idx) => <li key={idx}>{rule === '' || rule === '/' ?'Homepage' : rule}</li>)}</ul>
      </span>
    }

    const targetingSettings = getTargetingSettings(this.props.account, this.props);
    const visibilitySettings = getVisibilitySettings(this.props.account, this.props);

    let pollIcon = <i className="fas fa-poll" title="This a widget type survey." />
    if (poll.settings.selector) {
      pollIcon = <i className="fas fa-arrows-to-dot" title="This an embedded survey." />
    }
    if (visibilitySettings.renderOnExitIntent) {
      pollIcon = <i className="fas fa-person-walking-dashed-line-arrow-right" title="This survey is triggered via exit intent." />
    }
    if (poll.settings.apiOnly) {
      pollIcon = <i className="fas fa-link" title="This survey is only visible via a public link." />
    }
    if (poll.settings.apiOnly && poll.settings.pollType === 'api') {
      pollIcon = <i className="fas fa-code" title="This survey is only visible via a public link and API calls." />
    }
    if (isEmail) {
      isEmail = <i className="fas fa-envelope" title="This survey is sent via email." />;      
      pollIcon = <i className="fas fa-envelope" title="This survey is sent via email." />
      if (isSms) {
        isEmail = <i className="fas fa-sms" title="This survey is sent via SMS." />;      
        pollIcon = <i className="fas fa-sms" title="This survey is sent via SMS." />
      }
    }
    const template = getTemplateFromDisplayRules(poll.pageRules, poll.pageExcludeRules, true);
    if (template) {
      pollIcon = <i className={`fas ${template.icon}`} title={template.title} />
    }

    let displayRules = <p>{ pollIcon } Show on all pages.</p>
    if (this.props.pageRules.length) {
      displayRules = <ul>{this.props.pageRules.map((rule,idx) => <li key={idx}>{rule === '' || rule === '/' ?'Homepage' : rule}</li>)}</ul>;
    }

    if (template) {
      displayRules = <p>{ pollIcon } { template.shortTitle || template.title }</p>;
      pageExcludeRules = null;
    }
    if (this.props.settings && this.props.settings.apiOnly) {
      displayRules = <p><i className="fas fa-link"/> Link Only</p>      
    }
    if (this.props.settings && this.props.settings.apiOnly && this.props.settings.pollType === 'api') {
      displayRules = <p><i className="fas fa-code"/> API Only</p>      
    }

    if (this.props.settings && this.props.settings.shopifyWebhooks) {
      isEmail = true;
      isSms = this.props.settings.sms;

      let emailDelay = this.props.settings.emailDelay || 1;
      let emailDelayInterval = this.props.settings.emailDelayInterval || 'hours';
      emailDelayInterval = emailDelayInterval.slice(0, -1);

      if (this.props.settings.shopifyWebhooks[0] === 'orders/paid') {
        displayRules = <p><i className={`fas ${isSms ? 'fa-sms' : 'fa-envelope '}`}/> {isSms ? 'Send SMS' : 'Email'} <strong>{emailDelay} { emailDelayInterval }{ emailDelay != 1 ? 's' : ''}</strong> after an order is <strong>paid</strong></p>;
      } else if (this.props.settings.shopifyWebhooks[0] === 'orders/fulfilled') {
        displayRules = <p><i className={`fas ${isSms ? 'fa-sms' : 'fa-envelope '}`}/> {isSms ? 'Send SMS' : 'Email'} <strong>{emailDelay} { emailDelayInterval }{ emailDelay != 1 ? 's' : ''}</strong> after an order is <strong>fulfilled</strong></p>;
      } else if (this.props.settings.shopifyWebhooks[0] === 'fulfillment_events/create') {
        displayRules = <p><i className={`fas ${isSms ? 'fa-sms' : 'fa-envelope '}`}/> {isSms ? 'Send SMS' : 'Email'} <strong>{emailDelay} { emailDelayInterval }{ emailDelay != 1 ? 's' : ''}</strong> after an order is <strong>delivered</strong></p>;
      } else if (this.props.settings.shopifyWebhooks[0] === 'orders/cancelled') {
        displayRules = <p><i className={`fas ${isSms ? 'fa-sms' : 'fa-envelope '}`}/> {isSms ? 'Send SMS' : 'Email'} <strong>{emailDelay} { emailDelayInterval }{ emailDelay != 1 ? 's' : ''}</strong> after an order is <strong>cancelled</strong></p>;
      }
    }
    if (this.props.settings && this.props.settings.shopifyAbandonedCheckout) {
      isEmail = true;
      let emailDelay = this.props.settings.emailDelay || 1;
      let emailDelayInterval = this.props.settings.emailDelayInterval || 'hours';
      emailDelayInterval = emailDelayInterval.slice(0, -1);

      displayRules = <p><i className="fas fa-envelope"/> Email {emailDelay} { emailDelayInterval }{ emailDelay != 1 ? 's' : ''} after abandoned checkout</p>;
    }

    const percentage = targetingSettings.percentage;
    let targetingItems = [`Show ${percentage || 100}% of the time to:`];

    if (Object.keys(targetingSettings).length) {
      const { referral, geography, language, technology, ip, visitors, pageViews } = targetingSettings;

      targetingItems = [
        `Show ${percentage || 100}% of the time to:`
      ];
      if (referral) {
        if (referral.type === 'direct') {
          targetingItems.push('Visitors who went directly to the site');
        }
        if (referral.type === 'search-engine') {
          targetingItems.push('Visitors who came from a search engine');
        }
        if (referral.type === 'only') {
          targetingItems.push(`Visitors who came from: ${referral.domains.join(', ')}`);
        }
        if (referral.type === 'except') {
          targetingItems.push(`Visitors who did not come from: ${referral.domains.join(', ')}`);
        }
      }
      if (geography && geography.countries) {
        if (geography.type === 'only') {
          targetingItems.push(`Visitors from: ${geography.countries.map((code) => getName(code)).join(', ')}`); 
        }
        if (geography.type === 'except') {
          targetingItems.push(`Visitors who are not from: ${geography.countries.map((code) => getName(code)).join(', ')}`);
        }
      }
      if (language && language.languages) {
        if (language.type === 'only') {
          targetingItems.push(`Visitors with language set to: ${language.languages.map((code) => code).join(', ')}`); 
        }
        if (language.type === 'except') {
          targetingItems.push(`Visitors with language not set to: ${language.languages.map((code) => code).join(', ')}`);
        }
      }
      if (technology && technology.technologies) {
        if (technology.type === 'only') {
          targetingItems.push(`Visitors using: ${technology.technologies.join(', ')}`); 
        }
        if (technology.type === 'except') {
          targetingItems.push(`Visitors who are not using: ${technology.technologies.join(', ')}`);
        }
      }
      if (ip && ip.ipAddresses) {
        if (ip.type === 'only') {
          targetingItems.push(`Visitors with IP: ${ip.ipAddresses.join(', ')}`); 
        }
        if (ip.type === 'except') {
          targetingItems.push(`Visitors without IP: ${ip.ipAddresses.join(', ')}`);
        }
      }
      if (visitors) {
        if (visitors.type === 'returning') {
          targetingItems.push(`Returning visitors`); 
        }
        if (visitors.type === 'first-time') {
          targetingItems.push(`First time visitors`);
        }
      }
      if (pageViews && (pageViews > 1)) {
        targetingItems.push(`Visitors who have viewed ${pageViews} pages`)
      }
    };

    if (this.props.settings.shopifyCustomerTargeting) {
      const shopifyCustomerTargeting = prepareShopifyCustomerTargeting(this.props.settings.shopifyCustomerTargeting);

      const match = _.find(customerTargetingOptions, ({ value }) => _.isEqual(value, shopifyCustomerTargeting || [[],[]]));
      if (match) {
        targetingItems.push(match.label);
      } else {
        shopifyCustomerTargeting[0].forEach((row, idx) => {
          // if (idx !== 0) {
          //   targetingItems.push('OR');
          // }
          row = [...row];
          if (row[0] === 'tags') {
            row[0] = 'customer_tags';
          }
          const val = row.join(' → ').replace('lt', 'less than').replace('gt', 'greater than').replace('_', ' ').replace(/,/g, ', ');
          targetingItems.push(capitalizeFirstLetter(val));
        });

        if (shopifyCustomerTargeting[1].length) {
          targetingItems.push('AND');

          shopifyCustomerTargeting[1].forEach((row, idx) => {
            // if (idx !== 0) {
            //   targetingItems.push('OR');
            // }
            row = [ ...row ];
            if (row[0] === 'tags') {
              row[0] = 'customer_tags';
            }
            const val = row.join(' → ').replace('lt', 'less than').replace('gt', 'greater than').replace('_', ' ').replace(/,/g, ', ');
            targetingItems.push(capitalizeFirstLetter(val));
          });
        }
      }
    }

    if (visibilitySettings.renderOnExitIntent) {
      targetingItems.push('Show on Exit Intent.');

      if (visibilitySettings.exitIntentCondition) {
        const condition = visibilitySettings.exitIntentCondition;
        if (condition === 'cartOnly') {
          targetingItems.push('Show to customers with at least one item in their cart.');
        }
        if (condition === 'emptyCartOnly') {
          targetingItems.push('Show to customers with no items in their cart.');
        }
        if (condition === 'customCartRules') {
          targetingItems.push('Show to customers based on custom conditions.');
        }
      }
    }

    if (targetingItems.length === 1) {
      targetingItems = [`Show to ${percentage || 100}% of visitors`];
    }

    let targetingRules = <ul>
      { targetingItems.map((item) => <li>{item}</li>)}
    </ul>

    let targetingBlock = (
      <div className="inline">
        <label>Targeting Rules</label>
        { targetingRules }
      </div>
    );

    if (linkOnly) {
      targetingBlock = null;
    }

    if (isEmail) {
      isEmail = <div className="poll-info"><i className="fas fa-envelope" title="This survey is sent via email." />Email</div>; 

      if (isSms) {
        isEmail = <div className="poll-info"><i className="fas fa-sms" title="This survey is sent via sms." />SMS</div>;         
      }
    }

    return connectDropTarget(<div key={this.props._id} className={`${isDragging ? 'dragging' : ''} item ${isOver ? `is-over ${direction}` : ''}`}>
      <div className="object-container">
        <div className="idx">{this.props.idx + 1}</div>
        {connectDragPreview(<div className={`poll ${this.props.idx === 0 ? 'first' : ''} ${this.props.active ? 'active' : ''}`}>
        {connectDragSource(<span className="handle" />)}
        <div className="up-down-arrows">
          <div className={`up ${this.props.idx === 0 ? 'disabled' : ''}`} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (this.props.idx === 0) {
              return;
            }
            this.props.reorderPolls(this.props.idx-1, this.props.idx);
          }}>
            <i className="fas fa-chevron-up" />
          </div>
          <div className={`down ${this.props.idx === this.props.polls.length-1 ? 'disabled' : ''}`} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (this.props.idx === this.props.polls.length-1) {
              return;
            }
            this.props.reorderPolls(this.props.idx+1, this.props.idx);
          }}>
            <i className="fas fa-chevron-down" />
          </div>
        </div>
        <div className="top">
          <div className="poll-icons">{visibility}{editLink}<MoreActions
            {...this.props}
            idx={this.props.idx}
            navigateTo={this.props.navigateTo}
            toggleVisibility={this.props.toggleVisibility}
            duplicate={this.props.duplicate}
            archive={this.props.archive}
            delete={this.props.delete}
          /></div>
          <Link to={`/dashboard/a/${encode(this.props.accountId)}/p/${encode(this.props._id)}`}>
            <div className="poll-title">{this.props.title}<i className="fas fa-edit" /></div>
          </Link>
        </div>

        <div className="poll-details">
          <div className="inline">
            <label>Display Rules</label>
            { displayRules }
            { pageExcludeRules }
          </div>

          { targetingBlock }

          {/* <div className="inline">
            <label>Status</label>
            { visibilityStatus }
          </div>*/}
        </div>

        </div>)}
      </div>
    </div>);
  }
}

/* Drag */
const dragSource = {
  beginDrag(props) {
    return {
      idx: props.idx
    };
  }
};

const dragCollect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }
};

let direction;
const dropTarget = {
  hover(props, monitor, component) {
    const dragIdx = monitor.getItem().idx;
    let dropIdx = props.idx;

    if (dragIdx < dropIdx) {
      direction = 'down';
      component.forceUpdate();
    } else if (dragIdx > dropIdx) {
      direction = 'up';
      component.forceUpdate();
    }
  },
  drop(props, monitor, component) {
    const dragIdx = monitor.getItem().idx;
    let dropIdx = props.idx;

    if (dragIdx === dropIdx) {
      return;
    }

    let idx;
    if (dragIdx < dropIdx) {
      idx = dropIdx - 1 < 0 ? 0 : dropIdx;
      props.reorderPolls(dragIdx, idx);
    } else {
      idx = dropIdx + 1 >= props.pollCount ? props.pollCount - 1 : dropIdx;
      props.reorderPolls(dragIdx, idx);
    }
  }
};

const dropCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver()
})

function mapStateToProps(state) {
  return {
    loading: state.accounts.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions, ...RouterActions }, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  DragSource('POLL', dragSource, dragCollect),
  DropTarget('POLL', dropTarget, dropCollect)
)(Poll);
