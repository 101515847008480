import React, { Component } from 'react';

import Toggle from './Toggle';
import Tooltip from './Tooltip';
import InlineInput from './InlineInput';
import { AuthSubmitButton } from './Buttons';
import Switch from './Switch';
import HeaderButtons from './HeaderButtons';

import _ from 'lodash';

export default class DynamicOpenInput extends Component {
  constructor(props) {
    super(props);

    this.initialState = { ...this.state };
  }

  onChange(name, value, ignoreFormatting) {
    const state = { ...this.state };
    state[name] = value.replace(/[^\d.-]/g,'');

    if (ignoreFormatting) {
      state[name] = value;
    } else {
      state[name] = value.replace(/[^\d.-]/g,'');
    }

    this.props.onChange(state);
  }

  renderInputs() {
    const names = this.props.names || ['autoOpenDuration', 'autoOpenPosition', 'autoOpenDevice', 'autoOpenDurationType'];
    const labels = this.props.labels || ['Duration', 'Position', 'Device']

    return (
      <div>
        <InlineInput
          label={labels[0]}
          tooltip={<Tooltip>Example: Enter 0 if you want it to activate automatically or 3 if you want it to activate after 3 seconds.</Tooltip>}
          name={names[0]}
          value={ this.props.autoOpenDuration }
          prefix="Open after"
          suffix={<span>seconds spent <div className="select-wrapper"><select value={this.props.autoOpenDurationType} onChange={(e) => {
            const { name, value } = e.target;
            this.onChange(name, value, true);
          }} name={ names[3] }><option value="page">on a single page</option><option value="site">on the whole site</option></select></div></span>}
          idx={0}
          onChange={this.onChange.bind(this)}
        />

        <InlineInput
          label={labels[1]}
          tooltip={<Tooltip>Example: Enter 0 if you want it to activate automatically when the page is loaded or 100 if you want it to activate when the user is scrolled to the very bottom.</Tooltip>}
          name={names[1]}
          value={ this.props.autoOpenPosition }
          prefix="Open when the user is"
          suffix="% from the top of the page."
          idx={1}
          onChange={this.onChange.bind(this)}
        />

        <div className="inline-input">
          <label>{labels[2]}<Tooltip>Choose if you want to open dynamically on all devices or just one or the other.</Tooltip></label>

          <Switch
            options={[
              { label: 'All Devices', value: false },
              { label: 'Desktop Only', value: 'desktop' },
              { label: 'Mobile Only', value: 'mobile' },
            ]}
            value={this.props.autoOpenDevice}
            onChange={(value) => {
              this.props.onChange({ [names[2]]: value });
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    let inputs = null;

    if (this.props.showDynamicRules) {
      inputs = (this.renderInputs());
    }

    return (<div
      className={`dynamic-open-input ${this.props.disabled ? 'disabled' : ''}`}
      active={this.props.active}
    >
      <div style={{ marginBottom: this.props.showDynamicRules ? 25 : 0, marginTop: -15, position: 'relative' }}>
        { this.props.warning }
        { this.props.toggleWarning }
        <label>Open Dynamically<Tooltip>This is useful if you want the survey to open based on either a duration or scroll position.</Tooltip></label>
        <div className="inline-toggle">
          <Toggle
            active={this.props.showDynamicRules}
            onChange={(value) => {
              this.props.onToggle(value);
              if (!value) {
                this.props.onChange({ autoOpenPosition: false, autoOpenDuration: false, autoOpenDevice: false });
              } else {
                this.props.onChange({
                  autoOpenPosition: '0',
                  autoOpenDuration: '0',
                });
              }
            }}
          />
          <div className="subtle">Open the survey automatically based on rules.</div>
        </div>
      </div>

      { inputs }

    </div>);
  }
}