import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as AccountActions from '../actions/Accounts';

import { NameInput, DomainsInput } from './SimpleInputs';
import { AuthSubmitButton } from './Buttons';

import AuthForm from './AuthForm';

class AccountCreateForm extends AuthForm {
  constructor(props)  {
    super(props);
    this.state = { 
      inputs: {
        title: '',
        domains: []
      },
      valid: undefined
    };
    this.inputStates = {};
  }

  submitAction() {
    const account = {};

    account.title = this.state.inputs.title;
    account.domains = this.state.inputs.domains;

    this.props.createAccount(account);
  }

  renderInputs() {

    let domainsInput = null;
    if (this.props.showDomainsInput) {
      domainsInput = (
        <DomainsInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Domain Restrictions"
          moreInfo={<span>Fill out this section if you want to ensure that the survey only shows up on certain domains. If you're not worried about who can display your survey select <strong>allow on all domains</strong>.</span>}
          type="text"
          placeholder="domain.com"
          name="domains"
          value={this.state.inputs.domains}
          errorMessage="Please enter a valid domain name."
          ref={this.setRef.bind(this)}
        />
      );
    }
    return (
      <div className="inputs">
        <NameInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Account Title"
          moreInfo="This title will be used for your reference so pick something simple that you can easily identify."
          maxlength={40}
          type="text"
          placeholder="Example: Your Business Name"
          name="title"
          value={this.state.inputs.title}
          errorMessage="Please a title for the account."
          ref={this.setRef.bind(this)}
        />

        { domainsInput }

        {/*
        <RadioGroup
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Type"
          type="text"
          placeholder="Free, Premium, Pro"
          name="type"
          options={[ 'free', 'essential', 'pro', 'premium' ]}
          value={this.state.inputs.type}
          errorMessage="Please enter a valid type."
        />
        */}

      </div>
    );
  }

  renderActions() {
    return (
      <div className="actions">
        <AuthSubmitButton title="Create Account" />

        <div className="login">
          <div>Need to join an existing account?</div>
          <a target="_blank" rel="noopener noreferrer" href="https://docs.zigpoll.com/accounts/join-existing-account">Learn more</a>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.accounts.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(AccountActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountCreateForm);

