import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as UserActions from '../actions/Users';

const Logout = (props) => (
  <button 
    className="logout"
    onClick={props.logout.bind(this)}
  >Log Out</button>
);

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(UserActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);

