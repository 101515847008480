import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import moment from 'moment';
import $ from 'jquery';
import _ from 'lodash';

import ColumnLoading from './ColumnLoading';

import ColumnLayout from '../components/ColumnLayout';
import HeaderButtons from '../components/HeaderButtons';
import DateRangeInput from '../components/DateRangeInput';
import { AuthSubmitButton } from '../components/Buttons';

import SectionHeader from '../components/SectionHeader';
import ExportReportModal from '../components/ExportReportModal';

import * as ReportsActions from '../actions/Reports';
import * as ParticipantsActions from '../actions/Participants';
import * as FlashNotificationActions from '../actions/FlashNotifications';

import qs from 'query-string';

import { decode, encode, renderNumber, renderAccountTitle } from '../utils';

import {
  useTable,
  useResizeColumns,
  useBlockLayout,
} from 'react-table';
import { useVirtual } from 'react-virtual'

const getWidth = (string) => {
  if (string === 'Sentiment') { return 160 };

  const $container = $('<div />');

  $container.css({
    display: 'inline-block',
    visibility: 'hidden',
    position: 'absolute',
    padding: '0 40px',
    fontWeight: 700,
    fontSize: '16px',
    left: -10000
  });

  $('body').append($container);

  $container.append(string);

  const w = $container.outerWidth(true);
  $container.remove();

  return w;
};

function getSentimentClass(sentiment) {
  let className = 'neutral';
  if (sentiment === 'Positive')  {
    className = "positive";
  }

  if (sentiment === 'Negative') {
    className = "negative";
  }

  return className;
}

function getSentimentIcon(sentiment) {
  let title = "Neutral";
  let icon = 'fa-meh'
  let color = ''

  if (sentiment === 'Positive')  {
    icon = 'fa-smile';
    title = 'Positive';
    color = 'green';
  }

  if (sentiment === 'Negative') {
    icon = 'fa-frown';
    title = 'Negative';
    color = 'red';
  }

  return <i title={title} className={`${color} fa ${icon}`}/>
}

const hiddenColumns = ['_id']

const printCell = function (column, value) {  
  if (!value) { return printHeader(column); }
  value = value.toString();

  if (column === 'Created Date' || column === 'Last Updated') {
    return moment(value).fromNow();
  }
  return value;
}

function printHeader(header) {
  if (header.indexOf('Survey Metadata: ') !== -1) {
    header = header.replace('Survey Metadata: ', '');
  } else if (header.indexOf('Metadata: ') !== -1) {
    header = header.replace('Metadata: ', '');
  } else if (header.indexOf('Slide: ') !== -1) {
    header = header.replace('Slide: ', '');
    header = header.split(' | Id: ')[0]
  }

  return header; 
}

class Checkbox extends Component {
  render() {
    const { column, hiddenColumns } = this.props;

    return (
      <div 
        className={`checkbox ${hiddenColumns.indexOf(column.id) === -1 ? 'selected' : null}`}
        onClick={() => this.props.onClick(column)}
      >
        {/*<span />*/}
        <i className={`${hiddenColumns.indexOf(column.id) === -1 ? 'fas fa-square-check' : 'far fa-square'}`} />
        <div>{printHeader(column.id)}</div>
      </div>
    )
  }
}

const Table = (props) => {
  const data = props.data;
  const columns = props.columns;

  const [activeHeaders, setActiveHeaders] = React.useState([]);

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 100,
      width: 150
    }),
    []
  );

  const tableInstance = useTable({ columns, data, defaultColumn, initialState: {
      hiddenColumns: [ ...props.hiddenColumns ]
  } }, useBlockLayout, useResizeColumns);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow,
    allColumns,
    getToggleHideAllColumnsProps
  } = tableInstance

  const tableContainerRef = React.useRef();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  })
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  function renderHeader(header) {
    if (header.indexOf('Survey Metadata: ') !== -1) {
      return <div><span className="metadata pill grey">survey metadata</span><span>{ header.replace('Survey Metadata: ', '') }</span></div>
    }
    if (header.indexOf('Metadata: ') !== -1) {
      return <div><span className="metadata pill grey">metadata</span><span>{ header.replace('Metadata: ', '') }</span></div>
    }
    if (header.indexOf('Slide: ') !== -1) {
      let [ title, slideId ] = header.split(' | ');
      title = title.replace('Slide: ', '');
      slideId = slideId.replace('Id: ', '');

      return <div><span className="slide-info pill grey">slide</span><a target="_blank" href={`/a/${encode(props.accountId)}/p/${encode(props.pollId)}/s/${encode(slideId)}`}>{ title }</a></div>
    }

    return header;
  }

  function segmentColumns(columns) {
    const result = {
      Participant: [],
      'Participant Metadata': [],
      'Response': [],
      'Response Metadata': [],
    }

    columns.forEach((col) => {
      if (hiddenColumns.indexOf(col.Header) !== -1) { return; }

      if (col.Header.indexOf('Slide: ') !== -1) {
        result.Response.push(col);
      } else if (col.Header.indexOf('Survey Metadata: ') !== -1) {
        result['Response Metadata'].push(col);
      } else if (col.Header.indexOf('Metadata:') !== -1) {
        result['Participant Metadata'].push(col);        
      } else {
        result.Participant.push(col);
      }
    })

    return result;
  }

  function renderCell(cell) {
    if (cell.column.Header === 'Id') {
      return <a target="_blank" href={`/participants/a/${encode(props.accountId)}/pa/${encode(cell.row.values._id)}`}>{cell.render('Cell')}</a>
    }
    if (cell.column.Header === 'Handle') {
      return <a target="_blank" href={`/participants/a/${encode(props.accountId)}/pa/${encode(cell.row.values._id)}`}>{cell.render('Cell')}</a>
    }
    if (cell.column.Header === 'Created Date' || cell.column.Header === 'Last Updated') {
      return <div>{ moment(cell.value).fromNow() }</div>
    }
    if (cell.column.Header === 'Survey Metadata: shopify_customer_id') {
       return <a target="_blank" rel="noopener noreferrer" href={`https://${props.account.shop}/admin/customers/${cell.value}`}>{cell.render('Cell')}</a>
    }
    if (cell.column.Header === 'Survey Metadata: shopify_order_id') {
        return <a target="_blank" rel="noopener noreferrer" href={`https://${props.account.shop}/admin/orders/${cell.value}`}>{cell.render('Cell')}</a>
    }
    if (cell.column.Header === 'Survey Metadata: shopify_checkout_id') {
       return <a target="_blank" rel="noopener noreferrer" href={`https://${props.account.shop}/admin/checkouts/${cell.value}`}>{cell.render('Cell')}</a>
    }
    if (cell.column.Header === 'Sentiment') {
      return <span class={`sentiment ${getSentimentClass(cell.value)}`}>{getSentimentIcon(cell.value)} {cell.render('Cell')}</span>
    }
    return cell.render('Cell');
  }

  const columnSections = segmentColumns(allColumns);

  function getSortedIcon(header) {
    if (!props.sortByState) { return null; }

    if (props.sortByState[header]) {
       return <span>{props.sortByState[header] === 1 ? <i class="fas fa-arrow-down"></i> : <i class="fas fa-arrow-up"></i>}</span>
    }

    return null;
  }

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;
  return (
   <div className={`table-component-wrapper ${data.length ? 'active' : 'empty'}`} {...getTableProps()}>
      <div className="table-controls">
      <div className="filters">
        <strong><i className="fas fa-filter" />Filters</strong>
        {Object.keys(columnSections).map(key => {
          const cols = columnSections[key];
          if (cols.length === 0) { return null; }

          const active = activeHeaders.indexOf(key) !== -1;

          return (
            <div className={`header ${active ? 'active' : ''}`} onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              let newHeaders = [ ...activeHeaders ];

              if (newHeaders.indexOf(key) !== -1) {
                newHeaders = [];
              } else {
                newHeaders = [key]
              }

              setActiveHeaders(newHeaders);
            }}><strong>{key}</strong><i className={`fas ${active ? 'fa-minus' : 'fa-plus'}`} /></div>)
        })}
      </div>

      <div className="filter-columns">
        {Object.keys(columnSections).map(key => {
          const cols = columnSections[key];
          if (cols.length === 0) { return null; }

          const active = activeHeaders.indexOf(key) !== -1;
          if (!active) { return null; }

          return <div>{ cols.map((column) => (
            <div key={column.id}>
              <Checkbox
                column={column}
                hiddenColumns={props.hiddenColumns}
                onClick={(column) => props.toggleHideColumn(column.id)}
              />
            </div>
          ))}</div>
        })}
      </div>
      </div>

    <div ref={tableContainerRef} className={`table ${props.loading ? 'loading' : ''}`} style={{ width: props.tableWidth }}>
      <div style={{ height: paddingTop+'px' }} />
       <div className="header">
         {headerGroups.map(headerGroup => (
           <div
            className="row"
            {...headerGroup.getHeaderGroupProps()}
            style={{width: props.tableWidth, overflow: 'hidden'}}
          >
             {headerGroup.headers.map(column => {
              if (hiddenColumns.indexOf(column.Header) !== -1) { return null; }
              const icon = getSortedIcon(column.Header);
              return(
               <div className={`cell ${icon ? 'has-icon' : 'no-icon'}`} {...column.getHeaderProps()} title={column.render('Header')}
               onClick={() => props.sortBy(column.Header)}>
                 { renderHeader(column.Header) }
                 { icon || <i class="fas fa-arrow-down placeholder"></i> }
                  <div
                  {...column.getResizerProps()}
                  title="Drag to resize column."
                  className={`resizer ${
                  column.isResizing ? 'isResizing' : ''
                  }`}
                  />
               </div>)
             })}
           </div>
         ))}
       </div>
       <div className="body" {...getTableBodyProps()}>
        { virtualRows.map((r, i) => {
          const row = rows[r.index];
          prepareRow(row);
          return (
            <div
              {...row.getRowProps()}
              className="row"
              style={{width: props.tableWidth, overflow: 'hidden'}}
            >
              {row.cells.map(cell => {
                if (hiddenColumns.indexOf(cell.column.Header) !== -1) { return null; }

                return (
                  <div {...cell.getCellProps()} className="cell" title={cell.value}>
                    <span>{ renderCell(cell) }</span>
                    <div
                    {...cell.column.getResizerProps()}
                    title="Drag to resize column."
                    className={`resizer ${
                    cell.column.isResizing ? 'isResizing' : ''
                    }`}
                    />
                  </div>
                )
              })}
            </div>
          );
         })}
       </div>
      <div style={{ height: paddingBottom+'px' }} />
    </div>
   </div>
  );
}

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      dateRangeOption: 'all-time',
      pollId: props.pollId,
      page: 0,
      dateRange: { 
        startDate: moment().subtract(6, 'days').startOf('day').toDate(),
        endDate: moment().endOf('day').toDate() 
      },
      sortBy: undefined,
    };
    this.columnWidths = {};
    this.showModal = false;
    this.hiddenColumns = ['_id', 'id', 'IP Address', 'User Agent', 'Device', 'Browser', 'OS', 'Platform', 'Latitude', 'Sentiment', 'Longitude', 'Zip', 'City', 'Region Code', 'Region Name', 'Country Code', 'Country Name', 'Metadata: repeat_customer_twoday', 'Metadata: has_purchased', 'Metadata: shopify_customer_id', 'Metadata: shopify_address', 'Metadata: shopify_city', 'Metadata: shopify_country', 'Metadata: shopify_country_code', 'Metadata: shopify_province', 'Metadata: shopify_province_code', 'Metadata: shopify_zip', 'Created Date', 'Metadata: customer_id', 'Survey Metadata: shopify_customer_email', 'Survey Metadata: utm_source', 'Survey Metadata: utm_medium', 'Survey Metadata: utm_campaign', 'Survey Metadata: utm_content', 'Survey Metadata: original-referrer', 'Survey Metadata: landing-page', 'Survey Metadata: First Name', 'Survey Metadata: Last Name', 'Survey Metadata: email', 'Survey Metadata: completed_poll', 'Survey Metadata: activityId', 'Survey Metadata: browser-language', 'Survey Metadata: __ttr'];
  }

  setInitialState() {
    this.initialState = this.state;
    this.forceUpdate();
  }

  componentDidMount() {
    if (this.props.user.accounts.length) {
      this.props.fetchData(this.props.accountId, this.props.isPollReport).then(() => {
        if (!this.props.isPollReport) {
          if (this.props.polls && this.props.polls[0]) {
            this.setState({
              pollId: this.props.polls[0]._id
            });
          }
        }

        if (this.props.isPollReport) {
          this.setState({ page: 0, sortBy: undefined }, this.fetch.bind(this))
        }
      });
    } else {
      this.props.history.replace('/a/create');
    }
  }

  componentWillUnmount() {
    this.props.clear();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.reports.loading !== nextProps.reports.loading) {
      // console.log('update cuz of loading');
      return true;
    }
    if (this.props.polls !== nextProps.polls) {
      // console.log('update cuz of polls');
      return true;
    }
    if (!_.isEqual(this.state, nextState)) {
      // console.log('update cuz of next state');
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    if ((this.props.reports.loading !== prevProps.reports.loading) && this.props.reports.loading === false) {
      this.setInitialState();
    }
  }

  export() {
    this.showModal = true;
    this.forceUpdate();
  }

  onConfirm(email, includeColumns) {
    const report = this.props.reports[this.props.accountId];
    if (email && report) {
      const fields = report.fields;

      let columns;
      if (includeColumns) {
        columns = _.without(fields, ...this.hiddenColumns);
      }

      this.props.exportPollParticipants(email, this.state.pollId, this.state.dateRange, this.state.sortBy, columns);

    } else {
      this.props.flash('No report available. Please fill out the form.')
    }

    this.showModal = false;
    this.forceUpdate();
  }

  onChange(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ [e.target.name]: e.target.value });
  }

  hasChanged() {
    const x = JSON.stringify(this.state);
    const y = JSON.stringify(this.initialState);
    return !_.isEqual(x, y);
  }

  fetch() {
    this.props.fetchReport({
      accountId: this.props.accountId,
      pollId: this.state.pollId,
      page: this.state.page || 0,
      dateRange: this.state.dateRange,
      sortBy: this.state.sortBy
    });
  }

  fetchPage() {
    this.props.fetchReportPage({
      accountId: this.props.accountId,
      pollId: this.state.pollId,
      page: this.state.page || 0,
      dateRange: this.state.dateRange,
      sortBy: this.state.sortBy
    });
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ page: 0, sortBy: undefined }, this.fetch.bind(this))
  }

  getNextPage() {
    // this.setState({ page: this.state.page + 1 }, this.fetchPage.bind(this));
    this.state.page = this.state.page + 1;
    this.fetchPage();
  }

  sortBy(value) {
    let order = 1;
    if (this.state.sortBy && this.state.sortBy[value]) {
      order = this.state.sortBy[value] === 1 ? -1 : 1;
    }
    this.setState({ page: 0, sortBy: { [value]: order } }, this.fetch.bind(this));
  }

  toggleHideColumn(column) {
    const idx = this.hiddenColumns.indexOf(column);
    if (idx === -1) {
      this.hiddenColumns.push(column);
    } else {
      this.hiddenColumns.splice(idx, 1);
    }
    this.forceUpdate();
  }

  render() {
    let account;
    let loaded;

    if (this.props.accounts && this.props.user.accounts.length) {
      account = this.props.account;

      if (account && account.polls) {
        loaded = true;
      }
    }

    if (loaded && this.props.account.polls.length === 0) {
      return (
      <ColumnLayout
        title="Reports"
        graphics={false}
      >
        <div>
          <SectionHeader
            title=<span><i className="fas fa-columns" />Reports</span>
            subtitle="View all your survey data in a table format."
            className="no-margin"
          />

          <div className="card empty-object-list" style={{ margin: '0px auto' }}>
            <h3>You haven't made any surveys</h3>
            <p>Your reports will show up here when you make some.</p>

            <Link to={`/a/${encode(this.props.accountId)}/p/create`} className="green">Create Survey</Link>
          </div>
        </div>
      </ColumnLayout>
      );
    }

    const report = this.props.reports[this.props.accountId] || { data: [], fields: [] };

    if (!account) {
      return <ColumnLoading />
    }

    const getColumnWidths = (fields) => {
      const columns = {};

      /* Flatten fields for measuring */
      report.fields.forEach((field) => {
        if (this.columnWidths[field]) { return; }

        columns[field] = [printCell(field)];
        report.data.forEach((d) => {
          if (d[field]) {
            columns[field].push(printCell(field, d[field]));
          }
        });
      });

      Object.keys(columns).forEach((field) => {
        if (this.columnWidths[field]) { return; }
        const sorted = _.sortBy(columns[field], (d) => d.length).slice(-3);
        // if (sorted.length > 1) {
          columns[field] = sorted;
        // } else {
        //   delete columns[field];
        // }
      });

      const columnWidths = {};
      Object.keys(columns).forEach((field) => {
        if (this.columnWidths[field]) { return; }
        let maxWidth = -1000;
        columns[field].forEach((d) => {
          const w = getWidth(d);
          if (w > maxWidth) {
            maxWidth = _.min([w, 500]);
          }
        });
        columnWidths[field] = maxWidth;
      });

      this.columnWidths = { ...this.columnWidths, ...columnWidths };
      return this.columnWidths;
    }

    let columns = [];
    const columnWidths = getColumnWidths(report.fields);
    let tableWidth = 0;
    Object.keys(columnWidths).map((key) => {
      if (this.hiddenColumns.indexOf(key) === -1) {
        tableWidth += columnWidths[key] - 1;
      }
    });

    report.fields.forEach((field) => {
      // let isPopulated = columnWidths[field] !== undefined;

      const column = {
        Header: field,
        accessor: (d) => d[field],
        sortType: 'alphanumeric',
        width: columnWidths[field]
      }

      // if (!isPopulated) {
        // this.hiddenColumns.push(field);
      // }

      columns.push(column);
    });

    let nextPage = (<div id="next-page">Showing {Math.min(report.data.length, (100 * (this.state.page + 1)))} of { report.total }. <span onClick={this.getNextPage.bind(this)}>Load next 100</span></div>);
    if (this.props.reports.loading) {
      nextPage = <div id="next-page">Loading...</div>
    }
    if (report.data.length === 0) {
      nextPage = <div id="next-page">Submit the form to generate a report.</div>
    }
    if (report.pages === this.state.page) {
      nextPage = <div id="next-page">All results have been loaded.</div>
    }
    
    let content = (<div className="table-wrapper">
      <Table
        accountId={this.props.accountId}
        account={this.props.account}
        pollId={this.state.pollId}
        data={report.data}
        columns={columns}
        tableWidth={tableWidth}
        hiddenColumns={this.hiddenColumns}
        sortBy={this.sortBy.bind(this)}
        sortByState={this.state.sortBy}
        toggleHideColumn={this.toggleHideColumn.bind(this)}
        loading={this.props.reports.loading}
      />
    </div>);
    if (report.data.length === 0 && report.pages === 0) {
      content = (<div className="no-results empty-object-list">
        <h3>No results found.</h3>
        <p>Try broadening your date range or waiting for some more feedback.</p>
      </div>)
    }

    return (
      <ColumnLayout
        title="Reports"
        graphics={false}
      >
        <div className="report-wrapper" style={{ minWidth: 1000 }}>
          <form
            id="report-inputs"
            className={`${this.props.reports.loading ? 'loading' : ''}`}
            onSubmit={this.onSubmit.bind(this)}
          >
            <div className="input">
              <label>Date Range</label>
              <DateRangeInput
                startDate={this.state.dateRange.startDate}
                endDate={this.state.dateRange.endDate}
                onChange={(dateRange) => this.setState({ dateRange })}
              />
            </div>
            <div className="input">
              <label>Survey Responses</label>
              <div className="select-wrapper"><select name="pollId" value={this.state.pollId} style={{ minWidth: 300 }} onChange={this.onChange.bind(this)}>
                <option value="">None Selected</option>
                {this.props.polls.map((poll) =>
                  <option value={ poll._id } >{ poll.title }</option>
                )}
              </select></div>
            </div>
            <div className="input">
              <AuthSubmitButton
                title="Generate Report" 
                disabled={false}
                loading={this.props.reports.loading}
              />
            </div>
          </form>

          { content }
          { nextPage }

          <ExportReportModal
            isOpen={this.showModal}
            onConfirm={this.onConfirm.bind(this)}
            closeModal={() => {
              this.showModal = false;
              this.forceUpdate();
            }}
          />
        </div>

        <HeaderButtons>
          <button
            onClick={this.export.bind(this)}
            disabled={report.data.length === 0}
          >Export to CSV</button>
        </HeaderButtons>
      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId] || {};

  const params = qs.parse(ownProps.location.search);
  const idx = parseInt(params.idx) || 0;
  
  let pollId = decode(ownProps.match.params.pollId);
  let isPollReport = true;

  if (!pollId) {
    pollId = decode(params.id);
    isPollReport = false;
  }

  return {
    account,
    accounts,
    accountId,
    pollId,
    reports: state.reports,
    activePoll: state.polls[pollId],
    polls: account.polls || [],
    user: state.user,
    diffs: state.diffs,
    isPollReport,
    idx
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...ParticipantsActions, ...FlashNotificationActions, ...ReportsActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reports));
