import React, { Component } from 'react';
import moment from 'moment';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as FlashNotificationActions from '../actions/FlashNotifications';
import * as AccountsActions from '../actions/Accounts';

import Modal from './Modal';
import Tooltip from './Tooltip';
import Toggle from './Toggle';
import Switch from './Switch';
import DateRangeInput from './DateRangeInput';

import { decode, validateEmail } from '../utils';

class ExportCsvModal extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, includePollInExport: this.props.dashboardExport ? true : false, email: this.props.email, pollId: this.props.pollId, dateRangeOption: 'all-time', dateRange: { startDate: moment().subtract(6, 'days').startOf('day').toDate(), endDate: moment().endOf('day').toDate() } };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.setState({ showModal: this.props.show });
      this.props.fetchAccount();
    }
  }

  onChange(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ [e.target.name]: e.target.value });
  }

  closeModal() {
    this.setState({ showModal: false });
    this.props.onCancel();
  }

  render() {
    let pollSelector = null;

    if (!this.props.account) { return null; }

    if (this.state.includePollInExport && !this.props.pollId) {
      pollSelector = (<div>
        <label>Survey</label>
        <div className="select-wrapper" style={{ marginBottom: 30, width: 'auto', maxWidth: '100%' }}><select name="pollId" style={{ width: 'auto', maxWidth: '100%' }}value={this.state.pollId} onChange={this.onChange.bind(this)}>
          {this.props.polls.map((poll) =>
            <option value={ poll._id } >{ poll.title }</option>
          )}
        </select></div>
      </div>);
    }

    const exportPercentage = this.props.account.exportPercentage;
    let warning = null;
    if (exportPercentage !== undefined) {
      warning = <div className="warning">
        You are currently running an export. It is { Math.floor(exportPercentage) }% complete. Please wait for it to finish before running another one.
      </div>
    }

    let includePollResponses = (
      <div style={{ marginTop: 10, marginBottom: 20, position: 'relative', zIndex: 1 }}>
        <label>Include Responses<Tooltip>Enable if you want to return responses in addition to information about each participant.</Tooltip></label>
        <div className="inline-toggle">
          <Toggle
            active={this.state.includePollInExport}
            onChange={(value) => {
              this.setState({ includePollInExport: value });
            }}
          />
          <div className="subtle">Include responses to {this.props.pollId ? 'this' : 'a'} {this.props.slideId ? 'slide' : 'survey' } with your export.</div>
        </div>
      </div>
    );

    let dateRange = (
      <div style={{ marginTop: 10, marginBottom: 20, position: 'relative', zIndex: 1 }}>
        <label>Date Range<Tooltip>Enable if you want to export within a certain date range.</Tooltip></label>
        <div className="inline-toggle">
          <Switch
            value={this.state.dateRangeOption}
            options={[{
              label: 'All time',
              value: 'all-time'
            }, {
              label: 'Custom Dates',
              value: 'custom-dates'
            }]}
            onChange={(value) => {
              this.setState({ dateRangeOption: value });
            }}
          />
        </div>
      </div>
    );

    let dateRangeInputs = null;
    if (this.state.dateRangeOption === 'custom-dates') {
      dateRangeInputs = (<span>
        <DateRangeInput
          startDate={this.state.dateRange.startDate}
          endDate={this.state.dateRange.endDate}
          onChange={(dateRange) => this.setState({ dateRange })}
        />
      </span>);
    }

    if (this.props.dashboardExport) {
      includePollResponses = null;
    }

    return (
      <Modal 
        isOpen={this.state.showModal}
        onRequestClose={this.closeModal.bind(this)}
      >
        <div className="frame export-csv">
          <div className="close" onClick={this.closeModal.bind(this)} />
          <div className="title">{ this.props.title }</div>
          <div className="content">
            { warning }
            <div className="subtitle">{ this.props.subtitle }</div>
            <div className="copy">{ this.props.copy }</div>

            { includePollResponses }
            { pollSelector }
            { dateRange }
            { dateRangeInputs }

            <div>
              <label>Send To:</label>
              <input type="email" name="email" value={ this.state.email } onChange={this.onChange.bind(this)}/>
            </div>

            <div className="actions">
              <button className="positive" disabled={exportPercentage === undefined ? false : true} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                let pollId = this.state.pollId;

                if (!this.state.pollId && this.state.includePollInExport) {
                  const polls = this.props.polls || [{}];
                  pollId = polls[0]._id;
                }

                if (!validateEmail(this.state.email)) {
                  return this.props.flash('Please enter a valid email address.');
                }


                const data = {
                  email: this.state.email,
                  responsePollId: this.state.includePollInExport ? pollId : undefined
                };


                if (this.state.dateRangeOption === 'custom-dates') {
                  data.dateRange = this.state.dateRange;
                }

                this.props.onConfirm(data);

                this.closeModal();
              }}>Send CSV</button>
              <button className="negative" onClick={this.closeModal.bind(this)}>Cancel</button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId] || {};

  return {
    polls: account.polls || [],
    account
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...FlashNotificationActions, ...AccountsActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExportCsvModal));


