import React, { Component } from 'react';

import Tooltip from '../components/Tooltip';
import SectionHeader from '../components/SectionHeader';


export default class SentimentCard extends Component {
  render() {
    if (this.props.sentiment === undefined) { return null;}

    let group = "neutral";
    let title = "Neutral";
    let icon = 'fa-meh'

    if (this.props.sentiment > 0)  {
      group = "positive";
      icon = 'fa-smile';
      title = 'Positive';
    }

    if (this.props.sentiment < 0) {
      group = "negative";
      icon = 'fa-frown'
      title = 'Negative'
    }

    return (<div>
      <div className={`card sentiment-card ${group}`} style={{ marginBottom: 25 }}>
        <div className="title">Sentiment: { title }</div>
        <div><i className={`fa ${icon}`}/></div>
      </div>
    </div>)
  }
}