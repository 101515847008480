import { post } from '../ajax';
import { url } from '../settings';

export const FETCHING_INSIGHT_DATA = 'FETCHING_INSIGHT_DATA';
export const FETCHED_INSIGHT_DATA = 'FETCHED_INSIGHT_DATA';

export function generateInsight(accountId, pollId, slideId, dateRange, query) {
  return (dispatch, getState) => {

    let insights = getState().insights[slideId] || [];
    let history = [ ...insights ];
    history = history.map(({ content, query }) => ({ content, query }));
    dispatch({ type: FETCHING_INSIGHT_DATA, key: slideId, query });
    return post(`${url}/insight/account/${accountId}/poll/${pollId}/slide/${slideId}`, { dateRange, query, history })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        console.log('error');
        console.log(json.error);

        dispatch({ type: FETCHED_INSIGHT_DATA, slideId, insight: { content: json.error, type: 'error', query } })
      } else {
        console.log('got insight');
        console.log(json);

        const accounts = getState().accounts;
        const account = accounts[accountId];
        if (account.manualInsightCount === undefined) {
          account.manualInsightCount = 1;
        } else {
          account.manualInsightCount = account.manualInsightCount + 1;
        }

        dispatch({ type: 'UPDATED_ACCOUNT', account })
        dispatch({ type: FETCHED_INSIGHT_DATA, slideId, insight: json.insight });
      }
    })
    .catch(error => console.log(error))
  }
}

