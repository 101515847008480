import React, { Component } from 'react';

import NumericInput from 'react-numeric-input';

export default class DateInput extends Component {
  render() {
    return (
      <div className="numeric-input">
        <NumericInput 
          value={this.props.value}
          onChange={this.props.onChange}
          style={{
              wrap: {
                fontSize: 16,
                borderRadius: '5px',
              },
              input: {
                display: 'block',
                fontWeight: 500,
              },
              arrowUp: {
              },
              arrowDown: {
              }
          }}
        />
      </div>
    );
  }
}