import React, { Component } from 'react';

import { getShareDomain, encode } from '../utils';

import tinycolor from 'tinycolor2';

const fonts = {
  'sans': 'Helvetica',
  'serif': 'Georgia',
  'slab': 'Courier New'
}

class Row extends Component {
  render() {
    if (!this.props.content) { return null; }

    return (
      <div
        className={this.props.className}
        dangerouslySetInnerHTML={{ __html : this.props.content }}
      />
    );
  }
}

export default class BrowserFrame extends Component {
  render() {
    const { message } = this.props;
    let url = `${getShareDomain(this.props.account)}${this.props.account ? encode(this.props.account._id) :  'ACCOUNTID'}/${this.props.poll ? encode(this.props.poll._id) : 'POLLID'}`;

    if (this.props.customLink) {
      url = this.props.customLink;
    }

    let displayMessage = <pre style={{ whiteSpace: 'pre-wrap' }}>{message} <br/><br/><u style={{display:'block', wordBreak: 'break-all'}}>{url}</u></pre>

    console.log(this.props);

    return (
      <div className="sms-preview-wrapper">
        <div class="sms-preview">
          <div className="sms-content">
            <div className="content" >{ displayMessage }</div> 
          </div>
        </div>
      </div>
    );
  }
}