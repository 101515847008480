import React, { Component } from 'react';

// import 'rc-slider/assets/index.css';
// import 'rc-tooltip/assets/bootstrap.css';

import Slider from 'rc-slider';
import RCTooltip from 'rc-tooltip';

const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <RCTooltip
      prefixCls="rc-slider-tooltip"
      overlay={value+'px'}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </RCTooltip>
  );
};

export class FontSizeSlider extends Slider {

}

FontSizeSlider.defaultProps = {
  ...Slider.defaultProps,
  min: 13,
  max: 19,
  step: .5,
  marks: { 13: 'Small', 16: 'Default', 19: 'Large' },
  handle: handle
}

export class TargetingSlider extends Slider {

}

const percHandle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <RCTooltip
      prefixCls="rc-slider-tooltip"
      overlay={value+'%'}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </RCTooltip>
  );
};

TargetingSlider.defaultProps = {
  ...Slider.defaultProps,
  min: 0,
  max: 100,
  step: 1,
  handle: percHandle
}

const borderRadiusHandle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <RCTooltip
      prefixCls="rc-slider-tooltip"
      overlay={value+'px'}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </RCTooltip>
  );
};

export class BorderRadiusSlider extends Slider {

}

BorderRadiusSlider.defaultProps = {
  ...Slider.defaultProps,
  min: 0,
  max: 20,
  step: 1,
  marks: { 0: 'Sharp', 20: 'Round' },
  handle: borderRadiusHandle
}


export class LauncherBorderRadiusSlider extends Slider {

}

LauncherBorderRadiusSlider.defaultProps = {
  ...Slider.defaultProps,
  min: 0,
  max: 25,
  step: 1,
  marks: { 0: 'Sharp', 25: 'Round' },
  handle: borderRadiusHandle
}

export class BorderWidthSlider extends Slider {

}

BorderWidthSlider.defaultProps = {
  ...Slider.defaultProps,
  min: 0,
  max: 4,
  step: 1,
  marks: { 0: 'None', 4: 'Thick' },
  handle: borderRadiusHandle
}