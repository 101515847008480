import React, { Component } from 'react';

class InlineInput extends Component {
  onChange(e) {
    let value = e.target.value;
    this.props.onChange(value, this.props.name);
  }

  render() {
    return (
      <div className="inline-input">
        <label>{ this.props.label }</label>
        <input
          type="text"
          name={ this.props.name }
          value={ this.props.value }
          onChange={ this.onChange.bind(this) }
          disabled={this.props.disabled}
        />
        <span className="suffix">{ this.props.suffix }</span>
      </div> 
    );
  }
}

export default class SizeInput extends Component {
  handleChange(value, name) {
    let size = this.props.size;
    size[name] = value.replace(/[^\d.-]/g,'');

    this.props.onChange(size);
  }

  render() {
    return (<div
      className={`position-input ${this.props.disabled ? 'disabled' : ''}`}
      active={this.props.active}
    >

      <InlineInput
        label="Width"
        name="maxWidth"
        value={ this.props.size.maxWidth }
        suffix="Pixels"
        disabled={this.props.disabled || this.props.bottomDisabled}
        onChange={this.handleChange.bind(this)}
      />

      <InlineInput
        label="Max Height"
        name="maxHeight"
        value={ this.props.size.maxHeight }
        suffix="Pixels"
        disabled={this.props.disabled}
        onChange={this.handleChange.bind(this)}
      />

    </div>);
  }
}