import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Linke } from 'react-router-dom';

import qs from 'query-string';

import * as UserActions from '../actions/Users';

import { NameInput, EmailInput, PasswordInput } from './SimpleInputs';
import { AuthSubmitButton } from './Buttons';

import AuthForm from './AuthForm';

class LifetimeUserCreateForm extends AuthForm {
  constructor(props)  {
    super(props);

    const { code } = qs.parse(props.location.search);
    this.state = { 
      inputs: {
        email: '',
        name: '' ,
        password: '',
        code: code || '',
        accountTitle: ''
      },
      valid: undefined
    };
    this.inputStates = {};
  }

  submitAction() {
    this.props.createLifetimeUser(this.state.inputs);
  }

  renderInputs() {
    return (
      <div className="inputs">
        <NameInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Full Name"
          type="text"
          placeholder="Full Name"
          name="name"
          value={this.state.inputs.name}
          errorMessage="Please enter your full name."
          ref={this.setRef.bind(this)}
        />

        <EmailInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Email Address"
          type="email"
          placeholder="name@company.com"
          name="email"
          value={this.state.inputs.email}
          errorMessage="Please enter a valid email address."
          ref={this.setRef.bind(this)}
        />

        <PasswordInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Password"
          type="password"
          placeholder="Password (at least 6 characters)"
          name="password"
          value={this.state.inputs.password}
          errorMessage="Please enter a password with at least 6 characters."
          ref={this.setRef.bind(this)}
        />

        <NameInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Account Title"
          type="text"
          placeholder="Account Title"
          name="accountTitle"
          value={this.state.inputs.accountTitle}
          errorMessage="Please enter a title for your account."
          ref={this.setRef.bind(this)}
        />

        <NameInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Rebeliance Code"
          type="text"
          placeholder="Rebeliance Redemption Code"
          name="code"
          value={this.state.inputs.code}
          errorMessage="Please enter a rebeliance code"
          ref={this.setRef.bind(this)}
        />
      </div>
    );
  }

  renderActions() {
    return (
      <div className="actions">
        <AuthSubmitButton className="full" title="Hook Me Up For Life!" />

        <div className="login">
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.user.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(UserActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LifetimeUserCreateForm));

