import Modal from 'react-modal';
Modal.setAppElement('#root');

class CustomModal extends Modal {

}

CustomModal.defaultProps = {
  ...Modal.defaultProps,
  className: 'modal',
  overlayClassName: 'overlay',
  closeTimeoutMS: 300
}

export default CustomModal;