import { replace, push } from 'connected-react-router';
import { pushServerError, clearNotifications } from './Notifications';
import { flash } from './FlashNotifications';
import { post, postFormData } from '../ajax';
import { encode, parseUrl, move } from '../utils';
import { url } from '../settings';
import ReactGA from 'react-ga'
import { createdPoll, creatingPoll } from './Polls';

export const CREATING_ACCOUNT = 'CREATING_ACCOUNT';
export const CREATED_ACCOUNT = 'CREATED_ACCOUNT';

export const UPDATING_ACCOUNT = 'UPDATING_ACCOUNT';
export const UPDATED_ACCOUNT = 'UPDATED_ACCOUNT';

export const FETCHING_ACCOUNT = 'FETCHING_ACCOUNT';
export const FETCHED_ACCOUNT = 'FETCHED_ACCOUNT';

export const FETCHING_ADMIN = 'FETCHING_ADMIN';
export const FETCHED_ADMIN = 'FETCHED_ADMIN';

export const DELETING_ACCOUNT = 'DELETING_ACCOUNT';
export const DELETED_ACCOUNT = 'DELETED_ACCOUNT';

export const NO_ACCOUNTS = 'NO_ACCOUNTS';
export const START_KEY_LOADING = 'START_KEY_LOADING';
export const STOP_KEY_LOADING = 'STOP_KEY_LOADING';

function creatingAccount() {
  return {
    type: CREATING_ACCOUNT
  };
}

function createdAccount(json) {
  return {
    type: CREATED_ACCOUNT,
    account: json
  };
}

function updatingAccount() {
  return {
    type: UPDATING_ACCOUNT
  };
}

function updatedAccount(json, reset) {
  return {
    type: UPDATED_ACCOUNT,
    account: json,
    reset
  };
}

function updatingSenderDomain() {
  return {
    type: 'UPDATING_SENDER_DOMAIN'
  };
}

function updatedSenderDomain(json, reset) {
  return {
    type: 'UPDATED_SENDER_DOMAIN',
  };
}

function fetchingAccount() {
  return {
    type: FETCHING_ACCOUNT
  };
}

function fetchedAccount(json) {
  return {
    type: FETCHED_ACCOUNT,
    account: json
  };
}

function fetchedAdmin(accountId, json = []) {
  return {
    type: FETCHED_ADMIN,
    accountId,
    admin: json
  };
}

function deletingAccount() {
  return {
    type: DELETING_ACCOUNT
  };
}

function deletedAccount(accountId) {
  return {
    type: DELETED_ACCOUNT,
    accountId,
  };
}

export function createAccount(account) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(creatingAccount());

    return post(`${url}/account`, account)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(updatedAccount({}));
        dispatch(pushServerError(json.error))
      } else {
        dispatch(createdAccount(json))
        // dispatch(replace(`/account/${json._id}`));
        dispatch(push(`/a/${encode(json._id)}`));
        dispatch(push(`/a/${encode(json._id)}/p/create`));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

function reduce(account) {
  if (!account.polls) { return account; }

  account.polls = account.polls.map(s => s._id);
  return { ...account };
}

export function updateAccount(account, message) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingAccount());

    account = reduce(account);

    return post(`${url}/account/${account._id}/update`, account)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        dispatch(updatedAccount(json))
        if (message) {
          dispatch(flash(message));
        }
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function updateAccountDisplaySettings(accountId, formData, message) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingAccount());

    return postFormData(`${url}/account/${accountId}/update-display`, formData)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        dispatch(updatedAccount(json))
        if (message) {
          dispatch(flash(message));
        }
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function updateAccountEmailDisplaySettings(accountId, formData, message) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingAccount());

    return postFormData(`${url}/account/${accountId}/update-email-display`, formData)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        dispatch(updatedAccount(json))
        if (message) {
          dispatch(flash(message));
        }
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function updateAccountPageDisplaySettings(accountId, formData, message) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingAccount());

    return postFormData(`${url}/account/${accountId}/update-page-display`, formData)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        dispatch(updatedAccount(json))
        if (message) {
          dispatch(flash(message));
        }
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function updateAccountSmsDisplaySettings(accountId, formData, message) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingAccount());

    return postFormData(`${url}/account/${accountId}/update-sms-display`, formData)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        dispatch(updatedAccount(json))
        if (message) {
          dispatch(flash(message));
        }
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}


export function fetchAllAccounts(_id) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(fetchingAccount());

    return post(`${url}/accounts/fetch`, {})
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        console.log('here');

        if (!json || json.length === 0) {
          dispatch({ type: NO_ACCOUNTS });
        }

        json.forEach((account) => dispatch(fetchedAccount(account)));
        return json;
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

function checkForCheckoutExtensions(account) {
  return post(`${url}/account/checkoutProfile`, { accountId: account._id })
  .then((response) => {
    return response.json();
  })
  .then((response) => {
    console.log('CALLED');
    console.log(response);
  })
  .catch((err) => {
    console.log('Error checking for checkout extensions');
    console.log(err);
  })
}

export function fetchAccount(_id, includeStats, reset) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(fetchingAccount());

    const [ accountId ] = parseUrl(getState().router);

    if (_id === undefined) {
      _id = accountId;
    }

    return post(`${url}/account/${_id}/fetch`, { _id, includeStats })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        if (reset) {
          dispatch(updatedAccount(json, true))
        } else {
          dispatch(fetchedAccount(json));
        }

        // if (json.shop) {
          /* Check if they have checkout extensions */
          // checkForCheckoutExtensions(json);
        // }

        return json;
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function fetchAccountWithPolls(_id) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(fetchingAccount());

    const [ accountId ] = parseUrl(getState().router);

    if (_id === undefined) {
      _id = accountId;
    }

    return post(`${url}/account/${_id}/fetch-full`, { _id })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(fetchedAccount(json));
        return json;
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function reorderPolls(dragIdx, dropIdx) {
  return (dispatch, getState) => {
    const { 0: accountId } = parseUrl(getState().router);
    const account = { ...getState().accounts[accountId] };

    account.polls = move(account.polls, dragIdx, dropIdx);

    dispatch(updateAccount(account));
  }
}

export function fetchAdmin(_id) {
  return (dispatch, getState) => {
    dispatch({ type: FETCHING_ADMIN });

    const [ accountId ] = parseUrl(getState().router);

    if (_id === undefined) {
      _id = accountId;
    }

    return post(`${url}/account/${_id}/admin`, {})
      .then(response => {
        if (response.status === 401) {
          throw new Error('Unauthorized');
        }
        return response.json()
      })
      .then(json => {
        if (!json) { return; }

        if (json.error) {
          dispatch({ type: NO_ACCOUNTS });
          dispatch(flash(json.error));
        } else {
          dispatch(fetchedAdmin(_id, json));
        }
      })
      .catch(error => {
        dispatch(flash(error));
      })
  };
}

export function addAdmin(email) {
  return (dispatch, getState) => {
    dispatch({ type: FETCHING_ADMIN });

    const [ accountId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/admin/add`, { email })
      .then(response => {
        if (response.status === 401) {
          throw new Error('Unauthorized');
        }
        return response.json()
      })
      .then(json => {
        if (!json) { return; }

        if (json.error) {
          dispatch({ type: NO_ACCOUNTS });
          dispatch(flash(json.error));
        } else {
          dispatch(updatedAccount(json));
          dispatch(flash('The user was successfully added.'));
        }
      })
      .catch(error => {
        dispatch(flash(error));
      })
  };
}

export function removeAdmin(_id) {
  return (dispatch, getState) => {
    dispatch({ type: FETCHING_ADMIN });

    const [ accountId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/admin/remove`, { _id })
      .then(response => {
        if (response.status === 401) {
          throw new Error('Unauthorized');
        }
        return response.json()
      })
      .then(json => {
        if (!json) { return; }

        if (json.error) {
          dispatch({ type: NO_ACCOUNTS });
          dispatch(flash(json.error));
        } else {
          dispatch(updatedAccount(json));
          dispatch(flash('The user was successfully removed.'));
        }
      })
      .catch(error => {
        dispatch(flash(error));
      })
  };
}

export function changePlan(planId, source, shopify) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingAccount());

    const { user } = getState();
    const [ accountId ] = parseUrl(getState().router);

    return post(`${url}${user.type === 'shopify' ? '/shopify' : ''}/account/${accountId}/subscribe`, { planId, source })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedAccount({}));
      } else if (json.redirect_url) {
        if (planId !== 'plan_EthbyzrheBNH7H') {
          ReactGA.event({
            category: 'zigpoll',
            action: 'change-plan',
            label: 'change-plan'
          });
        }

        window.location = json.redirect_url;
      } else {
        /* Don't count free plans */
        if (planId !== 'plan_EthbyzrheBNH7H') {
          ReactGA.event({
            category: 'zigpoll',
            action: 'change-plan',
            label: 'change-plan'
          });
        }
        dispatch(updatedAccount(json));
        dispatch(flash("Your subscription has been changed."));
      }
    })
    .catch(error => { dispatch(flash(error)); })
  };
}

export function deleteAccount() {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(deletingAccount());

    const [ accountId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/delete`)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(deletedAccount(accountId));
        dispatch(replace(`/settings`));
        dispatch(flash('The account has been deleted'));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function toggleEmbedCode(msg) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingAccount());

    const [ accountId ] = parseUrl(getState().router);

    return post(`${url}/shopify/${accountId}/toggleEmbed`)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        dispatch(updatedAccount(json, true))
        dispatch(flash(msg));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function toggleAutoShopifyEmbed(msg) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingAccount());

    const [ accountId ] = parseUrl(getState().router);

    return post(`${url}/shopify/${accountId}/toggleAutoShopifyEmbed`)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        dispatch(updatedAccount(json, true))
        dispatch(flash(msg));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function updateEmbedCode(displayScope, msg) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingAccount());

    const [ accountId ] = parseUrl(getState().router);

    return post(`${url}/shopify/${accountId}/embed`, { displayScope })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        dispatch(updatedAccount(json, true))
        dispatch(flash(msg));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function applyPromoCode(code) {
  return (dispatch, getState) => {
    dispatch({ type: UPDATING_ACCOUNT });

    const [ accountId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/promo`, { code })
      .then(response => {
        if (response.status === 401) {
          throw new Error('Unauthorized');
        }
        return response.json();
      })
      .then(json => {
        if (!json) { return; }

        if (json.error) {
          dispatch({ type: NO_ACCOUNTS });
          dispatch(flash(json.error));
        } else {
          dispatch(updatedAccount(json));
          dispatch(flash('The promo code was applied.'));
        }
      })
      .catch(error => {
        dispatch({ type: NO_ACCOUNTS });
        dispatch(flash(error));
      })
  };
}

export function cancelPromo() {
  return (dispatch, getState) => {
    dispatch({ type: UPDATING_ACCOUNT });

    const [ accountId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/promo/cancel`)
      .then(response => {
        if (response.status === 401) {
          throw new Error('Unauthorized');
        }
        return response.json();
      })
      .then(json => {
        if (!json) { return; }

        if (json.error) {
          dispatch({ type: NO_ACCOUNTS });
          dispatch(flash(json.error));
        } else {
          dispatch(updatedAccount(json));
          dispatch(flash('The promo was canceled.'));
        }
      })
      .catch(error => {
        dispatch({ type: NO_ACCOUNTS });
        dispatch(flash(error));
      })
  };
}

export function getGoogleUrl(accountId) {
  return (dispatch, getState) => {
    return post(`${url}/google/auth/url`, { accountId })
      .then(response => {
        if (response.status === 401) {
          throw new Error('Unauthorized');
        }
        return response.json();
      })
      .then(json => {
        if (!json) { return; }

        if (json.error) {
          dispatch(flash(json.error));
        } else {
          console.log('got url');
          console.log(json.url);;
          return json.url;
        }
      })
      .catch(error => {
        dispatch(flash(error));
      })
  };
}

export function translateContent(code, content, loadingKey) {
  return (dispatch, getState) => {
    const [ accountId ] = parseUrl(getState().router);

    dispatch({ type: START_KEY_LOADING, key: loadingKey });
    return post(`${url}/google/translate`, { accountId, content, code })
      .then(response => {
        dispatch({ type: STOP_KEY_LOADING });
        if (response.status === 401) {
          throw new Error('Unauthorized');
        }
        return response.json();
      })
      .then(json => {
        if (!json) { return; }

        if (json.error) {
          dispatch(flash(json.error));
        } else {
          return json.translations;
        }
      })
      .catch(error => {
        dispatch(flash(error));
      })
  };
}

export function createWebhook(pollId, endpoint) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingAccount());

    const { _id } = getState().user;
    const [ accountId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/webhook`, { _id, pollId, endpoint })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedAccount({}));
      } else {
        dispatch(flash('The webhook key has been created.'));
        dispatch(updatedAccount(json));
      }
    })
    .catch(error => {
      dispatch(flash(error));
    })
  };
}

export function removeWebhook(key) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingAccount());

    const { _id } = getState().user;
    const [ accountId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/remove-webhook`, { _id, key })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedAccount({}));
      } else {
        dispatch(flash('The webhook has been removed.'));
        dispatch(updatedAccount(json));
      }
    })
    .catch(error => {
      dispatch(flash(error));
    })
  };
}

export function createCustomSenderDomain(domain) {
  return (dispatch, getState) => {
    if (getState().accounts.loadingDomain) {
      return;
    }

    const { _id } = getState().user;
    const [ accountId ] = parseUrl(getState().router);

    dispatch(updatingSenderDomain());    
    return post(`${url}/account/${accountId}/create-sender-domain`, { _id, domain })
    .then(response => response.json())
    .then(json => {
        dispatch(updatedSenderDomain());
      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedAccount({}));
      } else {
        dispatch(flash('The domain has been created.'));
        dispatch(updatedAccount(json));
      }
    })
    .catch(error => {
      dispatch(flash(error));
    })
  }
}

export function verifyCustomShareDomain(domain) {
  return (dispatch, getState) => {
    if (getState().accounts.loadingDomain) {
      return;
    }

    const { _id } = getState().user;
    const [ accountId ] = parseUrl(getState().router);

    dispatch(updatingSenderDomain());    
    return post(`${url}/account/${accountId}/verify-share-domain`, { _id, domain })
    .then(response => response.json())
    .then(json => {
      dispatch(updatedSenderDomain());
      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedAccount({}));
      } else {
        dispatch(flash('Thanks! We will verify your domain within 24 hours.'));
        dispatch(updatedAccount(json));
      }
    })
    .catch(error => {
      dispatch(flash(error));
    })
  }
}

export function verifyCustomSenderDomain(domain) {
  return (dispatch, getState) => {
    if (getState().accounts.loadingDomain) {
      return;
    }

    const { _id } = getState().user;
    const [ accountId ] = parseUrl(getState().router);

    dispatch(updatingSenderDomain());    
    return post(`${url}/account/${accountId}/verify-sender-domain`, { _id, domain })
    .then(response => response.json())
    .then(json => {
      dispatch(updatedSenderDomain());
      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedAccount({}));
      } else {
        let isComplete = true;
        const { sending_dns_records } = json.customSenderDomain || {};
        if (sending_dns_records) {
          sending_dns_records.forEach((record) => {
            if (record.record_type === 'CNAME') { return null; }
            if (record.valid !== 'valid') {
              isComplete = false;
            }
          });
        }

        if (isComplete) {
          json.customSenderDomain.state = 'active';
          dispatch(flash('The domain has been verified.'));
        } else {
          dispatch(flash('Please check again in a couple of minutes.'));
        }
        dispatch(updatedAccount(json));
      }
    })
    .catch(error => {
      dispatch(flash(error));
    })
  }
}

export function removeCustomSenderDomain() {
  return (dispatch, getState) => {
    if (getState().accounts.loadingDomain) {
      return;
    }

    const { _id } = getState().user;
    const [ accountId ] = parseUrl(getState().router);

    dispatch(updatingSenderDomain());    
    return post(`${url}/account/${accountId}/remove-sender-domain`, { _id })
    .then(response => response.json())
    .then(json => {
      dispatch(updatedSenderDomain());
      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedAccount({}));
      } else {
        dispatch(flash('The domain has been removed.'));
        dispatch(updatedAccount(json));
      }
    })
    .catch(error => {
      dispatch(flash(error));
    })
  }
}

export function createCustomShareDomain(domain) {
  return (dispatch, getState) => {
    if (getState().accounts.loadingDomain) {
      return;
    }

    const { _id } = getState().user;
    const [ accountId ] = parseUrl(getState().router);

    dispatch(updatingSenderDomain());    
    return post(`${url}/account/${accountId}/create-share-domain`, { _id, domain })
    .then(response => response.json())
    .then(json => {
      dispatch(updatedSenderDomain());
      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedAccount({}));
      } else {
        dispatch(flash('The domain has been created. Please follow the next steps.'));
        dispatch(updatedAccount(json));
      }
    })
    .catch(error => {
      dispatch(flash(error));
    })
  }
}

export function removeCustomShareDomain() {
  return (dispatch, getState) => {
    if (getState().accounts.loadingDomain) {
      return;
    }

    const { _id } = getState().user;
    const [ accountId ] = parseUrl(getState().router);

    dispatch(updatingSenderDomain());    
    return post(`${url}/account/${accountId}/remove-share-domain`, { _id })
    .then(response => response.json())
    .then(json => {
      dispatch(updatedSenderDomain());
      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedAccount({}));
      } else {
        dispatch(flash('The domain has been removed.'));
        dispatch(updatedAccount(json));
      }
    })
    .catch(error => {
      dispatch(flash(error));
    })
  }
}

export function saveEmailTemplate(formData) {
  return (dispatch, getState) => {
    const { _id } = getState().user;
    const [ accountId ] = parseUrl(getState().router);

    dispatch(updatingAccount());    
    return postFormData(`${url}/account/${accountId}/save-email-template`, formData)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedAccount({}));
      } else {
        dispatch(flash('The email template has been created.'));
        dispatch(updatedAccount(json));
      }
    })
    .catch(error => {
      dispatch(flash(error));
    })
  }
}

export function updateEmailTemplate(formData) {
  return (dispatch, getState) => {
    const { _id } = getState().user;
    const [ accountId ] = parseUrl(getState().router);

    dispatch(updatingAccount());    
    return postFormData(`${url}/account/${accountId}/update-email-template`, formData)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedAccount({}));
      } else {
        console.log(json);
        console.log('updated');
        dispatch(flash('The email template has been updated.'));
        dispatch(updatedAccount(json));
      }
    })
    .catch(error => {
      dispatch(flash(error));
    })
  }
}

export function importPoll(poll) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(creatingPoll());

    const [ accountId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/import-poll`, { import: poll })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        dispatch(createdPoll(json, accountId));
        dispatch(flash('The survey has been imported successully.'));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

