import React, { Component } from 'react';
import Tooltip from './Tooltip';

export default class Option extends Component {
  render() {
    const { label, value, tooltip } = this.props;

    let tip = null;
    if (tooltip) {
      tip = <Tooltip>{ tooltip }</Tooltip>
    }

    let tag = null;
    if (this.props.tag) {
      tag = <span className="option-tag">{ this.props.tag }</span>
    }

    return (
      <div
        className={`${this.props.selected ? 'selected' : ''} ${this.props.className}`}
        onClick={(e) => { this.props.onClick(e, value) }}
      >
        <div className="option-content">
          { tag }
          <div>{ label }{ tip }</div>
        </div>
      </div>  
    );
  }
}
