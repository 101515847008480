import React, { Component } from 'react';

import tinycolor from 'tinycolor2';

export class EmbedButton extends Component {
  constructor(props) {
    super(props);
    this.state = { active: this.props.active };
  }

  render() {
    const props = this.props;

    const style = {
      borderColor: 'transparent',
      backgroundColor: props.color,
      // textShadow: `0 2px 0 ${tinycolor(props.color).darken(10).toString()}`,
      textShadow: `0 2px 0 transparent`
    }

    if ((this.state.hover || this.state.focus) && !this.props.disabled) {
      style.backgroundColor = tinycolor(props.color).darken(10).toString();
    }

    return (
      <button
        onClick={props.onClick}
        className={props.className}
        style={style}
        type={props.type}
        disabled={props.disabled}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ focus: true })}
        onBlur={() => this.setState({ focus: false })}
      >{props.children}</button>
    );
  }
}

export class EmbedBackButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const props = this.props;

    const style = {
      color: tinycolor(props.color).toString(),
    }

    if ((this.state.hover || this.state.focus) && !this.props.disabled) {
      style.color = tinycolor(props.color).darken(10).toString();
    }

    return (
      <button
        onClick={props.onClick}
        className={props.className}
        style={style}
        type={props.type}
        disabled={props.disabled}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ focus: true })}
        onBlur={() => this.setState({ focus: false })}
      >{props.children}</button>
    );
  }
}
