import React, { Component } from 'react';

import Toggle from './Toggle';
import Tooltip from './Tooltip';
import { NameInput } from './SimpleInputs';
import { AuthSubmitButton } from './Buttons';
import Switch from './Switch';
import HeaderButtons from './HeaderButtons';

export default class CharacterLimitInput extends Component {
  onChange(e) {
    const state = { key: this.props.mKey, namespace: this.props.namespace };

    state[e.target.name] = e.target.value;
    this.props.onChange(state);
  }

  validate() {
    return;
  }

  render() {
    let inputs = null;
    let advancedInputs = null;

    if (this.props.active) {
      inputs = (
      <div className="metafield-inputs">
        <div style={{ width: '50%', display: 'inline-block', paddingRight: 3 }}>
          <NameInput
            name="namespace"
            label="Namespace"
            value={ this.props.namespace }
            onChange={this.onChange.bind(this)}
            noValidate={true}
            type="text"
            placeholder="namespace"
          />
        </div>
        <div style={{ width: '50%', display: 'inline-block', paddingLeft: 3 }}>
          <NameInput
            name="key"
            label="Key"
            value={ this.props.mKey }
            onChange={this.onChange.bind(this)}
            noValidate={true}
            type="text"
            placeholder="key"
          />
        </div>
      </div>
      );
    }

    return (<div
      className={`dynamic-render-input ${this.props.disabled ? 'disabled' : ''}`} style={{ position: 'relative', zIndex: 1 }}
      active={this.props.active}
    >
      <div style={{ position: 'relative', marginBottom: this.props.active ? 20 : 0 }}>
        <label>Custom Metafield Configuration<Tooltip>If you want to save the results of this slide to a specific customer metafield, override the namespace and key below.</Tooltip></label>
        <div className="inline-toggle">
          <Toggle
            active={this.props.active}
            onChange={(value) => {
              if (!value) {
                this.props.onChange(false);
              } else {
                const state = { key: this.props.mKey, namespace: this.props.namespace };
                this.props.onChange(state);
              }
            }}
          />
          <div className="subtle">Save results as a customer metafield.</div>
        </div>
      </div>

      { inputs }
    </div>);
  }
}