import _ from 'lodash';

export const PUSH_FLASH_NOTIFICATION = "PUSH_FLASH_NOTIFICATION";
export const POP_FLASH_NOTIFICATION = "POP_FLASH_NOTIFICATION";

const pending = [];
let timeout;

function checkPending(dispatch) {
  if (pending.length) {
    const { message, type } = pending.pop();
    dispatch(pushNotification(message, type));
  }
}

const pushNotification = (message, type = 'default') => {
  return (dispatch, getState) => {
    const { flashNotifications } = getState();
    const notification = { type, message };
    notification.key = Date.now();

    if (flashNotifications.length >= 1) {
      return pending.push(notification);
    }

    /* Push */
    dispatch({
      type: PUSH_FLASH_NOTIFICATION,
      notification
    });

    timeout = setTimeout(() => {
      timeout = undefined;
      dispatch(popNotification());
      setTimeout(() => { checkPending(dispatch); }, 300);
    }, 3000);
  }
}

export const popNotification = () => {
  return (dispatch, getState) => {
    if (timeout) {
      clearTimeout(timeout);
      setTimeout(() => { checkPending(dispatch); }, 300);
    }
    return dispatch({
      type: POP_FLASH_NOTIFICATION
    });
  }
}

const throttlePush = _.throttle((dispatch, message, type) => {
  dispatch(pushNotification(message, type));
}, 300, { trailing: false });

export const flash = (message, type) => {
  return (dispatch, getState) => {
    throttlePush(dispatch, message, type);
  }
}
