import { post } from '../ajax';
import { url } from '../settings';

import { fetchAccount } from './Accounts';
import { fetchPollWithSlides } from './Polls';
import { fetchFullSlide } from './Slides';

import { replace } from 'connected-react-router';

import qs from 'query-string';
import _ from 'lodash';

import { encode, decode, parseUrl } from '../utils';
import { fetchChartDataForAccount, fetchChartDataForPoll } from './Charts';

export const SET_IDX = 'SET_IDX';
export const SET_POLL_ID = 'SET_POLL_ID';
export const SET_PAGE = 'SET_PAGE';

export const FETCHED_DIFFS = 'FETCHED_DIFFS';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export function fetchData(accountId) {
  return (dispatch, getState) => {
    if (!accountId) { return; }

    return dispatch(fetchAccount(accountId, true))
      .then((account) => dispatch(fetchAccountDiff(account)))
      .then((account) => {
        if (!account) {
          return replace('/404');
        }

        if (account.polls && account.polls[0]) {
          let { id } = qs.parse(getState().router.location.search);
          id = decode(id);

          if (!id || !_.find(account.polls, ({ _id }) => _id === id)) {
            dispatch(setPollId(account.polls[0]._id));
          }
        }
      }).then(() => {
        dispatch(fetchChartDataForAccount(accountId));
      });
  }
}

const lastChecked = {};

function getLastChecked(key) {
  return lastChecked[key] || 0;
}

function setLastChecked(key) {
  lastChecked[key] = Date.now();
}

function fetchAccountDiff(account) {
  return (dispatch, getState) => {
    if (!account) { return; }

    /* Only check every 10 seconds */
    if ((Date.now() - getLastChecked(account._id)) < 10000) {
      console.log('THROTTLED');
      return;
    }

    setLastChecked(account._id);
    return post(`${url}/diffs/account/${account._id}`)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        console.log('error');
        console.log(json);
      } else {
        console.log('got diff');
        console.log(json);
        dispatch({ type: FETCHED_DIFFS, diffs: json });
      }
    })
    .catch(error => console.log(error))
  }
}

function fetchPollDiff(pollId) {
  return (dispatch, getState) => {

    const [ accountId ] = parseUrl(getState().router);

    /* Only check every 10 seconds */
    if ((Date.now() - getLastChecked(pollId)) < 10000) {
      console.log('THROTTLED');
      return;
    }

    setLastChecked(pollId);
    return post(`${url}/diffs/account/${accountId}/poll/${pollId}`)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        console.log('error');
        console.log(json);
      } else {
        console.log('got diff');
        console.log(json);
        dispatch({ type: FETCHED_DIFFS, diffs: json });
      }
    })
    .catch(error => console.log(error))

  }
}

export function fetchPollData(pollId) {
  return (dispatch, getState) => {
    const [ accountId ] = parseUrl(getState().router);
    dispatch({ type: REMOVE_NOTIFICATION, pollId, accountId })

    return dispatch(fetchPollWithSlides(pollId))
      .then((poll) => dispatch(fetchPollDiff(pollId)))
      .then(() => dispatch(fetchChartDataForPoll(accountId, pollId)));
    ;
  }
}

export function fetchSlideData() {
  return (dispatch, getState) => {
    const [ accountId, pollId, slideId ] = parseUrl(getState().router);
    return dispatch(fetchFullSlide(slideId)).then((poll) => dispatch(fetchPollDiff(pollId)));
  }
}

export function setPollId(pollId) {
  return (dispatch, getState) => {  
    const curQuery = qs.parse(getState().router.location.search);
    const params = { ...curQuery, id: encode(pollId) }

    return dispatch(replace({ search: qs.stringify(params) }))
  }
}

export function setIdx(idx) {
  return (dispatch, getState) => {  
    const curQuery = qs.parse(getState().router.location.search);
    const params = { ...curQuery, idx }
    return dispatch(replace({ search: qs.stringify(params) }))
  }
}

export function setPage(page) {
  return (dispatch, getState) => {  
    const curQuery = qs.parse(getState().router.location.search);
    const params = { ...curQuery, page }
    return dispatch(replace({ search: qs.stringify(params) }))
  }
}