import React, { Component } from 'react';
// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';

import $ from 'jquery';
import _ from 'lodash';

// import * as AccountActions from '../actions/Accounts';
// import * as FlashNotificationActions from '../actions/FlashNotifications';

import HeaderButtons from '../components/HeaderButtons';
import Toggle from '../components/Toggle';
import Switch from '../components/Switch';
import Tooltip from '../components/Tooltip';
import { NameInput } from '../components/SimpleInputs';
import SectionHeader from '../components/SectionHeader';
import Modal from '../components/Modal';
import isoLanguages from '../iso-languages';
import WysiwygInput from './WysiwygInput';

import { AuthSubmitButton } from './Buttons';
import { getDisplaySettings, getPollType, getSlideIcon, stripTags, encode, previewSlides, truncate } from '../utils';

const supportedLanguageCodes = ['af','sq','am','ar','hy','as','ay','az','bm','eu','be','bn','bho','bs','bg','ca','ceb','zh','co','hr','cs','da','dv','doi','nl','en','eo','et','ee','fil','fi','fr','fy','gl','ka','de','el','gn','gu','ht','ha','he','haw','heiw','hi','hmn','hu','is','ig','ilo','id','ga','it','ja','jvjw','kn','kk','km','rw','gom','ko','kri','ku','ckb','ky','lo','la','lv','ln','lt','lg','lb','mk','mai','mg','ms','ml','mt','mi','mr','mni','lus','mn','my','ne','no','ny','or','om','ps','fa','pl','pt','pa','qu','ro','ru','sm','sa','gd','nso','sr','st','sn','sd','si','sk','sl','so','es','su','sw','sv','tl','tg','ta','tt','te','th','ti','ts','tr','tk','ak','uk','ur','ug','uz','vi','cy','xh','yi','yo','zu'];

const supportedIsoLanguages = isoLanguages.filter(({ code }) => {
  return supportedLanguageCodes.indexOf(code) !== -1;
})

const getIsoLanguageCodes = (filter, selectedLanguages) => {
  const languages = []
  supportedIsoLanguages.forEach(({ code, name }) => {
    const val = ({ value: code, label: `${name} (${code})` });
    if (selectedLanguages.indexOf(code) !== -1) { return; }
    if (!filter) { return languages.push(val) }
    if (`${code} ${name}`.toLowerCase().indexOf(filter) !== -1) {
      languages.push(val);
    }    
  });
  return languages;
}

const getLanguageName = (code) => {
  return `${_.find(isoLanguages, { code }).name} (${code})`;
}

const defaultTranslations = {
  "next": "Next",
  "back": "Back",
  "submit": "Submit",
  "reset": "Edit",
  "skip": "Skip",
  "thanks": "Thanks!",
  "close": "Close",
  "refresh": "Refresh",
  "load-more": "Load More",
  "optional": "Optional",
  "answer": "Answer",
  "or": "or",
  "day": "Day",
  "month": "Month",
  "year": "Year",
  "dropdown-placeholder": "Please select one",
  "top-responses": "Top Responses",
  "no-responses": "No Responses have been approved yet. Check back later.",
  "copy-to-clipboard": "Copied to clipboard",
  "empty-error-message":"This cannot be left blank.",
  "invalid-date-message":"Please enter a valid date.",
  "email-error-message":"Please enter a valid email.",
  "phone-error-message":"Please enter a valid phone number.",
  "modal-exit-confirmation": "You haven't completed the survey are you sure you want to exit?"
}

const buttonTranslationKeys = [
{ key: "next", defaultTranslation: "Next", placeholder: 'Ex: próximo' },
{ key: "back", defaultTranslation: "Back", placeholder: 'Ex: esplada' },
{ key: "submit", defaultTranslation: "Submit", placeholder: 'Ex: enviar' },
{ key: "reset", defaultTranslation: "Edit", placeholder: 'Ex: editar' },
{ key: "skip", defaultTranslation: "Skip", placeholder: 'Ex: omitir' },
{ key: "close", defaultTranslation: "Close", placeholder: 'Ex: cerrar' },
{ key: "refresh", defaultTranslation: "Refresh", placeholder: 'Ex: actualizar' },
{ key: "load-more", defaultTranslation: "Load More", placeholder: 'Ex: carga más' }
]

const wordsAndPhrasesTranslationKeys = [
{ key: "thanks", defaultTranslation: "Thanks!", placeholder: 'Ex: gracias!' },
{ key: "optional", defaultTranslation: "Optional", placeholder: 'Ex: opcional' },
{ key: "or", defaultTranslation: "or", placeholder: 'Ex: o' },
{ key: "answer", defaultTranslation: "Answer", placeholder: 'Ex: Answer' },
{ key: "day", defaultTranslation: "Day", placeholder: 'Ex: Day' },
{ key: "month", defaultTranslation: "Month", placeholder: 'Ex: Month' },
{ key: "year", defaultTranslation: "Year", placeholder: 'Ex: Year' },
{ key: "top-responses", defaultTranslation: "Top Responses", placeholder: 'Ex: principales respuestas' },
]

const errorMessagesTranslationKeys = [
{ key: "empty-error-message", defaultTranslation: "This cannot be left blank.", placeholder: 'Ex: Esto no puede dejarse en blanco.' },
{ key: "invalid-date-message", defaultTranslation: "Please enter a valid date.", placeholder: 'Ex: Por favor introduzca una fecha valida.' },
{ key: "email-error-message", defaultTranslation: "Please enter a valid email.", placeholder: 'Ex: Introduzca un correo electrónico válido.' },
{ key: "dropdown-placeholder", defaultTranslation: "Please select one", placeholder: 'Ex: Por favor, seleccione uno' },
{ key: "phone-error-message", defaultTranslation: "Please enter a valid phone number.", placeholder: 'Ex: Por favor ingrese un número de teléfono válido.' },
]

const alertsTranslationKeys = [
{ key: "copy-to-clipboard", defaultTranslation: "Copied to clipboard", placeholder: 'Ex: Copiado al portapapeles' },
{ key: "modal-exit-confirmation", defaultTranslation: "You haven't completed the survey are you sure you want to exit?", placeholder: 'Ex: No ha completado la encuesta, ¿está seguro de que desea salir?' },
{ key: "no-responses", defaultTranslation: "No Responses have been approved yet. Check back later.", placeholder: 'Ex: Aún no se han aprobado respuestas. Vuelva más tarde.' }
]

const launcherTranslationKeys = [
{ key: "before-launcher-text", defaultTranslation: "Take Our Survey", placeholder: 'Ex: Take Our Survey' },
{ key: "after-launcher-text", defaultTranslation: "Thanks!", placeholder: 'Ex: Thanks!' },
];

/* Need to compile the launcher translation keys... then you can use them as the launcher text. */
function compileLauncherTranslationKeys(account, poll) {
  const displaySettings = getDisplaySettings(account, poll);

  if (!displaySettings || !displaySettings.launcherSettings) { return launcherTranslationKeys; }

  const launcherKeys = [ ...launcherTranslationKeys ];
  const launcherSettings = displaySettings.launcherSettings;

  if (launcherSettings['before-submission'] && launcherSettings['before-submission'].launcherText) {
    launcherKeys[0].defaultTranslation = launcherSettings['before-submission'].launcherText;
  }
  if (launcherSettings['after-submission'] && launcherSettings['after-submission'].launcherText) {
    launcherKeys[1].defaultTranslation = launcherSettings['after-submission'].launcherText;   
  } else {
    launcherKeys.splice(1, 1);
  }

  return launcherKeys;
}

function getTranslationKeys(account, poll) {
  const allTranslationKeys = [ { icon: 'fa-circle-dot', divider: true, title: 'Buttons & Actions' }, ...buttonTranslationKeys, { icon: 'fa-quote-left', divider: true, title: 'Words & Phrases' }, ...wordsAndPhrasesTranslationKeys, { icon: 'fa-circle-exclamation', divider: true, title: 'Error Messages' }, ...errorMessagesTranslationKeys, { icon: 'fa-triangle-exclamation', divider: true, title: 'Alert Messages' }, ...alertsTranslationKeys, { icon: 'fa-rocket', divider: true, title: 'Launcher Content', tooltip: 'To edit your default launcher content, click Settings → Display.' }, ...compileLauncherTranslationKeys(account, poll) ];

  const allTranslationKeysWithoutDividers = [ ...buttonTranslationKeys, ...wordsAndPhrasesTranslationKeys, ...errorMessagesTranslationKeys, ...alertsTranslationKeys, ...compileLauncherTranslationKeys(account, poll) ];

  return { allTranslationKeys, allTranslationKeysWithoutDividers };
}

class Checkbox extends Component {
  render() {
    const { value, title, selected } = this.props;
    return (
      <div 
        className={`checkbox`}
        onClick={() => this.props.onClick(value)}
      >
        <div className={`${selected ? 'selected' : ''}`}>
          {/*<span></span>*/}
          <i className={`${selected ? 'fas fa-square-check' : 'far fa-square'}`} />
          <div>{title}</div>
        </div>
      </div>
    )
  }
}

function prepareSlide(slide) {
  let answers = {};
  if (slide.answers) {
    slide.answers.forEach(({ title, handle }) => answers[handle] = title);
  }
  let actions = {};
  if (slide.actions) {
    slide.actions.forEach(({ title, handle }) => actions[handle] = title);
  }
  return {
    _id: slide._id,
    type: slide.type,
    title: slide.title,
    copy: slide.copy,
    answers: answers,
    leftLabel: slide.leftLabel,
    rightLabel: slide.rightLabel,
    actions: actions,
    rewardCode: slide.rewardCode,
    rewardBanner: slide.rewardBanner
  };
}

function prepareSlideTranslationValue(slide, translation) {
  /* Chose transltion or slide to fill in gaps */
  return {
    title: slide.title,
    copy: slide.copy,
    answers: { ...slide.answers },
    leftLabel: slide.leftLabel,
    rightLabel: slide.rightLabel,
    actions: { ...slide.actions },
    rewardCode: slide.rewardCode,
    rewardBanner: slide.rewardBanner
  };
}

function prepareSettings(languageSettings = {}, accountLanguageSettings = {}) {
  if (languageSettings.default) {
    return languageSettings;
  } else {
    if (Object.keys(languageSettings).length === 0) {
      languageSettings = accountLanguageSettings;
    }
    return { default: { ...languageSettings } };
  }
}

function prepareSelectedLanguages(languageSettings, globalLanguageSettings) {
  if (languageSettings.indexOf('disassociated') !== -1) {
    return languageSettings;
  } else {
    return _.uniq([ ...globalLanguageSettings, ...languageSettings ]);
  }
}

function getLang(languageSettings = {}, pollId, code) {
  const group = languageSettings || {};
  const lang = group[code] ? { ...group[code] } : {};
  return lang;
}

export default class LanguageSettingsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    const { allTranslationKeys, allTranslationKeysWithoutDividers } = getTranslationKeys(this.props.account, this.props.poll);

    this.allTranslationKeys = allTranslationKeys;
    this.allTranslationKeysWithoutDividers = allTranslationKeysWithoutDividers;
    // this.state = { type: 'cta', pollId: 'global', tmpSelectedLanguages: [] };

    /* Convert to updated language settings */
    // let languageSettings = { global: prepareSettings(this.props.account.languageSettings) };
    // if (this.props.poll) {
    //   languageSettings[this.props.poll._id.toString()] = prepareSettings(this.props.poll.languageSettings || this.props.account.languageSettings);
    // }
    // let selectedLanguages = {};
    // Object.keys(languageSettings).forEach((key) => {
    //   const val = languageSettings[key];
    //   console.log(Object.keys(val));
    //   selectedLanguages[key] = prepareSelectedLanguages(Object.keys(val), Object.keys(languageSettings.global));
    //   console.log(selectedLanguages[key]);
    // });

    // this.state.selectedLanguages = selectedLanguages;
    // this.state.inputs = languageSettings;

    // this.setInitialState();
  }

  onChange(code, key, value) {
    const pollId = this.state.pollId;
    const state = { ...this.state.inputs };
    const language = state[code] ? { ...state[code] } : {};

    language[key] = value;
    state[code] = language;
    this.setState({ inputs: state });
  }

  setInitialState() {
    this.initialState = { ...this.state.inputs };

    setTimeout(() => {
      this.forceUpdate();
    }, 1000);
  }

  componentDidMount() {
    if (this.props.pollId) {
      this.setPollId(this.props.pollId);
    }
  }

  // componentDidUpdate(prevProps, prevState) {
    // const prevSettings = { ...prevProps.settings };
    // if (JSON.stringify(prevSettings) !== JSON.stringify(this.props.settings)) {
    //   this.setInitialState();
    // }
  // }

  setPollId(pollId) {
    const setPoll = (poll) => {
      let type = 'cta';
      // if (poll.slides.length) {
        // type = 'slides'
      // }

      let languageSettings = {};
      languageSettings = prepareSettings(poll.languageSettings, this.props.account.languageSettings);
      const selectedLanguages = Object.keys(languageSettings);
      this.setState({ pollId, inputs: languageSettings, selectedLanguages, tmpSelectedLanguages: [], type }, () => {
        this.setInitialState();
      });
    }

    const poll = _.find(this.props.polls, ({ _id }) => _id === pollId);
    if (poll) {
      return setPoll(poll);
    }

    this.props.fetchPollWithSlides(pollId)
    .then((poll) => {
      if (poll && poll._id) {
        return setPoll(poll);
      }
    });
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.updateSettings();
  }

  cancel() {
    this.setState({ inputs: { ...this.initialState } });
    this.props.flash('Your changes have been cancelled.');
  }

  updateSettings() {
    const account = {};
    account._id = this.props.account._id;
    account.languageSettings = this.state.inputs;

    this.props.updateLanguageSettings(this.state.inputs, 'Your changes have been saved.');

    // this.props.updateAccount(account, 'Your changes have been saved and pushed live.');
  }

  renderLanguageForm(code) {
    // if (code === 'disassociated') { return null; }
    const language = code === 'default' ? 'Default Language' : getLanguageName(code);
    const subhead = code === 'default' ? <div className="sub">This will be shown to all visitors unless overridden.</div> : <div className="sub">This will be shown to visitors with language set to <strong>{code}</strong>.</div>;

    let button = <div className="button" onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();

      this.props.translateContent(code, this.allTranslationKeysWithoutDividers, code)
      .then((translations) => {
        const state = { ...this.state.inputs };
        const language = { ...state[code], ...translations };

        state[code] = language;
        this.setState({ inputs: state });
      });
    }}><i className="fas fa-language" />Auto-translate</div>
    let moreActions = <div className="more-actions first">
      <div className="menu-toggle"><i className="fas fa-ellipsis-vertical" /></div>
      <div className="menu">
        <div>
          <div className="red" onClick={() => {
            this.removeLanguage(code);
          }}><i className="fas fa-trash" />Remove Language</div>
          <div className="red" onClick={() => {
            this.clearTranslations(code);
          }}><i className="fas fa-arrows-rotate" />Clear Translations</div>
        </div>
      </div>
    </div>

    if (code === 'default') {
      button = <div className="button" onClick={this.addLanguage.bind(this)}><i className="fas fa-plus" />Add Language</div>;
      moreActions = null;
    }

    const globalLang = getLang(this.state.inputs, 'global', code)
    const lang = getLang(this.state.inputs, this.state.pollId, code);
    return (
      <form 
        onSubmit={this.onSubmit.bind(this)}
        className={`account-settings ${this.props.loadingKeys.indexOf(code) !== -1 ? 'loading' : ''}`}
      >
        <div className="card">

          <div className="header">
            <div>{ language }<div className="buttons">{ button } { moreActions }</div></div>
            { subhead }
          </div>

          { this.allTranslationKeys.map(({ key, defaultTranslation, placeholder, divider, title, icon, tooltip }) => {

            if (divider) {
              return <div className="divider"><i className={`fas ${icon}`} />{ title }{ tooltip && code === 'default' ? <Tooltip>{ tooltip }</Tooltip> : null }</div>
            }

            let value = ((lang[key] === undefined) && code === 'default') ? defaultTranslation : lang[key];
            if ((lang[key] === undefined) && globalLang[key]) {
              value = globalLang[key];
            }

            return <div className={`setting ${defaultTranslation.length < 20 ? 'inline' : 'inline'}`}><NameInput
              disabled={key.indexOf('launcher') !== -1 && code === 'default'}
              value={value}
              placeholder={defaultTranslation}
              // maxlength={defaultTranslation.length + 20}
              optional={true}
              name={key}
              noValidate={true}
              onValidate={() => {
                // console.log('validate');
              }}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.onChange(code, e.target.name, e.target.value);
              }}
            /></div>
          })}

        </div>
      </form>
    );
  }

  // disassociatePollFromGlobal() {
  //   /* If languages changed from global, have to keep a record */
  //   if (this.state.pollId === 'global') { return true };

  //   const pollId = this.state.pollId;
  //   const inputs = { ...this.state.inputs };
  //   const group = { ...inputs[pollId] };
  //   const newGroup = {}
  //   this.state.selectedLanguages[this.state.pollId].forEach((code) => {
  //     newGroup[code] = group[code] || {};
  //   });

  //   newGroup['disassociated'] = true;

  //   inputs[pollId] = newGroup;
  //   this.setState({ inputs });
  // }

  removeLanguage(code) {
    // const selectedLanguages = { ...this.state.selectedLanguages };
    const selectedLanguages = [ ...this.getSelectedLanguages() ];
    const idx = selectedLanguages.indexOf(code);
    selectedLanguages.splice(idx, 1);

    const state = { ...this.state.inputs };
    delete state[code];

    this.setState({ selectedLanguages, inputs: state }, () => {
      console.log('removedlanguage');
      // this.disassociatePollFromGlobal();
    });
  }

  clearTranslations(code) {
    const state = { ...this.state.inputs };
    const language = {};

    state[code] = language;
    this.setState({ inputs: state });
  }

  renderLanguageForms() {
    return this.getSelectedLanguages().map((code) => this.renderLanguageForm(code));
  }

  renderDefaults() {
    return null;

    const button = <div className="button" onClick={this.addLanguage.bind(this)}><i className="fas fa-plus" />Add Language</div>

    return (<div className="defaults-list">
      <div className="header">
        <div>Default Buttons & Hints <div className="buttons">{ button }</div></div>
        <div className="sub">All hard-coded language on your public-facing survey.</div>
      </div>
      {  this.allTranslationKeys.map(({ key, defaultTranslation, divider, title, icon }) => {
        if (divider) {
          return <div className="divider"><i className={`fas ${icon}`} />{ title }</div>
        }
        return <div>{ defaultTranslation }</div>
      }) }
    </div>)
  }

  renderSlideLanguageDefaults() {
    if (this.state.pollId === 'global') {
      return <div className="all-surveys">
        <i class="fa-solid fa-arrow-left-long" />
        <div className="top">Select a survey on the left to translate your slides.</div>
        <div>If no surveys exist, create a new one.</div>
      </div>
    }

    const poll = _.find(this.props.polls, ({ _id }) => _id === this.state.pollId);
    if (!poll) {
      return <div className="loading" />;
    }
    if (!poll.slides) {
      return <div>Please add slides and then check back here to translate them.</div>
    }
    const button = <div className="button" onClick={this.addLanguage.bind(this)}><i className="fas fa-plus" />Add Language</div>
 
    return <div className="slides-list">
      <div className="header">
        <div>Survey Slides <div className="buttons">{ button }</div></div>
        <div className="sub">Select an input from your right to translate the contents of each slide.</div>
      </div>
      {poll.slides.map((slide, idx) => {
        return <div className="slide" onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          console.log('Clicked, should load detail window.');
        }}>
          <i className={`icon fas ${getSlideIcon(slide.type)}`} />
          <div className="title">{truncate(stripTags(slide.title), 100)}</div>
        </div>
      }) }
    </div>
  }

  showSlideTranslationModal(selectedSlide, selectedLanguage, selectedLanguageCode) {
    /* Show modal (make it fullscreen later, give option to translate copy) */
    /* Time to start building out the data model... */

    let slideTranslationModalValue = prepareSlideTranslationValue(selectedSlide);

    const lang = getLang(this.state.inputs, this.state.pollId, selectedLanguageCode);
    if (lang[selectedSlide._id]) {
      slideTranslationModalValue = { ...prepareSlideTranslationValue(selectedSlide), ...lang[selectedSlide._id],  }
    }

    this.setState({ showSlideTranslationModal: true, selectedSlide, selectedLanguage, selectedLanguageCode, slideTranslationModalValue });
  }

  renderSlideLanguageForms() {
    const poll = _.find(this.props.polls, ({ _id }) => _id === this.state.pollId);
    if (!poll || !poll.slides) {
      return null;
    }
    return this.getSelectedLanguages().map((code) => {
      if (code === 'disassociated') { return null; }
      if (code === 'default') { return null; }

      const translations = getLang(this.state.inputs, this.state.pollId, code)

      const button = <div className="button" onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();

        poll.slides.forEach((slide, idx) => {
          this.props.translateContent(code, prepareSlide(slide), `${slide._id}-${code}`)
          .then((translations) => {
            /* Set language forms */
            const key = slide._id;
            const state = { ...this.state.inputs };
            const language = state[code] ? { ...state[code] } : {};

            language[key] = translations;
            state[code] = language;
            this.setState({ inputs: state });
          });
        });
      }}><i className="fas fa-language" />Auto-translate</div>
      const moreActions = <div className="more-actions first">
        <div className="menu-toggle"><i className="fas fa-ellipsis-vertical" /></div>
        <div className="menu">
          <div>
            <div className="red" onClick={() => {
              this.removeLanguage(code);
            }}><i className="fas fa-trash" />Remove Language</div>
            <div className="red" onClick={() => {
              this.clearTranslations(code);
            }}><i className="fas fa-arrows-rotate" />Clear Translations</div>
          </div>
        </div>
      </div>
  
      return <div className={`slides-list`}>
        <div className="header">
          <div>{ getLanguageName(code) } <div className="buttons">{ button }{ moreActions }</div></div>
          <div className="sub">Click a cell to translate the contents of each slide.</div>
        </div>
        {poll.slides.map((slide, idx) => {
          return <div className={`slide short-code ${this.props.loadingKeys.indexOf(`${slide._id}-${code}`) !== -1 ? 'loading' : ''}`} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.showSlideTranslationModal(prepareSlide(slide), getLanguageName(code), code)
          }}>
            { translations[slide._id] === undefined ? <span><i className={`icon fas fa-file-lines`} /> Click to enter translation.</span> : <span><i className={`icon fas fa-check-circle`} /> Translation entered.</span>
            }<i className="expand fas fa-up-right-and-down-left-from-center" />
          </div>
        }) }
      </div>
    });
  }

  addLanguage() {
    this.setState({ showLanguageModal: true });
  }

  closeModal() {
    this.setState({ showLanguageModal: false, languageFilter: '', tmpSelectedLanguages: [] });
  }

  closeSlideTranslationModal() {
    this.setState({ showSlideTranslationModal: false });
  }

  renderTranslationColumn(header, subheader, readOnly) {
    const code = this.state.selectedLanguageCode;
    let button = (<div className="button" onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();

      this.props.translateContent(code, this.state.slideTranslationModalValue, this.state.selectedSlide._id)
      .then((translations) => {
        this.setState({ slideTranslationModalValue: translations, cacheBuster: Math.random() });
      });
    }}><i className="fas fa-language" />Auto-translate</div>);

    let selectedSlide = this.state.selectedSlide || {};
    const type = selectedSlide.type;

    if (readOnly) {
      button = <div className="button" onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        const accountId = encode(this.props.account._id);
        const pollId = encode(this.props.poll._id);
        const slideId = encode(this.state.selectedSlide._id);
        const url = `/a/${accountId}/p/${pollId}/s/${slideId}?tab=details`;
        window.open(url, '_blank');
      }}><i className="fas fa-edit" />Edit Slide</div>;
    } else {
      selectedSlide = this.state.slideTranslationModalValue || {};
    }

    let answersInput = null;
    let labelsInput = null;
    let actionsInput = null;
    let rewardInput = null;

    const onChange = (key, value, idx) => {
      const val = { ...this.state.slideTranslationModalValue } 
      if (idx !== undefined) {
        val[key][idx] = value;
      } else {
        if (!value) { 
          delete val[key];
        } else {
          val[key] = value;
        }
      }

      this.setState({ slideTranslationModalValue: val });
    }

    let hasAnswers = previewSlides[type] ? previewSlides[type].answers : false;
    if (hasAnswers && selectedSlide.answers && Object.keys(selectedSlide.answers).length) {
      answersInput = <div className="section">
      <label>Answer Options</label>
      { Object.keys(this.state.selectedSlide.answers).map((key, idx) => {
        const title = selectedSlide.answers[key];
        return <input disabled={readOnly} value={title} onChange={(e) => {
          e.preventDefault();
          e.stopPropagation();
          const val = e.target.value;
          const k = 'answers';
          onChange(k, val, key);
        }} />
      })}</div>
    }
    if (['range', 'slider', 'country', 'star-rating', 'satisfaction'].indexOf(type) !== -1) {
      answersInput = null;
    }

    let hasLabels = previewSlides[type] ? previewSlides[type].leftLabel : false;
    if (hasLabels && (selectedSlide.leftLabel || selectedSlide.rightLabel)) {
      answersInput = <div className="section">
      <label>Label Options</label>
      { [selectedSlide.leftLabel, selectedSlide.rightLabel].map((title, idx) => {
        return <input disabled={readOnly} value={title} onChange={(e) => {
          e.preventDefault();
          e.stopPropagation();
          const val = e.target.value;
          const key = idx === 0 ? 'leftLabel' : 'rightLabel';
          onChange(key, val);
        }} />
      })}</div>
    }

    let hasActions = previewSlides[type] ? previewSlides[type].actions : false;
    if (hasActions && (selectedSlide.actions && selectedSlide.actions.length)) {
      actionsInput = <div className="section">
      <label>Action Options</label>
      { Object.keys(this.state.selectedSlide.actions).map((key, idx) => {
        const title = selectedSlide.answers[key];
        return <input disabled={readOnly} value={title} onChange={(e) => {
          e.preventDefault();
          e.stopPropagation();
          const val = e.target.value;
          const key = 'actions';
          onChange(key, val);
        }} />
      })}</div>
    }

    if (type === 'reward') {
      rewardInput = <div className="section">
        <label>Reward Code</label>
        <input style={{ marginBottom: 15 }} disabled={readOnly} value={selectedSlide.rewardCode} onChange={(e) => {
          e.preventDefault();
          e.stopPropagation();
          const val = e.target.value;
          const key = 'rewardCode';
          onChange(key, val);
        }} />

        <WysiwygInput
          onChange={(e) => {
            onChange('rewardBanner', e.target.value);
          }}
          key={`rewardBanner-${this.state.cacheBuster}`}
          placeholder=""
          readOnly={readOnly}
          label={'Reward Banner'}
          value={selectedSlide.rewardBanner}
        />
      </div>
    }

    return (
      <div className="column">
        <div className="header">
          { button }
          <div className="top">{ header }</div>
          <div>{ subheader }</div>
        </div>

        <div className="content">
          <WysiwygInput
            onChange={(e) => {
              onChange('title', e.target.value);
            }}
            label={null}
            placeholder=""
            readOnly={readOnly}
            label={'Main Copy'}
            value={selectedSlide.title}
            key={`title-${this.state.cacheBuster}`}
          />

          <WysiwygInput
            onChange={(e) => {
              onChange('copy', e.target.value);
            }}
            key={selectedSlide.copy}
            label={null}
            placeholder=""
            readOnly={readOnly}
            label={'Fine Print'}
            value={selectedSlide.copy}
            key={`copy-${this.state.cacheBuster}`}
          />

          { answersInput }
          { actionsInput }
          { labelsInput }
          { rewardInput }
        </div>
      </div>

    );
  }

  getSelectedLanguages() {
    return this.state.selectedLanguages;
  }

  render() {
    if (!this.state.pollId) { return null; }

    const hasChanged = this.initialState && (JSON.stringify(this.state.inputs) !== JSON.stringify(this.initialState));

    let title = (<SectionHeader
      title=<span><i class="fas fa-globe-americas"/>Language Settings</span>
      subtitle="Control the language for buttons and slides across your surveys."
      className="no-margin center"
    />
    );

    const tabs = (<div className={`big-switch ${this.state.type === 'cta' ? 'left' : 'right'}`}>
      <div 
        onClick={() => {
          this.setState({ type: 'cta'});
        }}
        className={`${this.state.type === 'cta' ? 'active' : ''}`}>Buttons & Hints</div>
      <div
        onClick={() => {
          this.setState({ type: 'slides'});
        }}
        className={`${this.state.type === 'cta' ? '' : 'active'}`}>Questions</div>
    </div>);

    const surveys = (<div className="surveys object-list">
      {this.props.account.polls.map((poll) => {
        return (
          <div key={poll._id} className={`${poll._id === this.state.pollId ? 'active' : ''}`} onClick={(e) => this.setPollId(poll._id)}>
            <div className="poll-title">{poll.title}</div>
            <div className="poll-type">{getPollType(poll)}</div>
          </div>);
      })}
    </div>);

    const addLanguage = (<div className="add-language" onClick={this.addLanguage.bind(this)}>
      <div>
        <i class="icon fa-solid fa-language"></i>
        <i className="inline-icon fas fa-plus"></i>
        <strong>Add Language</strong>
      </div>
    </div>)

    let languageModalContent = <div className="empty"><i className="fas fa-user-secret" />
      <div className="top">No matching languages found.</div>
      <div>Try a different search.</div>
    </div>;
    const languageCodes = getIsoLanguageCodes(this.state.languageFilter, this.getSelectedLanguages());
    if (languageCodes.length) {
      languageModalContent = languageCodes.map(({ value, label }) => {
        return <Checkbox value={value} selected={this.state.tmpSelectedLanguages.indexOf(value) !== -1} title={label} onClick={(value) => {
          const tmpSelectedLanguages = [ ...this.state.tmpSelectedLanguages ];
          const idx = tmpSelectedLanguages.indexOf(value);
          if (idx === -1) {
            tmpSelectedLanguages.push(value);
          } else {
            tmpSelectedLanguages.splice(idx, 1);
          }
          this.setState({ tmpSelectedLanguages })
        }}/>
      })
    }

    const poll = this.props.poll || {};
    let langaugeFormContent = (
      <div>
        { surveys }
        { this.renderDefaults() }
        { this.renderLanguageForms() }
        { addLanguage }
      </div>
    );

    /* Need to fetch polls for this one (do it on click) */
    if (this.state.type === 'slides') {
      langaugeFormContent = <div>
        { surveys }
        { this.renderSlideLanguageDefaults() }
        { this.renderSlideLanguageForms() }
        { this.state.pollId === 'global' ? null : addLanguage }
      </div>
      if (!poll.slides || !poll.slides.length) {
        langaugeFormContent = <div>
          <div className="empty-survey-warning">
              <i class="fa-solid fa-triangle-exclamation" />
              <div className="top">Your survey has no slides.</div>
              <div>Once you create some slides for your survey you can translate them into multiple languages here.</div>
          </div>
        </div>
      }
    }

    return (
      <div className="language-settings" style={this.props.style || {}}>
        { title }
        { tabs }

        <div className="form-wrapper">
          { langaugeFormContent }
        </div>

       <Modal 
          isOpen={this.state.showSlideTranslationModal}
          onRequestClose={this.closeSlideTranslationModal.bind(this)}
        >
          <div className="frame slide-translation">
            <div className="close" onClick={this.closeSlideTranslationModal.bind(this)} />
            <div className="title">Translate Slide</div>
            <div className="content">
              <div className="columns">
                { this.renderTranslationColumn('Source', 'English', true) }
                { this.renderTranslationColumn('Translation', this.state.selectedLanguage, false) }
              </div>

              <div className="actions">
                <button className="positive" onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  /* Set language forms */
                  const code = this.state.selectedLanguageCode;
                  const selectedSlide = this.state.selectedSlide || {};
                  const key = selectedSlide._id;

                  const value = this.state.slideTranslationModalValue;

                  const state = { ...this.state.inputs };
                  const language = state[code] ? { ...state[code] } : {};

                  language[key] = value;
                  state[code] = language;
                  this.setState({ inputs: state }, () => {
                    // this.disassociatePollFromGlobal();
                  });
                  this.closeSlideTranslationModal();
                }}>Save</button>
                <button className="negative" onClick={this.closeSlideTranslationModal.bind(this)}>Cancel</button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal 
          isOpen={this.state.showLanguageModal}
          onRequestClose={this.closeModal.bind(this)}
        >
          <div className="frame language-selector">
            <div className="close" onClick={this.closeModal.bind(this)} />
            <div className="title">Select Language(s)</div>
            <div className="content">
              <div className="language-filter">
                <i className="fas fa-search" />
                <input
                  type="text"
                  value={this.state.languageFilter}
                  placeholder="Search languages"
                  onChange={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({ languageFilter: e.target.value });
                  }}
                />
              </div>
              <div className="language-options">
                { languageModalContent }  
              </div>
              <div className="actions">
                <button className="positive" onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  let selectedLanguages = [ ...this.getSelectedLanguages(), ...this.state.tmpSelectedLanguages ];

                  /* Set language forms */
                  this.setState({ tmpSelectedLanguages: [], selectedLanguages })
                  this.closeModal();
                }}>Save</button>
                <button className="negative" onClick={this.closeModal.bind(this)}>Cancel</button>
              </div>
            </div>
          </div>
        </Modal>

        <HeaderButtons>
          <button 
            disabled={!hasChanged}
            onClick={this.onSubmit.bind(this)}>Save Changes</button>
          <button 
            className="cancel"
            disabled={!hasChanged}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.cancel();
            }}
          >Cancel</button>
        </HeaderButtons>
      </div>
    );
  }
}
