import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import _ from 'lodash';
import getSymbolFromCurrency from 'currency-symbol-map'

import * as AccountsActions from '../actions/Accounts';
import * as ParticipantsActions from '../actions/Participants';

import { decode, encode, getDate, getSlideLabel, truncate, getSentimentTitle, getSentimentClass, numberWithCommas } from '../utils';

import shopifyLogo from '../images/shopifyLogo.png';

const Row = (props) => {
  if (!props.label || !props.value) { return null; }
  return (<div className="row">
    <label className="inline">{props.label}:</label> <div>{ props.value }</div>
  </div>)
}

const cache = {};
class ShopifyOrderDataCard extends Component {
  constructor(props) {
    super(props);
    const orderId = this.props.orderId;

    let query;
    if (orderId) {
      query = `id:${orderId}`;
    }

    props.fetchShopifyOrderData(this.props.participant, orderId).then(() => {
      this.forceUpdate();
    });
  }

  render() {
    if (!this.props.participant || !this.props.participant[this.props.orderId]) {
      return null;
    }

    const shopifyData = this.props.participant[this.props.orderId];

    let defaultAddress;
    if (shopifyData.default_address) {
      defaultAddress = _.trim(`${shopifyData.default_address.address1} ${shopifyData.default_address.address2}`);
    }

    let totalSpent;
    if (shopifyData.total_spent !== undefined) {
      totalSpent = `${this.props.user.currency}${numberWithCommas(shopifyData.total_spent)}`;
    }

    return <div>
      <a target="_blank" rel="noopener noreferrer" href={`https://${this.props.account.shop}/admin/orders/${shopifyData.id}`}><div className="section-subtitle small" style={{ marginTop: 0 }}><i class="fa-solid fa-arrow-turn-up fa-rotate-90"></i><img style={{ width: 25, marginRight: 10, verticalAlign: 'middle', marginTop: -4, marginLeft: -10 }} src={shopifyLogo} /><span style={{ verticalAlign: 'middle', fontSize: 22 }}>Shopify Order Information</span></div></a>

      <div className="object-list participant" style={{ overflow: 'hidden' }}>
        <div>
          <Row label="Order Name" value={shopifyData.name} />
          <Row label="Order Number" value={shopifyData.order_number} />
          <Row label="Total Price" value={`${this.props.user.currency || ''}${shopifyData.total_price}`} />
          <Row label="Line Items" value={shopifyData.line_items.map(({ name }) => name ).join(', ')} />
        </div>
      </div>
    </div>
  }
}

function mapStateToProps(state, ownProps) {
  const { user } = state;

  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];

  const pollId = decode(ownProps.match.params.pollId);
  const slideId = decode(ownProps.match.params.slideId);

  const participantId = decode(ownProps.match.params.participantId);

  const allParticipants = state.participants || [];
  const participant = allParticipants[participantId];

  return {
    user,
    accountId,
    account,
    pollId,
    slideId,
    participant,
    participantId,
    loading: state.participants.loading,
    loadingEvents: state.participants.loadingEvents
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...ParticipantsActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShopifyOrderDataCard));