import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Footer extends Component {

  render() {
    return (<div id="footer">
      <div className="wrapper">
        <div className="logo"><Link to="/">Zigpoll</Link></div>
        <div className="links">
          <a target="_blank" href="https://www.zigpoll.com/contact">Contact</a>
          <a target="_blank" href="https://docs.zigpoll.com">Docs</a>
          <a target="_blank" href="https://www.zigpoll.com/examples">Examples</a>
          <a target="_blank" href="https://www.zigpoll.com/terms-and-policies">Terms</a>
          <a target="_blank" href="https://www.zigpoll.com/blog">Blog</a>
          <a target="_blank" href="https://www.zigpoll.com/faq">FAQ</a>
          <a target="_blank" href="https://www.zigpoll.com">Website</a>
        </div>
      </div>
    </div>);
  }

};