export const post = (url, body = {}) => {
  return fetch(url, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'text/plain'
    },
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(body)
  })
}

export const postFormData = (url, formData) => {
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: formData
  })
}