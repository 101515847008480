import React, { Component } from 'react';

import $ from 'jquery';
import _ from 'lodash';

import BrowserFrame from './BrowserFrame';
import SmsPreview from './SmsPreview';

export default class VisualDisplay extends Component {
  render() {
    let style = {};
    if (this.props.height) {
      style.height = this.props.height;
    }
    return (
      <div className="visual-display" style={style}>
        <BrowserFrame mobile={true} />
        <SmsPreview
          {...this.props}
        />
      </div>
    );
  }
}