import React, { Component } from 'react';

import Modal from './Modal';

export default class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.setState({ showModal: this.props.show });
    }
  }

  closeModal() {
    this.setState({ showModal: false });
    this.props.onCancel();
  }

  render() {
    return (
      <Modal 
        isOpen={this.state.showModal}
        onRequestClose={this.closeModal.bind(this)}
      >
        <div className="frame">
          <div className="close" onClick={this.closeModal.bind(this)} />
          <div className="title">{ this.props.title }</div>
          <div className="content">
            <div className="subtitle">{ this.props.subtitle }</div>
            <div className="actions">
              <button className="positive" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                this.props.onConfirm();
                this.closeModal();
              }}>Yes</button>
              <button className="negative" onClick={this.closeModal.bind(this)}>No</button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}