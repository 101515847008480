import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import _ from 'lodash';

import Modal from '../components/Modal';

import { encode } from '../utils';

class EditSlideActions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  showModal() {
    this.setState({ showModal: true })
  }

  closeModal() {
    this.setState({ showModal: false })
  }

  render() {
    let nextPrev = null;
    if (this.props.poll && this.props.poll.slides.length > 1) {

      const slides = this.props.poll.slides || [];
      const idx = _.findIndex(slides, ({_id}) => this.props.slideId === _id );

      let next = null;
      let prev = null;

      if (idx < slides.length - 1) {
        next = (<Link className="next" to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(slides[idx+1]._id)}?tab=details`}>Next</Link>);
      } else {
        next = <span className="next">Next</span>
      }

      if (idx !== 0 && slides.length > 1) {
        prev = (<Link className="prev" to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(slides[idx-1]._id)}?tab=details`}>Prev</Link>);
      } else {
        prev = <span className="prev">Prev</span>
      }

      nextPrev = (<div className="next-prev">
        { prev }
        { next }
      </div>);
    }

    return (
      <div className="page-actions">
        <div className="back">
          <p>Done Editing?</p>
          <Link to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}?tab=slides`}>Back To Slides</Link>
        </div>

        <div className="create-delete">
          <div
            className="delete"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.showModal();
            }}
          >Delete Slide</div>
          <Modal 
            isOpen={this.state.showModal}
            onRequestClose={this.closeModal.bind(this)}
          >
            <div className="frame">
              <div className="close" onClick={this.closeModal.bind(this)} />
              <div className="title">Are you sure?</div>
              <div className="content">
                <div className="subtitle">This will remove the slide and all responses to it.</div>
                <div className="actions">
                  <button className="positive" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    this.props.remove();
                    this.closeModal();
                  }}>Yes</button>
                  <button className="negative" onClick={this.closeModal.bind(this)}>No</button>
                </div>
              </div>
            </div>
          </Modal>

          <Link className="new-slide" to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/create`}>Add Slide</Link>

          <div
            className="duplicate-slide"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.props.duplicateSlide(this.props.slideId);
            }}
          >Duplicate Slide</div>
        </div>

        { nextPrev }
      </div>
    )
  }
}

export default EditSlideActions;
