import React, { Component } from 'react';

import Toggle from './Toggle';
import Tooltip from './Tooltip';
import { NameInput } from './SimpleInputs';
import { AuthSubmitButton } from './Buttons';
import Switch from './Switch';
import HeaderButtons from './HeaderButtons';

export default class CharacterLimitInput extends Component {
  onChange(e) {
    const state = { regex: this.props.regex, message: this.props.message };
    state[e.target.name] = e.target.value;
    this.props.onChange(state);
  }

  validate() {
    return;
  }

  render() {
    let inputs = null;
    let advancedInputs = null;

    if (this.props.active) {
      inputs = (
      <div>
        <NameInput
          name="regex"
          label="Regular Expression"
          value={ this.props.regex }
          onChange={this.onChange.bind(this)}
          noValidate={true}
          type="text"
          placeholder="^\d+$"
        />

        <NameInput
          style={{ marginTop: -20 }}
          name="message"
          label="Message"
          type="text"
          placeholder="Please enter a valid number"
          value={ this.props.message }
          onChange={this.onChange.bind(this)}
          noValidate={true}
          maxlength={60}
        />
      </div>
      );
    }

    return (<div
      className={`dynamic-render-input ${this.props.disabled || this.props.warning ? 'disabled' : ''}`} style={{ position: 'relative', zIndex: 1 }}
      active={this.props.active}
    >
      <div style={{ position: 'relative', marginBottom: this.props.active ? 20 : 0 }}>
        <label>Format<Tooltip>Enter the contents of a regular expression in the input box. If you need help with a particular format please <a href="https://www.zigpoll.com/contact" target="_blank">contact us</a>.</Tooltip></label>
        <div className="inline-toggle">
          <Toggle
            active={this.props.active}
            onChange={(value) => {
              if (!value) {
                this.props.onChange(false);
              } else {
                this.props.onChange({ message: '', regex: '' });
              }
            }}
          />
          <div className="subtle">Enforce a specific format using a regular expression.</div>
        </div>
      </div>

      { inputs }
    </div>);
  }
}