import React, { Component } from 'react';

class InlineInput extends Component {
  onChange(e) {
    let value = e.target.value;
    this.props.onChange(value);
  }

  render() {
    return (
      <div className="inline-input">
        <label>{ this.props.label }</label>
        <input
          type="text"
          name={ this.props.name }
          value={ this.props.value }
          onChange={ this.onChange.bind(this) }
          disabled={this.props.disabled}
        />
        <span className="suffix">{ this.props.suffix }</span>
      </div> 
    );
  }
}

export default class PositionInput extends Component {
  handleChange(value) {
    this.props.onChange(value);
  }

  render() {
    return (<div
      className={`position-input ${this.props.disabled ? 'disabled' : ''}`}
      active={this.props.active}
    >

      <InlineInput
        label="Top"
        name="top"
        value={this.props.value}
        suffix="Pixels"
        idx={1}
        onChange={this.handleChange.bind(this)}
      />

    </div>);
  }
}