import React, { Component } from 'react';
import ColumnLayout from '../components/ColumnLayout';
import SingleFormPageHeader from '../components/SingleFormPageHeader';
import { Link } from 'react-router-dom';

class NotFound extends Component {
  render() {
    return (
      <ColumnLayout
        title="Page Not Found"
        className="not-found"
      >
        <SingleFormPageHeader
          title="This is awkward."
          subtitle="Nothing to see here. Get a snack."
          className="no-margin"
        />
      </ColumnLayout>
    );
  }
}

export default NotFound;
