import { FETCHED_USER } from '../actions/Users';
import { UPDATE_QUERY_VARS } from '../actions/Polls';
import { LOCATION_CHANGE } from 'connected-react-router'

export default function misc(state = {}, action) {
  switch (action.type) {
    case FETCHED_USER:
      return state;
    case UPDATE_QUERY_VARS:
      const pollId = action.pollId;
      const variables = action.variables;
      const queryVars = state.queryVars ? { ...state.queryVars } : {};

      queryVars[pollId] = variables;
      return { ...state, queryVars };
    case LOCATION_CHANGE:
      window.prevLocation = action.payload.location;
      return { ...state, prevLocation: action.payload.location };
    case "PAGE_AJAX_LOADING":
      return { ...state, pageLoading: true }
    case "PAGE_AJAX_LOADED":
      return { ...state, pageLoading: false }
    default:
      return state;
  }
}
