import { 
  FETCHED_DIFFS,
} from '../actions/Dashboard';

import { merge } from '../utils';

export default function responses(state = {}, action) {
  switch (action.type) {
    case FETCHED_DIFFS:
      const diffs = merge(state, action.diffs);
      return { ...state, ...diffs }
    default:
      return state;
  }
}
