import React, { Component } from 'react';
import tinycolor from 'tinycolor2';

import { getRangeFontSize, getBoxRatio, getRangeMaxWidth } from '../utils';

class Option extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onMouseOver() {
    this.setState({ hover: true });
  }

  onMouseOut() {
    this.setState({ hover: false });
  }

  render() {
    const { value, color } = this.props;

    const width = (100 / this.props.options.length) + '%';
    const fontSize = getRangeFontSize(this.props.options.length);

    const style = {width: width, fontSize};
    let spanStyle = { paddingTop: getBoxRatio(this.props.options.length) };
    if (this.props.selected) {
      spanStyle = { ...spanStyle, background: tinycolor(this.props.color).toString(), borderColor: tinycolor(this.props.color).darken(10).toString() };
    }

    return (
      <div
        className={`${this.props.selected ? 'selected' : ''} ${this.props.className || ''}`}
        onClick={(e) => { this.props.onClick(e, value) }}
        style={style}
        onMouseOver={this.onMouseOver.bind(this)}
        onMouseOut={this.onMouseOut.bind(this)}
      >
        <span style={spanStyle}><span>{value}</span></span>
      </div>  
    );
  }
}

export default class Radio extends Component {
  handleClick(e, value) {
    e.preventDefault();
    e.stopPropagation();

    if (this.props.disabled) {
      return;
    }

    if (value === this.props.value) {
      return this.props.onChange(undefined);
    }

    this.props.onChange(value);
  }

  renderLabels() {
    if (!this.props.labels) {
      return null;
    }

    return <div className="labels">
      <div className="left-label">{ this.props.labels[0] }</div>
      <div className="right-label">{ this.props.labels[1] }</div>
    </div>
  }

  render() {
    return (<div
      className={`${this.props.className ? this.props.className : 'radio-input'} ${this.props.disabled ? 'disabled' : ''}`}
      active={this.props.active}
      // style={{ maxWidth: getRangeMaxWidth(this.props.options.length) }}
    >
      <div className="answer-group">
        { this.props.options.map(value => {
          let selected;
          if (value === this.props.value) {
            selected = true;
          }

          return <Option
            value={value}
            key={value}
            color={this.props.color}
            selected={selected}
            onClick={this.handleClick.bind(this)}
            options={this.props.options}
          />
        }) }
      </div>

      { this.renderLabels() }
    </div>)
  }
}