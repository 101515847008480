import React, { Component } from 'react';

import Layout from '../components/Layout';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import SingleFormPageHeader from '../components/SingleFormPageHeader';

class Signup extends Component {
  render() {
    return (
      <Layout title="Forgot Password" graphics={true}>
        <SingleFormPageHeader
          title="Forgot your password?"
          subtitle="Enter your email and we'll reset it."
        />
        <ForgotPasswordForm />
      </Layout>
    );
  }
}

export default Signup;
