/* User Reducer */
import { PUSH_NOTIFICATION, POP_NOTIFICATION, CLEAR_NOTIFICATIONS } from '../actions/Notifications';

import { LOCATION_CHANGE } from 'connected-react-router'

export default function user(state = [], action) {
  switch (action.type) {
    case PUSH_NOTIFICATION:
      return [ ...state, action.notification ]
    case POP_NOTIFICATION:
      const notifications = [ ...state ];
      notifications.pop();
      return [ ...notifications ];
    case CLEAR_NOTIFICATIONS:
    case LOCATION_CHANGE:
      return [];
    default:
      return state;
  }
}
