import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import qs from 'query-string';
import _ from 'lodash';

import { encode, decode, getAccountId, stripTags } from '../utils';

class LinkItem extends Component {
  render() {
    let subNav = null;

    if ((this.props.isActive || this.props.showSubNav) && this.props.subNav) {
      const { tab } = qs.parse(this.props.location.search);
      subNav = <div className="sub-nav">
        { this.props.subNav.map(({ title, key, active }) => <Link className={`${key === tab || active ? 'active' : ''}`} to={`${this.props.location.pathname}?tab=${key}`}>{ stripTags(title) }</Link>)}
      </div>
    }

    return (
      <div className="nav">
        <Link to={this.props.url} className={`${this.props.className} ${this.props.isActive ? 'active' : ''} ${this.props.subNav ? 'has-subnav' : ''}`}><i className={`fas ${this.props.icon}`} />{ stripTags(this.props.title) }</Link>
        { subNav }
      </div>
    );
  }
}

export default class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = { active: false };
    this.icon = 'fa-folder-open';
    this.title = 'Account';
  }

  componentDidMount() {
    if (!this.props.items) { this.fetchItems(); }
  }

  fetchItems() {
    console.log('No fetch items was passed');
  }

  render() {
    const { currentId } = this.props;
    if (!this.props.items) { return null; }

    const url = `${this.props.urlPrefix}${encode(currentId)}${this.props.urlSuffix}`;
    const isOnPage = (this.props.isOnPage);

    let label = (<Link to={url}><label><i className={`fas ${isOnPage ? this.icon : 'fa-arrow-left-long'}`} />{ stripTags(this.title) }:</label></Link>)
    if (isOnPage) {
      label = (<label><i className={`fas ${isOnPage ? this.icon : 'fa-arrow-left-long'}`} />{ stripTags(this.title) }: <i style={{ float: 'right', marginRight: 0}} className="fas fa-chevron-down" /></label>);
    }

    let hasActiveItem;
    const createNew = <Link className="item" to={`${this.props.urlPrefix.replace('/dashboard', '')}create`}><i className="fas fa-plus" />Create New {stripTags(this.title)}</Link>;

    return (<div className={`${isOnPage ? 'is-on-page' : 'not-on-page'} accounts-dropdown ${this.className || ''} ${this.state.active ? 'active' : ''}`} onClick={() => {
        if (!isOnPage) {
          console.log('HERE');
          console.log(this.props.prevUrl);
          return this.props.history.push(this.props.prevUrl || url);
        }
        this.setState({ active: !this.state.active });
      }}
      onMouseLeave={() => this.setState({ active: false })}>

      <div className="active-item">

      { label }

      { this.props.items.map(item => {
        if (item._id === currentId) {
          hasActiveItem = true;
          return (<div className="item">{ stripTags(item.title) }</div>)
        }
      })}

      { hasActiveItem ? null : createNew }
      </div>

      <div className="items">
        <div className="items-container">
          { this.props.items.map(item => {
            if (item._id !== currentId) {
              return (<Link className="item" to={`${this.props.urlPrefix}${encode(item._id)}${this.props.urlSuffix}`} onClick={(e) => {
                this.setState({ active: false });
              }}>{ stripTags(item.title) }</Link>);
            }
          })}
      
          { hasActiveItem ? createNew : null }
        </div>
      </div>
    </div>);
  }
}