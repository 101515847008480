import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import PageDisplaySettingsForm from './PageDisplaySettingsForm';

import * as AccountActions from '../actions/Accounts';
import * as FlashNotificationActions from '../actions/FlashNotifications';

class AccountPageDisplaySettingsForm extends PageDisplaySettingsForm {

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions, ...FlashNotificationActions }, dispatch);
}

function mapStateToProps(state) {
  return {
    loading: state.accounts.loading
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageDisplaySettingsForm);
