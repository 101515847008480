import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import qs from 'query-string';
import moment from 'moment';
import _ from 'lodash';

import SectionHeader from './SectionHeader';

import * as FlashNotificationActions from '../actions/FlashNotifications';

import { encode, decode, capitalize } from '../utils';

function getPollTitle(polls, pollId) {
  const match = _.find(polls, ({ _id }) => _id == pollId) || {};
  return match.title || '';
}

class AutomationSettingsCard extends Component {
  render() {
    if (!this.props.account) { return null; }

    let link = '/automations';
    if (this.props.accountId) {
      link += `/a/${encode(this.props.accountId)}`;
    }
    if (this.props.pollId) {
      link += `/p/${encode(this.props.pollId)}`;
    }
    if (this.props.slideId) {
      link += `/s/${encode(this.props.slideId)}`;
    }

    const automationSettings = this.props.account.automationSettings || [];

    let content = <div>
      <div className="integration empty"><span><i class="fas fa-info-circle"></i>No automations enabled.</span></div>
    </div>;

    if (automationSettings.length > 0) {
      content = (<div>
        <label>Active Automations</label>
        <div className="automations">
        { automationSettings.map((automation) => { 
          return <div className="automation"><span>Export a CSV</span> for <span>{ automation.params.pollId ? `${ getPollTitle(this.props.account.polls, automation.params.pollId) }` : 'your surveys' }</span> containing responses from the last <span>{ automation.params.daysToExport } day{ automation.params.daysToExport === 1 ? '' : 's'}</span>.<br/>Send to <span>{ automation.params.recipients.join(', ') }</span> every <span>{ automation.schedule.frequency } day{ automation.schedule.frequency === 1 ? '' : 's'}</span>.<br/>Started on <span>{ moment(automation.schedule.startDate).format('MM/DD/YYYY') }</span>.</div>
        }) }
        </div>
      </div>)
    }

    return (<span>
        <SectionHeader
          title=<span><i className="fas fa-arrows-spin" />Automations</span>
          subtitle="Automate Zigpoll tasks."
        />

        <div className="card integrations-card">
          <div className="links">
            <div>
              { content }
              <Link to={link}>Manage Automations</Link>
            </div>
          </div>
        </div>
    </span>)
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const pollId = decode(ownProps.match.params.pollId);
  const slideId = decode(ownProps.match.params.slideId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];

  return {
    accountId,
    pollId,
    slideId,
    account,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(FlashNotificationActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AutomationSettingsCard))
