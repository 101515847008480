import download from 'downloadjs';

import { replace } from 'connected-react-router';
import { pushServerError } from './Notifications';
import { flash } from './FlashNotifications';

import { post } from '../ajax';
import { parseUrl, generateFilename } from '../utils';
import { url } from '../settings';

import { fetchAccount } from './Accounts';
import { fetchPoll } from './Polls';
import { fetchSlide } from './Slides';

export const FETCHING_EMAILS = 'FETCHING_EMAILS';
export const FETCHED_EMAILS = 'FETCHED_EMAILS';

function fetchingEmails(slideId) {
  return {
    type: FETCHING_EMAILS,
    slideId
  };
}

function fetchedEmails(slideId, json) {
  return {
    type: FETCHED_EMAILS,
    slideId,
    emails: json
  };
}

export function fetchSlideAndEmails() {
  return (dispatch, getState) => {
    return dispatch(fetchSlide())
      .then(dispatch(fetchEmails()));
  }
}

export function fetchAccountAndEmails() {
  return (dispatch, getState) => {
    return dispatch(fetchAccount())
      .then(dispatch(fetchAllEmails()));
  }
}

export function fetchAllEmails(page = 0) {
  return (dispatch, getState) => {

    const [ accountId ] = parseUrl(getState().router);

    dispatch(fetchingEmails(accountId));
    return post(`${url}/account/${accountId}/emails/getPage/${page}`)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(fetchedEmails(accountId, json));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function fetchPollAndEmails() {
  return (dispatch, getState) => {
    return dispatch(fetchPoll())
      .then(dispatch(fetchPollEmails()));
  }
}

export function fetchPollEmails(page = 0) {
  return (dispatch, getState) => {

    const [ accountId, pollId ] = parseUrl(getState().router);

    dispatch(fetchingEmails(pollId));
    return post(`${url}/account/${accountId}/poll/${pollId}/emails/getPage/${page}`)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(fetchedEmails(pollId, json));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function fetchEmails(page = 0) {
  return (dispatch, getState) => {

    const [ accountId, pollId, slideId ] = parseUrl(getState().router);

    dispatch(fetchingEmails(slideId));
    return post(`${url}/account/${accountId}/poll/${pollId}/slide/${slideId}/emails/getPage/${page}`)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(fetchedEmails(slideId, json));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function exportEmails() {
  return (dispatch, getState) => {

    const [ accountId, pollId, slideId ] = parseUrl(getState().router);

    // dispatch(fetchingEmails(slideId));
    return post(`${url}/account/${accountId}/poll/${pollId}/slide/${slideId}/emails/export`)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }

      return response.blob();
    }).then((blob) => {
      download(blob, generateFilename('Slide Email Export'), 'text/csv');
      dispatch(flash('Successfully downloaded emails.'));
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function exportAllEmails() {
  return (dispatch, getState) => {

    const [ accountId ] = parseUrl(getState().router);

    // dispatch(fetchingEmails(slideId));
    return post(`${url}/account/${accountId}/emails/export`)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }

      return response.blob();
    }).then((blob) => {
      download(blob, generateFilename('Account Email Export'), 'text/csv');
      dispatch(flash('Successfully downloaded emails.'));
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

/* UPDATE */
export function exportPollEmails() {
  return (dispatch, getState) => {

    const [ accountId, pollId ] = parseUrl(getState().router);

    // dispatch(fetchingEmails(slideId));
    return post(`${url}/account/${accountId}/poll/${pollId}/emails/export`)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }

      return response.blob();
    }).then((blob) => {
      download(blob, generateFilename('Survey Email Export'), 'text/csv');
      dispatch(flash('Successfully downloaded emails.'));
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}
