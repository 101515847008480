import React, { Component } from 'react';

import ColumnLayout from '../components/ColumnLayout';

class Loading extends Component {
  render() {
    return (
      <ColumnLayout title="Loading" />
    );
  }
}

export default Loading;
