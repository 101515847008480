import React, { Component } from 'react';
import tinycolor from 'tinycolor2';

import { getRangeFontSize, getBoxRatio } from '../utils';

const icons = [
  'fa-star',
];

const colors = [
'rgb(220, 58, 40)',
'rgb(228, 133, 48)',
'rgb(242,205,48)',
'rgb(148,206,53)',
'rgb(102,181,125)'
]

class Option extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { value, color } = this.props;

    const width = (100 / this.props.options.length) + '%';
    const fontSize = getRangeFontSize(this.props.options.length);

    const style = {width: width, fontSize};
    const iStyle = { transform: 'scale(1.20) translateY(-50%)' };
    const spanStyle = { paddingTop: getBoxRatio(5, this.props.maxWidth, true) };

    const activeIdx = this.props.selectedIdx === undefined ? this.props.hoverIdx : this.props.selectedIdx;

    if (this.props.idx <= activeIdx) {
      spanStyle.backgroundColor = colors[activeIdx];
      spanStyle.borderColor = tinycolor(colors[activeIdx]).darken(12);
      iStyle.color = "#ffffff";
      iStyle.transform = 'scale(1.60) translateY(-50%)';
    }

    return (
      <div
        className={`${this.props.selected ? 'selected' : ''} ${this.props.className || ''}`}
        onClick={(e) => { this.props.onClick(e, value, this.props.idx) }}
        style={style}
        onMouseEnter={() => this.props.onMouseEnter(this.props.idx)}
        onMouseLeave={this.props.onMouseLeave}
        title={value}
      >
        <span style={spanStyle}><i style={iStyle}className={`fas fa-star`} /></span>
      </div>  
    );
  }
}

export default class Radio extends Component {
  constructor(props) {
    super(props);

    let hoverIdx, selectedIdx;
    if (props.disabled && props.value && props.options) {
      props.options.map((value, idx) => {
        if (value === this.props.value) {
          hoverIdx = idx;
          selectedIdx = idx;
        }
      });
    }

    this.state = { hoverIdx, selectedIdx };
  }

  onMouseEnter(idx) {
    this.setState({ hoverIdx: idx });
  }

  onMouseLeave() {
    this.setState({ hoverIdx: undefined });
  }

  handleClick(e, value, idx) {
    e.preventDefault();
    e.stopPropagation();

    if (this.props.disabled) {
      return;
    }

    if (value === this.props.value) {
      this.setState({ selectedIdx: undefined })
      return this.props.onChange(undefined);
    }

    this.setState({ selectedIdx: idx })
    this.props.onChange(value);
  }

  renderLabels() {
    if (!this.props.labels) {
      return null;
    }

    return <div className="labels">
      <div className="left-label">{ this.props.labels[0] }</div>
      <div className="right-label">{ this.props.labels[1] }</div>
    </div>
  }

  render() {
    return (<div
      className={`${this.props.className ? this.props.className : 'star-rating-input'} ${this.props.disabled ? 'disabled' : ''}`}
      active={this.props.active}
    >
      <div className="answer-group">
        { this.props.options.map((value, idx) => {
          let selected;
          if (value === this.props.value) {
            selected = true;
          }

          return <Option
            value={value}
            key={value}
            idx={idx}
            color={this.props.color}
            selected={selected}
            selectedIdx={this.state.selectedIdx}
            hoverIdx={this.state.hoverIdx}
            onMouseEnter={this.onMouseEnter.bind(this)}
            onMouseLeave={this.onMouseLeave.bind(this)}
            onClick={this.handleClick.bind(this)}
            options={this.props.options}
          />
        }) }
      </div>

      { this.renderLabels() }
    </div>)
  }
}