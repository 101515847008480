import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { url } from '../settings';

import _ from 'lodash';

import * as AccountActions from '../actions/Accounts';
import * as FlashNotificationActions from '../actions/FlashNotifications';

import ColumnLayout from '../components/ColumnLayout';
import ColumnLoading from './ColumnLoading';

import SectionHeader from '../components/SectionHeader';
import AuthForm from '../components/AuthForm';
import LoadingIndicator from '../components/LoadingIndicator';

import { decode } from '../utils';

import HeaderButtons from '../components/HeaderButtons';
import { AuthSubmitButton } from '../components/Buttons';

import Toggle from '../components/Toggle';
import Tooltip from '../components/Tooltip';
import DateInput from '../components/DateInput';
import { BasicRecipientsInput} from '../components/SimpleInputs';

import klaviyoLogo from '../images/klaviyoLogo.jpg';
import mailchimpLogo from '../images/mailchimpLogo.png';
import getResponseLogo from '../images/getResponseLogo.png';
import gorgiasLogo from '../images/gorgiasLogo.png';
import gaLogo from '../images/gaLogo.svg';
import activeCampaignLogo from '../images/activeCampaignLogo.png';
import slackLogo from '../images/slackLogo.png';

import copy from 'copy-to-clipboard';

class AutomationSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    if (!this.props.account && this.props.accountId) {
      this.props.fetchAccount(this.props.accountId);
    }
  }

  render() {
    if (!this.props.account) {
      return <ColumnLoading />
    }

    return (
      <ColumnLayout title="Insights" graphics={true}>

        <SectionHeader
          title=<span><i className="fas fa-envelopes-bulk" />Campaigns</span>
          subtitle="Run email and sms survey campaigns."
          className="no-margin"
        />

        
        
        
        <LoadingIndicator loading={this.props.accounts.loading} />

      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const pollId = decode(ownProps.match.params.pollId);
  const slideId = decode(ownProps.match.params.slideId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];
  const polls = state.polls || [];

  return {
    accountId,
    pollId,
    polls,
    slideId,
    accounts,
    account,
    loading: accounts.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions, ...FlashNotificationActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AutomationSettings);
