import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import copy from 'copy-to-clipboard';

import * as FlashNotificationActions from '../actions/FlashNotifications';

import { encode, decode } from '../utils';
import { url, stripeConnectId } from '../settings';

import stripeButton from '../images/stripe-button-grey/light-on-dark@3x.png';

class ShopifyAffiliateLink extends Component {
  render() {
    const link = `https://app.zigpoll.com/shopify-app-store?ref=${encode(this.props.user._id)}`;

    return (<div className="copy-affiliate-link" onClick={(e) => {
      copy(link);
      this.props.flash('The link has been copied to your clipboard.');
    }}>
      <label>Affiliate link <span style={{float: 'right'}}>Shopify App Store</span></label>
      <div>{link}</div>
    </div>);
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(FlashNotificationActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopifyAffiliateLink);
