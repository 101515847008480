import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { url } from '../settings';

import _ from 'lodash';
import copy from 'copy-to-clipboard';

import * as UserActions from '../actions/Users';
import * as AccountActions from '../actions/Accounts';
import * as FlashNotificationActions from '../actions/FlashNotifications';

import ColumnLayout from '../components/ColumnLayout';
import ColumnLoading from './ColumnLoading';

import SectionHeader from '../components/SectionHeader';
import AuthForm from '../components/AuthForm';
import LoadingIndicator from '../components/LoadingIndicator';

import { decode } from '../utils';

import HeaderButtons from '../components/HeaderButtons';
import { AuthSubmitButton } from '../components/Buttons';

import Toggle from '../components/Toggle';
import Switch from '../components/Switch';
import Tooltip from '../components/Tooltip';
import Modal from '../components/Modal';

import klaviyoLogo from '../images/klaviyoLogo.png';
import mailchimpLogo from '../images/mailchimpLogo.png';
import omnisendLogo from '../images/omnisendLogo.png';
import getResponseLogo from '../images/getResponseLogo.png';
import gorgiasLogo from '../images/gorgiasLogo.png';
import gaLogo from '../images/gaLogo.svg';
import activeCampaignLogo from '../images/activeCampaignLogo.png';
import slackLogo from '../images/slackLogo.png';
import googleSheetsLogo from '../images/googleSheetsLogo.png';
import shopifyLogo from '../images/shopifyLogo.png';
import tiktokLogo from '../images/tiktokLogo.png';

class ApiKey extends Component {
  render() {
    const key = this.props.apiKey;

    return (<div className="key copy-api-key" onClick={(e) => {
        copy(key);
      this.props.flash('The key has been copied to your clipboard.');
    }}>
      <div>{key}</div>
    </div>);
  }
}

export class PrivateKeyForm extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }

  showModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  showRemoveModal(key) {
    this.setState({ showRemoveModal: key });
  }

  closeRemoveModal() {
    this.setState({ showRemoveModal: false });
  }

  render() {
    const apiKeys = this.props.user.apiKeys || [];
    let content;
    if (apiKeys.length === 0) {
      content = <div className="empty-object-list tight no-margin info">
        <h3>No API Keys exist.</h3>
        <p><span className="underline" onClick={() => this.showModal()}>Click here</span> to create one.</p>
      </div>
    } else {
      content = <div className="card api-keys">
        { apiKeys.map(({ key, label }) => {
          return <div className="api-key" style={{ marginBottom: 20 }}>
            <div className="label">
              {label}
              <div className="remove" onClick={() => {
                this.setState({ showRemoveModal: key })
              }}>Remove</div>
            </div>
            <ApiKey key={key} apiKey={key} flash={this.props.flash} />
          </div>
        })}
        <div className="actions">
          <div className="add" onClick={() => this.showModal()}>Add Key</div>
        </div>
      </div>
    }

    let keySelectedForRemoval = this.state.showRemoveModal;
    if (keySelectedForRemoval) {
      keySelectedForRemoval = _.find(apiKeys, ({key}) => key === keySelectedForRemoval);
    }

    return <div>
      <SectionHeader
        title=<span><i className="fas fa-lock-open" />Private Keys</span>
        subtitle="Manage your Private API keys."
        className=""
      />

      {content}

      <Modal 
        isOpen={this.state.showModal}
        onRequestClose={this.closeModal.bind(this)}
      >
        <div className="frame">
          <div className="close" onClick={this.closeModal.bind(this)} />
          <div className="title">Enter a label</div>
          <div className="content">
            <div className="subtitle" style={{ marginBottom: 5 }}>Please enter a label for this key.</div>
            <div className="copy subtle">The label should be something simple that associates the key with the functionality it provides.</div>

            <label className="label">API Key Label</label>
            <input
              value={this.state.value}
              onChange={(e) => {
                this.setState({ value: e.target.value });
              }}
            />

            <div className="actions">
              <button className="positive" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (!this.state.value) {
                  this.props.flash('Please enter a label for the key.');
                  return;
                }

                this.props.createPrivateApiKey(this.state.value)

                /* Save key. */
                this.closeModal();
                setTimeout(() => {
                  this.setState({ value: '' });
                }, 500);
              }}>Add Key</button>
              <button className="negative" onClick={this.closeModal.bind(this)}>Cancel</button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal 
        isOpen={this.state.showRemoveModal}
        onRequestClose={this.closeRemoveModal.bind(this)}
      >
        <div className="frame">
          <div className="close" onClick={this.closeRemoveModal.bind(this)} />
          <div className="title">Are you sure?</div>
          <div className="content">
            <div className="subtitle" style={{ marginBottom: 5 }}>This will remove the key <strong>"{ keySelectedForRemoval ? keySelectedForRemoval.label : '' }"</strong> from your account.</div>
            <div className="copy subtle">Any existing integrations that are connected to this key will no longer work if the key is removed.</div>

            <div className="actions">
              <button className="negative" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                this.props.removePrivateApiKey(this.state.showRemoveModal)

                /* Save key. */
                this.closeRemoveModal();
              }}>Remove</button>
              <button className="positive" onClick={this.closeRemoveModal.bind(this)}>Cancel</button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  }
}

export class WebhookEndpointForm extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }

  showModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  showRemoveModal(key) {
    this.setState({ showRemoveModal: key });
  }

  closeRemoveModal() {
    this.setState({ showRemoveModal: false });
  }

  render() {
    const webhooks = this.props.account.webhooks || [];
    let content;
    if (webhooks.length === 0) {
      content = <div className="empty-object-list tight no-margin info">
        <h3>No webhooks activated.</h3>
        <p><span className="underline" onClick={() => this.showModal()}>Click here</span> to create one.</p>
      </div>
    } else {
      content = <div className="card webhooks">
        { webhooks.map(({ pollId, endpoint, key }) => {
          const poll = _.find(this.props.account.polls, ({ _id }) => _id == pollId);
          const title = poll ? poll.title : '[REMOVED]';

          return <div className="webhook">
            <div className="row">
              <label>Survey<div className="remove" onClick={() => {
              this.setState({ showRemoveModal: key })
            }}>Remove</div></label>
              <div>{ title }</div>
            </div>

            <div className="row">
              <label>Endpoint</label>
              <div>{ endpoint }</div>
            </div>

            <div className="row">
              <label>Secret Key</label>
              <div>{ key }</div>
            </div>

          </div>
        })}
        <div className="actions">
          <div className="add" onClick={() => this.showModal()}>Add Webhook</div>
        </div>
      </div>
    }

    let polls = this.props.account.polls || [];

    let webhookSelectedForRemoval = this.state.showRemoveModal;
    if (webhookSelectedForRemoval) {
      webhookSelectedForRemoval = _.find(webhooks, ({key}) => key === webhookSelectedForRemoval);
    }

    return <div>
      <SectionHeader
        title=<span><i className="fas fa-map-location-dot" />Webhook Settings</span>
        subtitle="Manage your Webhook endpoints."
      />

      {content}

      <Modal 
        isOpen={this.state.showModal}
        onRequestClose={this.closeModal.bind(this)}
      >
        <div className="frame">
          <div className="close" onClick={this.closeModal.bind(this)} />
          <div className="title">Enter a label</div>
          <div className="content">
            <div className="subtitle" style={{ marginBottom: 5 }}>Please enter a label for this key.</div>
            <div className="copy subtle">The label should be something simple that associates the key with the functionality it provides.</div>

            <label className="label">Survey</label>
            <div className="select" style={{ marginBottom: 15 }}>
              <select
                value={this.state.pollId}
                onChange={(e) => {
                  this.setState({ pollId: e.target.value });
                }}
                selected={this.state.pollId}
              >
                <option value={''}>Select a survey</option>
                { polls.map((poll) => {
                  return <option value={poll._id}>{ poll.title }</option>
                })}
              </select>
            </div>

            <label className="label">Webhook URL</label>
            <input
              value={this.state.value}
              onChange={(e) => {
                this.setState({ value: e.target.value });
              }}
            />

            <div className="actions">
              <button className="positive" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (!this.state.value) {
                  this.props.flash('Please enter an endpoint for the webhook.');
                  return;
                }
                if (!this.state.pollId) {
                  this.props.flash('Please select a survey.');
                  return;
                }

                this.props.createWebhook(this.state.pollId, this.state.value)

                /* Save key. */
                this.closeModal();
                setTimeout(() => {
                  this.setState({ value: '' });
                }, 500);
              }}>Add Webhook</button>
              <button className="negative" onClick={this.closeModal.bind(this)}>Cancel</button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal 
        isOpen={this.state.showRemoveModal}
        onRequestClose={this.closeRemoveModal.bind(this)}
      >
        <div className="frame">
          <div className="close" onClick={this.closeRemoveModal.bind(this)} />
          <div className="title">Are you sure?</div>
          <div className="content">
            <div className="subtitle" style={{ marginBottom: 5 }}>This will remove the webhook with endpoint <strong>"{ webhookSelectedForRemoval ? webhookSelectedForRemoval.endpoint : '' }"</strong> from your account.</div>
            <div className="copy subtle">Any future activity will no longer be send to this endpoint once it is removed.</div>

            <div className="actions">
              <button className="negative" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                this.props.removeWebhook(this.state.showRemoveModal)

                /* Save key. */
                this.closeRemoveModal();
              }}>Remove</button>
              <button className="positive" onClick={this.closeRemoveModal.bind(this)}>Cancel</button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  }
}

class IntegrationSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    if (!this.props.account && this.props.accountId) {
      this.props.fetchAccount(this.props.accountId);
    }
  }

  render() {
    if (!this.props.account || !this.props.user) {
      return <ColumnLoading />
    }

    return (
      <ColumnLayout title="Api Settings" graphics={true}>

        <PrivateKeyForm user={this.props.user} createPrivateApiKey={this.props.createPrivateApiKey} removePrivateApiKey={this.props.removePrivateApiKey} flash={this.props.flash} />
        <WebhookEndpointForm user={this.props.user} account={this.props.account} createWebhook={this.props.createWebhook} removeWebhook={this.props.removeWebhook} flash={this.props.flash} />
        <LoadingIndicator loading={this.props.accounts.loading} />

      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const pollId = decode(ownProps.match.params.pollId);
  const slideId = decode(ownProps.match.params.slideId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];
  const user = state.user || {};

  return {
    accountId,
    pollId,
    slideId,
    accounts,
    account,
    user,
    loading: accounts.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions, ...UserActions, ...FlashNotificationActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationSettings);
