import React, { Component } from 'react';

export default class Tooltip extends Component {
  constructor(props) {
    super(props);
    this.state = { active: false };
  }

  render() {
    let style = {};

    if (this.props.width) {
      style = { width: this.props.width };
    }

    let zIndex = 4;
    if (this.props.zIndex) {
      zIndex = this.props.zIndex;
    }

    return (<div
      className={`tooltip ${this.props.bottom ? 'bottom' : ''}`}
      style={{ zIndex: this.state.active ? zIndex : 4 }}
      onMouseEnter={(e) => {
        if (this.timeout) { clearTimeout(this.timeout); }
        this.setState({ active: true });
      }}
      onMouseLeave={() => {
        if (this.timeout) { clearTimeout(this.timeout); }
        this.timeout = setTimeout(() => {
          this.setState({ active: false });
        }, 500);
      }}
    >
      <span />
      <div 
        className={`content ${this.props.className || ''}`}
        style={style}
      >
        <div>{ this.props.children }</div>
      </div>
    </div>);
  }
}