import { 
  FETCHING_EMAILS,
  FETCHED_EMAILS
} from '../actions/Emails';

export default function emails(state = {}, action) {
  let emails;

  switch (action.type) {
    case FETCHING_EMAILS:
      return { ...state, loading: true }
    case FETCHED_EMAILS:
      emails = {};
      emails[action.slideId] = action.emails;
      return { ...state, ...emails, loading: false }
    default:
      return state;
  }
}
