import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withRouter } from 'react-router-dom';
// import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { encode, decode, getAccountId, renderText, getShareDomain, downloadURI, loadContactUsSurvey } from '../utils';

import Modal from './Modal';
const QRCode = require('qrcode')

export default class QRCodeModal extends Component {
  render() {
    const { link } = this.props;

    return (<Modal 
      isOpen={this.props.isOpen}
      onAfterOpen={() => {
        QRCode.toCanvas(document.getElementById('qr-code-canvas'), link, {
          color: {
            dark: '#2167f5',
            light: "#fff"
          },
          width: 300,
          height: 300,
        }, (error) => {
          if (error) console.error(error)
          console.log('success!');
        });
      }}
      onRequestClose={() => this.props.onClose()}
    >
      <div className="frame">
        <div className="close" onClick={() => this.props.onClose()} />
        <div className="title">Survey QR Code</div>
        <div className="content">
          <div className="subtitle">Use the QR code below to directly link to this survey.</div>
          <canvas style={{ margin: '-20px auto 20px', display: 'block' }} id="qr-code-canvas" />
          <div className="actions">
            <button className="positive" onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              QRCode.toDataURL(document.getElementById('qr-code-canvas'), link, {
                  color: {
                    dark: '#2167f5',
                    light: "#fff"
                  },
                  width: 300,
                  height: 300,
                },
              (error, url) => {
                if (error) console.error(error)

                downloadURI(url, `${this.props.poll.title} QR Code`);
              });
            }}>Download Image</button>
          </div>
        </div>
      </div>
    </Modal>)
  }
}
