import React, { Component } from 'react';

import LayoutAbstract from './LayoutAbstract'
import SingleFormPageGraphics from './SingleFormPageGraphics';
import Footer from './Footer';

import topRight from '../images/topright.png';
import bottomLeft from '../images/bottomleft.png';

const Graphics = (props) => {
  return (<SingleFormPageGraphics 
    bottomLeft={bottomLeft}
    topRight={topRight}
  />)
}

export default class Layout extends LayoutAbstract {
  renderContent() {
    let graphics;

    if (this.props.graphics) {
      graphics = <Graphics />
    }

    return (
      <div>
        { graphics }

        <div className="foreground no-column" id="foreground">
          { this.props.children }
        </div>

        <Footer />
      </div>
    );
  }
}