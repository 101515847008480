import React from 'react';
// import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { encode } from '../utils';

const Accounts = (props) => {
  if (!props.accounts || !props.accounts.length) {
    return (<div className="empty-object-list">
      <h3>There are no accounts made yet.</h3>
      <Link to={`/a/create`}>Create One</Link>
    </div>);
  }

  return (
    <div>
      <div className="object-list">
        { props.accounts.map((account, idx) => (
          <div className="account" key={idx}><Link to={`/a/${encode(account._id)}`}>{account.title}</Link></div>
        ))}
      </div>

      <Link className="create-additional" to={`/a/create`}>Create Account</Link>

    </div>
  );
};

function mapStateToProps(state) {
  return {
    accounts: state.user.accounts || []
  }
}

export default connect(mapStateToProps)(Accounts);
