import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Modal from './Modal';
import RangeNextSlideInput from './RangeNextSlideInput';

import _ from 'lodash';

const sortedHandles = ['very-unsatisfied','unsatisfied','neutral','satisfied','very-satisfied' ];

const sortedAnswers = [
  { title: 'Very Unsatisfied', handle: 'very-unsatisfied' },
  { title: 'Unsatisfied', handle: 'unsatisfied' },
  { title: 'Neutral', handle: 'neutral' },
  { title: 'Satisfied', handle: 'satisfied' },
  { title: 'Very Satisfied', handle: 'very-satisfied' }
];

export default class SatisfactionNextSlideInput extends RangeNextSlideInput {

  onChange(e) {
    const answers = this.props.answers;

    const rules = this.state.rules || [];
    if (rules.length) {
      answers.forEach((answer) => {
        const match = _.find(rules, (rule) => (rule.handle == answer.handle));
        if (match) {
          answer.nextSlide = match.nextSlide;
        } else {
          answer.nextSlide = undefined;
        }
      });
    } else {
      answers.forEach((answer) => {
        answer.nextSlide = undefined;
      })
    }

    this.props.onChange({ target: { name: 'answers', value: answers } })
  }

  addRule(e) {
    const answer = e.target.value;
    const rules = this.state.rules;

    let idx = 0;
    const sortedRules = _.sortBy(rules, ({ handle }) => sortedHandles.indexOf(handle));

    sortedRules.forEach((rule) => {
      if (rule.handle == sortedAnswers[idx].handle) {
        ++idx;
      }
    });

    rules.push({ nextSlide: this.props.slides[0]._id, ...sortedAnswers[idx] });
    this.setState({ rules });
  }

}