/* User Reducer */
import { 
  CREATED_SLIDE,
  CREATING_SLIDE,
  UPDATED_SLIDE,
  UPDATING_SLIDE,
  FETCHING_SLIDE,
  FETCHED_SLIDE,
  DELETING_SLIDE,
  DELETED_SLIDE,
  RESET_SLIDE_STATE,
} from '../actions/Slides';

export default function slides(state = {}, action) {
  switch (action.type) {
    case CREATING_SLIDE:
    case UPDATING_SLIDE:
    case FETCHING_SLIDE:
    case DELETING_SLIDE:
      return { ...state, loading: true }
    case CREATED_SLIDE:
    case UPDATED_SLIDE:
    case FETCHED_SLIDE:
      const slide = {};
      slide[action.slide._id] = action.slide;
      return { ...state, ...slide, loading: false }
    case RESET_SLIDE_STATE:
      return { ...state, loading: false }
    case DELETED_SLIDE:
      const slides = { ...state };
      delete slides[action.slideId];
      return { ...slides, loading: false }      
    default:
      return state;
  }
}
