import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as AccountActions from '../actions/Accounts';

import ColumnLoading from './ColumnLoading';

import ColumnLayout from '../components/ColumnLayout';
import Breadcrumbs from '../components/Breadcrumbs';
import SingleFormPageHeader from '../components/SingleFormPageHeader';

import ManageAdmin from '../components/ManageAdmin';
import AccountSubscriptionForm from '../components/AccountSubscriptionForm';
import ShopifyAccountSubscriptionForm from '../components/ShopifyAccountSubscriptionForm';

import DeleteConfirm from '../components/DeleteConfirm';

import { decode } from '../utils';

class ManageAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    if (!this.props.account) {
      this.props.fetchAccount();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.accountId !== prevProps.accountId) {
      this.props.fetchAccount();
    }
  }

  render() {
    if (!this.props.account) {
      return (
        <ColumnLoading />
      );
    }

    const { account } = this.props;

    if (account.owner !== this.props.user._id) {
      return (<div>NOT AUTHORIZED</div>);
    }

    let SubscriptionForm = <AccountSubscriptionForm />;
    if (this.props.user.type === 'shopify') {
      SubscriptionForm = <ShopifyAccountSubscriptionForm />;
    }

    let ManagementOptions = (
      <div>
        <div className="card-subtitle">Management Options</div>
        <div className="account-management card">
          <div>
            <label>Delete Account</label>
            <div>
              <div className="subtle">This will erase the survey, its slides, and responses.</div>
              <button
                className="delete-poll"
                onClick={(value) => {
                  this.setState({ showDeleteConfirm: true });
                }}
              >Delete</button>
            </div>
          </div>
        </div>
      </div>
    );

    if (this.props.user.type === 'shopify') {
      ManagementOptions = null;
    }

    return (
      <ColumnLayout
        title={`White Label: ${this.props.account.title}`}
        className="white-label"
      >

        <SingleFormPageHeader
          title="Manage White Label"
          subtitle="Customize the appearance of Zigpoll."
          className="no-margin"
        />

        <div className="card">
          Form Goes Here<br/>
          Settings include:<br/>

          1. Custom Domain name (CNAME) + instructions<br/>
          2. Primary Color<br/>
          3. Logo<br/>
          4. Custom mailer domain name ____@domain.com + instructions<br/>

          I think thats it.

        </div>

      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];
  const user = state.user;

  return {
    accountId,
    accounts,
    account,
    user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(AccountActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageAccount));
