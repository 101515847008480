import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as FlashNotificationActions from '../actions/FlashNotifications';

import copy from 'copy-to-clipboard';

import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import jsx from 'react-syntax-highlighter/dist/languages/prism/jsx';
import style from 'react-syntax-highlighter/dist/styles/prism/tomorrow'; 
SyntaxHighlighter.registerLanguage('jsx', jsx);

class CodeBlock extends Component {
  render() {
    const code = this.props.code;

    return (
      <div className={`embed-code card`} onClick={e => {
        copy(code);
        this.props.flash('The code has been copied to your clipboard.');
      }}>
        <SyntaxHighlighter language={this.props.language} style={style} customStyle={{padding:25, fontSize: 14, borderRadius: 10, overflow: 'hidden'}}>{code}</SyntaxHighlighter>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...FlashNotificationActions }, dispatch);
}

export default connect(null, mapDispatchToProps)(CodeBlock);


