import { 
  FETCHING_REPORT,
  FETCHED_REPORT,
  FETCHING_REPORT_PAGE,
  FETCHED_REPORT_PAGE,
  CLEAR_REPORTS
} from '../actions/Reports';

export default function reports(state = {}, action) {
  let report;

  switch (action.type) {
    case FETCHING_REPORT:
    case FETCHING_REPORT_PAGE:
      return { ...state, loading: true }
    case FETCHED_REPORT:
      report = {};
      report[action.id] = action.report;
      return { ...state, ...report, loading: false }
    case FETCHED_REPORT_PAGE:
      if (action.report.data.length === 0) {
        return { ...state, loading: false };
      }

      report = { [action.id] : action.report };
      let curData = state[action.id].data || [];
      report[action.id].data = [ ...curData, ...report[action.id].data ];
      return { ...state, ...report, loading: false }
    case CLEAR_REPORTS:
      return {};
    default:
      return state;
  }
}
