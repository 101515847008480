import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as FlashNotificationActions from '../actions/FlashNotifications';

import { Editor, EditorState, ContentState, RichUtils, Modifier, CompositeDecorator, CharacterMetadata, SelectionState, BlockMapBuilder } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

import Modal from './Modal';
import CharCount from './CharCount';

import { isUrl } from '../utils';

const getCurrentBlock = (editorState) => {
  const currentSelection = editorState.getSelection();
  const blockKey = currentSelection.getStartKey();
  return(editorState.getCurrentContent().getBlockForKey(blockKey));
}

function blockStyleFn(block, defaultTextAlignment) {
  if (!defaultTextAlignment) { return ''; }

  let alignment = getBlockAlignment(block, defaultTextAlignment);
  if (!block.getText()) {
    let previousBlock = editorState.getCurrentContent().getBlockBefore(block.getKey());
    if (previousBlock) {
      alignment = getBlockAlignment(previousBlock, defaultTextAlignment);
    }
  }
  return `alignment--${alignment}`;
}


function getBlockAlignment(block, defaultTextAlignment) {
  let style = defaultTextAlignment;
  block.findStyleRanges(function(e) {
    if (e.hasStyle('left')) style = 'left';
    if (e.hasStyle('center')) style = 'center';
    if (e.hasStyle('right')) style = 'right';
  })
  return style;
}

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}

const Link = (props) => {
  const {url} = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} style={{ textDecoration: 'underline', color: '#2167f5' }}>
      {props.children}
    </a>
  );
};

let editorState;
class WysiwygInput extends Component {
  constructor(props) {
    super(props);

    const decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link
      },
    ]);

    if (props.value) {
      const contentState = stateFromHTML(props.value);
      this.state = { editorState: EditorState.createWithContent(contentState, decorator) };
    } else {
      this.state = { editorState: EditorState.createEmpty(decorator) };
    }

    this.state.showURLInput = false;
    this.state.urlValue = '';

    this.promptForLink = this._promptForLink.bind(this);
    this.onURLChange = (e) => this.setState({ urlValue: e.target.value });
    this.confirmLink = this._confirmLink.bind(this);
    this.onLinkInputKeyDown = this._onLinkInputKeyDown.bind(this);
    this.removeLink = this._removeLink.bind(this);
    editorState = this.state.editorState;

    const options = {
      blockStyleFn: (block) => {
        if (!this.props.defaultTextAlignment) {
          return;
        }

        let alignment = getBlockAlignment(block, this.props.defaultTextAlignment);
        if (!block.getText()) {
          let previousBlock = editorState.getCurrentContent().getBlockBefore(block.getKey());
          if (previousBlock) {
            alignment = getBlockAlignment(previousBlock, this.props.defaultTextAlignment);
          }
        }
        return { style: { 'text-align': alignment } };
      },
      entityStyleFn: (entity) => {
        const entityType = entity.get('type').toLowerCase();

        if (entityType === 'link') {
          const data = entity.getData();
          return {
            element: 'a',
            attributes: {
              href: data.url,
              target:'_blank',
              rel: 'noreferrer noopener'
            }
          };
        }
      }
    };

    this.onChange = (editorState) => {
      this.setState({ editorState })

      let plainText = editorState.getCurrentContent().getPlainText() || '';
      plainText = plainText.replace(/\s/g, '');

      if (plainText) {
        /* Should probably change this */
        props.onChange({
          target: {
            name: props.name,
            value: stateToHTML(editorState.getCurrentContent(), options)
          }
        });
      } else {
        props.onChange({
          target: {
            name: props.name,
            value: ''
          }
        });        
      }
    };

    this.setEditor = (editor) => {
      this.editor = editor;
    };

    this.focusEditor = () => {
      if (this.editor) {
        this.editor.focus();
      }
    };
  }

  hasLink() {
    const { editorState } = this.state;

    const contentState = editorState.getCurrentContent();
    const startKey = editorState.getSelection().getStartKey();
    const startOffset = editorState.getSelection().getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

    let url = '';
    if (linkKey) {
      const linkInstance = contentState.getEntity(linkKey);
      url = linkInstance.getData().url;
    }
    if (url) {
      return true;
    }

    return false;
  }

  _promptForLink(e) {
    e.preventDefault();
    const { editorState } = this.state;
    const selection = editorState.getSelection();

    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      let url = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }

      this.setState({
        showURLInput: true,
        urlValue: url,
      }, () => {
        setTimeout(() => this.refs.url.focus(), 0);
      });
    }
  }

  _confirmLink(e) {
    e.preventDefault();
    const {editorState, urlValue} = this.state;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      {url: urlValue}
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    this.setState({
      editorState: RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      ),
      showURLInput: false,
      urlValue: '',
    }, () => {
      setTimeout(() => this.editor.focus(), 0);
    });
  }

  _onLinkInputKeyDown(e) {
    if (e.which === 13) {
      if (this.validateUrl()) {    
        this._confirmLink(e);
      }
    }
  }

  _removeLink(e) {
    e.preventDefault();
    const {editorState} = this.state;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      this.setState({
        editorState: RichUtils.toggleLink(editorState, selection, null),
      });
    }
  }
  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  toggleInlineStyle(style) {
    const { editorState } = this.state;

    const editorStateFocused = EditorState.forceSelection(
      editorState,
      editorState.getSelection(),
    );

    this.onChange(RichUtils.toggleInlineStyle(editorStateFocused, style));
  }

  styleWholeSelectedBlocksModifier(editorState, style, removeStyles = []) {
    let currentContent = editorState.getCurrentContent();
    let selection = editorState.getSelection();
    let focusBlock = currentContent.getBlockForKey(selection.getFocusKey());
    let anchorBlock = currentContent.getBlockForKey(selection.getAnchorKey());
    let selectionIsBackward = selection.getIsBackward();

    let changes = {
      anchorOffset: 0,
      focusOffset: focusBlock.getLength()
    }

    if (selectionIsBackward) {
      changes = {
        focusOffset: 0,
        anchorOffset: anchorBlock.getLength()
      }
    }
     let selectWholeBlocks = selection.merge(changes)
     let modifiedContent = Modifier.applyInlineStyle(currentContent, selectWholeBlocks, style);
     let finalContent = removeStyles.reduce(function(content, style) {
        return Modifier.removeInlineStyle(content, selectWholeBlocks, style);
     }, modifiedContent);
     return this.onChange(EditorState.push(editorState, finalContent, 'change-inline-style'));
  }

  getLengthOfSelectedText() {
    const currentSelection = this.state.editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = this.state.editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);

      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        };
      }
    }

    return length;
  }

  handleBeforeInput() {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = this.getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > this.props.maxlength - 1) {
      // console.log('you can type max ten characters');
      return 'handled';
    }
  }

  handlePastedText(pastedText) {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = this.getLengthOfSelectedText();

    if (currentContentLength + pastedText.length - selectedTextLength > this.props.maxlength) {
      // console.log('you can type max ten characters');
      const remaining = this.props.maxlength - currentContentLength;
      const blockMap = ContentState.createFromText(pastedText.slice(0, remaining)).blockMap;
      const newState = Modifier.replaceWithFragment(this.state.editorState.getCurrentContent(), this.state.editorState.getSelection(), blockMap);
        this.onChange(EditorState.push(this.state.editorState, newState, 'insert-fragment'));

        return 'handled';
    }
  }

  validateUrl() {
    const { urlValue } = this.state;
    if (!isUrl(urlValue)) {
      if (urlValue.indexOf('http') !== 1) {
        this.props.flash('Please include http:// or https:// in the url');
      } else {
        this.props.flash('Please enter a valid url');
      }
      return false;
    }
    return true;
  }

  render() {
    let optional = null;
    if (this.props.optional) {
      optional = <span>(Optional)</span>
    }

    const inlineStyle = this.state.editorState.getCurrentInlineStyle();

    const isBold = inlineStyle.has('BOLD');
    const isItalic = inlineStyle.has('ITALIC');
    const isUnderline = inlineStyle.has('UNDERLINE');

    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;

    const currentBlock = getCurrentBlock(this.state.editorState);
    const alignment = blockStyleFn(currentBlock, this.props.defaultTextAlignment).replace('alignment--', '');
    let alignmentTools = [];
    if (this.props.defaultTextAlignment) {
      alignmentTools = [
        <div className="divider"></div>,
        <span className={`text-align-left ${alignment === 'left' ? 'active' : ''}`} onClick={() => {
          this.styleWholeSelectedBlocksModifier(this.state.editorState, 'left', ['center', 'right'])
        }} />,
        <span className={`text-align-center ${alignment === 'center' ? 'active' : ''}`} onClick={() => {
          this.styleWholeSelectedBlocksModifier(this.state.editorState, 'center', ['left', 'right'])
        }} />,
        <span className={`text-align-right ${alignment === 'right' ? 'active' : ''}`} onClick={() => {
          this.styleWholeSelectedBlocksModifier(this.state.editorState, 'right', ['left', 'center'])
        }} />
      ];
    }
    return (
      <div className={`input wysiwyg ${this.props.readOnly ? 'read-only' : ''} ${this.props.optional ? 'optional' : ''}`}>
        <label>{ this.props.label }{ optional }</label>

        <div className="toolbar">
          <span className={`bold ${isBold ? 'active' : ''}`} onClick={() => this.toggleInlineStyle('BOLD')} />
          <span className={`italic ${isItalic ? 'active' : ''}`} onClick={() => this.toggleInlineStyle('ITALIC')} />
          <span className={`underline ${isUnderline ? 'active' : ''}`} onClick={() => this.toggleInlineStyle('UNDERLINE')} />

          { !this.props.hideLinkInput && <span
            title="Add Link"
            className={`${this.hasLink() ? 'active' : ''} add-link`}
            onMouseDown={this.promptForLink} /> }
          { !this.props.hideLinkInput && <span
            title="Remove Link"
            className="remove-link"
            onMouseDown={this.removeLink} /> }

          { alignmentTools.map((tool) => tool) }

        </div>

        <Editor
          ref={this.setEditor}
          editorState={this.state.editorState}
          handleKeyCommand={this.handleKeyCommand}
          handleBeforeInput={this.handleBeforeInput.bind(this)}
          handlePastedText={this.handlePastedText.bind(this)}
          placeholder={this.props.placeholder}
          onChange={this.onChange}
          readOnly={this.props.readOnly}
          blockStyleFn={(block) => {
            return blockStyleFn(block, this.props.defaultTextAlignment);
          }}
        />

        <CharCount 
          length={currentContentLength}
          maxlength={this.props.maxlength}
        />

        <Modal
          isOpen={this.state.showURLInput}
          onRequestClose={() => { this.setState({ showURLInput: false, urlValue: '' }) }}
        >
          <div className="frame" style={{width: 400}}>
            <div className="close" onClick={() => { this.setState({ showURLInput: false, urlValue: '' }) }} />
            <div className="title">Please enter a URL</div>
            <div className="content">
              <input
                onChange={this.onURLChange}
                ref="url"
                type="text"
                placeholder="https://www.example.com"
                value={this.state.urlValue}
                onKeyDown={this.onLinkInputKeyDown}
              />

              <div className="actions">
                <button className="positive" onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  if (this.validateUrl()) {
                    this.confirmLink(e);
                    this.setState({ showURLInput: false, urlValue: '' });
                  }
                }}>Save</button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...FlashNotificationActions }, dispatch);
}

export default connect(null, mapDispatchToProps)(WysiwygInput);

