import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ColumnLoading from './ColumnLoading';

import ColumnLayout from '../components/ColumnLayout';
import Breadcrumbs from '../components/Breadcrumbs';
import SingleFormPageHeader from '../components/SingleFormPageHeader';
import SlideInfo from '../components/SlideInfo';
import Pagination from '../components/Pagination';
import Email from '../components/Email';
import HeaderButtons from '../components/HeaderButtons';
import SectionHeader from '../components/SectionHeader';

import * as EmailsActions from '../actions/Emails';

import { encode, decode, getDate } from '../utils';

class Slide extends Component {
  render() {
    const slide = this.props;

    return (<div className='poll-card slide-dashboard'>
      <div>
        <div className="poll-content">
          <SlideInfo diffs={this.props.diffs} slide={slide} hideLinks={true} />
        </div>
      </div>
    </div>)
  }
}

class Emails extends Component {
  constructor(props) {
    super(props);
    props.fetchSlideAndEmails();
  }

  export() {
    this.props.exportEmails();
  }

  render() {

    if (!this.props.emails || !this.props.slide) {
      return <ColumnLoading />
    }

    let results = null;
    if (this.props.emails.data.length) {
      results = (
        <span>
          <div className="responses-header">
            <button
              onClick={this.export.bind(this)}
            >Download CSV</button>
            <Pagination
              showPagination={this.props.emails.pages}
              showNext={this.props.emails.page !== this.props.emails.pages}
              showPrev={this.props.emails.page !== 0}
              next={() => { this.props.fetchEmails(this.props.emails.page + 1); }}
              prev={() => { this.props.fetchEmails(this.props.emails.page - 1); }}
              curPage={this.props.emails.page + 1 }
              pageLength={this.props.emails.pages + 1}
            />
          </div>
          <div className={`card object-table emails ${this.props.loading ? 'loading' : ''}`}>
            {this.props.emails.data.map((email) => <Email {...email} url={`/participants/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(this.props.slideId)}/pa/${encode(email.participant._id)}`} />)}
          </div>
        </span>
      );
    } else {
      results = (
        <div className="card empty-object-list emails">
          <h3>No Emails Yet</h3>
          <p>A list will show once someone has submitted their email.</p>
        </div>
      );
    }

    return (
      <ColumnLayout
        title={`Slide → Email Addresses`}
        className="emails"
        graphics={true}
      >

      {/*
        <Slide {...this.props.slide} diffs={this.props.diffs} />

        <div className="heading below">
          <h5>Collected Emails</h5>
        </div>
      */}

        <SectionHeader
          title=<span><i className="fas fa-at" />Slide Email Addresses</span>
          subtitle="All email addresses captured by this slide."
          className="no-margin"
        />

        { results }

        <div className="below-card-info"><Link to={`/emails/a/${encode(this.props.accountId)}`}>Click here</Link> to see all emails you've ever captured on this account.</div>

        <HeaderButtons>
          <button
            onClick={this.export.bind(this)}
            disabled={this.props.emails.data.length ? false : true}
          >Download CSV</button>
        </HeaderButtons>

      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const pollId = decode(ownProps.match.params.pollId);
  const slideId = decode(ownProps.match.params.slideId);

  const allEmails = state.emails || [];
  const emails = allEmails[slideId];

  const slide = state.slides[slideId];

  return {
    pollId,
    slideId,
    accountId,
    emails,
    slide,
    diffs: state.diffs,
    loading: state.emails.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(EmailsActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Emails));
