import React, { Component } from 'react';

export default class CharCount extends Component {
  render() {
    if (!this.props.maxlength) {
      return null;
    }

    const remaining = this.props.maxlength - this.props.length;
    let content = <div><span>{remaining}</span> characters remaining.</div>;

    if (remaining === 1) {
      content = <div><span>{remaining}</span> character remaining.</div>
    }

    return <div className="char-count">
      { content }
    </div>
  }
}
