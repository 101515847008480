import React from 'react';

const SingleFormPageHeader = (props) => {
  let subtext = null;
  if (props.subtext) {
    subtext = <h6>{ props.subtext }</h6>
  }

  return (
    <div className={`heading ${props.className}`}>
      <h2>{ props.title }</h2>
      <h5>{ props.subtitle }</h5>
      { subtext }
    </div>
  );
}

export default SingleFormPageHeader;