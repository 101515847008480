import download from 'downloadjs';

import { replace } from 'connected-react-router';
import { pushServerError } from './Notifications';
import { flash } from './FlashNotifications';

import { post } from '../ajax';
import { encode, parseUrl, generateFilename } from '../utils';
import { url } from '../settings';

import { fetchAccount } from './Accounts';
import { fetchPoll } from './Polls';
import { fetchSlide } from './Slides';

export const FETCHING_PARTICIPANTS = 'FETCHING_PARTICIPANTS';
export const FETCHED_PARTICIPANTS = 'FETCHED_PARTICIPANTS';

export const FETCHING_PARTICIPANT_DATA = 'FETCHING_PARTICIPANT_DATA';
export const FETCHED_PARTICIPANT_DATA = 'FETCHED_PARTICIPANT_DATA';

export const FETCHING_EVENTS = 'FETCHING_EVENTS';
export const FETCHED_EVENTS = 'FETCHED_EVENTS';

function fetchingParticipants(id) {
  return {
    type: FETCHING_PARTICIPANTS,
    id
  };
}

function fetchedParticipants(id, json) {
  return {
    type: FETCHED_PARTICIPANTS,
    id,
    participants: json
  };
}

function fetchingEvents(id) {
  return {
    type: FETCHING_EVENTS,
    id
  };
}

function fetchedEvents(id, json) {
  return {
    type: FETCHED_EVENTS,
    id,
    events: json
  };
}

function fetchingParticipantData(id, key) {
  return {
    type: FETCHING_PARTICIPANT_DATA,
    id,
    key
  };
}

function fetchedParticipantData(id, key, data) {
  return {
    type: FETCHED_PARTICIPANT_DATA,
    id,
    key,
    data
  };
}


export function fetchSlideAndParticipants() {
  return (dispatch, getState) => {
    return dispatch(fetchSlide())
      .then(dispatch(fetchParticipantsForSlide(0)));
  }
}

export function fetchParticipantsForSlide(page = 0, handle) {
  return (dispatch, getState) => {

    const [ accountId, pollId, slideId ] = parseUrl(getState().router);

    let endpoint = `${url}/account/${accountId}/poll/${pollId}/slide/${slideId}/participants/getPage/${page}`;
    if (handle) {
      endpoint = `${url}/account/${accountId}/poll/${pollId}/slide/${slideId}/participants/getPage/${page}/filter/${encodeURIComponent(handle)}`
    }

    dispatch(fetchingParticipants(slideId));
    return post(endpoint)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(fetchedParticipants(slideId, json));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function fetchPollAndParticipants() {
  return (dispatch, getState) => {
    return dispatch(fetchPoll())
      .then(dispatch(fetchParticipantsForPoll(0)));
  }
}

export function fetchParticipantsForPoll(page = 0) {
  return (dispatch, getState) => {

    const [ accountId, pollId ] = parseUrl(getState().router);

    dispatch(fetchingParticipants(pollId));
    return post(`${url}/account/${accountId}/poll/${pollId}/participants/getPage/${page}`)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(fetchedParticipants(pollId, json));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function fetchAccountAndParticipants() {
  return (dispatch, getState) => {
    return dispatch(fetchAccount())
      .then(dispatch(fetchParticipantsForAccount(0)));
  }
}

export function fetchParticipantsForAccount(page = 0) {
  return (dispatch, getState) => {

    const [ accountId ] = parseUrl(getState().router);

    dispatch(fetchingParticipants(accountId));
    return post(`${url}/account/${accountId}/participants/getPage/${page}`)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(fetchedParticipants(accountId, json));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function fetchParticipant(participantId, useId) {
  return (dispatch, getState) => {

    const [ accountId ] = parseUrl(getState().router);

    dispatch(fetchingParticipants(participantId));
    return post(`${url}/account/${accountId}/participant/${participantId}`, { useId })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        if (useId) {
          let url = window.location.pathname;
          url = url.split('/pa/')[0];
          dispatch(replace(`${url}/pa/${encode(json._id)}`));
        }
        dispatch(fetchedParticipants(json._id, json));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function fetchEvents(participantId, page = 0) {
  return (dispatch, getState) => {

    const [ accountId ] = parseUrl(getState().router);

    dispatch(fetchingEvents(participantId));
    return post(`${url}/account/${accountId}/participant/${participantId}/events/${page}`)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(fetchedEvents(participantId, json));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function fetchShopifyCustomerData(participant, query) {
  return (dispatch, getState) => {

    const [ accountId ] = parseUrl(getState().router);

    const { metadata } = participant.metadata

    dispatch(fetchingParticipantData(participant._id));
    return post(`${url}/shopify/customers/search`, { accountId, query })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        if (Object.keys(json).length) {
          dispatch(fetchedParticipantData(participant._id, 'shopifyData', json));
        }
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function fetchShopifyOrderData(participant, orderId) {
  return (dispatch, getState) => {

    const [ accountId ] = parseUrl(getState().router);

    dispatch(fetchingParticipantData(participant._id));
    return post(`${url}/shopify/orders/search`, { accountId, orderId })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        if (Object.keys(json).length) {
          dispatch(fetchedParticipantData(participant._id, orderId, json));
        }
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function exportAccountParticipants(email, responsePollId, dateRange) {
  return (dispatch, getState) => {

    const [ accountId ] = parseUrl(getState().router);

    // dispatch(fetchingEmails(slideId));
    return post(`${url}/account/${accountId}/participants/export`, { email, responsePollId, dateRange, timezoneOffset: new Date().getTimezoneOffset() })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json();
    }).then((json) => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(flash('Export process initiated. Please wait and check your email.'));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

// export function exportAccountParticipants() {
//   return (dispatch, getState) => {

//     const [ accountId ] = parseUrl(getState().router);

//     // dispatch(fetchingEmails(slideId));
//     return post(`${url}/account/${accountId}/participants/export`)
//     .then(response => {
//       if (response.status === 404) {
//         dispatch(replace('/404'));
//       }

//       return response.blob();
//     }).then((blob) => {
//       download(blob, generateFilename('Account Participants Export'), 'text/csv');
//       dispatch(flash('Successfully downloaded participants.'));
//     })
//     .catch(error => dispatch(pushServerError(error)))
//   };
// }

export function exportPollParticipants(email, responsePollId, dateRange, sortBy, columns) {
  return (dispatch, getState) => {

    let [ accountId, pollId ] = parseUrl(getState().router);

    if (!pollId) {
      pollId = responsePollId;
    }

    if (!pollId) {
      dispatch(flash('Please select a poll'));
      return;
    }

    // dispatch(fetchingEmails(slideId));
    return post(`${url}/account/${accountId}/poll/${pollId}/participants/export`, { email, responsePollId, dateRange, sortBy, columns, timezoneOffset: new Date().getTimezoneOffset() })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json();
    }).then((json) => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(flash('Export process initiated. Please wait and check your email.'));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function exportSlideParticipants(email, responsePollId, dateRange) {
  return (dispatch, getState) => {

    const [ accountId, pollId, slideId ] = parseUrl(getState().router);

    // dispatch(fetchingEmails(slideId));
    return post(`${url}/account/${accountId}/poll/${pollId}/slide/${slideId}/participants/export`, { email, responsePollId, dateRange, timezoneOffset: new Date().getTimezoneOffset() })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json();
    }).then((json) => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(flash('Export process initiated. Please wait and check your email.'));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function submitTags(tags, participant) {
  return (dispatch, getState) => {
    const [ accountId ] = parseUrl(getState().router);
    const { _id } = participant;

    return post(`${url}/account/${accountId}/addTags`, { tags, participantId: _id })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(flash(json.error));
      } else {
        console.log('done');
        console.log(json);
      }
    })
    .catch(error => { dispatch(flash(error)); })
  };
}

export function deleteParticipant(participantId) {
  return (dispatch, getState) => {
    const [ accountId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/participant/${participantId}/delete`)
    .then(response => {
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(flash(json.error));
      } else {
        dispatch(flash('The participant has been deleted'));
        dispatch(replace(`/participants/a/${encode(accountId)}`));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function deleteParticipantResponses(participantId, eventIds) {
  return (dispatch, getState) => {
    const [ accountId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/participant/${participantId}/delete-responses`, { eventIds })
    .then(response => {
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(flash(json.error));
      } else {
        dispatch(flash('The events have been deleted'));
        // dispatch(replace(`/participants/a/${encode(accountId)}`));
        // window.location.reload();
        dispatch(fetchParticipant(participantId, true));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}
