import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import _ from 'lodash';

import Tooltip from './Tooltip';
import Toggle from './Toggle';
import Switch from './Switch';

import { NameInput } from './SimpleInputs';

import * as FlashNotificationsActions from '../actions/FlashNotifications';

import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'

const numbers = [2,3,4,5,6,7,8,9,10];

class OptionsInput extends Component {
  onChange(e, idx) {
    e.preventDefault();
    e.stopPropagation();

    const options = [ ...this.props.options ];
    options[idx] = e.target.value;

    this.props.onChange({ target: { name: this.props.name, value: options } });
  }

  addOption() {
    let options = [ ...this.props.options, '' ];
    if (this.props.isBinary && options.length > 2) {
      options = options.slice(0, 2);
      this.props.flash('Only two options allowed for binary questions');
    }
    this.props.onChange({ target: { name: this.props.name, value: options } });
  }

  removeOption(idx) {
    const options = [ ...this.props.options ]
    options.splice(idx, 1);
    this.props.onChange({ target: { name: this.props.name, value: options } });
  }

  render() {
    return (<div className="form-options">
      <label>Options</label>

      { this.props.options.map((option, idx) => 
        <div className="option-input" key={idx}>
          <input 
            value={option}
            type="text"
            placeholder={`Option #${idx + 1}`}
            onChange={(e) => this.onChange(e, idx)}
          />
          <div className="remove-option" onClick={() => {
            this.removeOption(idx);
          }} />
        </div>
      ) }
      <div className="add-option" onClick={this.addOption.bind(this)}>Add Option</div>

    </div>)
  }
}

function getPlaceholder(type) {
  if (type === 'phone-number') {
    return "Ex. Enter phone number";
  }
  if (type === 'select') {
    return "Ex. Select one";
  }
  if (type === 'country') {
    return "Ex. Select your country";
  }
  if (type === 'email-capture') {
    return 'Ex. Enter email';
  }

  return '';
}

const skipPlaceholder = [
'radio',
'checkbox',
'binary',
'range',
'satisfaction',
'star-rating',
'date',
]

const includeLabels = [
'range',
'satisfaction',
'star-rating'
]

class FormInput extends Component {
  constructor(props) {
    super(props);
    if (this.props.type == 'range') {
      this.state = { min: 1, max: 5 };
    } else {
      this.state = {};
    }
  }

  onChange(e) {
    const name = e.target.name.split('-').shift();
    const value = e.target.value;
    this.props.onChange(this.props.idx, name, value);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.type !== prevProps.type) {
      if (this.props.type === 'range') {
        this.setState({ min: 1, max: 5 });
      }
      if (prevProps.type === 'range') {
        this.setState({ min: undefined, max: undefined });
      }
    }

    if (this.state.min !== prevState.min || this.state.max !== prevState.max) {
      let options = [];
      if (this.state.min !== undefined && this.state.max !== undefined) {
        options = [];
        for (let i = this.state.min; i <= this.state.max; i++) {
          options.push(i);
        }
      }

      this.props.onChange(this.props.idx, 'options', options);
    }

    if (this.props.type !== prevProps.type) {
      if (this.props.type === 'binary') {
        this.props.onChange(this.props.idx, 'options', ['Option 1', 'Option 2']);
      }
    }
  }

  onValidate() {

  }

  render() {
    let options = null;
    if (this.props.type === 'radio' || this.props.type === 'select' || this.props.type === 'checkbox' || this.props.type === 'binary') {
      options = (
        <OptionsInput 
          flash={this.props.flash}
          onChange={this.onChange.bind(this)}
          options={this.props.options || []}
          name={`options-${this.props.idx}`}
          isBinary={this.props.type === 'binary'}
        />
      );
    }

    let placeholderInput = (
      <NameInput
        onChange={this.onChange.bind(this)}
        onValidate={this.onValidate.bind(this)}
        label={`placeholder`}
        type="text"
        placeholder={getPlaceholder(this.props.type)}
        name={`placeholder-${this.props.idx}`}
        value={this.props.placeholder}
        errorMessage="Please enter a label for the input."
        ref={this.props.setRef.bind(this)}
        optional={true}
        // maxlength={40}
      />
    );

    if (skipPlaceholder.indexOf(this.props.type) !== -1) {
      placeholderInput = null;
    }

    let labelsInput = (<div className="form-labels-input">
      <NameInput
        onChange={(e) => {
          const value = e.target.value;
          let labels = this.props.labels || [];
          if (labels[1]) {
            labels[1] = labels[1];
          } else {
            labels[1] = '';
          }
          labels[0] = value;

          if (!labels[0] && !labels[1]) {
            labels = undefined;
          }

          this.props.onChange(this.props.idx, 'labels', labels);
        }}
        onValidate={this.onValidate.bind(this)}
        label={`Left Label`}
        type="text"
        placeholder={'Ex. Very Unsatisfied'}
        name={`left-label-${this.props.idx}`}
        value={this.props.labels ? this.props.labels[0] : ''}
        errorMessage="Please enter a value for the input."
        ref={this.props.setRef.bind(this)}
        optional={true}
        // maxlength={40}
      />
      <NameInput
        onChange={(e) => {
          const value = e.target.value;
          let labels = this.props.labels || [];
          if (labels[0]) {
            labels[0] = labels[0];
          } else {
            labels[0] = '';
          }
          labels[1] = value;

          if (!labels[0] && !labels[1]) {
            labels = undefined;
          }

          this.props.onChange(this.props.idx, 'labels', labels);
        }}
        onValidate={this.onValidate.bind(this)}
        label={`Right Label`}
        type="text"
        placeholder={'Ex. Very Satisfied'}
        name={`right-label-${this.props.idx}`}
        value={this.props.labels ? this.props.labels[1] : ''}
        errorMessage="Please enter a value for the input."
        ref={this.props.setRef.bind(this)}
        optional={true}
        // maxlength={40}
      />
    </div>);

    if (includeLabels.indexOf(this.props.type) === -1) {
      labelsInput = null;
    }

    let rangeSelector = null;
    if (this.props.type === 'range') {
      rangeSelector = (<div className="form-range-input-box">
        <div className="input unchanged">
          <div>
            From <select
              onChange={(e) => this.setState({ min: e.target.value })}
            >{ [0, 1].map((val) => {
              return <option selected={val === this.state.min}>{val}</option>
            }) }</select> to <select
              onChange={(e) => this.setState({ max: e.target.value })}
            >{ numbers.map((val) => {
              return <option selected={val === this.state.max}>{val}</option>
            }) }</select>
          </div>
        </div>
      </div>)
    }

    return (<div className="form-input" style={{ marginBottom: 25 }} key={this.props.idx}>

      <div className="card-divider">
        <h6>Form Input #{this.props.idx + 1}</h6>
      </div>

      <div className="top">
        <div>
          <label>Type</label>

          <div className="select-wrapper"><select name="type" value={this.props.type} onChange={this.onChange.bind(this)}>
            <option value="short-answer">Short Answer</option>
            <option value="long-answer">Long Answer</option>
            <option value="email-capture">Email</option>
            <option value="select">Dropdown</option>
            <option value="radio">Radio Buttons</option>
            <option value="checkbox">Checkbox</option>
            <option value="phone-number">Phone Number</option>
            <option value="binary">Binary</option>
            <option value="range">Range</option>
            <option value="satisfaction">Satisfaction</option>
            <option value="star-rating">Star Rating</option>
            <option value="date">Date</option>
            <option value="country">Country</option>
          </select></div>
        </div>

        <div>
          <label>Priority</label>
          <Switch
            active={this.props.optional}
            options={[
              { label: 'Required', value: false},
              { label: 'Optional', value: true}
            ]}
            value={this.props.optional}
            onChange={(value) => {
              this.onChange({ target: { name: 'optional', 'value': value }});
            }}
          />
        </div>
      </div>

      { this.props.type === 'phone-number' ? <div>

        <label>Country</label>
        <div className="select-wrapper"><select
          value={this.props.country}
          name="country"
          onChange={this.onChange.bind(this)}>
          <option value="">
            {en['ZZ']}
          </option>
          {getCountries().map((country) => (
            <option key={country} value={country}>
              {en[country]} +{getCountryCallingCode(country)}
            </option>
          ))}
        </select></div>

      </div> : null}

      <div>
        <NameInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label={`title`}
          type="text"
          placeholder="Public facing label for the input"
          name={`label-${this.props.idx}`}
          value={this.props.label}
          errorMessage="Please enter a label for the input."
          ref={this.props.setRef.bind(this)}
          // maxlength={40}
        />

        { placeholderInput }

        { options }
      </div>

      { rangeSelector }

      { labelsInput }

      <div className="remove" onClick={() => {
        this.props.removeFormInput(this.props.idx);
      }} />
    </div>);
  }
}

class FormInputs extends Component {
  constructor(props) {
    super(props);
    // this.state = { formInputs: props.formInputs || [] };
  }

  onChange(idx, name, value) {
    const formInputs = [...this.props.formInputs]
    formInputs[idx][name] = value;

    this.props.onChange({ target: { name: 'formInputs', value: formInputs } })
  }

  addFormInput() {
    let formInputs = this.props.formInputs;
    let blank = { label: `Input #${formInputs.length + 1}`, type: 'short-answer', country: 'US', options: [], optional: false, labels: undefined };
    formInputs.push(blank);
    this.props.onChange({ target: { name: 'formInputs', value: formInputs } });
  }

  removeFormInput(idx) {
    let formInputs = [ ...this.props.formInputs ];
    formInputs.splice(idx, 1);
    this.props.removeRef(`label-${formInputs.length}`);
    this.props.onChange({ target: { name: 'formInputs', value: formInputs } })
  }

  render() {
    let inputs = (<div>
      { this.props.formInputs.map((formInput, idx) =>
        <FormInput
          onChange={this.onChange.bind(this)}
          removeFormInput={this.removeFormInput.bind(this)}
          idx={idx}
          setRef={this.props.setRef.bind(this)}
          flash={this.props.flash}
          {...formInput } />
      ) }

      <div className="card-divider" style={{ marginBottom: 17, marginTop: this.props.formInputs.length ? 30 : 10 }}>
        <h6></h6>
      </div>

      <div className="add-form-input" onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        this.addFormInput();
      }}>Add Input</div>

    </div>)

    return (
      <div style={{ marginBottom: 25 }} className="form-inputs card">
        {/*
        <div className="input unchanged">
          <label>Form Inputs <Tooltip>You can add different types of inputs to your form. Add as many as you need and label them however you want.</Tooltip></label>
        </div>
        */}

        { inputs }

      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(FlashNotificationsActions, dispatch);
}

export default connect(null, mapDispatchToProps, null, { withRef: true })(FormInputs);
