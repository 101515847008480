import React, { Component } from 'react';
import SlideBankModal from './SlideBankModal';

export default class SlideBankButton extends Component {
  constructor(props) {
    super(props);
    this.state = { active: false }
  }

  render() {
    return (<span>
      <button
        className="secondary"
        onClick={() => this.setState({ active: true })}
      ><i className="fas fa-piggy-bank" style={{marginRight: 8}} /> Question Bank</button>
      <SlideBankModal
        active={this.state.active}
        skipRedirect={this.props.skipRedirect}
        onClose={() => this.setState({ active: false })}
      />
    </span>)
  }
}