import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import * as PollActions from '../actions/Polls';
import Dropdown from './Dropdown';

import { connect } from 'react-redux';

class SlidesDropdown extends Dropdown {
  constructor(props) {
    super(props);
    this.state = { active: false };
    this.icon = 'fa-poll-h';
    this.title = 'Slide';
  }

  fetchItems() {
    this.props.fetchPoll();
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PollActions }, dispatch);
}

export default withRouter(connect(undefined, mapDispatchToProps)(SlidesDropdown));
