import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import qs from 'query-string';

import { EmailInput } from './SimpleInputs';

import SectionHeader from './SectionHeader';
import AffiliateLink from './AffiliateLink';
import ShopifyAffiliateLink from './ShopifyAffiliateLink';

import * as UserActions from '../actions/Users';
import * as FlashNotificationActions from '../actions/FlashNotifications';

import { encode, decode, validateEmail } from '../utils';
import { url, stripeConnectId } from '../settings';

import paypalLogo from '../images/paypalLogo.png';
import stripeLogo from '../images/stripeLogo.png';

import stripeButton from '../images/stripe-button-grey/light-on-dark@3x.png';

class AffiliateSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payoutService: !this.props.user.paypalEmail && this.props.user.stripeConnectId ? 'stripe' : 'paypal',
      paypalEmail: this.props.user.paypalEmail
    };
  }

  submitPaypalInfo() {
    if (!this.state.paypalEmail) {
      // this.props.flash('Please enter a valid email address');
      this.props.setPaypalEmail(null);
      return;
    }

    if (!validateEmail(this.state.paypalEmail)) {
      this.props.flash('Please enter valid email address');
      return
    }

    this.props.setPaypalEmail(this.state.paypalEmail);
  }

  render() {
    // if (!this.props.user.stripeConnectId) {
      const user = this.props.user;

      const params = {     
        redirect_uri: `https:${url}/stripe-connect/oauth`,
        client_id: stripeConnectId,
        'stripe_user[email]': this.props.user.email || undefined,
        'stripe_user[business_type]': 'individual',
        'stripe_user[product_description]': 'Affiliate Services'
      }

      // return (
      // <span>
      //   <SectionHeader
      //     title=<span><i className="fas fa-comments-dollar" />Affiliate Settings</span>
      //     subtitle="Follow the steps below and join our affiliate program."
      //     tooltip="You need to connect with stripe before participating in our affiliate program. This is required so that we can issue payments to you."
      //   />

      //   <div className="card affiliate-card">
      //     <label>Connect with stripe</label>

      //     <p className="subtle">Click the link below to create your Stripe account.</p>
      //     <a href={`https://connect.stripe.com/express/oauth/authorize?${qs.stringify(params)}`}><img style={{ maxWidth: '200px', marginBottom: 10, marginTop: 10 }} src={stripeButton} /></a>
      //     <p className="subtle">Once completed check back here for your referral link. You can use this link to get credit for any customer you get to sign up for Zigpoll. You will get <strong>30% revenue share</strong> for any accounts that convert to a paid plan.</p>
      //     <p className="subtle">Still have questions? <a href="https://docs.zigpoll.com/affiliate-program" target="_blank">Learn More</a>.</p>
      //   </div>
      // </span>);
    // }

    let link = '/affiliate';
    if (this.props.accountId) {
      link += `/a/${encode(this.props.accountId)}`;
    }
    if (this.props.pollId) {
      link += `/p/${encode(this.props.pollId)}`;
    }
    if (this.props.slideId) {
      link += `/s/${encode(this.props.slideId)}`;
    }

    let connection = (
      <div className="connect-block">
        <label>Connect with Stripe</label>

        <p className="subtle">Click the link below to create your Stripe account.</p>
        <a href={`https://connect.stripe.com/express/oauth/authorize?${qs.stringify(params)}`}><img style={{ maxWidth: '200px', marginBottom: 10, marginTop: 10 }} src={stripeButton} /></a>
      </div>
    );

    if (this.props.user.stripeConnectId) {
      connection = (
      <div className="connect-block" style={{marginTop: 0}}>
        <p className="subtle"><i className="fas fa-check" />You are successfully connected with Stripe. You will recieve a payout to this account on the 28th of each month.</p>
      </div>
      );
    }

    if (this.state.payoutService === 'paypal') {
      connection = (
      <div className="connect-block">
        <p className="subtle">Enter your PayPal email below</p>
        <form onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          this.submitPaypalInfo();
        }}>
          <input
            onChange={(e) => {
              this.setState({ paypalEmail: e.target.value });
            }}
            onValidate={() => {
              console.log('validate');
            }}
            type="email"
            placeholder="name@company.com"
            name="email"
            value={this.state.paypalEmail}
          />

          <button type="submit" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.submitPaypalInfo();
          }}>Save</button>
        </form>
      </div>
      )
    }

    let paymentSelector = <div>
        <div className="selector">
          <div
            className={`payment-option ${this.state.payoutService === 'paypal' ? 'active' : '' }`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.setState({ payoutService: 'paypal' })
            }}
          ><img src={paypalLogo} /></div>
          <div
            className={`payment-option ${this.state.payoutService === 'stripe' ? 'active' : '' }`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.setState({ payoutService: 'stripe' })
            }}
          ><img src={stripeLogo} /></div>
        </div>

        { connection }
    </div>
    if (this.props.isCard) {
      paymentSelector = null;
    }

    return (<span>
        { this.props.isCard && <SectionHeader
          title=<span><i className="fas fa-comments-dollar" />Affiliate Settings</span>
          subtitle="Sign up and start getting paid."
          tooltip="If you refer someone to Zigpoll and they create a paid account, we will send you 30% of the payment each month as long as the account stays valid."
        /> }

        <div className="card affiliate-card">
          { paymentSelector }

          <AffiliateLink />
          <ShopifyAffiliateLink />

          <div className="links">
            { this.props.isCard && <div><Link to={link}>View your Affiliate Details</Link></div> }
            <div><a href="https://docs.zigpoll.com/affiliate-program" target="_blank" rel="noopener noreferrer" className="learn-more">Learn More About Our Affiliate Program</a></div>
          </div>
        </div>
    </span>)
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const pollId = decode(ownProps.match.params.pollId);
  const slideId = decode(ownProps.match.params.slideId);

  return {
    accountId,
    pollId,
    slideId,
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...FlashNotificationActions, ...UserActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AffiliateSettings))
