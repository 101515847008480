import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import CharCount from './CharCount';
import Toggle from './Toggle';
import Switch from './Switch';
import Tooltip from './Tooltip';

import brace from "brace";
import AceEditor from "react-ace";

import "brace/mode/css";
import "brace/theme/monokai";

export class NameInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      valid: undefined,
      invalid: undefined,
    };
  }

  reset() {
    this.setState({ valid: undefined, invalid: undefined });
  }

  setValid() {
    this.setState({ valid: true, invalid: false });
  }

  setInvalid() {
    this.setState({ invalid: true, valid: false });
  }

  validate() {
    if (this.props.noValidate) { return true; }

    if (this.props.optional) {
      this.props.onValidate(this.props.name, true);
      if (this.props.value) {
        this.setValid();
      } else {
        this.reset();
      }
      return true;
    }

    if (this.props.value) {
      this.setValid();
      this.props.onValidate(this.props.name, true);
      return true;
    } else {
      this.setInvalid();
      this.props.onValidate(this.props.name, false);
      return false;
    }
  }

  onChange(e) {
    if (this.interacted === undefined) {
      this.interacted = true;
      this.reset();
    }
    this.props.onChange(e);
  }

  onFocus(e) {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  onKeyPress(e) {
    if (e.key === 'Enter') {
      this.validate();
    }
  }

  getOptional() {
    let optional = null;
    if (this.props.optional) {
      optional = <span>(Optional)</span>
    }
    return optional;
  }

  getClassName() {
    let className = '';
    if (this.state.invalid && this.interacted) {
      className = 'invalid' 
    } 
    if (this.state.valid && this.interacted) {
      className = 'valid';
    }
    if (!this.interacted) {
      if (this.props.optional) {
        className = 'unchanged';
      } else if (this.props.value) {
        className = 'unchanged';
      }
    }

    return className;
  }

  render() {
    const optional = this.getOptional();
    const className = this.getClassName();

    let charCount = null;
    if (this.props.maxlength) {
      charCount = <CharCount
        length={this.props.value ? this.props.value.length : 0}
        maxlength={this.props.maxlength}
      />
    }

    let tooltip = null;
    if (this.props.moreInfo) {
      tooltip = <Tooltip bottom={this.props.bottom}>{ this.props.moreInfo }</Tooltip>
    }

    return (
      <div className={`input ${className}`} style={this.props.style || {}}>
        <div style={{ position: 'relative' }}>
          { !this.props.hideLabel && <label>{ this.props.label }{ tooltip }{ optional }</label> }
          <input
            type={ this.props.type }
            placeholder={ this.props.placeholder }
            name={ this.props.name }
            onChange={ this.onChange.bind(this) }
            onKeyPress={ this.onKeyPress.bind(this) }
            value={ this.props.value || '' }
            onFocus={this.onFocus.bind(this)}
            onBlur={ this.validate.bind(this) }
            disabled={this.props.disabled ? 'disabled' : ''}
            autoComplete="new-password"
            autofill="off"
            maxLength={this.props.maxlength}
          />
          <div className="error">{this.props.errorMessage}</div>
          {charCount}
        </div>
        { this.props.extraComponent ? this.props.extraComponent : null }
      </div>
    )
  }
}

export class EmailInput extends NameInput {
  validateEmail(email) {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  getClassName() {
    let className = '';
    if (this.state.invalid && this.interacted) {
      className = 'invalid' 
    } 
    if (this.state.valid && this.interacted) {
      className = 'valid';
    }
    if (!this.interacted && this.validateEmail(this.props.value)) {
      className = 'unchanged';
    }

    return className;
  }

  validate() {
    if (this.props.noValidate) { return true; }

    if (this.props.optional && !this.props.value) {
      this.reset();
      return true;
    }
    if (this.props.value && this.validateEmail(this.props.value)) {
      this.setValid();
      this.props.onValidate(this.props.name, true);
      return true;
    } else {
      this.setInvalid();
      this.props.onValidate(this.props.name, false);
      return false;
    }
  }
}

export class PasswordInput extends NameInput {
  getClassName() {
    let className = '';
    if (this.state.invalid && this.interacted) {
      className = 'invalid' 
    } 
    if (this.state.valid && this.interacted) {
      className = 'valid';
    }
    if (!this.interacted && this.props.value.length < 6) {
      if (this.props.optional) {
        className = 'unchanged';
      } else if (this.props.value) {
        className = 'unchanged';
      }
    }

    return className;
  }

  validate() {
    if (this.props.value && this.props.value.length >= 6) {
      this.setValid();
      this.props.onValidate(this.props.name, true);
      return true;
    } else {
      this.setInvalid();
      this.props.onValidate(this.props.name, false);
      return false;
    }
  }

  render() {
    let forgotPasswordLink;
    if (this.props.showForgotPasswordLink) {
      forgotPasswordLink = (<Link to="/forgot-password">Forgot password?</Link>)
    }

    const className = this.getClassName();

    return (
      <div className={`input ${className}`}>
        <label>{ this.props.label }{ forgotPasswordLink }</label>
        <input
          type={ this.props.type }
          placeholder={ this.props.placeholder }
          name={ this.props.name }
          onChange={ this.onChange.bind(this) }
          onKeyPress={ this.onKeyPress.bind(this) }
          value={ this.props.value || ''}
          onBlur={ this.validate.bind(this) }
          autoComplete="new-password"
          autofill="off"
          maxLength="100"
        />
        <div className="error">{this.props.errorMessage}</div>
      </div>
    ) 
  }
}

export class CSSInput extends NameInput {
  render() {
    const className = this.getClassName();

    return (
      <div className={`input ${className}`} style={this.props.style || {}}>
        <label>{ this.props.label }</label>
        <AceEditor
          mode="css"
          theme="monokai"
          palceholder={this.props.placeholder}
          width='100%'
          height={300}
          fontSize={12}
          tabSize={2}
          showGutter={true}
          value={this.props.value}
          onChange={this.props.onChange.bind(this)}
          name="CSSInput"
          editorProps={{ $blockScrolling: true }}
        />
      </div>
    ) 
  }
}

export class HTMLInput extends NameInput {
  render() {
    const className = this.getClassName();

    return (
      <div className={`input ${className}`} style={this.props.style || {}}>
        <label>{ this.props.label }</label>
        <AceEditor
          mode="html"
          theme="monokai"
          palceholder={this.props.placeholder}
          width='100%'
          height={100}
          fontSize={12}
          tabSize={2}
          showGutter={true}
          value={this.props.value}
          onChange={this.props.onChange.bind(this)}
          name="CSSInput"
          editorProps={{ $blockScrolling: true }}
        />
      </div>
    ) 
  }
}

export class JSInput extends NameInput {
  render() {
    const className = this.getClassName();

    return (
      <div className={`input ${className}`} style={this.props.style || {}}>
        <AceEditor
          mode="js"
          theme="monokai"
          palceholder={this.props.placeholder}
          width='100%'
          height={100}
          fontSize={12}
          tabSize={2}
          showGutter={true}
          value={this.props.value}
          onChange={this.props.onChange.bind(this)}
          name="CSSInput"
          editorProps={{ $blockScrolling: true }}
        />
      </div>
    ) 
  }
}

export class DomainInput extends NameInput {
  render() {
    let forgotPasswordLink;
    if (this.props.showForgotPasswordLink) {
      forgotPasswordLink = (<Link to="/forgot-password">Forgot password?</Link>)
    }

    const className = this.getClassName();

    return (
      <div className={`input ${className}`}>
        <label className={this.props.label ? '' : 'empty'}>{ this.props.label }{ forgotPasswordLink }</label>
        <input
          type={ this.props.type }
          placeholder={ this.props.placeholder }
          name={ this.props.name }
          onChange={ this.onChange.bind(this) }
          onKeyPress={ this.onKeyPress.bind(this) }
          value={ this.props.value || ''}
          onBlur={ this.validate.bind(this) }
          autoComplete="new-password"
          disabled={this.props.disabled}
          autofill="off"
        />
        <div className="error">{this.props.errorMessage}</div>
      </div>
    ) 
  }

  validateDomain(domain) {
    if (/^([a-zA-Z0-9]([-a-zA-Z0-9]{0,61}[a-zA-Z0-9])?\.)?([a-zA-Z0-9]{1,2}([-a-zA-Z0-9]{0,252}[a-zA-Z0-9])?)\.([a-zA-Z]{2,63})$/.test(domain)) {
      return true;
    } else {
      return false;
    }
  }

  validate() {
    if (this.props.value && this.validateDomain(this.props.value)) {
      this.setValid()
      this.props.onValidate(this.props.name, true);
      return true;
    } else {
      this.setInvalid()
      this.props.onValidate(this.props.name, false);
      return false;
    }
  }
}

class Domain extends Component {
  onChange(e) {
    e.preventDefault();
    e.stopPropagation();

    this.props.onChange(e.target.name, e.target.value, this.props.idx);
  }

  onRemove(e) {
    e.preventDefault();
    e.stopPropagation();

    this.props.onRemove(this.props.idx);
  }

  render() {
    const type = this.props.type;

    let styles = {};
    if (this.props.type === 'exact') {
      styles.select = {width: 150};
      styles.input = {width: `calc(100% - ${150 + 10}px)`};
    } else {
      styles.select = {width: 193};
      styles.input = {width: `calc(100% - ${193 + 10}px)`};
    }

    const raw = `${this.props.type === 'exact' ? '' : '(.*).'}${this.props.path || 'domain.com'}`;

    return (
      <div className="rule">
        <div>
          <select name="type" value={type} onChange={this.onChange.bind(this)} style={styles.select}>
            <option value="includes">Include Subdomains</option>
            <option value="exact">Match Exactly</option>
          </select>

          <input 
            name="path"
            style={styles.input}
            value={this.props.path}
            onChange={this.onChange.bind(this)}
            placeholder={this.props.type === 'exact' ? 'sub.domain.com' : 'domain.com'}
          />
        </div>

        <div className="raw">{ raw }</div>
        <span className="remove" onClick={this.onRemove.bind(this)} />
      </div>
    );
  }
}

export class DomainsInput extends NameInput {
  constructor(props) {
    super(props)
    this.state = {
      valid: undefined,
      invalid: undefined,
    };

    let domains = this.props.value;
    domains = domains.map(domain => {
      let type = 'exact';
      let path = domain;

      if (domain.indexOf('(.*).') !== -1) {
        type = 'includes';
        path = domain.replace('(.*).', '');
      }

      return { path, type }
    });

    if (domains.length === 0) {
      domains.push({ path: '', type: 'includes' });
    }

    this.state.domains = domains;
    this.state.all = this.props.value.length === 0 ? true : false;
  }

  addDomain(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const domains = [ ...this.state.domains ];
    domains.push({ domain: '', type: 'includes' });

    this.setState({ domains });
  }

  update(domains) {
    this.setState({ domains }, () => {
      /* Pass Value */
      let values = [];
      this.state.domains.forEach(({ path, type }) => {
        if (!path) { return; }

        path = path.split('/')[0];

        if (type === 'includes') {
          values.push('(.*).'+path);
        } else {
          values.push(path);
        }
      })

      this.props.onChange({ target: { name: this.props.name, value: values } }); 
    })
  }

  onRemove(idx) {
    let domains = [ ...this.state.domains ];
    domains.splice(idx, 1);

    if (domains.length === 0) {
      domains.push({ path: '', type: 'includes' });
    }

    this.update(domains);
  }

  onChange(name, value, idx) {
    /* Generate Value */
    const domains = [ ...this.state.domains ];
    domains[idx][name] = value;

    this.update(domains);
  }

  render() {
    const optional = this.getOptional();
    const className = this.getClassName();

    let domains = null;
    let addDomain = null;
    if (!this.state.all) {
      domains = this.state.domains.map(({ path, type }, idx) => <Domain
        key={idx}
        idx={idx}
        path={path}
        type={type}
        onChange={this.onChange.bind(this)}
        onRemove={this.onRemove.bind(this)} 
      />);
      addDomain = <div className="add-domain" style={this.props.noMargin ? { marginBottom: 0 } : {}} onClick={this.addDomain.bind(this)}>Add Domain</div>
    }

    let tooltip = null;
    if (this.props.moreInfo) {
      tooltip = <Tooltip>{this.props.moreInfo}</Tooltip>
    }

    return (
      <div className={`input domains ${className}`} style={this.props.noMargin ? { marginBottom: 0 } : {}}>
        <label>{ this.props.label }{ tooltip }{ optional }</label>

        <div className="inline-toggle">
          <Switch 
            value={this.state.all}
            vertical={true}
            options={[
              {
                label: 'Allow Zigpoll to be used on all domains',
                value: true
              },
              {
                label: 'Allow Zigpoll to be used on specific domains only',
                value: false
              }
            ]}
            onChange={(value) => {
              this.setState({ all: value });
              if (value) {
                this.props.onChange({ target: { name: this.props.name, value: [] } }); 
              } else {
                this.update(this.state.domains);
              }
            }}
          />
        </div>

        <div className="domains">
         { domains }
        </div>

        { addDomain }
      </div>
    )
  }
}

export class RadioGroup extends Component {
  constructor(props) {
    super(props)
    this.state = { valid: undefined, invalid: undefined };
  }

  validate() {
    if (this.props.value) {
      this.setValid();
      this.props.onValidate(this.props.name, true);
      return true;
    } else {
      this.setInvalid();
      this.props.onValidate(this.props.name, false);
      return false;
    }
  }

  onChange(e) {
    this.props.onChange(e);
  }

  render() {
    return (
      <div className={`input ${this.state.invalid ? 'invalid' : '' } ${this.state.valid ? 'valid' : ''}`}>
        <label>{ this.props.label }</label>
        { this.props.options.map(opt => (
          <input
            type="radio"
            name={ this.props.name }
            onChange={ this.onChange.bind(this) }
            value={ this.props.value || ''}
            autoComplete="new-password"
            autofill="off"
          />
        ))}
        <div className="error">{this.props.errorMessage}</div>
      </div>
    )
  }
}

class DisplayRule extends NameInput {
  onChange(e) {
    e.preventDefault();
    e.stopPropagation();

    this.props.onChange(e.target.name, e.target.value, this.props.idx);
  }

  onRemove(e) {
    e.preventDefault();
    e.stopPropagation();

    this.props.onRemove(this.props.idx);
  }

  render() {
    const type = this.props.type;

    let styles = {
      select: { width: 167 },
      input: { width: `calc(100% - ${40 + 169 + 10}px)`}
    };
    if (this.props.type === 'exact') {
      styles.select = {width: 167};
      styles.input = {width: `calc(100% - ${40 + 169 + 10}px)`};
    } else if (this.props.type === 'starts_with') {
      styles.select = {width: 125};
      styles.input = {width: `calc(100% - ${40 + 125 + 10}px)`};
    } else if (this.props.type === 'matches_variable') {
      styles.select = {width: 170};
      styles.input = {width: `calc(100% - ${40 + 170 + 10}px)`};
    } else if (this.props.type === 'ends_with') {
      styles.select = {width: 110};
      styles.input = {width: `calc(100% - ${40 + 110 + 10}px)`};
    }

    let domain = this.props.domains[0];
    if (!domain) {
      domain = 'domain.com';
    } else {
      domain = domain.replace('(.*).', '');
    }

    let raw = `${domain}/${this.props.type === 'ends_with' ? '(.*)/' : ''}${this.props.path}${this.props.type === 'starts_with' ? '/(.*)' : ''}${this.props.type === 'matches_variable' ? '/:variable' : ''}`;

    if (!this.props.path) {
      raw = `${domain}${this.props.path}${this.props.type === 'starts_with' ? '/(.*)' : ''}${this.props.type === 'matches_variable' ? '/:variable' : ''}`;
    }

    if (this.props.path === '/' && this.props.type === 'starts_with') {
      raw = `${domain}${this.props.path}/(.*)/`;
    }

    if (this.props.path === '/' && this.props.type === 'matches_variable') {
      raw = `${domain}${this.props.path}/:variable`;
    }    

    if (this.props.type === 'contains') {
      raw = `${domain}/(.*)${this.props.path}(.*)`;
    }

    console.log(this.props.type);
    console.log(raw);
    console.log(this.props.path);

    return (
      <div className="rule">
        <div>
          <p>URL</p>

          <select value={type} name="type" onChange={this.onChange.bind(this)} style={styles.select}>
            <option value="exact">Matches Exactly</option>
            <option value="matches_variable">Matches Variable</option>
            <option value="starts_with">Starts With</option>
            <option value="ends_with">Ends With</option>
            <option value="contains">Contains</option>
          </select>

          <input 
            name="path"
            autoComplete="off"
            autofill="off"
            style={styles.input}
            value={this.props.path}
            onChange={this.onChange.bind(this)}
          />
        </div>

        <div className="raw">{ raw }</div>
        <span className="remove" onClick={this.onRemove.bind(this)} />
      </div>
    );
  }
}

export class DisplayRulesInput extends NameInput {

  constructor(props) {
    super(props)
    this.state = {
      valid: undefined,
      invalid: undefined,
    };

    let rules = this.props.value;
    rules = rules.map(rule => {
      let type = 'exact';
      let path = rule.slice(1);

      if ((path.substring(0,4) === '(.*)') && (path.slice(-4) === '(.*)')) {
        type = 'contains';
        path = path.replace('(.*)', '').replace('(.*)', '');
      } else if (rule.indexOf('/(.*)') === 0) {
        type = 'ends_with';
        path = rule.replace('/(.*)', '');
        path = path.slice(1);
      } else if (rule.indexOf('/(.*)') !== -1) {
        type = 'starts_with';
        path = rule.replace('/(.*)', '');
        path = path.slice(1);
      }

      if (rule.indexOf('/:variable') !== -1) {
        type = 'matches_variable';
        path = rule.replace('/:variable', '');
        path = path.slice(1);
      }

      return { path, type }
    });

    if (rules.length === 0 && !this.props.allowEmpty) {
      rules.push({ path: '', type: 'exact' });
    }

    this.state.rules = rules;
    this.state.all = this.props.value.length === 0 ? true : false;
    if (this.props.hideAllPages) {
      this.state.all = false;
    }
  }

  addRule(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const rules = [ ...this.state.rules ];
    rules.push({ path: '', type: 'exact' });

    this.setState({ rules });
  }

  update(rules) {
    this.setState({ rules }, () => {
      /* Pass Value */
      let values = [];
      console.log(this.state.rules);
      this.state.rules.forEach(({ path, type }) => {
        if (type !== 'ends_with' && type !== 'contains') {
          if (path) {
            path = path + '/';
          } else {
            path = '/';
          }
        }

        // let cleanPath = [];
        // path.split('/').forEach((val) => {
        //   if (val) {
        //     cleanPath.push(val);
        //   }
        // });

        // if (path) {
        //   path = '/' + cleanPath.join('/');
        // }

        if (type === 'starts_with') {
          if (path === '/') {
            values.push(path + '(.*)');
          } else {
            values.push(path + '/(.*)');
          }
        } else if (type === 'matches_variable') {
          if (path === '/') {
            values.push(path + ':variable');
          } else {
            values.push(path + '/:variable');
          }
        } else if (type === 'ends_with') {
          if (path) {
            values.push('/(.*)/' + path);
          }
        } else if (type === 'contains') {
          if (path) {
            values.push('(.*)' + path + '(.*)');
          }          
        } else {
          values.push(path);
        }
      })

      this.props.onChange({ target: { name: this.props.name, value: values } }); 
    })
  }

  onRemove(idx) {
    let rules = [ ...this.state.rules ];
    rules.splice(idx, 1);

    if (rules.length === 0 && !this.props.allowEmpty) {
      rules.push({ path: '', type: 'exact' });
    }

    this.update(rules);
  }

  onChange(name, value, idx) {
    /* Generate Value */
    const rules = [ ...this.state.rules ];
    rules[idx][name] = value;

    this.update(rules);
  }

  render() {
    const optional = this.getOptional();
    const className = this.getClassName();

    let rules = null;
    let addRule = null;
    if (!this.state.all) {
      rules = this.state.rules.map(({ path, type }, idx) => <DisplayRule
        key={idx}
        idx={idx}
        path={path}
        type={type}
        domains={this.props.domains}
        onChange={this.onChange.bind(this)}
        onRemove={this.onRemove.bind(this)} 
      />);
      addRule = <div className="add-rule" onClick={this.addRule.bind(this)}>Add Logic</div>
    }

    let tooltip = null;
    if (this.props.moreInfo) {
      tooltip = <Tooltip>{ this.props.moreInfo }</Tooltip>
    }

    let allPagesToggle = (
      <div className="inline-toggle">
        <Switch 
          value={this.state.all}
          vertical={true}
          options={[
            {
              label: 'Show this survey on all pages',
              value: true
            },
            { 
              label:'Show this survey on select pages only',
              value: false
            }
          ]}
          onChange={(value) => {
            this.setState({ all: value });
            if (value) {
              this.props.onChange({ target: { name: this.props.name, value: [] } }); 
            } else {
              this.update(this.state.rules);
            }
          }}
        />
      </div>
    );

    if (this.props.hideAllPages) {
      allPagesToggle = null;
    }

    let label = null;
    if (this.props.label) {
      label = (<label>{ this.props.label }{ tooltip }{ optional }</label>);
    }

    let noRulesFound = null;
    if (!this.state.rules.length) {
      noRulesFound = <div className="empty">No exclusion paths applied</div>
    }

    return (
      <div className={`input display-rules ${className}`}>
        { label }

        { allPagesToggle }

        { noRulesFound }

        <div className="rules">
         { rules }
        </div>

        { addRule }
      </div>
    )
  }
}

export class RecipientsInput extends Component {
  constructor(props) {
    super(props);

    let showRecipients = props.value ? true : false;
    let recipients;
    if (typeof this.props.value === 'string') {
      recipients = [this.props.value];
    } else {
      recipients = this.props.value || [''];
    }
    this.state = { showRecipients, recipients };
  }

  update(recipients = []) {
    let value = [];
    recipients.forEach((val) => {
      if (val) {
        value.push(val);
      }
    });
    value = _.uniq(value);

    if (value.length === 0) {
      value = false;
    }
    this.props.onChange({ target: { name: 'recipients', value } });
  }

  validate() {
    return true;
  }

  reset() {
    return true;
  }

  render() {
    let tooltip = null;
    if (this.props.moreInfo) {
      tooltip = <Tooltip>{ this.props.moreInfo }</Tooltip>
    }

    let optional = null;
    if (this.props.optional) {
      optional = <span>(Optional)</span>
    }

    let recipientsForm = null;
    if (true) {
      recipientsForm = (
      <div style={{ marginTop: 0 }}>
        <label>{ this.props.label }{ tooltip }{ optional }</label>
        <div className="emails">
          { this.state.recipients.map((email, idx) => {
            return (<div className="email">
              <EmailInput
                value={ email }
                placeholder="email@domain.com"
                optional={true}
                onValidate={() => {
                  console.log('validate');
                }}
                onChange={(e) => {
                  const val = e.target.value;
                  const recipients = this.state.recipients;
                  recipients[idx] = val;
                  this.setState({ recipients })
                  this.update(recipients);
                }}
                errorMessage="Please enter a valid email address."
              />
              <div className="close" onClick={() => {
                let recipients = this.state.recipients;
                recipients.splice(idx, 1);
                if (recipients.length === 0) {
                  recipients = [''];
                }
                this.setState({ recipients })
                this.update(recipients);
              }}/>
            </div>)
          })}
        </div>
  
        <div className="add-domain" style={{ marginBottom: 0, marginTop: 0 }} onClick={() => {
          const recipients = this.state.recipients;
          recipients.push('');

          this.setState({ recipients });
        }}>Add Recipient</div>
      </div>
      );
    }

    return <div className={`input recipients ${this.props.disabled ? 'disabled' : ''}`}>

        {/*
        <div className="inline-toggle">
          <Switch 
            value={!this.state.showRecipients}
            vertical={true}
            options={[
            {
                label: this.props.isAccount ? "Send to the account owner only" : "Use your Account's recipient settings",
                value: true
            },
            { 
                label: this.props.isAccount ? "Send to custom recipients" : "Set custom recipients for this Survey",
                value: false
            }
            ]}
            onChange={(value) => {
              if (value) {
                this.props.onChange({ target: { name: 'recipients', value: false } }); 
              } else {              
                this.update(this.state.recipients); 
              }
              this.setState({ showRecipients: !value });
            }}
          />
        </div>
        */}

        { recipientsForm }

    </div>;
  }
}

export class CCRecipientsInput extends RecipientsInput {
  render() {
    let tooltip = null;
    if (this.props.moreInfo) {
      tooltip = <Tooltip>{ this.props.moreInfo }</Tooltip>
    }

    let optional = null;
    if (this.props.optional) {
      optional = <span>(Optional)</span>
    }

    let recipientsForm = null;
    if (this.state.showRecipients) {
      recipientsForm = (
      <div style={{ marginTop: 15 }}>
        <div className="emails">
          { this.state.recipients.map((email, idx) => {
            return (<div className="email">
              <EmailInput
                value={ email }
                placeholder="email@domain.com"
                optional={true}
                onValidate={() => {
                  console.log('validate');
                }}
                onChange={(e) => {
                  const val = e.target.value;
                  const recipients = this.state.recipients;
                  recipients[idx] = val;
                  this.setState({ recipients })
                  this.update(recipients);
                }}
                errorMessage="Please enter a valid email address."
              />
              <div className="close" onClick={() => {
                let recipients = this.state.recipients;
                recipients.splice(idx, 1);
                if (recipients.length === 0) {
                  recipients = [''];
                }
                this.setState({ recipients })
                this.update(recipients);
              }}/>
            </div>)
          })}
        </div>
  
        <div className="add-domain" style={{ marginBottom: 0, marginTop: 0 }} onClick={() => {
          const recipients = this.state.recipients;
          recipients.push('');

          this.setState({ recipients });
        }}>Add Recipient</div>
      </div>
      );
    }

    return <div className="input recipients">

        <label>CC Recipients</label>

        <div className="inline-toggle">
          <Toggle
            active={this.state.showRecipients}
            onChange={(value) => {
              if (!value) {
                this.props.onChange({ target: { name: 'recipients', value: false } }); 
              } else {              
                this.update(this.state.recipients); 
              }
              this.setState({ showRecipients: value });
            }}
          />
          <div className="subtle">Add CC contacts to outgoing emails.</div>
        </div>

        { recipientsForm }

    </div>;
  }
}

export class ReplyToInput extends RecipientsInput {
  update(recipients = []) {
    let value = [];
    recipients.forEach((val) => {
      if (val) {
        value.push(val);
      }
    });
    value = _.uniq(value);

    if (value.length === 0) {
      value = false;
    } else {
      value = value[0];
    }

    this.props.onChange({ target: { name: 'recipients', value } });
  }

  render() {
    let tooltip = null;
    if (this.props.moreInfo) {
      tooltip = <Tooltip>{ this.props.moreInfo }</Tooltip>
    }

    let optional = null;
    if (this.props.optional) {
      optional = <span>(Optional)</span>
    }

    let recipientsForm = null;
    if (this.state.showRecipients) {
      recipientsForm = (
      <div style={{ marginTop: 15 }}>
        <div className="emails">
          { this.state.recipients.map((email, idx) => {
            return (<div className="email">
              <EmailInput
                value={ email }
                placeholder="email@domain.com"
                optional={true}
                onValidate={() => {
                  console.log('validate');
                }}
                onChange={(e) => {
                  const val = e.target.value;
                  const recipients = this.state.recipients;
                  recipients[idx] = val;
                  this.setState({ recipients })
                  this.update(recipients);
                }}
                errorMessage="Please enter a valid email address."
              />
              <div className="close" onClick={() => {
                let recipients = this.state.recipients;
                recipients.splice(idx, 1);
                if (recipients.length === 0) {
                  recipients = [''];
                }
                this.setState({ recipients })
                this.update(recipients);
              }}/>
            </div>)
          })}
        </div>
      </div>
      );
    }

    return <div className="input recipients">

        <label>Reply-To Address <Tooltip>Want to use a custom sender domain? <a href="https://www.zigpoll.com/blog/how-to-setup-a-custom-sender-domain" target="_blank">Click here</a> to learn about how to set one up.</Tooltip></label>

        <div className="inline-toggle">
          <Toggle
            active={this.state.showRecipients}
            onChange={(value) => {
              if (!value) {
                this.props.onChange({ target: { name: 'reply-to', value: false } }); 
              } else {              
                this.update(this.state.recipients); 
              }
              this.setState({ showRecipients: value });
            }}
          />
          <div className="subtle">Use a custom reply-to header on outgoing emails.</div>
        </div>

        { recipientsForm }

    </div>;
  }
}

export class BasicRecipientsInput extends RecipientsInput {
  render() {
    let tooltip = null;
    if (this.props.moreInfo) {
      tooltip = <Tooltip>{ this.props.moreInfo }</Tooltip>
    }

    let recipientsForm = null;
    if (true) {
      recipientsForm = (
      <div style={{ marginTop: 0 }}>
        <div className="emails">
          { this.state.recipients.map((email, idx) => {
            return (<div className="email">
              <EmailInput
                value={ email }
                placeholder="email@domain.com"
                optional={true}
                onValidate={() => {
                  console.log('validate');
                }}
                onChange={(e) => {
                  const val = e.target.value;
                  const recipients = this.state.recipients;
                  recipients[idx] = val;
                  this.setState({ recipients })
                  this.update(recipients);
                }}
                errorMessage="Please enter a valid email address."
              />
              <div className="close" onClick={() => {
                let recipients = this.state.recipients;
                recipients.splice(idx, 1);
                if (recipients.length === 0) {
                  recipients = [''];
                }
                this.setState({ recipients })
                this.update(recipients);
              }}/>
            </div>)
          })}
        </div>
  
        <div className="add-domain" style={{ marginBottom: 0, marginTop: 0, float: 'left' }} onClick={() => {
          const recipients = this.state.recipients;
          recipients.push('');

          this.setState({ recipients });
        }}>Add Recipient</div>
      </div>
      );
    }

    return <div className="input recipients">
        { recipientsForm }
    </div>;
  }
}
