import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ColumnLayout from '../components/ColumnLayout';
import ColumnLoading from './ColumnLoading';

import PollCreateForm from '../components/PollCreateForm';
import SingleFormPageHeader from '../components/SingleFormPageHeader';

import * as AccountsActions from '../actions/Accounts';

import { decode } from '../utils';

class CreatePoll extends Component {
  constructor(props) {
    super(props);
    if (!this.props.account) {
      this.props.fetchAccount();
    }
  }
  render() {
    if (!this.props.account) {
      return <ColumnLoading />
    }

    if (!this.state) {
      let displayRulesOption = 'link-only';
      let useSlidesTemplate = true;
      if (this.props.account.shop) {
        displayRulesOption = 'template';
        useSlidesTemplate = true;
      }
      this.setState({ useSlidesTemplate: useSlidesTemplate, displayRulesOption });
      return <ColumnLoading />;
    }

    let topNav = null;
    if (this.props.account.polls.length !== 0) {
      topNav = (
        <div className="top-nav wrapper" style={{ float: 'left' }}>
          <Link to=""><i className="fas fa-arrow-left-long" />Surveys</Link>
        </div>
      );
    }

    return (
      <ColumnLayout title="Create Survey">

        <div style={{ minWidth: this.state.useSlidesTemplate || this.state.displayRulesOption === 'template' ? 1100 : 0 }}>
          { topNav }

          <SingleFormPageHeader
            title="Let's do this!"
            subtitle=<span>Fill out the form to get started.<i className="fas fa-arrow-turn-down" /></span>
            subtext="(Don't stress — you can edit everything later)"
            className="no-margin"
          />

          <PollCreateForm
            account={this.props.account}
            useSlidesTemplate={this.state.useSlidesTemplate}
            displayRulesOption={this.state.displayRulesOption}
            set={(key, value) => {
              const state = {};
              state[key] = value;
              this.setState(state);
            }}
          />
        </div>

      </ColumnLayout>
    );
  }
}


function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || [];
  const account = accounts[accountId];

  return {
    accountId,
    account
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(AccountsActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatePoll));
