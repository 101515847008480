import _ from 'lodash';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import { NameInput } from './SimpleInputs';
import Modal from './Modal';
import Toggle from './Toggle';
import Tooltip from './Tooltip';
import NextSlideInput from './NextSlideInput';

import * as FlashNotificationsActions from '../actions/FlashNotifications';

import { handleize, move } from '../utils';

import { DragDropContext, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import SingleImageUploader from './SingleImageUploader';

/* Drag */
const dragSource = {
  beginDrag(props) {
    return {
      idx: props.idx
    };
  }
};

const dragCollect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }
};

let direction;
const dropTarget = {
  hover(props, monitor, component) {
    const dragIdx = monitor.getItem().idx;
    let dropIdx = props.idx;

    if (dragIdx < dropIdx) {
      direction = 'down';
      component.forceUpdate();
    } else if (dragIdx > dropIdx) {
      direction = 'up';
      component.forceUpdate();
    }
  },
  drop(props, monitor, component) {
    const dragIdx = monitor.getItem().idx;
    let dropIdx = props.idx;

    if (dragIdx === dropIdx) {
      return;
    }

    let idx;
    if (dragIdx < dropIdx) {
      idx = dropIdx - 1 < 0 ? 0 : dropIdx;
      props.reorderAnswers(dragIdx, idx);
    } else {
      idx = dropIdx + 1 >= props.pollCount ? props.pollCount - 1 : dropIdx;
      props.reorderAnswers(dragIdx, idx);
    }
  }
};

const dropCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver()
})

class DumbDragHandle extends Component {
  render() {
    const { connectDragSource } = this.props;
    return connectDragSource(<span className="handle" />)
  }
}

class DumbDropContext extends Component {
  render() {
    const { connectDropTarget, isOver } = this.props;
    return connectDropTarget(<div className={`drop-context ${isOver ? `is-over ${direction}` : ''}`}>{ this.props.children }</div>);
  }
}

const DragHandle = compose(
  DragSource('ANSWER', dragSource, dragCollect),
)(DumbDragHandle);

const DropContext = compose(
  DropTarget('ANSWER', dropTarget, dropCollect)
(DumbDropContext))

class Answer extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }

  onChange(e) {
    this.props.onChange(e, this.props.idx);
  }

  onValidate(e) {
    this.props.onValidate(e, this.props.idx);
  }

  setRef(element) {
    if (!this.inputs) {
      this.inputs = {};
    }
    if (element) {
      this.inputs[element.props.name] = element;
    }
  }

  validate() {
    let valid = true;

    // for (const key in this.inputs) {
    //   if (!this.inputs[key].validate()) {
    //     valid = false;
    //   }
    // }

    return valid;
  }

  removeAnswer(e) {
    this.props.removeAnswer(this.props.idx);
  }

  showModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  onImageUpload(image, idx) {
    if (!image) { return; }

    console.log(image);
    console.log(this.props.idx);
    console.log('image uploaded');
    this.props.onChange({ target: { name: 'image', value: image }}, this.props.idx);
  }

  render() {
    return <DropContext
      reorderAnswers={this.props.reorderAnswers}
      idx={this.props.idx}
    ><div className="answer image">
      <DragHandle idx={this.props.idx} />
      <div>
        {/*
        <NameInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label={`Image #${this.props.idx + 1}`}
          type="text"
          placeholder="https://www.placeholder.com"
          name="url"
          value={this.props.url}
          errorMessage="Please a url for the answer."
          ref={this.setRef.bind(this)}
        />
        */}
        <NameInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label={<em>Title {this.props.idx + 1}<Tooltip>Enter the title you want to see for this image on your charts and reports.</Tooltip></em>}
          type="text"
          placeholder="Image Choice A"
          name="title"
          value={this.props.title}
          errorMessage="Please a title for the answer."
          ref={this.setRef.bind(this)}
        />
        <SingleImageUploader
          label={<span><label>Image {this.props.idx + 1}<Tooltip>Upload the image you want to use for this answer option.</Tooltip></label></span>}
          image={this.props.url}
          key={this.props.idx}
          onChange={this.onImageUpload.bind(this)} />
      </div>
      <div className="remove" onClick={() => {
        if (this.props.votes) {
          this.showModal();
        } else {
          this.removeAnswer();
        }
      }} />
      <Modal 
        isOpen={this.state.showModal}
        onRequestClose={this.closeModal.bind(this)}
      >
        <div className="frame">
          <div className="close" onClick={this.closeModal.bind(this)} />
          <div className="title">Are you sure?</div>
          <div className="content">
            <div className="subtitle">This will remove the answer and all responses associated with it.</div>
            <div className="actions">
              <button className="positive" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                this.removeAnswer();
                this.closeModal();
              }}>Yes</button>
              <button className="negative" onClick={this.closeModal.bind(this)}>No</button>
            </div>
          </div>
        </div>
      </Modal>
    </div></DropContext>
  }
}

class AnswersInput extends Component {
  constructor(props) {
    super(props);

    let answers = [];

    props.answers.forEach((answer) => {
      answers.push(answer);
    });

    this.state = {
      answers
    };
  }

  reset() {
    this.setState({ valid: undefined, invalid: undefined });
  }

  addAnswer(e) {
    e.preventDefault();
    e.stopPropagation();

    const answers = this.state.answers;
    answers.push({ title: '', handle: '' });
    this.setState({ answers });
  }

  removeAnswer(idx) {
    delete this.inputs[idx];
    const answers = this.state.answers;
    answers.splice(idx, 1);
    this.setState({ answers }, this.update.bind(this));
  }

  onChange(e, idx) {
    let answers = _.cloneDeep(this.state.answers);
    const answer = answers[idx];
    answer[e.target.name] = e.target.value;

    if (e.target.name === 'title') {
      answer.handle = handleize(e.target.value)
    }

    if (e.target.name === 'image') {
      const image = e.target.value;
      answer.image = image;
      answer.url = image.url;
    }

    console.log(answers);

    this.setState({ answers }, this.update.bind(this));
  }

  update() {
    const answers = this.state.answers.map(answer => { delete answer.votes; delete answer.totalOrderValue; delete answer.votesSinceOrderValueAdded; return answer; delete answer.rankings; });

    _.remove(answers, ({ handle }) => !handle);

    const e = { target: { name: 'answers', value: answers } };
    this.props.onChange(e);
  }

  onValidate(e, idx) {
    this.props.onValidate('answers', true);
  }

  setRef(element) {
    if (!this.inputs) {
      this.inputs = {};
    }

    if (element) {
      if (element.getDecoratedComponentInstance && element.getDecoratedComponentInstance()) {
        element = element.getDecoratedComponentInstance();
      }
      this.inputs[element.props.idx] = element;
    }
  }

  reorderAnswers(dragIdx, dropIdx) {
    const answers = move(this.state.answers, dragIdx, dropIdx);
    this.setState({ answers }, this.update.bind(this));
  }

  validate() {
    let valid = true;

    for (const key in this.inputs) {
      if (!this.inputs[key].validate()) {
        valid = false;
      }
    }

    const answers = [ ...this.state.answers];
    _.remove(answers, ({ handle }) => !handle);

    var uniq = _.uniqBy(answers, answer => answer.handle);

    if (uniq.length !== answers.length) {
      const e = { target: { name: 'answers', value: uniq } };
      this.props.onChange(e)

      this.props.flash('Duplicate answers detected. They were automatically removed.', 'warning');

      this.setState({ answers: uniq });
    }

    return valid;
  }

  renderAnswers() {
    let answers = null;

    answers = this.state.answers.map(({ title, handle, value, url, votes }, idx) => (
      <Answer 
        key={url}
        title={title}
        url={url}
        value={value}
        idx={idx}
        votes={votes}
        onChange={this.onChange.bind(this)}
        onValidate={this.onValidate.bind(this)}
        removeAnswer={this.removeAnswer.bind(this)}
        ref={this.setRef.bind(this)}
        slideId={this.props.slideId}
        slides={this.props.slides}
        showRules={this.props.showRules}
        reorderAnswers={this.reorderAnswers.bind(this)}
      />
    ));

    return answers;
  }

  render() {
    if (this.inputs) {
      delete this.inputs[1000];
    }

    return (
      <DndContext><div className={`answers-input ${this.props.className}`}>
        {/*
        <div className="input" style={{ marginBottom: 20 }}>
          <label>Answer Options<Tooltip>Each option needs to be unique.</Tooltip></label>
        </div>
        */}

        { this.renderAnswers() }
        <div className="add-answer">
          <div onClick={this.addAnswer.bind(this)}>Add Answer</div>
        </div>
      </div></DndContext>
    );
  }
}

class Context extends Component {
  render() {
    return <div>{ this.props.children }</div>
  }
}

const DndContext = DragDropContext(HTML5Backend)(Context);

function mapDispatchToProps(dispatch) {
  return bindActionCreators(FlashNotificationsActions, dispatch);
}

export default connect(null, mapDispatchToProps, null, { withRef: true })(AnswersInput);
