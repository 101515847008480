import React, { Component } from 'react';
import { encode } from '../utils';

class Notification extends Component {
  render() {
    return (
      <div className={`notification ${this.props.type}`}>
        <div className="content"
          dangerouslySetInnerHTML={{ __html : this.props.message }} 
        />
        <div 
          className="close"
          onClick={this.props.close}
        ><i className="fas fa-close" /></div>
      </div>
    );
  }
}

export default class HeaderNotifications extends Component {
  render() {
    let notification = null;
    let count = null;

    notification = <Notification
      type="error"
      message={`You have reached the submission limit for your current plan. Please <a href='/a/${encode(this.props.accountId)}/manage'>change your plan</a> to resume service for this month. If you have any questions or need assistance please <a target='_blank' href='https://www.zigpoll.com/contact'>get in touch</a>.`}
    />

    return (
    <div id="header-notifications" className={'active'}>
      { notification }
    </div>
    )
  }
}