import { 
  FETCHING_ACTIVITIES,
  FETCHED_ACTIVITIES,
  UPDATING_ACTIVITY,
  UPDATED_ACTIVITY,
} from '../actions/Activities';

import _ from 'lodash';

export default function activities(state = {}, action) {
  let activities;

  switch (action.type) {
    case FETCHING_ACTIVITIES:
      return { ...state, loading: true }
    case FETCHED_ACTIVITIES:
      activities = {};
      activities[action.id] = action.activities;
      return { ...state, ...activities, loading: false }
    case UPDATING_ACTIVITY: {
      const activities = state[action.accountId];
      let idx = _.findIndex(activities.data, ({ _id }) => _id === action.activityId);
      activities.data[idx].loading = true;
      return { ...state, loading: true }
    }
    case UPDATED_ACTIVITY: {
      const activities = state[action.accountId];
      let idx = _.findIndex(activities.data, ({ _id }) => _id === action.activityId);
      if (action.activity) {
        activities.data[idx] = action.activity;
      } else {
        activities.data[idx].loading = false;
      }
      return { ...state, loading: false }
    }
    default:
      return state;
  }
}
