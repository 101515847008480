import React, { Component } from 'react';

import Modal from './Modal';

export default class DuplicateModal extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, value: props.value };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.setState({ showModal: this.props.show });
    }
  }

  closeModal() {
    this.setState({ showModal: false });
    this.props.onCancel();
  }

  onChange(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ value: e.target.value });
  }

  render() {
    return (
      <Modal 
        isOpen={this.state.showModal}
        onRequestClose={this.closeModal.bind(this)}
      >
        <div className="frame">
          <div className="close" onClick={this.closeModal.bind(this)} />
          <div className="title">{ this.props.title }</div>
          <div className="content">
            <div className="subtitle">{ this.props.subtitle }</div>

            <div className="verification-input">
              <label>Enter the title for the duplicate survey.</label>
              <input 
                type="text"
                value={this.state.value}
                onChange={this.onChange.bind(this)}
              />
            </div>

            <div className="actions">
              <button className="positive" disabled={!this.state.value} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                this.props.onConfirm(this.state.value);
                this.closeModal();
              }}>Duplicate</button>
              <button className="negative" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                this.closeModal();
              }}>Cancel</button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}