import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import * as UsersActions from '../actions/Users';
import Dropdown from './Dropdown';

import { connect } from 'react-redux';

class AccountsDropdown extends Dropdown {
  fetchItems() {
    console.log('Should never need to fetch user');
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...UsersActions }, dispatch);
}

export default withRouter(connect(undefined, mapDispatchToProps)(AccountsDropdown));
