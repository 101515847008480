import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import _ from 'lodash';

import * as PollActions from '../actions/Polls';

import Toggle from './Toggle';
import Tooltip from './Tooltip';
import DynamicOpenInput from './DynamicOpenInput';
import Confirm from './Confirm';
import SectionHeader from './SectionHeader';

class PollToggles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // isVisible: this.props.settings.isVisible,
      // showResults: settings.showResults,
      showCounter: this.props.settings.showCounter,
      hideAfterSubmission: this.props.settings.hideAfterSubmission,
      allowResubmissions: this.props.settings.allowResubmissions,
      // autoOpenDuration: this.props.settings.autoOpenDuration,
      // autoOpenPosition: this.props.settings.autoOpenPosition,
      // autoOpenDevice: this.props.settings.autoOpenDevice,
      notifyOnComplete: this.props.settings.notifyOnComplete,
      urlDependant: this.props.settings.urlDependant
      // expiration: settings.expiration === undefined ? false : new Date(settings.expiration),
    };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.settings, this.props.settings)) {
      this.setState(this.props.settings);
    }
  }

  updateSettings(message) {
    const poll = {};
    poll._id = this.props.poll._id;
    poll.settings = {
      // isVisible: this.state.isVisible,
      showCounter: this.state.showCounter,
      // hideAfterSubmission: this.state.hideAfterSubmission,
      allowResubmissions: this.state.allowResubmissions,
      // autoOpenPosition: this.state.autoOpenPosition,
      // autoOpenDuration: this.state.autoOpenDuration,
      // autoOpenDevice: this.state.autoOpenDevice,
      notifyOnComplete: this.state.notifyOnComplete,
      urlDependant: this.state.urlDependant
    };
    this.props.updatePoll(poll, message);
  }

  showConfirmModal() {
    this.setState({ showConfirmModal: true });
  }

  onConfirm() {
    this.setState({ allowResubmissions: !this.state.allowResubmissions }, this.updateSettings);
  }

  render() {

    let tooltip = null;
    if (this.props.poll.slides.length === 0) {
      tooltip = (<Tooltip>Only a survey that has slides can be marked as visible.</Tooltip>);
    }

    if (this.props.poll.isArchived) {
      tooltip = (<Tooltip>A survey that is archived cannot be visible.</Tooltip>);
    }

    return (
      <div>

        <div className="account-settings card" style={{ marginTop: 25 }}>

          <div>
            <label>Show Progress</label>
            <div className="inline-toggle">
              <Toggle
                active={this.state.showCounter}
                onChange={(value) => {
                  this.setState({ showCounter: value }, this.updateSettings);
                }}
              />
              <div className="subtle">Show progress percentage when filling out the poll.</div>
            </div>
          </div>

          <div>
            <label>Allow Resubmissions<Tooltip>This can only be enabled if "Hide After Submission" is disabled.</Tooltip></label>
            <div className="inline-toggle">
              <Toggle
                active={this.state.allowResubmissions}
                onChange={(value) => {
                  if (this.props.settings.isVisible) {
                    this.showConfirmModal();
                  } else {
                    this.setState({ allowResubmissions: value }, this.updateSettings);
                  }
                }}
              />
              <div className="subtle">Let users fill out the survey multiple times.</div>

              <Confirm 
                title="Are you sure?"
                subtitle="This survey is visible. If you make this change anyone who has filled out this form will have their progress reset."
                show={this.state.showConfirmModal}
                onConfirm={this.onConfirm.bind(this)}
                onCancel={() => {
                  this.setState({ showConfirmModal: false });
                }}
              />
            </div>
          </div>

          <div>
            <label>Notify When Completed</label>
            <div className="inline-toggle">
              <Toggle
                active={this.state.notifyOnComplete}
                onChange={(value) => {
                  this.setState({ notifyOnComplete: value }, this.updateSettings);
                }}
              />
              <div className="subtle">Send an email every time this survey is completed.</div>
            </div>
          </div>

          <div>
            <label>Make Survey Unique Per Page<Tooltip>This will treat each survey as unique if it is on a different url. This is useful if you have dynamic pages and want to ask the same question multiple times depending on the context. For example: running a survey on a checkout page.</Tooltip></label>
            <div className="inline-toggle">
              <Toggle
                active={this.state.urlDependant}
                onChange={(value) => {
                  this.setState({ urlDependant: value }, this.updateSettings);
                }}
              />
              <div className="subtle">Make the survey unique based on URL</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const settings = ownProps.poll.settings || {};

  return {
    settings
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(PollActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PollToggles);
