import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Modal from './Modal';
import NextSlideInput, { ActionSelector, SlideSelector} from './NextSlideInput';

import _ from 'lodash';

function calculateWidth(str) {
  if (str === undefined) { return 20; }

  str = '' + str;
  const w = parseInt(str.length * 7.25 + 22);
  return w;
}

class RuleRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      action: this.props.nextSlide === -1 ? 'end-poll' : 'skip-to',
      nextSlide: this.props.nextSlide,
      handle: this.props.handle
    };
  }

  onChange(e) {
    const state = this.state;
    state[e.target.name] = e.target.value;

    if (e.target.name === 'action') {
      if (e.target.value === 'end-poll') {
        state['nextSlide'] = -1;
      }
    }

    this.setState(state, () => { this.props.onRowChange(this.state, this.props.idx) });
  }

  remove() {
    this.props.removeRow(this.props.idx);
  }

  render() {
    const props = this.props;

    return (
      <div className="row">
        <div className="remove" onClick={this.remove.bind(this)} />
        <div className="answer">If answer is <select
          value={this.state.handle}
          name="handle"
          style={{ width: calculateWidth(this.state.handle) }}
          onChange={this.onChange.bind(this)}
        >{ props.answers.map(({ handle, title }) => <option value={handle}>{title}</option>)}</select></div>
        <div className="copy">
          <ActionSelector
            value={this.state.action}
            name='action'
            onChange={this.onChange.bind(this)}
          /> {this.state.action === 'skip-to' ? <span>slide: <SlideSelector
              value={this.state.nextSlide}
              name="nextSlide"
              onChange={this.onChange.bind(this)}
              slides={this.props.slides}
            />
          </span> : ''}</div>
      </div>
    );
  }
}

export default class RangeNextSlideInput extends NextSlideInput {

  constructor(props) {
    super(props);

    const rules = [];
    const answers = props.answers || [];

    answers.forEach((answer) => {
      if (answer.nextSlide) {
        rules.push({ ...answer });
      }
    });

    this.state = { showModal: false, rules: rules };
  }

  onRowChange(rule, idx) {
    const rules = [ ...this.state.rules ];
    rules[idx] = rule;
    this.setState({ rules });
  }

  onChange(e) {
    this.props.onChange(this.state.rules);
  }

  addRule(e) {
    const answer = e.target.value;
    const rules = this.state.rules;

    let min = this.props.min;
    const sortedRules = _.sortBy(rules, ({ handle }) => handle);

    sortedRules.forEach((rule) => {
      if (rule.handle == min) {
        ++min;
      }
    });

    rules.push({ nextSlide: this.props.slides[0]._id, handle: min });
    this.setState({ rules });
  }

  removeRow(idx) {
    const rules = [ ...this.state.rules ];
    rules.splice(idx, 1);

    this.setState({ rules });
  }

  removeAll() {
    this.setState({ rules: [] }, this.onChange.bind(this))
  }

  remove() {
    const rules = this.state.rules;
    rules.pop();

    this.setState({ rules });
  }

  renderModal() {

    let remove = null;
    if (this.state.rules.length !== 0) {
      remove = (
        <button className="negative" onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          this.closeModal();
          setTimeout(() => {
            this.removeAll();
          }, 100);
        }}>Remove All</button>
      );
    }

    let title = this.props.title || (<span>If answer is equal to <strong>{ this.props.value }</strong></span>);

    let answers = [];
    this.props.answers.forEach((answer) => {
      if (!_.find(this.state.rules, ({ handle }) => handle == answer.handle)) {
        answers.push(answer);
      }
    })

    let addRule = (
      <div className="action">
        <div
          className="new-rule"
          onClick={this.addRule.bind(this)}
        >Add branching logic</div>
      </div>
    );

    if (this.state.rules.length === this.props.answers.length) {
      addRule = null;
    }

    return (
      <Modal 
        isOpen={this.state.showModal}
        onRequestClose={this.closeModal.bind(this)}
      >
        <div className="frame large range">
          <div className="close" onClick={this.closeModal.bind(this)} />
          <div className="title">{this.state.rules.length !== 0 ? 'Edit' : 'Add'} Branching Logic</div>

          <div className="content">
            { this.state.rules.map((rule, idx) => {
              let match = _.find(this.props.answers, ({ handle }) => rule.handle == handle);
              return (<RuleRow
                { ...rule }
                key={rule.handle}
                idx={idx}
                answers={[ match, ...answers ]}
                slides={this.props.slides}
                removeRow={this.removeRow.bind(this)}
                onRowChange={this.onRowChange.bind(this)}
              />);
            })}
            { this.state.rules.length === 0 ? <div className="row empty">
              <div>No logic is added to this slide.<br/>Click <strong>Add branching logic</strong> to add branches to this slide.</div>
            </div> : null }
            { addRule }
          </div>

          <div className="actions">
            <button className="positive" onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.closeModal();
              setTimeout(() => {
                this.onChange()
              }, 100);
            }}>Apply</button>
            { remove }
            <button className="neutral" onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.closeModal();
            }}>Cancel</button>
          </div>
        </div>
      </Modal>
    );
  }

  renderLabel() {
    let label = (<div className="add-rules" onClick={this.openModal.bind(this)}>Add branching logic</div>);

    if (this.state.rules.length !== 0) {
      label = <div className="add-rules edit" onClick={this.openModal.bind(this)}>Edit branching logic</div>
    }

    return label;
  }
}