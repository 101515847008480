import React, { Component } from 'react';

import _ from 'lodash';

import { getTimestamp } from '../utils';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official'

import moment from 'moment';

export default class EventsTimelineChart extends Component {
  constructor(props) {
    super(props);

    this.data = this.prepareData(props.data);
    this.allowZeroPrinting = false;

    let marker = {};
    if (!this.props.smallDots) {
      marker = {
        symbol: 'circle',
        radius: 5,
        lineWidth: 2,
        lineColor: '#ff0000'
      }
    }

    const options = {
      title: {},
      chart: {
        type: 'spline',
        reflow: false,
        styledMode: true,
        minPointLength: 3,
        height: this.props.height || undefined,
      },
      time: {
        useUTC: false,
      },
      xAxis: {
        title: 'time',
        type: 'datetime',
        title: 'highcharts-axis-line',
        tickInterval: (props.isWeekly ? 1 : 7) * (24 * (60 * (60 * 1000))),
      },
      yAxis: {
        title: {
          text: ''
        },
      },
      legend: {
        enabled: false
      },
      tooltip: {
        useHTML: true,
        borderRadius: 10,
        formatter: function (val) {
          return `<div class="tooltip-wrapper">
            <div class="tooltip-header">${moment(this.point.x).format('MMM Do')}</div>
            <div class="tooltip-content"><b>Submissions</b>: ${this.point.y.toLocaleString()}</div>
          </div>`;
        }
      },
      plotOptions: {
        series: {
          marker
        }
      },
      series:[{
        name: 'Submission Count',
        animation: false,
        turboThreshold: 0,
        data: this.data,
        dataGrouping: {
          connectNulls: true,
          enabled: true,
          forced: true,
          approximation: function (groupData) {
            if (groupData.indexOf(0) !== -1) {
              this.allowZeroPrinting = true;
            }
            if (!this.allowZeroPrinting && groupData.length === 0) {
              return;
            }
            return _.sum(groupData) || 0;
          },
          units: [ [ 'day', [1] ] ],
        }
      }],
    };

    this.state = { options };
  }

  prepareData(data) {
    if (!data) { return; }
    if (data === "loading") { return; }

    const offset = new Date().getTimezoneOffset();

    data = data.map((d, idx) => {
      const time = getTimestamp(d);
      return { x: time, y: 1 }
    });

    const now = moment().valueOf();
    let before = moment().subtract(1, 'month').startOf('day').toDate();
    if (this.props.isWeekly) {
      before = moment().subtract(1, 'week').startOf('day').toDate();      
    }

    data.unshift({ x: before, y: 0 });
    data.push({ x: now, y: 0 });

    data = _.sortBy(data, "x");

    return data;
  }

  shouldComponentUpdate(nextProps) {
    if (!_.isEqual(nextProps.data, this.props.data)) {
      this.data = this.prepareData(nextProps.data)
      const state = { ...this.state };
      const series = [ ...state.options.series ];
      series[0].data = this.data;
      state.series = series;
      this.setState(state);
      return true;
    }
    return false;
  }

  render() {
    if (this.props.data === 'loading') {
      if (this.props.showLoading) {
        return <div className="loading"></div>
      }
      return null;
    }

    // if (!this.data) { 
    //   return null;
    // }

    return <div className={`${this.props.className}`}>
      { this.props.title }
      <div className="card highcharts-wrapper">
        <HighchartsReact
          highcharts={Highcharts}
          options={this.state.options}
          updateArgs={[true, false, false]}
        />
      </div>
    </div>
  }
}
