import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as PollActions from '../actions/Polls';

import HeaderVisibilityToggle from './HeaderVisibilityToggle';
import TopBarNotification from './TopBarNotification';

import BodyVisibilityToggle from './BodyVisibilityToggle';
import Toggle from './Toggle';
import Modal from './Modal';

import { decode } from '../utils';

class VisibilityToggle extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }

  componentDidMount() {
    if (!this.props.poll) { this.props.fetchPoll(); }
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    if (!this.props.poll) { return null; }

    let icon = 'fa-eye-slash';
    let status = 'hidden';
    if (this.props.poll.isArchived) {
      status = 'archived'
      icon = 'fa-archive';
    }

    if (this.props.poll.isVisible) {
      status = 'live';
    }

    let headerToggle = (
      <span>
        <p>{this.props.poll.isVisible ? <span><i className="fas fa-eye" /></span> : <span><i className="fas fa-eye-slash" /></span>}</p>

        <Toggle
          active={this.props.poll.isVisible}
          className="visibility-toggle"
          words={['Live', 'Hidden']}
          onChange={() => {
            if (!this.props.poll.isVisible) {
              if (this.props.poll.slides.length === 0) {
                this.setState({ showModal: true });
                return;
              }
            }
            this.props.toggleVisibility(this.props.poll);
          }}
        />

        <div className="tooltip">Toggle the visibility of your survey.</div>

        <Modal 
          isOpen={this.state.showModal}
          onRequestClose={this.closeModal.bind(this)}
        >
          <div className="frame">
            <div className="close" onClick={this.closeModal.bind(this)} />
            <div className="title">Sorry, you can't do that yet</div>
            <div className="content">
              <div className="subtitle">You need to add a slide to the survey before you can make it live.</div>
              <div className="actions">
                <button className="positive" onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.closeModal();
                }}>OK</button>
              </div>
            </div>
          </div>
        </Modal>
      </span>
    );

    if (this.props.poll.isArchived) {
      headerToggle = (
        <span>
          <p>Archived</p>

          <Toggle
            active={this.props.poll.isVisible}
            disabled={true}
            onChange={() => {
              // this.props.toggleVisibility(this.props.poll);
            }}
          />

        </span>
      );
    }



    let topBarNotification = (
      <TopBarNotification><div className="visibility-warning"><i className="fas fa-eye-slash" />This survey is currently hidden. Click <span onClick={() => this.props.toggleVisibility(this.props.poll)}>here</span> to make it visible.</div></TopBarNotification>
    );
    if (this.props.poll && this.props.poll.slides && this.props.poll.slides.length !== 0) {
      topBarNotification = (<TopBarNotification><div className="visibility-warning"><i className="fas fa-eye-slash" />This survey is currently hidden. Click <span onClick={() => this.props.toggleVisibility(this.props.poll)}>here</span> to make it visible or <a target="_blank" href={`/preview/${this.props.accountId}/${this.props.pollId}`}>click here to see a preview</a>.</div></TopBarNotification>);
    }
    if (this.props.poll.isArchived) {
      topBarNotification = (
      <TopBarNotification><div className="visibility-warning"><i className="fas fa-archive" />This survey is currently archived. Click the Manage tab to restore it.</div></TopBarNotification>
      );
    }

    if (this.props.poll.isVisible) {
      topBarNotification = null;
    }

    return (
      <div>
        <BodyVisibilityToggle>
          <div
            id="visibility-toggle"
            className={`${this.props.poll.isVisible ? '' : 'active'}`}
            title={`This survey is currently ${status}.`}
          >
            <i
              className={`fas ${icon}`}
            />
          </div>
        </BodyVisibilityToggle>

        { topBarNotification } 
        <HeaderVisibilityToggle>
          { headerToggle }
        </HeaderVisibilityToggle>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const pollId = decode(ownProps.match.params.pollId);

  const polls = state.polls || [];
  const poll = polls[pollId];

  return {
    accountId,
    pollId,
    polls,
    poll,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(PollActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VisibilityToggle));
