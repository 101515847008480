import React, { Component } from 'react';

import _ from 'lodash';

import CreatableSelect from 'react-select/creatable';

import { applyTheme } from '../utils'

class TagInputSelect extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      isMenuOpen: false,
      value: this.props.value || []
    };
  }

  onChange(selectedOptions) {
    let value;
    if (selectedOptions) {
      value = selectedOptions.map(({ value }) => value);
    }

    this.props.onChange(value);
  }

  render() {
    let list = this.props.value || [];

    if (list) {
      list = list.map((val) => ({ value: val, label: val }))
    }

    return (<span style={{ marginTop: 5, marginBottom: 5, display: 'block' }}>
      <CreatableSelect
        value={list}
        isMulti
        name="react-select"
        className={this.state.isMenuOpen ? 'react-select active' : 'react-select'}
        onMenuOpen={() => this.setState({ isMenuOpen: true })}
        onMenuClose={() => this.setState({ isMenuOpen: false })}
        theme={applyTheme}
        onChange={this.onChange.bind(this)}
      />
    </span>);
  }
}

export default class ResponseEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = { tags: this.props.tags }
  }

  render() {
    return (<div>
      <label>Tags</label>
      <TagInputSelect
        value={this.state.tags}
        onChange={(tags) => {
          this.setState({ tags });
          this.props.submitTags(tags, { _id: this.props.responseId });
        }}
      />
    </div>);
  }
}
