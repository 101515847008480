import { replace } from 'connected-react-router';
import { pushServerError } from './Notifications';
import { flash } from './FlashNotifications';

import { post } from '../ajax';
import { parseUrl } from '../utils';
import { url } from '../settings';

import { fetchAccount } from './Accounts';

export const FETCHING_ACTIVITIES = 'FETCHING_ACTIVITIES';
export const FETCHED_ACTIVITIES = 'FETCHED_ACTIVITIES';
export const UPDATING_ACTIVITY = 'UPDATING_ACTIVITY';
export const UPDATED_ACTIVITY = 'UPDATED_ACTIVITY';

function fetchingActivities(slideId) {
  return {
    type: FETCHING_ACTIVITIES,
    slideId
  };
}

function fetchedActivities(id, json) {
  return {
    type: FETCHED_ACTIVITIES,
    id,
    activities: json
  };
}

function updatingActivity(accountId, activityId) {
  return {
    type: UPDATING_ACTIVITY,
    activityId,
    accountId
  };
}

function updatedActivity(accountId, activityId, activity) {
  return {
    type: UPDATED_ACTIVITY,
    activityId,
    accountId,
    activity
  };
}

export function fetchAccountAndActivities(pollId, activityType, page = 0) {
  return (dispatch, getState) => {
    return dispatch(fetchAccount())
      .then(dispatch(fetchActivities(pollId, activityType, page)));
  }
}

export function fetchActivities(pollId, activityType, page = 0) {
  return (dispatch, getState) => {

    const [ accountId ] = parseUrl(getState().router);

    dispatch(fetchingActivities(accountId));
    return post(`${url}/account/${accountId}/activities/getPage/${page}`, { pollId, activityType })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(fetchedActivities(accountId, json));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function fetchPollActivities(pollId, page = 0) {
  return (dispatch, getState) => {

    const [ accountId ] = parseUrl(getState().router);

    dispatch(fetchingActivities(pollId));
    return post(`${url}/account/${accountId}/poll/${pollId}/activities/getPage/${page}`)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(fetchedActivities(pollId, json));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function resend(activityId) {
  return (dispatch, getState) => {

    const [ accountId ] = parseUrl(getState().router);

    dispatch(updatingActivity(accountId, activityId));
    return post(`${url}/account/${accountId}/resendFromActivity`, { activityId })
    .then(response => {
      // if (response.status === 404) {
      //   dispatch(replace('/404'));
      // }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(updatedActivity(accountId, activityId));
        dispatch(flash(json.error));
      } else {
        dispatch(flash('Survey has been sent successfully'));
        dispatch(updatedActivity(accountId, activityId, json.activity));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}
