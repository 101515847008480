import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

import _ from 'lodash';
import qs from 'query-string';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as PollActions from '../actions/Polls';
import * as DashboardActions from '../actions/Dashboard';

import Pagination from '../components/Pagination';

import { encode, decode, renderNumber, getTemplateFromDisplayRules, getVisibilitySettings, loadContactUsSurvey } from '../utils';

const pageSize = 13;

class PollSelector extends Component {
  constructor(props) {
    super(props);
    this.state = { hideHelpBlock: window.localStorage.getItem('zigpoll-hide-help-block') }
  }
  render() {
    const polls = this.props.polls || [];
    const page = polls.slice(pageSize * (this.props.page - 1), pageSize * (this.props.page));
    
    let activePoll;
    if (this.props.pollId) {
      activePoll = _.find(this.props.account.polls, (poll) => poll._id === this.props.pollId);
    }
    if (!activePoll) {
      activePoll = this.props.account.polls[0];
    }

    /* Somehow got on the wrong page, go back to 0 */
    if (page.length === 0 && this.props.polls.length) {
      this.props.setPage(0);
    }

    let helpBlock = null;
    if ((polls.length < 6) && !this.state.hideHelpBlock) {
      helpBlock = <div className="help-block">
        <div className="close" onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          window.localStorage.setItem('zigpoll-hide-help-block', true);
          this.setState({ hideHelpBlock: true });
        }}><i className="fas fa-close"></i></div>
        <i className='icon far fa-circle-question'></i>
        <p className="title">Need help getting the most out of Zigpoll?</p>
        <p>Check out our <a href="https://www.zigpoll.com/resources" target="_blank">resources index</a>, <a href="https://www.linkedin.com/in/jason-zigelbaum" target="_blank">connect to stay up to date</a>, and <a href="https://www.zigpoll.com/contact" target="_blank" onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          loadContactUsSurvey(this.props.user);
        }}>contact us</a> for tips, details, and solutions that are tailored to your business.</p>
      </div>
    }

    return (
      <div className="poll-selector-container">
        <div className="card-subtitle"><i className="fas fa-pie-chart" />Your Surveys <Pagination 
          showPagination={polls.length > pageSize}
          showPrev={this.props.page !== 1}
          showNext={(this.props.page * pageSize) < polls.length}
          next={() => this.props.setPage(this.props.page + 1)}
          prev={() => this.props.setPage(this.props.page - 1)}
          curPage={this.props.page}
          pageLength={Math.ceil(polls.length / pageSize)}
        /></div>

        <div className="poll-selector object-list">
          { page.map((poll) => {

            if (!poll || !poll.settings) { return null; }
        
            let isEmail = null;
            let isSms = null;
            if (poll.settings.shopifyWebhooks || poll.settings.shopifyAbandonedCheckout) {
              isEmail = true;
              if (poll.settings.sms) {
                isSms = true;
              }
            }

            let pollType = <i className="fas fa-poll" title="This a widget type survey." />
            let status = <span><i className="fas fa-eye-slash" title="This survey is hidden." /><div className="tooltip-content">This survey is hidden.</div></span>;
            if (poll.settings.selector) {
              pollType = <i className="fas fa-arrows-to-dot" title="This an embedded survey." />
            }
            if (poll.isVisible) {
              // status = <i className="fas fa-eye" title="This survey is visible." />;
              status = null;
            }
            if (poll.isArchived) {
              status = <span><i className="fas fa-archive" title="This survey is archived." /><div className="tooltip-content">This survey is archived.</div></span>;
            }

            const template = getTemplateFromDisplayRules(poll.pageRules, poll.pageExcludeRules, true);
            if (template) {
              pollType = <i className={`fas ${template.icon}`} title={template.title} />
            }
            if (poll.settings.apiOnly) {
              pollType = <i className="fas fa-link" title="This survey is only visiable via a link." />
            }
            if (poll.settings.apiOnly && poll.settings.pollType === 'api') {
              pollType = <i className="fas fa-code" title="This survey is only visiable via a link and via API calls." />
            }
            if (isEmail) {
              isEmail = <i className="fas fa-envelope" title="This survey is sent via email." />;      
              pollType = <i className="fas fa-envelope" title="This survey is sent via email." />
              if (isSms) {
                isEmail = <i className="fas fa-sms" title="This survey is sent via SMS." />;      
                pollType = <i className="fas fa-sms" title="This survey is sent via SMS." />
              }
            }

            const visibilitySettings = getVisibilitySettings(this.props.account, poll);
            if (visibilitySettings.renderOnExitIntent) {
              pollType = <i className="fas fa-person-walking-dashed-line-arrow-right" title="This survey is triggered via exit intent." />
            }

            let notifications = null;
            if (poll.hasNotifications) {
              notifications = <span className={`has-notifications ${ poll.hasNotifications ? 'active' : '' }`} ><span>{ renderNumber(poll.hasNotifications) }</span><div className="tooltip-content">{ renderNumber(poll.hasNotifications) } new notifications.</div></span>
            }

            let edit = <span><Link to={`/a/${encode(this.props.accountId)}/p/${encode(poll._id)}`}><i title="Edit survey" className="fas fa-edit"/></Link><div className="tooltip-content">Edit survey</div></span>

            let dashboard = <span><Link to={`/dashboard/a/${encode(this.props.accountId)}/p/${encode(poll._id)}`}><i title="View survey dashboard" className="fas fa-chart-simple"/></Link><div className="tooltip-content">View dashboard</div></span>

            return (
              <div key={poll._id} className={`${poll._id === activePoll._id ? 'active' : ''}`} onClick={(e) => this.props.setPollId(poll._id)}>
                <div className="poll-icons">{notifications}{status}{dashboard}{edit}</div>
                <div className="poll-title">{poll.title}</div>
                <div className="poll-type">{pollType}</div>
              </div>);
            }
          )}
        </div>

        { helpBlock }

        <Link className="create-poll" to={`/a/${encode(this.props.accountId)}/p/create`} ><i className="fas fa-plus" />New Survey</Link>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];

  const params = qs.parse(ownProps.location.search);
  const pollId = decode(params.id);
  const page = parseInt(params.page) || 1;

  return {
    accounts,
    accountId,
    account,
    polls: account.polls,
    user: state.user,
    page,
    pollId,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PollActions, ...DashboardActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PollSelector));
