import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as NotificationsActions from '../actions/Notifications';

class Notification extends Component {
  render() {
    return (
      <div className={`notification ${this.props.type}`}>
        { this.props.message.toString() }
        <button 
          className="close"
          onClick={this.props.close}
        />
      </div>
    );
  }
}

class Notifications extends Component {
  render() {
    let notification = null;
    let count = null;

    if (this.props.notifications.length) {
      notification = <Notification
        {...this.props.notifications[this.props.notifications.length-1]}
        close={this.props.popNotification.bind(this)}
      />
    }

    if (this.props.notifications.length > 1) {
      count = <div className="count">{`1 of ${this.props.notifications.length}`}</div>
    }

    return (
    <div id="notifications" className={this.props.notifications.length ? 'active' : ''}>
      { count }
      { notification }
    </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    notifications: state.notifications
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(NotificationsActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);

