import { 
  FETCHING_CHART_DATA,
  FETCHED_CHART_DATA,
} from '../actions/Charts';

import { merge } from '../utils';

export default function charts(state = {}, action) {
  switch (action.type) {
    case FETCHING_CHART_DATA: {
      if (state[action.key] === undefined) {
        state[action.key] = "loading";
      }
      if (action.reset) {
        state[action.key] = "loading";
      }
      return { ...state }
    }
    case FETCHED_CHART_DATA: {
      return { ...state, ...action.data }
    }
    default:
      return state;
  }
}
