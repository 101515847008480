import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import TargetingSettingsForm from './TargetingSettingsForm';

import * as AccountActions from '../actions/Accounts';
import * as FlashNotificationActions from '../actions/FlashNotifications';

class AccountVisibilitySettingsForm extends TargetingSettingsForm {
  onSubmit() {
    const account = {};
    account._id = this.props.account._id;
    account.targetingSettings = this.state.inputs.targetingSettings;

    this.props.updateAccount(account, "The targeting settings have been updated.");
  }
}

function mapStateToProps(state, ownProps) {
  const settings = ownProps.account.targetingSettings || {};

  return {
    settings,
    isAccountTargetingSettingsForm: true,
    loading: state.accounts.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions, ...FlashNotificationActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountVisibilitySettingsForm);
