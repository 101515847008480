import React, { Component } from 'react';

import Toggle from './Toggle';
import Tooltip from './Tooltip';
import InlineInput from './InlineInput';
import { AuthSubmitButton } from './Buttons';
import Switch from './Switch';
import HeaderButtons from './HeaderButtons';

export default class CharacterLimitInput extends Component {
  onChange(name, value) {
    const state = { min: this.props.min, max: this.props.max };

    state[name] = value.replace(/[^\d.-]/g,'');
    this.props.onChange(state);
  }

  render() {
    let inputs = null;
    let advancedInputs = null;

    if (this.props.active) {
      inputs = (
      <div>
        <InlineInput
          name="min"
          value={ this.props.min }
          prefix="Minimum of"
          suffix="characters"
          idx={0}
          onChange={this.onChange.bind(this)}
        />

        <InlineInput
          name="max"
          value={ this.props.max }
          prefix="Maximum of"
          suffix="characters"
          idx={1}
          onChange={this.onChange.bind(this)}
        />
      </div>
      );
    }

    return (<div
      className={`dynamic-render-input ${this.props.disabled || this.props.warning ? 'disabled' : ''}`}
      active={this.props.active}
    >
      <div style={{ position: 'relative', marginBottom: this.props.active ? 20 : 0 }}>
        <label>Character Limit</label>
        <div className="inline-toggle">
          <Toggle
            active={this.props.active}
            onChange={(value) => {
              if (!value) {
                this.props.onChange(false);
              } else {
                this.props.onChange({ min: 0, max: 0 });
              }
            }}
          />
          <div className="subtle">Enforce max and/or min character limits.</div>
        </div>
      </div>

      { inputs }
    </div>);
  }
}