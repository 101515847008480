import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LanguageSettingsForm from './LanguageSettingsForm';

import * as AccountActions from '../actions/Accounts';
import * as PollActions from '../actions/Polls';
import * as FlashNotificationActions from '../actions/FlashNotifications';

import { decode } from '../utils';

class AccountLanguageSettingsForm extends LanguageSettingsForm {

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions, ...PollActions, ...FlashNotificationActions }, dispatch);
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];
  const polls = state.polls || [];

  return {
    loading: state.accounts.loading,
    loadingKeys: state.accounts.loadingKeys || [],
    polls
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountLanguageSettingsForm));