import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import _ from 'lodash';
import moment from 'moment';

import qs from 'query-string';

import ColumnLoading from './ColumnLoading';

import ColumnLayout from '../components/ColumnLayout';
import Breadcrumbs from '../components/Breadcrumbs';
import SingleFormPageHeader from '../components/SingleFormPageHeader';
import SlideInfo from '../components/SlideInfo';
import Pagination from '../components/Pagination';
import Toggle from '../components/Toggle';
import SortBy from '../components/SortBy';
import HeaderButtons from '../components/HeaderButtons';
import ResponseEditForm from '../components/ResponseEditForm';

import * as ResponsesActions from '../actions/Responses';

import { decode, encode, getDate, getSlideLabel, renderParticipantId, renderNumber, truncate, getSlideIcon, uuid, scrollToTop, isUrl, getDateAsTimestamp } from '../utils';

class Slide extends Component {
  render() {
    const slide = this.props;

    return (<div className='poll-card slide-dashboard'>
      <div>
        <div className="poll-content">
          <SlideInfo slide={slide} diffs={this.props.diffs} hideLinks={true} accountId={this.props.accountId} pollId={this.props.pollId} />
        </div>
      </div>
    </div>)
  }
}

class RankInput extends Component {
  constructor(props) {
    super(props);
    this.state = { value: props.rank || 0 };
  }

  onChange(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.saved) { return; }

    this.setState({ value: e.target.value.replace(/[^\d.-]/g,'') })
    this.queueUpdate();
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    console.log('submitting');
  }

  markSaved() {
    this.setState({ saved: true });
    this.saveTimeout = setTimeout(() => {
      this.setState({ saved: false });
      this.saveTimeout = undefined;
    }, 500);
  }

  queueUpdate() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }

    this.timeout = setTimeout(() => {
      console.log('making change');
      console.log(this.state.value);

      this.props.setRank(this.props._id, this.state.value);

      this.markSaved();
      this.timeout = undefined;
    }, 1500);
  }

  render() {
    if (!this.props.showRankInput) {
      return null;
    }

    return <div className={`rank-input ${this.state.saved ? 'saved' : ''}`}>
      <form onSubmit={this.onSubmit.bind(this)}>
        <div>Rank:</div>

        <div>
          <span
          className="minus"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (this.state.saved) { return; }

            this.setState({ value: parseInt(this.state.value) - 1 });
            this.queueUpdate();
          }} />
          <input 
            type="text"
            onChange={this.onChange.bind(this)}
            value={this.state.saved ? '✓' : this.state.value}
            onBlur={this.queueUpdate.bind(this)}
          />
          <span
          className="plus"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (this.state.saved) { return; }

            this.setState({ value: parseInt(this.state.value) + 1 })
            this.queueUpdate();
          }} />
        </div>
      </form>
    </div>
  }
}

class Response extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let actions;
    if (this.props.showResponses) {
      actions = (
      <div className="actions">
        <div>
          <div className="inline-toggle">
            <Toggle
              active={this.props.approved}
              disabled={this.props.loading}
              mini={true}
              onChange={(value) => this.props.setApproval(this.props._id, value)}
            />
            <div className="subtle">Approved</div>
          </div>


          <div className="votes">{ renderNumber(this.props.voteCount) }</div>

          <RankInput {...this.props} key={this.props._id} />
        </div>
      </div>);
    }

    let href = null;
    if (this.props.event.href) {
      href = <div className="page"><a target='_blank' href={this.props.event.href}>{truncate(this.props.event.href, 40)}</a></div>
    }

    let response = (<div>{ this.props.value }</div>);
    if (this.props.event.responses && this.props.event.valueType === 'form-response') {
      response = (<div>
        { this.props.event.responses.map(({ label, value }) => {
          if (!value) { return null; }

          return (<div className="value" style={{ clear: 'both', paddingTop: 3 }}><strong style={{ fontWeight: 700 }}>{label}</strong>: {value}</div>);
        })}
      </div>)
    }

    if (this.props.type === 'date') {
      response = (<div>{ moment(this.props.value).format('MM/DD/YYYY') }</div>)
    }

    const metadata = this.props.event.metadata;
    let metadataOutput = null;

    if (metadata) {
      const keys = Object.keys(metadata);
      metadataOutput = (<div style={{ paddingBottom: 10, position: 'relative' }}>
      {/*<div className="heading-tag"><i className="fas fa-asterisk" />Metadata</div>*/}
      { keys.map((key, idx) => {
        let val = metadata[key];
        
        if (key === 'shopify_order_id' && this.props.account.shop) {
          val = <a target="_blank" rel="noopener noreferrer" href={`https://${this.props.account.shop}/admin/orders/${val}`}>{val}</a>
        } else if (key === 'shopify_customer_id' && this.props.account.shop) {
          val = <a target="_blank" rel="noopener noreferrer" href={`https://${this.props.account.shop}/admin/customers/${val}`}>{val}</a>
        } else {
          val = isUrl(val) ? <a href={val} title={val} target="_blank">{truncate(val, 40)}</a> : val;
        }

        const style = { marginBottom: 10 };

        if (['original-referrer', 'landing-page'].indexOf(key) !== -1) {
          style.wordBreak = 'break-all';
        }

        return (<div className="row" style={style}>
          <label>{ key }</label>
          <div>{ val }</div>
        </div>);
      }) }

      <div className="row">
        <label>Timestamp</label>
        <div>{getDateAsTimestamp(this.props.event._id)}</div>
      </div>

      <div className="row">
        <label>Participant</label>
        <div><Link to={`/participants/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(this.props.slideId)}/pa/${encode(this.props.participant._id)}`}>{this.props.participant.handle || renderParticipantId(this.props.participant.id, 20, true) }</Link></div>
      </div>

      </div>);
    }

    let metadataToggle = (
      <div className="show-more" onClick={() => {
      this.setState({ showDetails: !this.state.showDetails });
      }}>{ this.state.showDetails ? <span><i className="fas fa-minus" />Hide Details</span> : <span><i className="fas fa-plus"/>Show Details</span> }</div>
    );

    return (
      <div className="response no-padding" style={{ }}>

        <div style={{ marginBottom: 10 }}>{ response }</div>
        { actions }
        { metadataToggle }

        <div style={{ display: this.state.showDetails ? 'block' : 'none' }}>
          { metadataOutput }
        </div>

        <div style={{ display: this.state.showDetails ? 'block' : 'none' }}>
          <ResponseEditForm
            { ...this.props }
          />
        </div>

        {/*
        <div className="object-metadata">
          <div className="time">{getDate(this.props._id)}</div>

          { href }

          <div className="participant"><Link to={`/participants/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(this.props.slideId)}/pa/${encode(this.props.participant._id)}`}>{this.props.participant.handle || renderParticipantId(this.props.participant.id) }</Link></div>

        </div>
        */}
      </div>
    );
  }
}

class Responses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: props.sortBy
    };

    let page = 0;
    if (props.page) {
      page = props.page - 1;
    }

    props.fetchSlideAndResponses(page, props.sortBy);
  }

  onChange(value) {
    this.setState({ sortBy: value }, () => {
      this.props.fetchResponses(0, this.state.sortBy)
    });
  }

  export(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.exportResponses();
  }

  render() {
    if (!this.props.responses || !this.props.slide) {
      return <ColumnLoading />
    }

    let results;
    let header = null;
    let footer = null;

    let sortOptions = [
      { label: "Newest to Oldest", value: 'new-old' },
      { label: "Oldest to Newest", value: 'old-new' },
    ];

    if (this.props.slide.settings.showResponses) {
      sortOptions.push({ label: "Votes: Low to High", value: 'votes-low-high'});
      sortOptions.push({ label: "Votes: High to Low", value: 'votes-high-low'});
    }

    if (this.props.slide.settings.sortBy === 'manual') {
      sortOptions.push({ label: "Rank: High to Low", value: 'rank-high-low' });
      sortOptions.push({ label: "Rank: Low to HIgh", value: 'rank-low-high'})
    }

    if (this.props.responses.data.length) {
      header = (
        <div className="responses-header pages">
          <Pagination
            showPagination={this.props.responses.pages}
            showNext={this.props.responses.page !== this.props.responses.pages}
            showPrev={this.props.responses.page !== 0}
            next={() => { this.props.fetchResponses(this.props.responses.page + 1, this.state.sortBy); }}
            prev={() => { this.props.fetchResponses(this.props.responses.page - 1, this.state.sortBy); }}
            curPage={this.props.responses.page + 1 }
            pageLength={this.props.responses.pages + 1}
          />
          <SortBy
            onChange={this.onChange.bind(this)}
            value={this.state.sortBy}
            options={sortOptions}
          />
        </div>
      );

      let ids = [];
      this.props.responses.data.forEach(({ event }) => {
        if (event._id) {
          ids.push(event._id);
        } else {
          const id = uuid()
          event._id = id;
          ids.push(id);
        }
      });

      ids = _.uniq(ids);

      const responses = [];
      ids.forEach((eventId) => {
        const response = _.find(this.props.responses.data, ({ event }) => event._id === eventId);
        responses.push(response);
      });

      // const responses = this.props.responses.data.map((response, idx) => {
      //   let next = this.props.responses.data[idx+1];

      //   if (!next) { 
      //     return response;
      //   }

      //   if (!response.event._id) {
      //     return response;
      //   }

      //   if (response.event._id === next.event._id) {
      //     return null;
      //   }

      //   return response;
      // });

      results = (
        <div className={`object-list responses ${this.props.loading ? 'loading' : ''}`}>
          {responses.map((res) => {
            if (res === null) { return null; }
            return (<Response {...res} pollId={this.props.pollId} slideId={this.props.slideId} setApproval={this.props.setApproval} submitTags={this.props.submitTags} setRank={this.props.setRank} showResponses={this.props.slide.settings.showResponses} showRankInput={this.props.slide.settings.sortBy === 'manual'} key={res._id} />)
          })}
        </div>
      );
      footer = (
        <div className="responses-footer">
          <button
            onClick={this.export.bind(this)}
          >Download CSV</button>
        </div>
      );
    } else {
      results = (
        <div className="card empty-object-list responses">
          <h3>No Responses Yet</h3>
          <p>A list will show up here when people have responded.</p>
        </div>
      );
    }

    return (
      <ColumnLayout
        title={`Slide: Responses`}
        className="responses"
        graphics={true}
        // breadcrumbs={<Breadcrumbs currentPageTitle="Responses" includeDashboard={true} includeTail={true} />}
      >
        <Slide accountId={this.props.accountId} pollId={this.props.pollId} {...this.props.slide} diffs={this.props.diffs} />

        <div className="heading below" style={{marginTop: 20}}>
          <h5>Slide Responses</h5>
        </div>

        { header }

        { results }

        { footer }

        <HeaderButtons>
          <button
            onClick={this.export.bind(this)}
            disabled={this.props.responses.data.length ? false : true}
          >Download CSV</button>
        </HeaderButtons>
      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const pollId = decode(ownProps.match.params.pollId);
  const slideId = decode(ownProps.match.params.slideId);

  const allResponses = state.responses || [];
  const responses = allResponses[slideId];

  const params = qs.parse(ownProps.location.search);
  const page = parseInt(params.page) || 0;
  const sortBy = decode(params.sortBy);

  const slide = state.slides[slideId];

  return {
    pollId,
    slideId,
    accountId,
    responses,
    page,
    sortBy,
    slide,
    diffs: state.diffs,
    loading: state.responses.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ResponsesActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Responses));
