import React, { Component } from 'react';

import Layout from '../components/Layout';
import LifetimeUserCreateForm from '../components/LifetimeUserCreateForm';
import SingleFormPageHeader from '../components/SingleFormPageHeader';

class CreateAccount extends Component {
  render() {
    return (
      <Layout title="Sign Up" graphics={true}>
        <SingleFormPageHeader
          title="Redeem Rebeliance Code."
          subtitle="Fill out the form to redeem your lifetime deal."
        />
        <LifetimeUserCreateForm />
      </Layout>
    );
  }
}

export default CreateAccount;
