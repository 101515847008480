import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import _ from 'lodash';
import Papa from 'papaparse'

import * as PollActions from '../actions/Polls';
import * as FlashNotificationActions from '../actions/FlashNotifications';

import { AuthSubmitButton } from './Buttons';
import Toggle from './Toggle';
import Confirm from './Confirm';
import Modal from './Modal';
import Tooltip from './Tooltip';
import Switch from './Switch';
import DateInput from './DateInput';
import NumberInput from './NumberInput';

import CreatableSelect from 'react-select/creatable';

import HeaderButtons from './HeaderButtons';
import AuthForm from './AuthForm';
import SectionHeader from './SectionHeader';
import EmailSettingsVisualDisplay from './EmailSettingsVisualDisplay';

import { validateEmail, encode, shopifyCustomerTargetingEmailOptions } from '../utils';
import { defaultEmailDisplaySettings } from '../settings';

import PublicPollLinkCard from '../components/PublicPollLinkCard';
import CSVUploader from '../components/CSVUploader';

const applyTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#2167f5",
    danger: "#F26c57",
  }
});

class TargetingRow extends Component {
  render() {
    const [ value, operator, inputVal ] = this.props.row;
    const selectedOption = _.find(shopifyCustomerTargetingEmailOptions, (option) => option.value === value);

    let operators;
    let inputType = null;
    if (selectedOption) {
      inputType = selectedOption.inputType;
      operators = <div className="select-wrapper"><select value={operator} onChange={(e) => { this.props.onChange(e.target.value, 1, this.props.rowIdx) }}>
      { selectedOption.operators.map((operator) => <option value={operator.value}>{operator.name}</option>) }
      </select></div>
    }

    let input = null;

    if (value) {
      input = <input type="text" value={inputVal} onChange={(e) => { this.props.onChange(e.target.value, 2, this.props.rowIdx) }} />;

      if (inputType === 'number') {
        input = <NumberInput
          value={inputVal}
          onChange={(value) => { 
            console.log('onchange');
            console.log(value);
            this.props.onChange(value, 2, this.props.rowIdx) }} />
      }
      if (inputType === 'date') {
        input = <DateInput
          type="date"
          value={inputVal}
          onChange={(value) => { console.log('on change'); console.log(value); this.props.onChange(value, 2, this.props.rowIdx) }} />
      }
      if (inputType === 'tags') {
        let list = [];
        if (inputVal) {
          list = inputVal.split(',').map((val) => ({ label: val, value: val }));
        }

        input = <CreatableSelect
          value={list}
          isMulti
          name="react-select"
          placeholder="Add tags..."
          className={true ? 'react-select active' : 'react-select'}
          // onMenuOpen={() => this.setState({ isMenuOpen: true })}
          // onMenuClose={() => this.setState({ isMenuOpen: false })}
          theme={applyTheme}
          components={{ 
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null
           }}
          onChange={(value) => {
            let val = value;
            if (value) {
              val = value.map(({ value }) => value).join(',');
            }
            this.props.onChange(val, 2, this.props.rowIdx);
          }}
        />
      }
    }

    return (
    <div className="targeting-row small">
      {/*<div>{ value }{ operator }{ inputVal }</div>*/}

      <div className="select-wrapper"><select value={value} onChange={(e) => {
        const value = e.target.value;
        const selectedOption = _.find(shopifyCustomerTargetingEmailOptions, (option) => option.value === value);
        this.props.onChange(value, 0, this.props.rowIdx, selectedOption.operators[0].value);
      }}>
        <option disabled="disabled" selected={true}>Select</option>
        { shopifyCustomerTargetingEmailOptions.map((option) =>
        <option value={option.value}>{ option.name }</option>
        )}
      </select></div>

      { operators }

      { input }

      { value ? <div className="remove" onClick={() => this.props.removeRow(this.props.rowIdx)} ></div> : null }
    </div>);
  }
}

class ShopifyCustomerTargetingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nextTopInput: false
    };
  }

  removeRow(rowIdx) {
    let updates = [ ...this.props.shopifyCustomerTargeting ];
    const section = [ ...updates ];
    section.splice(rowIdx, 1);

    if (section.length === 0) {
      this.setState({ nextTopInput: true });
    }

    updates = section;
    this.props.onChange(updates);
  }

  onChange(value, updateIdx, rowIdx, operator) { 
    const updates = [ ...this.props.shopifyCustomerTargeting ];
    const isFirst = rowIdx === undefined;

    if (isFirst) {
      this.setState({ nextTopInput: false });
      updates.push([]);
      rowIdx = updates.length - 1;
    }

    const val = updates[rowIdx];
    val[updateIdx] = value;

    /* Operator, value, and input move in lock step */
    if (operator) {
      val[1] = operator;
      val[2] = undefined;
    }

    updates[rowIdx] = val;


    this.props.onChange(updates);
  }

  render() {
    let top = this.props.shopifyCustomerTargeting;

    return (
      <div className="targeting-form" style={{ marginBottom: 25, marginTop: 25 }}>
        { top.map((row, idx) => <TargetingRow key={idx} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={row} rowIdx={idx} /> )}
        { this.state.nextTopInput || top.length === 0 ? <TargetingRow key={-1} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={[]} isLast={true}/> : null }
        { this.state.nextTopInput ? null : <div className="or-button" onClick={() => this.setState({ nextTopInput: true })}>AND</div> }
      </div>
    );
  }
}


export class EmailRecipientsInput extends Component {
  onChange(e) {
    this.props.onChange({ target: { name: 'recipients', value: e.target.value } });
  }

  validate() {
    return true;
  }

  reset() {
    return true;
  }

  render() {
    return <div className="input emails-component">
        <label>Email Recipients</label>
        <div className="subtle">Seperate each email address with a new line.</div>

        <div className="row" style={{ marginTop: 10, marginBottom: 0 }}>
          <textarea
            style={{ minHeight: 200 }}
            value={this.props.value}
            onChange={this.onChange.bind(this)}
          />
        </div>
    </div>;
  }
}

function isAllowed (account) {
  const { plan } = account;
  let reject = ['lite'];
  if (account.shop) {
    reject = [];
  }
  if (plan.allowEmailCampaigns) { return true; }
  if (reject.indexOf(plan.key) !== -1) { return false; }
  return true;
}

class PollEditForm extends AuthForm {
  constructor(props)  {
    super(props);

    this.state = { 
      inputs: {
        recipients: '',
      },
      validatedRecipients: [],
      valid: undefined,
      showModal: false,
      position: 'left',
      shopifyCustomerTargeting: [],
    };
    this.inputStates = {};
    this.setInitialState();
  }

  validateCsv() {
    const csv = [ ...this.state.inputs.csv ];
    const header = csv.shift();
    const data = csv;

    if (header.indexOf('email') === -1) {
      this.props.flash("Please include an email column.");
      return false;
    }

    if (data.length === 0) {
      this.props.flash("Please include at least one row.");
      return false;
    }

    // if (data.length > 1000) {
    //   this.props.flash("You cannot email more than 1K people at once.");
    //   return;
    // }

    if (!isAllowed(this.props.account)) {
      this.props.flash('Upgrade to a basic plan or higher to send email campaigns.');
      return;
    }

    this.setState({ csvLength: data.length });

    return true;
  }

  submitAction() {
    if (!this.props.poll.isVisible) {
      this.props.flash("Your survey must be visible before sending.");
      return;
    }

    if (this.state.inputs.csv) {
      if (this.validateCsv()) {
        this.setState({ showCsvModal: true });
        return;
      } else {
        return;
      }
    }

    if (this.state.position === 'center') {
      this.setState({ showLoadingSegmentModal: true });
      this.props.generateShopifyCustomerSegment(this.state.shopifyCustomerTargeting).then((customerCount) => {
        this.setState({ showSegmentModal: true, customerCount, showLoadingSegmentModal: false });
      })
      return;
    }

    let recipients = this.state.inputs.recipients.replace(/,/g, '\n').split('\n')
    recipients = _.uniq(recipients);

    const validatedRecipients = [];
    recipients = recipients.map((email) => {
      if (validateEmail(email)) {
        validatedRecipients.push(email);
      }
    });

    if (validatedRecipients.length === 0) {
      this.props.flash("Please enter at least one valid email address.");
      return;
    }

    // if (validatedRecipients.length > 1000) {
    //   this.props.flash("You cannot email more than 1K people at once.");
    //   return;
    // }

    if (!isAllowed(this.props.account)) {
      this.props.flash('Upgrade to a basic plan or higher to send email campaigns.');
      return;
    }

    this.setState({ validatedRecipients, showModal: true });
    this.setState({ showModal: true })
  }

  onCsvConfirm() {
    console.log('running csv import path');
    this.props.runCsvEmailCampaign(this.state.inputs.csv);

    setTimeout(() => {
      const state = { ...this.state };
      state.inputs["csv"] = undefined;
      state.file = undefined;
      this.setState(state);
    }, 500);
  }

  onSegmentConfirm() {
    const { shopifyCustomerTargeting } = this.state;

    this.props.runShopifySegmentEmailCampaign(shopifyCustomerTargeting);

    setTimeout(() => {
      const state = { ...this.state };
      state.shopifyCustomerTargetin = undefined;
      state.customerCount = undefined;
      this.setState(state);
    }, 500);
  }

  onConfirm() {
    this.props.runEmailCampaign(this.state.validatedRecipients);

    setTimeout(() => {
      this.setState({ validatedRecipients: [] });
    }, 500);
  }

  renderInputs() {
    let emailDisplaySettings = this.props.poll.emailDisplaySettings || this.props.account.emailDisplaySettings;

    if (!emailDisplaySettings) {
      emailDisplaySettings = defaultEmailDisplaySettings;
    }

    let emailDisplayLink = `/a/${encode(this.props.accountId)}?tab=design&type=email`;

    if (this.props.poll.emailDisplaySettings) {
      emailDisplayLink = `/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}?tab=design&type=email`
    }

    const isShopify = this.props.account.shop === undefined ? false : true;

    return (
      <div className="account-display poll-email-form">
        <SectionHeader
          title=<span><i className="fas fa-at" />Email Settings</span>
          subtitle="Enter some email addresses to run an email campaign."
          bottom={true}
          className="no-margin center"
        />

        <div className="split email-settings">
          <div>
            <form 
              onSubmit={this.onSubmit.bind(this)}
              className={`account-settings ${this.props.loading ? 'loading' : ''}`}
            >

              <div className={`small-switch ${isShopify ? 'thirds' : 'half' } ${this.state.position || 'left'}`} style={{marginBottom: 10}}>
                <div 
                  onClick={() => {
                    this.setState({ position: 'left' });
                  }}
                  className={`${this.state.position === 'left' ? 'active' : ''}`}>Manual</div>
                { isShopify && <div 
                  onClick={() => {
                    this.setState({ position: 'center' });
                  }}
                  className={`${this.state.position === 'center' ? 'active' : ''}`}>Auto Segment</div> }
                <div 
                  onClick={() => {
                    this.setState({ position: 'right' });
                  }}
                  className={`${this.state.position === 'right' ? 'active' : ''}`}>CSV Upload</div>
              </div>

              <div className="card" style={{ marginBottom: 25, display: this.state.position === 'left' ? 'block' : 'none'}}>
                <EmailRecipientsInput
                  onChange={this.onChange.bind(this)}
                  onValidate={this.onValidate.bind(this)}
                  value={this.state.inputs.recipients}
                />
                <div className={`actions active`}>
                  <AuthSubmitButton 
                    title="Send Emails"
                  />
                </div>
              </div>

              <div className="card" style={{ marginBottom: 25, display: this.state.position === 'center' ? 'block' : 'none' }}>
                <div style={{}}>
                  <label>Email Recipients</label>
                  <div className="subtle">Fill out the form to create a segment from your customers.</div>

                  <ShopifyCustomerTargetingForm
                    shopifyCustomerTargeting={this.state.shopifyCustomerTargeting}
                    onChange={(value) => {
                      console.log(value);

                      // const settings = this.state.inputs.settings;
                      // settings.shopifyCustomerTargeting = value;            
                      this.setState({ shopifyCustomerTargeting: value });
                    }}
                  />

                  <div className="blue-info-link" style={{ textAlign: 'left' }}>
                    <a href="https://www.zigpoll.com/blog/email-shopify-customer-segment" target="_blank">Learn more about how to segment customers.</a>
                  </div>
                </div>

                <div className={`actions active`}>
                  <AuthSubmitButton 
                    title="Send Emails"
                  />
                </div>
              </div>

              <div className="card" style={{ marginBottom: 25, display: this.state.position === 'right' ? 'block' : 'none' }}>
                <div style={{}}>
                  <label>Email Recipients</label>
                  <div style={{ marginBottom: 20 }} className="subtle">Upload a CSV of recipients.</div>
                  <CSVUploader
                    file={this.state.file}
                    onChange={(file) => {
                      if (!file) {
                        const state = { ...this.state };
                        state.file = undefined;
                        state.inputs["csv"] = undefined;
                        this.setState(state);
                        return;
                      }

                      Papa.parse(file, {
                        skipEmptyLines: true,
                        error: (err, file, inputElem, reason) => {
                          console.log(err);
                        },
                        complete: ({ data }) => {
                          const state = { ...this.state };
                          state.inputs["csv"] = data;
                          state.file = file;
                          this.setState(state);
                        }
                      });
                    }}
                  />
                  <div className="blue-info-link">
                    <a href="https://www.zigpoll.com/blog/email-campaign-csv-formatting" target="_blank">Learn more about how to format your CSV.</a>
                  </div>
                </div>
                <div className={`actions active`}>
                  <AuthSubmitButton 
                    title="Send Emails"
                  />
                </div>
              </div>
            </form>
          </div>

          <div style={{ width: '50%' }}>
            <EmailSettingsVisualDisplay
              poll={this.props.poll}
              {...emailDisplaySettings}
            />

            <div className="card info wide full center" style={{ marginTop: 10 }}>
              <div className="top">Want to change the way your email looks?</div>
              <p>Go to <Link to={emailDisplayLink}>email design settings</Link>.</p>
            </div>

          </div>

          <Confirm 
            title="Are you sure?"
            subtitle={`This will send an email to ${this.state.validatedRecipients.length === 1 ? this.state.validatedRecipients.length +' person' : this.state.validatedRecipients.length + ' people'}. Should we send it?`}
            show={this.state.showModal}
            onConfirm={this.onConfirm.bind(this)}
            onCancel={() => {
              this.setState({ showModal: false });
            }}
          />
        </div>

        <Modal 
          isOpen={this.state.showLoadingSegmentModal}
          onRequestClose={() => { }}
        >
          <div className="frame">
            <div className="title">Loading Segment...</div>
            <div className="content">
              <div className="subtitle">Loading your customer segment.</div>
              <div className="copy"><strong>Just a moment please...</strong></div>
            </div>
          </div>
        </Modal>

        <Confirm 
          title="Are you sure?"
          subtitle={`This will send an email to ${this.state.csvLength === 1 ? this.state.csvLength +' person' : this.state.csvLength + ' people'}. Should we send it?`}
          show={this.state.showCsvModal}
          onConfirm={this.onCsvConfirm.bind(this)}
          onCancel={() => {
            this.setState({ showCsvModal: false });
          }}
        />

        <Confirm 
          title="Are you sure?"
          subtitle={`This will send an email to ${this.state.customerCount === 1 ? this.state.customerCount +' customer' : this.state.customerCount + ' customers'}. Should we send it?`}
          show={this.state.showSegmentModal}
          onConfirm={this.onSegmentConfirm.bind(this)}
          onCancel={() => {
            this.setState({ showSegmentModal: false });
          }}
        />
      </div>
    );
  }

  render() {
    return (
      <form onSubmit={this.onSubmit.bind(this)} className={`${this.state.valid === false ? 'invalid' : ''} ${this.state.loading || this.props.loading ? 'loading' : ''} ${this.props.className}`}>
          { this.renderInputs() }
          <HeaderButtons>
            <button 
              onClick={this.onSubmit.bind(this)}>Send Emails</button>
          </HeaderButtons>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.polls.loading,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PollActions, ...FlashNotificationActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PollEditForm);

