import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import RangeNextSlideInput from './RangeNextSlideInput';

import _ from 'lodash';

import * as FlashNotificationsActions from '../actions/FlashNotifications';

const numbers = [2,3,4,5,6,7,8,9,10];

class RangeInput extends Component {
  constructor(props) {
    super(props);


    this.state = {
      min: props.min === undefined ? 0 : props.min,
      max: props.max || 100,
      step: props.step || 1
    };

    if (
      props.min === undefined ||
      props.max === undefined ||
      props.step === undefined) {
      this.update();
    }
  }

  onMinChange(e) {
    this.setState({ min: e.target.value }, this.update.bind(this))
  }

  onMaxChange(e) {
    this.setState({ max: e.target.value }, this.update.bind(this))
  }

  onStepChange(e) {
    this.setState({ step: e.target.value }, this.update.bind(this))
  }

  update() {
    const answers = [];
    const min = +this.state.min;
    const max = +this.state.max;
    const step = +this.state.step;

    if (min === undefined || max === undefined) {
      return;
    }

    if (min >= max) {
      return;
    }

    if (!step) {
      return;
    }

    if (step <= 0) {
      return;
    }

    if (step >= (max - min)) {
      return;
    }

    // answers.push({ title: min.toString(), handle: min.toString() });

    // for (var i = (step); i <= max; i+= step) {
    //   answers.push({ title: i.toString(), handle: i.toString() });
    // }

    // if (answers[answers.length-1].handle != max) {
    //   answers.push({ title: max.toString(), handle: max.toString() });
    // }

    for (var i = min; i <= max; i+= step) {
      answers.push({ title: i.toString(), handle: i.toString() });
    }

    if (answers.length > 300) {
      return this.props.flash('Please chose a smaller range or a larger increment.');
    }

    this.props.onChange({ target: { name: 'answers', value: answers } })
  }

  render() {
    return (
      <div className="slider-input-box" style={{ marginBottom: 10 }}>
        <div className="input unchanged">
          <div>
            Values range from <input
              value={this.state.min}
              name="min"
              onChange={this.onMinChange.bind(this)}
            /> to <input
              value={this.state.max}
              name="max"
              onChange={this.onMaxChange.bind(this)}
            /> in increments of <input
              value={this.state.step}
              name="step"
              onChange={this.onStepChange.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(FlashNotificationsActions, dispatch);
}

export default connect(null, mapDispatchToProps, null, { withRef: true })(RangeInput);
