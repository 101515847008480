import React, { Component } from 'react';

import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const MapComponent = withScriptjs(withGoogleMap((props) =>
<GoogleMap
  defaultZoom={12}
  defaultCenter={{ lat: props.latitude, lng: props.longitude }}
  options={{
    panControl: false,
    scrollwheel: false,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    gestureHandling: 'greedy'
  }}
>
  <Marker position={{ lat: props.latitude, lng: props.longitude }} />
</GoogleMap>));

MapComponent.defaultProps = {
  googleMapURL:"https://maps.googleapis.com/maps/api/js?key=AIzaSyA0L1zXBjPFlqnBMegJ9hinU4q8ypzDcx0",
  loadingElement: <div style={{ height: `200px` }} />,
  containerElement: <div style={{ height: `200px` }} />,
  mapElement: <div style={{ height: `100%` }} />,
}

export default MapComponent;