import { 
  FETCHING_INSIGHT_DATA,
  FETCHED_INSIGHT_DATA,
} from '../actions/Insights';

export default function insights(state = {}, action) {
  switch (action.type) {
    case FETCHING_INSIGHT_DATA: {
      if (state[action.key] === undefined) {
        state[action.key] = [];
      }
      state[action.key].push({ content: '...', type: 'loading', loading: true, query: action.query });
      return { ...state }
    }
    case FETCHED_INSIGHT_DATA: {
      let insights = state[action.slideId] || [];
      insights.pop();
      insights.push(action.insight);
      state[action.slideId] = insights;
      return { ...state }
    }
    default:
      return state;
  }
}
