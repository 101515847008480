import React, { Component } from 'react';

import { renderNumber } from '../utils';

export default class Diff extends Component {
  render() {
    const value = this.props.value;
    if (!value) { return null; }

    return (<span className="diff" title={value}>{renderNumber(value)}</span>)
  }
}
