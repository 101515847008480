import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ColumnLoading from './ColumnLoading';

import ColumnLayout from '../components/ColumnLayout';
import Breadcrumbs from '../components/Breadcrumbs';
import SectionHeader from '../components/SectionHeader';
import Pagination from '../components/Pagination';
import Participant from '../components/Participant';
import HeaderButtons from '../components/HeaderButtons';

import Toggle from '../components/Toggle';
import Tooltip from '../components/Tooltip';
import ExportCsvModal from '../components/ExportCsvModal'

import * as ParticipantsActions from '../actions/Participants';

import { decode, encode, getDate, renderParticipantId } from '../utils';

class Participants extends Component {
  constructor(props) {
    super(props);
    this.state = { showExportModal: false }
    props.fetchPollAndParticipants();
  }

  export() {
    this.setState({ showExportModal: true });
    // this.props.exportPollParticipants();
  }

  render() {
    if (!this.props.participants) {
      return <ColumnLoading />
    }

    let results = null;
    if (this.props.participants.data.length) {
      results = (<span>
        <div className="responses-header" style={{ marginBottom: 15 }}>
          <button
            className="download-csv"
            onClick={this.export.bind(this)}
          >Download CSV</button>
          <Pagination
            showPagination={this.props.participants.pages}
            showNext={this.props.participants.page !== this.props.participants.pages}
            showPrev={this.props.participants.page !== 0}
            next={() => { this.props.fetchParticipantsForPoll(this.props.participants.page + 1); }}
            prev={() => { this.props.fetchParticipantsForPoll(this.props.participants.page - 1); }}
            curPage={this.props.participants.page + 1 }
            pageLength={this.props.participants.pages + 1}
          />
        </div>

        <div className={`card object-list emails ${this.props.loading ? 'loading' : ''}`}>
          {this.props.participants.data.map((p) => <Participant url={`/participants/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/pa/${encode(p._id)}`} accountId={this.props.accountId} {...p} />)}
        </div>
      </span>);
    } else {
      results = (
        <div className="card empty-object-list emails">
          <h3>No Participants Yet</h3>
          <p>A list will show up here once someone has interacted with this survey.</p>
        </div>
      );
    }

    return (
      <ColumnLayout
        title={`Survey → Participants`}
        className="participants"
        graphics={true}
      >


        <SectionHeader
          title=<span><i className="fas fa-users" />Survey Participants</span>
          subtitle="All the people who responded to this survey."
          className="no-margin"
        />

        { results }

        <div className="card info wide center" style={{ marginTop: 20 }}>
          <div className="top">Want to view all participants?</div>
          <p>For more data, <Link to={`/participants/a/${encode(this.props.accountId)}`}>View All Participants</Link> or <Link to={`/reports/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}`}>Generate Some Reports</Link>.</p></div>

        <HeaderButtons>
          <button
            onClick={this.export.bind(this)}
            disabled={this.props.participants.data.length ? false : true}
          >Download CSV</button>
        </HeaderButtons>

        <ExportCsvModal
          show={this.state.showExportModal}
          onCancel={() => this.setState({ showExportModal: false })}
          onConfirm={({ email, responsePollId, dateRange }) => {
            this.props.exportPollParticipants(email, responsePollId, dateRange);
          }}
          pollId={this.props.pollId}
          title={'Export Survey Participants'}
          subtitle={'This will export all participants who have ever interacted this survey.'}
          copy={'The CSV will be sent to the email listed below. It can take up to 1 hour to recieve a CSV export. The duration will depend on the amount of data your account has accumulated.'}
          email={this.props.user.email}
        />
      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const pollId = decode(ownProps.match.params.pollId);

  const allParticipants = state.participants || [];
  const participants = allParticipants[pollId];

  return {
    user: state.user,
    accountId,
    pollId,
    participants,
    loading: state.participants.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ParticipantsActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Participants));
