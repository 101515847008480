import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import TargetingSettingsForm from './TargetingSettingsForm';

import * as PollActions from '../actions/Polls';
import * as FlashNotificationActions from '../actions/FlashNotifications';

class PollTargetingSettingsForm extends TargetingSettingsForm {
  onSubmit() {
    const poll = {};
    poll._id = this.props.poll._id;
    poll.targetingSettings = this.state.inputs.targetingSettings;

    this.props.updatePoll(poll, 'The targeting settings have been updated.');
  }
}


function mapStateToProps(state, ownProps) {
  const settings = ownProps.poll.targetingSettings || ownProps.account.targetingSettings;

  let pollType = 'widget';
  if (ownProps.poll.settings.selector) {
    pollType = 'embed';
  }

  return {
    settings,
    pollType,
    loading: state.polls.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PollActions, ...FlashNotificationActions}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PollTargetingSettingsForm);
