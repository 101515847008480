import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as AccountActions from '../actions/Accounts';

import ColumnLoading from './ColumnLoading';

import ColumnLayout from '../components/ColumnLayout';
import SectionHeader from '../components/SectionHeader';

import ActivePolls from '../components/ActivePolls';

import { decode, getTab, capitalize } from '../utils';

class Polls extends Component {
  constructor(props) {
    super(props);

    if (!this.props.account && this.props.accountId) {
      this.props.fetchAccount(this.props.accountId);
    } else {
      
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.accountId !== prevProps.accountId) {
      this.props.fetchAccount();
    }
  }

  render() {
    if (!this.props.account) {
      return (
        <ColumnLoading />
      );
    }

    return (
      <ColumnLayout
        title="Your Saved Surveys"
        className="account"
      >
        <SectionHeader
          title="Active Surveys"
          subtitle="Drag and drop to reorder."
          tooltip="The rules for the survey at the top will take precendence over the ones below."
          className="no-margin"
          bottom={true}
        />

        <ActivePolls accountId={this.props.accountId} account={this.props.account} />

      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];
  const user = state.user;

  return {
    accountId,
    accounts,
    account,
    user,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(AccountActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Polls));
