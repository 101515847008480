/* User Reducer */
import _ from 'lodash';

import { 
  CREATED_POLL,
  CREATING_POLL,
  UPDATED_POLL,
  UPDATING_POLL,
  FETCHING_POLL,
  FETCHED_POLL,
  DELETING_POLL,
  DELETED_POLL,
  DUPLICATED_POLL,
  DUPLICATING_POLL,
  ARCHIVING_POLL,
  ARCHIVED_POLL,
  UNARCHIVING_POLL,
  UNARCHIVED_POLL,
} from '../actions/Polls';

import {
  CREATED_SLIDE,
  UPDATED_SLIDE,
  DELETED_SLIDE
} from '../actions/Slides';

export default function polls(state = {}, action) {
  let poll;

  switch (action.type) {
    case CREATING_POLL:
    case UPDATING_POLL:
    case FETCHING_POLL:
    case DELETING_POLL:
    case ARCHIVING_POLL:
    case UNARCHIVING_POLL:
    case DUPLICATING_POLL:
      return { ...state, loading: true }
    case CREATED_POLL:
    case UPDATED_POLL:
    case FETCHED_POLL:
    case ARCHIVED_POLL:
    case UNARCHIVED_POLL:
    case DUPLICATED_POLL:
      poll = {};
      poll[action.poll._id] = action.poll;
      return { ...state, ...poll, loading: false }
    case DELETED_POLL:
      const polls = { ...state };
      delete polls[action.pollId];

      return { ...polls, loading: false }
    case CREATED_SLIDE:
    case UPDATED_SLIDE:
      poll = state[action.pollId];
      if (!poll) { return { ...state } }

      let slides = poll.slides;
      const slide = action.slide;
      const idx = _.findIndex(slides, o => o._id === action.slide._id);

      if (idx === -1) {
        if (action.idx !== undefined) {
          slides.splice(action.idx, 0, slide);
          slides = _.uniq(slides);
        } else {
          slides.push(slide);
        }
      } else {
        slides[idx] = slide;
      }

      return { ...state };
    case DELETED_SLIDE:
      poll = state[action.pollId];
      if (!poll) { return { ...state } }

      poll.slides = _.remove(poll.slides, o => o._id !== action.slideId);

      return { ...state }
    default:
      return state;
  }
}
