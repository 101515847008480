import React, { Component } from 'react';

import Layout from '../components/Layout';

class Loading extends Component {
  render() {
    return (
      <Layout title="Loading" />
    );
  }
}

export default Loading;
